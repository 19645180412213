export const attendingVisitSGroups = [
    "visit_read",
        "visit_read_employees_attending_visit",
            "employee_attending_visit_read_employee",
                 "employee_read_id",
                 "employee_read_full_name",
                 "employee_read_is_in_lounge",
                 "employee_read_is_eating",
        "visit_read_flight",
            "flight_read",
            "flight_read_airline",
                "airline_read",
        "visit_read_check_ins",
            "check_in_read",
        "visit_read_guest",
            "guest_read_id",
            "guest_read_name",
            "guest_read_full_name",
        "visit_read_tables",
            "table_read",
            "lounge_map_item_read",
        "visit_read_seats",
            "seat_read",
            "lounge_map_item_read"
];
