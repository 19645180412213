import React from 'react';
import './CheckInInfo.scss';
import CheckInInfoCard from "./CheckInInfoCard/CheckInInfoCard";


const CheckInInfo = ({checkIns, onCheckInSelectIndex, selectedCheckInId,checkInTiming}) => {
    return (
        <div className="CheckInInfo">
            <div className="CheckInInfo-title">
                <div className="CheckInInfo-accessMethod CheckInInfo-column">
                    <p>Método de acceso</p>
                </div>
                <div className="CheckInInfo-time CheckInInfo-column">
                    <p>Hora de Entrada</p>
                </div>
                <div className="CheckInInfo-status CheckInInfo-column">
                    <p>Status</p>
                </div>
                <div className="CheckInInfo-numberCompanions CheckInInfo-column">
                    <p>Acompañantes</p>
                </div>
            </div>
            <div className="CheckInInfo-checkInInfoCard">
                {checkIns.map((checkIn, index)=>
                    <CheckInInfoCard
                        key={checkIn.id}
                        checkIn={checkIn}
                        checkInTiming={checkInTiming}
                        onSelect={()=>onCheckInSelectIndex(index)}
                        selected={selectedCheckInId===checkIn.id}
                    />
                )}
            </div>
        </div>
    );
};
export default CheckInInfo;
