import React from 'react';
import {ReactComponent as USAFlag} from "../../../../assets/images/icons/usaFlag.svg";
import {ReactComponent as EUFlag} from "../../../../assets/images/icons/euFlag.svg";
import {ReactComponent as MXNFlag} from "../../../../assets/images/icons/mexicoFlag.svg";
import './TopBarExchangeRate.scss';
import useExchangeRates from "../../../../hooks/useExchangeRates";
import {moneyFormatter} from "../../../../services/currency/currencyUtils";

const TopBarExchangeRate=()=>{

    const [exchangeRates]=useExchangeRates();

    const usd = exchangeRates && exchangeRates.USD? moneyFormatter(exchangeRates.USD, 1):'-';
    const eur = exchangeRates && exchangeRates.EUR? moneyFormatter(exchangeRates.EUR, 1):'-';

    const mxnCheckInCost = exchangeRates && exchangeRates.USD?moneyFormatter(exchangeRates.USD*30, 1):'-';
    return (
        <div className='TopBarExchangeRate'>
            <p><USAFlag/> <span>$ {usd}</span></p>
            <p><EUFlag/> <span>$ {eur}</span></p>
            <hr/>
            <p><MXNFlag/> <span>$ {mxnCheckInCost}</span></p>
        </div>
    );
};

export default TopBarExchangeRate;
