import React, {useMemo, useCallback, useEffect, useContext} from 'react';
import classNames from "classnames";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import Select from "react-select";
import reactSelectStyles from "../../../../assets/styles/react-select-styles";
import useCallbackCreator from "use-callback-creator";
import './SingleCheckInForm.scss';
import {flattenLoungeAccessMethodsTree,onlyPossiblesReCheckIn} from "../../../../services/modelUtils/loungeAccessMethodsUtils";
import CheckInExtraFieldsForm from "./CheckInExtraFieldsForm";
import {ApiContext} from "../../../../services/api/api-config";

/**
 * Componente selector de los metodos de acceso
 *
 */
function SingleCheckInForm({checkIn, onChange, isReCheckIn})
{

    const api = useContext(ApiContext);

    /**
     * Funcion de seleccion de metodo de acceso
     */
    const handleChange=useCallbackCreator((key, value)=>{

        const newCheckIn={...checkIn, [key]:value}

        if(key==='loungeAccessMethod')
        {
            delete newCheckIn.extraFields;
        }
        onChange(newCheckIn);

    },[checkIn, onChange]);

    /**
     * Seleccionamos el numero de adultos por cada checkIn seleccionado
     */
    const adultsNumberOptions = useMemo(()=> {

        if(!checkIn || !checkIn.loungeAccessMethod || !Number(checkIn.loungeAccessMethod.maxAdults))
        {
            return [];
        }

        return Array(Number(checkIn.loungeAccessMethod.maxAdults)+1).fill('').map((_, i) => ({value: i+1, label: i}))

    },[checkIn]);

    /**
     * Seleccionamos el numero de niños para cada CheckIn
     */
  const kidsNumberOptions = useMemo(()=> {
        if(!checkIn || !checkIn.loungeAccessMethod || !Number(checkIn.loungeAccessMethod.maxKids))
        {
            return [];
        }

        return Array(Number(checkIn.loungeAccessMethod.maxKids)+1).fill('').map((_, i) => ({value: i, label: i}))

    },[checkIn]);

    const selectedAdults=useMemo(()=>({value:checkIn.adultsNumber, label: checkIn.adultsNumber-1}), [checkIn.adultsNumber]);

  // ----------  Handle companions count changes ----------
    const handleAdultsChange=useCallback(({value})=>{
        const newCheckIn={...checkIn, adultsNumber:Number(value)};
        onChange(newCheckIn);
    },[checkIn, onChange]);




    const handleKidsChange=useCallback(({value})=>{
        const newCheckIn={...checkIn, kidsNumber:Number(value)};

        if(newCheckIn.kidsNumber){
            newCheckIn.seatKidNumber = Array(newCheckIn.kidsNumber).fill({seat:''});
            newCheckIn.kidCompanions=Array(newCheckIn.kidsNumber).fill({name:''});
        }
        else
            delete newCheckIn.kidCompanions;

        onChange(newCheckIn);
    },[checkIn, onChange]);

    const selectedKids=useMemo(()=>({value:checkIn.kidsNumber, label: checkIn.kidsNumber}), [checkIn.kidsNumber]);

    // --------  Handle re-check in pre-fill --------
    useEffect(()=>{
        if(!isReCheckIn?.loungeAccessMethod?.id) return;
        // Load full details of old check in Lounge access method
        api.loungeAccessMethods.get( { params:{id:isReCheckIn.loungeAccessMethod.id}, customProp:'_' } )
            .then( lams=>{
                if(lams?.length !== 1) return;
                onChange({...checkIn, loungeAccessMethod: lams[0], extraFields: isReCheckIn.extraFields});
            });
        //Do not add checkIn to inputs because it would prevent it from changing.
        // eslint-disable-next-line
    },[isReCheckIn, onChange]);

    // --------  Extra fields -------------

    return (
        <div className='SingleCheckInForm'>

            <div className="form-row">
                <div className={classNames('form-column full-width')}>
                    <p>Método de acceso:</p>
                    <TideEntitySelect
                        entity='loungeAccessMethods'
                        value={checkIn.loungeAccessMethod}
                        onChange={handleChange('loungeAccessMethod')}
                        additionalFilters={loungeAccessMethodFilters}
                        apiCustomProp={'SingleCheckInLoungeAccessMethods'}
                        filterEntities={isReCheckIn? onlyPossiblesReCheckIn: flattenLoungeAccessMethodsTree}
                        filterLocal
                    />
                </div>
            </div>

            <div className="form-row">
                {!!adultsNumberOptions.length &&
                <div className="form-column half-width">
                        <p>Acompañantes:</p>
                        <Select
                            styles={reactSelectStyles}
                            options={adultsNumberOptions}
                            placeholder={'0'}
                            onChange={handleAdultsChange}
                            value={selectedAdults}
                        />
                </div>}

                {!!kidsNumberOptions.length &&
                <div className="form-column half-width">
                    <p>Niños:</p>
                    <Select
                        styles={reactSelectStyles}
                        options={kidsNumberOptions}
                        placeholder={'0'}
                        onChange={handleKidsChange}
                        value={selectedKids}
                    />
                </div>}
            </div>

            <CheckInExtraFieldsForm checkIn={checkIn} onChange={onChange}/>

        </div>
    );
}

export default SingleCheckInForm;

const loungeAccessMethodFilters={'order[name]':'ASC', isActive:true, 'loungeParentAccessMethod[exists]':false};
