import React,{useContext, useState, useRef, useCallback, useEffect} from 'react';
import './FixedMenu.scss';
import getAppRoutes, {paths} from "../../services/routes/appRoutes";
import {Link} from "react-router-dom";
import {SecurityContext} from "../../services/SecurityManager";
import classNames from 'classnames';
import {ApiContext} from "../../services/api/api-config";

const REFRESH_ALARM_TIMEOUT = 30000; // 30s

const FixedMenu = ({className, bottomBar, reload}) => {
    const api = useContext(ApiContext);
    const securityManager=useContext(SecurityContext);
    const routes=getAppRoutes(securityManager);
    const [hasAlerts, setHasAlerts] = useState(false);

    const classes = classNames(
        'FixedMenu',
        className,
        bottomBar&&'bottomBar'
    );
    
    const loadVisitsCountTimeOutRef = useRef(0);
    const loadVisitsCount = useCallback(() => {
        clearTimeout(loadVisitsCountTimeOutRef.current);

        api.personsWithoutSeatCount.getPersonsWithoutSeatCount()
        .then((res) => {
            setHasAlerts(res>0);
            loadVisitsCountTimeOutRef.current = setTimeout(loadVisitsCount, REFRESH_ALARM_TIMEOUT);
        }).catch(() => {
            setHasAlerts(false);
            loadVisitsCountTimeOutRef.current = setTimeout(loadVisitsCount, REFRESH_ALARM_TIMEOUT);
        })

    }, [api, setHasAlerts]);

    useEffect(() => {
        loadVisitsCount();
        
        return ()=>{
            clearTimeout(loadVisitsCountTimeOutRef.current);
        }
    }, [loadVisitsCount, reload]);

    const iconClases = classNames(
        'FixedMenu-iconWrapper',
        hasAlerts&&'red-alert'
    );
    
    return (
        <div className={classes}>
            {
            routes.map(route=> route.hidden?null:
                <Link className="FixedMenu-iconWrapper" to={route.path} key={route.path}>
                    <div className={route.path===paths.alarms ? iconClases : 'FixedMenu-iconWrapper'}>
                        {route.icon}
                    </div>
                </Link>
                )
            }
        </div>
    )
};
export default FixedMenu
