import {employeeFullName} from "./employeeUtils";
import moment from 'moment';
import {prepareVisitForServer} from "./visitUtils";
import {serverDateTimeFormat} from "../timeUtils";
import {copyPropertyIdOrThrow} from "../formUtils";
import _ from "lodash";

export const reservationStatus={
    REQUESTED:'requested',
    COMPLETED:'completed',
    CANCELED:'canceled'
};

export const nearReservationsApiConfig = ({filter}={}) => {
    const from=moment().subtract(100,'minutes').format('YYYY-MM-DD HH:mm:ss');//De 100 minutos atrás
    const to=moment().add(24,'hours').format('YYYY-MM-DD HH:mm:ss');//Hasta 24 horas hacia adelante
    return {
        params:{
            pageSize:20,
            'reservationDate[after]':from,
            'reservationDate[before]':to,
            'order[reservationDate]':'ASC',
            status:reservationStatus.REQUESTED,
            'visit[exists]': false,
            sGroups:nearReservationsSGroups,
            search:filter?filter.trim():undefined
        }
    };
};

export const REMOTE_RESERVATION_SOURCE = "remote-davinci";

export const createReservationFromVisitForm = ( form )=>{
    if(!form)
        throw new Error('Error desconocido. Forma de visita no encontrada.');

    const reservation = prepareVisitForServer(form);

    copyPropertyIdOrThrow(form, reservation, 'lounge', 'Debes seleccionar la sala');
    reservation.checkInsData = form.checkIns.map( checkIn=>({...checkIn, loungeAccessMethod: checkIn.loungeAccessMethod.id}) );
    reservation.loungeAccessMethods = reservation.checkIns.map(checkIn=>checkIn.loungeAccessMethod);
    reservation.reservationDate = (form.reservationDate || moment()).format(serverDateTimeFormat);
    reservation.personsNumber = reservation.checkIns.reduce( (persons, checkIn)=>persons+checkIn.adultsNumber, 0);
    reservation.email = reservation.guest?.email;
    reservation.source = REMOTE_RESERVATION_SOURCE;

    if(form?.seats?.length)
        reservation.seats = _.map(form.seats, "id");

    delete reservation.checkIns;

    return reservation;
};

export const getGuestName=(reservation)=>{
    if(reservation.guest)
        return employeeFullName(reservation.guest);
    return `${reservation.name} ${reservation.lastNames?reservation.lastNames:(reservation.patLastName?reservation.patLastName:'')}`;
};

export const nearReservationsSGroups=[
    'reservation_read',
    'flight_read',
    'airline_read_name',
    'guest_read',
        'country_read',
    'reservation_read_lounge_access_methods',
        'lounge_access_method_read_access_time',
        'lounge_access_method_read_max_adults',
        'lounge_access_method_read_max_kids',
        'lounge_access_method_read_config',
    'reservation_read_seats',
        'lounge_map_item_read',
    'reservation_read_created_by',
        'user_read_employee',
            'employee_read_full_name'
];

export const mapReservationsSGroups=[
    'reservation_read',
    'guest_read',
    'reservation_read_seats',
        'seat_read',
        'lounge_map_item_read',
];
