import _ from 'lodash';
export const isPricedProduct=(orozcoProduct)=>{
    if(!orozcoProduct || !orozcoProduct.prices || !orozcoProduct.prices.length)
        return false;
    return !!orozcoProduct.prices.reduce((acc, price)=>acc+price.amount, 0);
};

export const isPricedProductButCanBeFree=(orozcoProduct)=>{
    return !!isPricedProduct(orozcoProduct) && orozcoProduct.canBeFreeMeal;
};

export const isPricedProductAndShouldBePayed=(orozcoProduct)=>{
    return !!isPricedProduct(orozcoProduct) && !orozcoProduct.canBeFreeMeal;
};

export const getAvailableCurrencies=(orozcoProducts)=>{

    if(!orozcoProducts || !orozcoProducts.length)
        return [];

    return orozcoProducts.reduce((acc, product)=>{
        if(!product || !product.prices || !product.prices.length )
            return acc;
        let newAcc=acc;
        _.forEach(product.prices, (price)=>{
            newAcc=_.union(newAcc, [price.currency]);
        });

        return newAcc;
    },[]);
};

export const OROZCO_PRODUCT_SPECIAL_ID = "OrozcoProduct-special";

export const extraChargeModalSGroups=[
    'orozco_product_read',
    'orozco_product_read_prices'
];