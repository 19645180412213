import React from 'react';
import './LoadingBar.scss';
import {useSelector} from "react-redux";

const LoadingBar=()=>{
    const loading=useSelector(({loading})=>!!loading);
    if(loading)
        return <div className='LoadingBar' />;
    return null;
};

export default LoadingBar;
