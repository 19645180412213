import React, {useMemo, useEffect, useCallback, useContext, useState} from 'react';
import Modal from "../../../../../components/Modal/Modal";
import Select from "react-select";
import reactSelectStyles from "../../../../../assets/styles/react-select-styles";
import {useSelector} from "react-redux";
import {flattenServices} from "../../../../../services/modelUtils/serviceUtils";
import {ApiContext} from "../../../../../services/api/api-config";
import './AddServiceModal.scss';
import Button from "../../../../../components/Button/Button";
import {getNotifier} from "../../../../../services/notifierUtils";
import _ from 'lodash';


const loadingId='@AddServiceModal.visit.update';
const AddServiceModal = ({visit, onClose, onChange})=>{

    const api=useContext(ApiContext);
    const notifier=getNotifier();

    const availableServices = useSelector(({api})=>api.services||[]);
    const servicesOptions = flattenServices(availableServices).map(s=>({
        ...s,
        label: s.name
    }));

    const [selectedService, setSelectedService]=useState(null);

    const scheduleService = useCallback(()=>{
        if(!selectedService){
            notifier.error('Selecciona un servicio');
            return;
        }

        api.serviceVisits.create({
            params: {
                service: selectedService.id,
                visit: visit.id
            },
            loadingId
        }).then(()=>{
            setSelectedService(null);
            onChange && onChange();
            onClose && onClose();
        });
    }, [api, visit, notifier, onChange, selectedService, onClose]);

    const limitRequested = useMemo(()=>{
        if(!selectedService){
            return 0;
        }
        if(selectedService.onePerPerson===true){
            return _.filter(visit.serviceVisits, s=>s.service.id===selectedService.id).length;
        }
        const onePerPersonServices = selectedService.onePerPersonServices;
        if(!onePerPersonServices){
            return null;
        }
        return _.filter(visit.serviceVisits, s=>_.includes(onePerPersonServices, s.service.id)).length;

    }, [selectedService, visit.serviceVisits]);

    const loading=useSelector(({loadingIds})=>loadingIds[loadingId]);

    const limitReached = limitRequested>=visit.activePersons;
    const saveDisabled = loading || limitReached;
    useEffect(()=>{
        if(limitReached){
            getNotifier().error(`La visita ya usó los servicios disponibles que tenía de esta categoría (${limitRequested})`);
        }
    }, [limitReached, limitRequested]);

    return <Modal
        className={'AddServiceModal'}
        onClose={onClose}
        title={'Agregar nuevo servicio'}
    >
        <div className="selectBox full-width">
            <div className="selectBox-services full-width">
                <Select
                    styles={reactSelectStyles}
                    options={servicesOptions}
                    placeholder={"Agenda un servicio..."}
                    value={selectedService}
                    onChange={setSelectedService}
                />
            </div>
            <div className='buttons'>
                <Button onClick={onClose} disabled={loading}>Cancelar</Button>
                <Button blue onClick={scheduleService} disabled={saveDisabled}>{loading?'Guardando...':'Guardar'}</Button>
            </div>
        </div>
    </Modal>
};

export default AddServiceModal;
