import {useState, useContext, useEffect, useMemo} from 'react';
import {ApiContext} from '../services/api/api-config';
import {useSelector} from 'react-redux';

/**
 *
 * @returns {[{MXN: number, EUR: number, USD: number}, boolean]}
 */
export default ()=>{
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(()=>{
        setLoading(true);
        api.currencyExchanges.get({params:{'order[date]':'DESC'}})
            .catch(()=>setError(true))
            .finally(()=>setLoading(false));
    }, [api]);
    const exchangeRates = useSelector(({api})=>api.currencyExchanges);
    const rates = useMemo(()=>{
        const latestCurrencies = {};
        if(exchangeRates)
            for(let  i=0; i<exchangeRates.length; i++){
                const curr = exchangeRates[i].currency;
                if(!latestCurrencies[curr])
                    latestCurrencies[curr] = exchangeRates[i].avgValue;
            }
        return {
            ...latestCurrencies,
            MXN: 1
        };
    }, [exchangeRates]);

    return [rates, loading, error];//[Rates array, loading, error];
}
