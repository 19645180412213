import React, {useCallback, useContext, useMemo} from 'react';
import PaymentFormOverlay from "./PaymentFormOverlay/PaymentFormOverlay";
import {ApiContext} from "../../services/api/api-config";
import _ from 'lodash';
import {getAvailableCurrencies} from "../../services/modelUtils/orozcoProductUtils";
import {
    getPrinterIdByKey,
    getPrinterIdByPrintArea,
    localStorageFrontDeskPrinterKey
} from "../../services/modelUtils/orozcoTicketPrinterUtils";
import {getNotifier} from "../../services/notifierUtils";
import {updateProductsPaymentsSGroups} from "../../services/modelUtils/orozcoVisitOrderUtils";

const OrderPaymentModal = ({onClose, visit, onPay}) => {

    const api = useContext(ApiContext);

    const paidOrderProducts = useMemo(() => {
        return (
            visit.orozcoVisitOrder.orozcoVisitOrderProducts
            .filter((orderProduct) =>
                !orderProduct.payed && orderProduct.shouldBePayed && !orderProduct.canceledDate
            )
        );
    }, [visit.orozcoVisitOrder.orozcoVisitOrderProducts]);

    const productsForPaymentModal = paidOrderProducts.map(orderProduct => ({
        name: orderProduct.orozcoProduct.name,
        prices: orderProduct.orozcoProduct.prices || [{amount: 0, currency: 'MXN'}],
        count: orderProduct.quantity,
        fullProductData: orderProduct
    }));

    const handlePayment = useCallback((check) => {

        //Set "payed" flag of the paid orderProducts
        const newPayedProducts = [];
        const orozcoVisitOrderProducts = visit.orozcoVisitOrder.orozcoVisitOrderProducts.map(
            (orderProduct) => {
                const paidProduct = _.find(paidOrderProducts, paidOrderProduct => paidOrderProduct.id === orderProduct.id);
                if (paidProduct) {
                    if (!paidProduct.isPayed)
                        newPayedProducts.push(paidProduct);
                    return {id: paidProduct.id};
                    //El back ahora se encarga de marcar el producto como pagado
                    //return {id: paidProduct.id, payed: true};
                } else
                    return orderProduct.id;
            }
        );

        const oldPayments = visit.orozcoVisitOrder.orozcoVisitOrderPayments ?
            _.map(visit.orozcoVisitOrder.orozcoVisitOrderPayments, 'id') : [];

        const newPayments = check.payments.map(payment => ({...payment, orozcoVisitOrder: visit.orozcoVisitOrder.id}));

        //Todo: imprimir en cocina los nuevos platillos
        const orozcoVisitOrder = {
            id: visit.orozcoVisitOrder.id,
            orozcoVisitOrderPayments: [...oldPayments, ...newPayments],
            orozcoVisitOrderProducts,
            sGroups: updateProductsPaymentsSGroups
        };

        api.orozcoVisitOrders.update({id: visit.orozcoVisitOrder.id, params: orozcoVisitOrder})
            .then((vo) => {

                const frontDeskPrinterId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
                if(!frontDeskPrinterId){
                    getNotifier().error('La impresora de recepción no se encuentra configurada');
                }
                const newProductsIds = _.map(newPayedProducts, 'id');

                const newPaymentsIds = _.difference(
                    _.map(vo.orozcoVisitOrderPayments, 'id'),
                    oldPayments
                );

                //Impresión en recepción
                api.orozcoPrints.create({
                    params: {
                        ticketPrinter: frontDeskPrinterId,
                        orozcoVisitOrderProducts: newProductsIds,
                        orozcoVisitOrderPayments: newPaymentsIds,
                    }
                });

                //Impresión en cocina/bar

                const prints = _.groupBy(newPayedProducts, 'orozcoProduct.printArea');

                _.each(prints, (nonPayedOrozcoProducts, printArea)=> {
                    const printerId = getPrinterIdByPrintArea(printArea);
                    if(!printerId){
                        getNotifier().error('La impresora de '+printArea+' no se encuentra configurada');
                        return;
                    }

                    api.orozcoPrints.create({
                        params: {
                            ticketPrinter: getPrinterIdByPrintArea(printArea),
                            orozcoVisitOrderProducts: _.map(nonPayedOrozcoProducts,(prod)=>prod.id)
                        }
                    }).then(()=>getNotifier().success('Productos impresos en '+printArea))
                });


                onPay();
            })
            .catch(onClose)
    }, [paidOrderProducts, visit, api, onPay, onClose]);

    const availableCurrencies = useMemo(() => getAvailableCurrencies(_.map(paidOrderProducts, 'orozcoProduct')), [paidOrderProducts]);

    return (
        <PaymentFormOverlay
            availableCurrencies={availableCurrencies}
            onClose={onClose}
            guest={visit.guest}
            products={productsForPaymentModal}
            onPay={handlePayment}
        />
    )
};

export default OrderPaymentModal;
