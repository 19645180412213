import React,{useContext, useCallback} from 'react';
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button/Button";
import './CloseRegisterModal.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import CashRegisterMoneyList from "../CashRegisterMoneyList/CashRegisterMoneyList";
import {ApiContext} from "../../../../services/api/api-config";
import moment from 'moment';
import {getNotifier} from "../../../../services/notifierUtils";
import {useSelector} from "react-redux";
import {getActualShiftForClosing} from "../../../../services/modelUtils/cashRegisterUtils";


const loadingId='@CloseRegisterModal.orozcoCashClosings';
const CloseRegisterModal=({onClose, closeDetails, cashRegister, onSave})=>{

    const api=useContext(ApiContext);
    const loading=!!useSelector(({loadingIds})=>loadingIds[loadingId]);

    const closeCashRegister = useCallback(() => {
        const cashClosing = {
            endDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
            orozcoCashRegister: cashRegister.id,
            shift: getActualShiftForClosing()
        };
        api.orozcoCashClosings.create({
            params: cashClosing,
            loadingId: loadingId,
        }).then((cashClosing) => {
            getNotifier().success('Caja cerrada');
            onSave(cashClosing);
        });
    }, [api, cashRegister, onSave]);

    return (
        <Modal onClose={onClose} title='Cerrar Caja' className='CloseRegisterModal'>
            <div className={'icons-container'}>
                <FontAwesomeIcon icon={faCashRegister} className='icon'/>
                <FontAwesomeIcon icon={faSignOutAlt} className='icon'/>
            </div>
            <p className='message'>Retira el dinero de la caja y cuéntalo muy bien.</p>
            <p className='message'>Esta acción no puede deshacerse.</p>
            <div className='amounts'>
                <CashRegisterMoneyList details={closeDetails && closeDetails.details} />
            </div>

            <Button main onClick={onClose} disabled={loading}>Cancelar</Button>
            <Button blue onClick={closeCashRegister} disabled={loading}>{loading?'Cerrando...':'Cerrar caja'}</Button>
        </Modal>
    );
};

export default CloseRegisterModal;
