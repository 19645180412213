import React,{useMemo, useEffect, useCallback, useState, useRef,useLayoutEffect} from 'react';
import './FullscreenDatePicker.scss';
import {ReactComponent as AngleLeft} from '../../assets/images/icons/angle-left.svg';
import moment from "moment";
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator/src";
import classNames from 'classnames';
import FullscreenPicker from "../FullscreenPicker/FullscreenPicker";


const FullscreenDatePicker = ({onHide, value, onChange,isInEditableSection, yearsToShow=10})=>{

    const [yearBlockStart, setYearBlockStart]=useState(1980);

    useEffect(()=>{
        if(value)
            setYearBlockStart(Math.floor(value.year()/yearsToShow)*yearsToShow);
    },[value, yearsToShow]);

    useLayoutEffect(()=>{
        firstYearRef.current.focus();
    },[]);
    //Handle selection
    const handleYearSelect=useCallback(({target})=>{
        const year=Number(target.dataset.value);
        if(value)
            onChange(moment(value).year(year));
        else
            onChange(moment(`${year}-01-01`));
        //Focus the first month buttons. It's in a timeout because we may no have the ref
        setTimeout(()=>{
            firstMonthRef.current && firstMonthRef.current.focus();
        },100);
    },[onChange, value]);

    const handleMonthSelect=useCallback(({target})=> {
        const month = Number(target.dataset.value);
        onChange(moment(value).month(month));
        firstDayRef.current.focus();
    },[onChange, value]);

    const handleDateSelect=useCallback(({target})=>{
        const date=Number(target.dataset.value);
        onChange(moment(value).date(date));
        onHide();
    },[onChange, value, onHide]);

    //Handle year block change
    const handleBlockChange=useCallbackCreator((dir)=>{
        dir=dir==='next'?1:-1;
        setYearBlockStart(yearBlockStart + (dir*yearsToShow));
    },[setYearBlockStart, yearBlockStart, yearsToShow]);

    //Create buttons
    const firstYearRef=useRef(null);
    const firstMonthRef=useRef(null);
    const firstDayRef=useRef(null);

    const yearButtons=useMemo(()=>{
        const buttons=[];
        const selected=value? Number(value.year()):'';
        for(let i=yearBlockStart; i<(yearBlockStart+yearsToShow); i++)
            buttons.push(<button
                key={i}
                ref={i===yearBlockStart?firstYearRef:undefined}
                className={classNames('picker-btn', selected===i?'selected':'')}
                onClick={handleYearSelect}
                data-value={i}>
                {i}</button>);
        return buttons;
    },[handleYearSelect, yearBlockStart, yearsToShow, value]);

    const monthButtons=useMemo(()=>{
        if(!value) return [];

        const selected=value? Number(value.month()):'';
        return moment.months().map((month, i)=>
            <button
                key={i}
                ref={i===0?firstMonthRef:undefined}
                data-value={i}
                className={classNames('picker-btn', selected===i?'selected':'')}
                onClick={handleMonthSelect}>
                {_.capitalize(month)}</button>
        )},[value, handleMonthSelect]);

    const dateButtons=useMemo(()=>{
        const buttons=[];
        if(!value) return  buttons;

        const selected=value.date();
        for(let i=0; i<value.daysInMonth(); i++)
            buttons.push(<button
                key={i}
                ref={i===0?firstDayRef:undefined}
                className={classNames('picker-btn', selected===i+1?'selected':'')}
                onClick={handleDateSelect}
                data-value={i+1}>
                {i+1}</button>);
        return buttons;
    },[value, handleDateSelect]);


    return (
        <FullscreenPicker onHide={onHide} className='FullscreenDatePicker'>
            <div className='part-container year-picker-container'>
                <div className='change-decade-block'>
                    <button onClick={handleBlockChange('back')}>
                        <AngleLeft className='year-arrow back'/>
                        <span>{yearBlockStart-yearsToShow}&nbsp;-&nbsp;{yearBlockStart-1}</span>
                    </button>
                </div>
                <div className='years-block'>
                        {yearButtons}
                </div>
                <div className='change-decade-block'>
                    <button onClick={handleBlockChange('next')}>
                        <span>{yearBlockStart+yearsToShow}&nbsp;-&nbsp;{yearBlockStart+yearsToShow-1}</span>
                        <AngleLeft className='year-arrow next'/>
                    </button>
                </div>
            </div>
            {value&&<>
            <div className='part-container month-picker-container animated slideInUp faster'>
                <div className='month-buttons-wrapper'>
                    {monthButtons}
                </div>
            </div>
            <div className='part-container date-picker-container animated slideInUp faster'>
                <div className='date-buttons-wrapper'>
                    {dateButtons}
                </div>
            </div>
            </>}
        </FullscreenPicker>
    );

};

export default FullscreenDatePicker;
