import React from 'react';
import './MenuAmountSelect.scss';
import className from 'classnames';


const MenuAmountSelect = ({quantity})=>{

    return(
        <div className={className("MenuAmountSelect",quantity?'openMenuAmountSelect':'')}>
            <div className="MenuAmountSelect-text">
                <p>{quantity}</p>
            </div>
        </div>
    )
};
export default MenuAmountSelect;
