import React from 'react';
import {copyPropertyIdOrNull, copyPropertyOrNull} from '../formUtils';
import {getAvailableCurrencies} from "./loungeAccessMethodsUtils";
import _ from 'lodash';
import {preparePaymentForServer} from "./paymentUtils";
import moment from "moment";
import {getProperty} from "../jsUtils";

export const emptyCheckIn = () => ({
    loungeAccessMethod: null,
    adultsNumber: 1,
    kidsNumber: 0,
    localId: Math.random(),
    captureTime: 0,
    isScanned: false
});

export const checkInFromLoungeAccessMethod = loungeAccessMethod => ({
    ...emptyCheckIn(),
    loungeAccessMethod
});


export const prepareCheckInForServer = (rawCheckIn, seconds) => {


    if (!rawCheckIn.loungeAccessMethod)
        throw new Error('El check in debe tener un método de acceso seleccionado.');

    const checkIn = {};
    if (rawCheckIn.id)
        checkIn.id = rawCheckIn.id;

    if (typeof rawCheckIn.loungeAccessMethod === 'object')
        checkIn.loungeAccessMethod = rawCheckIn.loungeAccessMethod.id;
    else
        checkIn.loungeAccessMethod = rawCheckIn.loungeAccessMethod;

    if (rawCheckIn.orozcoCheckInPayments && rawCheckIn.orozcoCheckInPayments.length)
        checkIn.orozcoCheckInPayments = rawCheckIn.orozcoCheckInPayments.map(preparePaymentForServer);

    copyPropertyIdOrNull(rawCheckIn, checkIn, 'loungeSubAccessMethod');
    copyPropertyOrNull(rawCheckIn, checkIn, 'chargeCurrency');
    copyPropertyOrNull(rawCheckIn, checkIn, 'price');
    copyPropertyOrNull(rawCheckIn, checkIn, 'adultsNumber');
    copyPropertyOrNull(rawCheckIn, checkIn, 'kidsNumber');
    copyPropertyOrNull(rawCheckIn, checkIn, 'extraFields');
    
    checkIn.isScanned = rawCheckIn.isScanned;
    checkIn.captureTime = seconds;

    if (rawCheckIn && rawCheckIn.companions && rawCheckIn.companions.length)
        checkIn.companions = rawCheckIn.companions.map(prepareCompanionForServer);


    if (rawCheckIn.loungeAccessMethod.config && rawCheckIn.loungeAccessMethod.config.extraFields) {

        _.each(rawCheckIn.loungeAccessMethod.config.extraFields, (fieldConfig, key) => {
            if (fieldConfig.mandatory) {

                const companionsNumber = checkIn.adultsNumber - 1;
                const kidsNumber = checkIn.kidsNumber;

                if ((companionsNumber > 0 && fieldConfig.onePerCompanion) && (
                    (checkIn.extraFields === undefined || checkIn.extraFields[fieldConfig.name] === undefined) ||
                    (checkIn.extraFields[fieldConfig.name].companions === undefined) ||
                    (checkIn.extraFields[fieldConfig.name].companions.length !== companionsNumber))
                ) {
                    throw new Error(`Debe llenar el campo de ${fieldConfig.name} de todos los acompañantes`)
                }

                if ((kidsNumber > 0 && fieldConfig.onePerChild) && (
                    (checkIn.extraFields === undefined || checkIn.extraFields[fieldConfig.name] === undefined) ||
                    (checkIn.extraFields[fieldConfig.name].kids === undefined) ||
                    (checkIn.extraFields[fieldConfig.name].kids.length !== kidsNumber))
                ) {
                    throw new Error(`Debe llenar el campo de ${fieldConfig.name} de todos los niños`)
                }

                if (fieldConfig.includingGuest &&  (
                    (checkIn.extraFields === undefined || checkIn.extraFields[fieldConfig.name] === undefined) ||
                    (checkIn.extraFields[fieldConfig.name].guest === undefined)
                    )
                ) {
                    throw new Error(`Debe llenar el campo de ${fieldConfig.name} del huésped`)
                }

                if ((!fieldConfig.includingGuest && !fieldConfig.onePerChild && !fieldConfig.onePerCompanion) &&
                    (checkIn.extraFields === undefined || checkIn.extraFields[fieldConfig.name] === undefined)) {
                    throw new Error(`Debe llenar el campo de ${fieldConfig.name}`)
                }

            }

            //Validate the extra field input against a regexp configured by the admin.
            if( fieldConfig.validation && fieldConfig.regexp && checkIn.extraFields[fieldConfig.name]){

                const regexp = new RegExp( fieldConfig.regexp );
                const extraField = checkIn.extraFields[fieldConfig.name];
                if( extraField.guest && !regexp.test(extraField.guest) )
                    throw new Error( fieldConfig.regexpMessage||`El campo ${fieldConfig.name} es inválido` );
                if( extraField.companions?.length ){
                    for(let i=0; i<extraField.companions.length; i++){
                        if( !regexp.test(extraField.companions[i]) ){
                            throw new Error( `Invitado ${i+1} - `+(fieldConfig.regexpMessage||`El campo ${fieldConfig.name} es inválido`) );
                        }
                    }
                }
                if( extraField.kids?.length ){
                    for(let i=0; i<extraField.companions.length; i++){
                        if( !regexp.test(extraField.companions[i]) ){
                            throw new Error( `Niño ${i+1} - `+(fieldConfig.regexpMessage||`El campo ${fieldConfig.name} es inválido`) );
                        }
                    }
                }
            }
        });

    }

    return checkIn;
};

const prepareCompanionForServer = (companion, forceName) => {

    if (typeof companion !== "object")
        return companion;

    const prepared = {};
    if (companion.id)
        prepared.id = companion.id;

    if (companion.name)
        prepared.name = companion.name.trim();
    else if (forceName)
        throw new Error('El nombre es obligatorio para todos los acompañantes');

    return companion;
};

export const isCheckInActive = (checkIn) => {
    return !!(checkIn && !checkIn.checkOutDate);
};

export const activeCheckIns = checkIns =>
    _.filter(checkIns, isCheckInActive);

/**
 * Checks whether the access method of the check in requires a payment
 * @param checkIn
 * @returns {*|boolean|boolean}
 */
export const isPayedCheckIn = (checkIn) => {
    return checkIn && !!getAvailableCurrencies(checkIn.loungeAccessMethod).length;
};

export const getCheckInTotalCostForCurrency = (checkIn, currency) => {
    if (!checkIn || !checkIn.loungeAccessMethod || !typeof checkIn.loungeAccessMethod === 'object' || !currency)
        throw new Error('Couldn\'t get check in price');

    if (getAvailableCurrencies(checkIn.loungeAccessMethod).indexOf(currency) === -1)
        return 0;

    const price = _.find(checkIn.loungeAccessMethod.config.prices, price => price.currency === currency);
    if (!price)
        return 0;

    return price.amount * checkIn.adultsNumber;
};

export const isCheckInExpired = (checkIn) => checkIn.expirationDate && moment().isAfter(checkIn.expirationDate);

export const getCheckInStatusComponent = (checkIn) => {
    if (checkIn.canceledDate)
        return <span>Cancelado</span>;
    if (checkIn.checkOutDate)
        return <span>Cerrado</span>;
    if (isCheckInExpired(checkIn))
        return <span className='red'>Expirado</span>;
    else
        return 'Abierto';
};

export const getCheckInsExpirationBarPercentage = (checkIns) => {

    const withExpiration = _.filter(checkIns, c=>c.expirationDate);

    if(withExpiration.length === 0)
        return 1;


    const checkIn = _.orderBy(withExpiration, (checkIn) => {
        return moment(checkIn.expirationDate).format('YYYYMMDD');
    }, ['asc'])[0];

    const minutes = -moment().diff(checkIn.expirationDate, 'minutes');
    return minutes > 120 ? 1 : minutes < 0 ? 0 : minutes / 120;
};

export const getCheckInExpirationBarPercentage = (checkIn) => {
    if (!checkIn || !checkIn.expirationDate)
        return 1;

    const minutes = -moment().diff(checkIn.expirationDate, 'minutes');
    return minutes > 120 ? 1 : minutes < 0 ? 0 : minutes / 120;
};
export const getCheckOutFromCheckIn = (checkIn) => {
    if (!checkIn)
        return;
    return {id: checkIn.id, activeAdults: 0, activeKids: 0};
};

export const MINUTES_LIMIT_FOR_CANCEL = 10;

export const canCancelCheckIn = (checkIn) => moment().diff(checkIn.createdDate, 'minutes') <= MINUTES_LIMIT_FOR_CANCEL;

export const getCheckInAvailableFreeMeals = (checkIn) => {
    if (
        !checkIn ||
        checkIn.canceledDate ||
        (!checkIn.activeAdults&&!checkIn.activeKids) ||
        !getProperty(checkIn,'loungeAccessMethod', 'config','freeMeals') )
        return 0;

    return ((checkIn.activeAdults||0)+(checkIn.activeKids||0)) * checkIn.loungeAccessMethod.config.freeMeals;
};

export const orderCheckInsByReCheckIn = checkIns => {
    if (checkIns.length === 0) {
        return [];
    }
    const firstCheckIn = checkIns[0];
    const sortedTail = orderCheckInsByReCheckIn(checkIns.slice(1));
    return setWithReCheckInOrder(firstCheckIn, sortedTail);
};

const setWithReCheckInOrder = (checkIn, sorted) => {
    if (sorted.length === 0) {
        return [checkIn];
    }
    const firstElement = sorted[0];
    if ((checkIn.reCheckInFrom || {}).id === firstElement.id) {
        return [checkIn, ...sorted];
    }
    return [firstElement, ...setWithReCheckInOrder(checkIn, sorted.slice(1))];
};

// Hard coded extra fields validation.
export const handleExtraFieldChanges = (extraField, value)=>{

    if(extraField.name === 'Nombre'){
        return value.replace(/[^a-zA-Z ]/g, '');
    }
    if(extraField.name === '# de Tarjeta'){
        const nums=value.replace(/[^0-9]/g, '').substring(0,9);
        if(!nums)
            return '';
        return 'AM '+nums;
    }
    return value;
};

//Hard coded extra fields placeholder
export const extraFieldPlaceHolder = (extraField)=>{
    if(extraField.name === '# de Tarjeta'){
        return 'AM ';
    }
    return '';
};

export const nearToExpireSGroups = [
    'check_in_read_id',
    'check_in_read_expiration_date',
    'check_in_read_visit',
        'visit_read_id',
        'visit_read_guest',
            'guest_read_id',
            'guest_read_name',
            'guest_read_pat_last_name',
            'guest_read_mat_last_name',
];
