import React,{useState, useCallback,useContext} from 'react';
import Modal from "../../../../../components/Modal/Modal";
import {guestFullName} from "../../../../../services/modelUtils/guestUtils";
import './CheckOutModal.scss';
import Button from "../../../../../components/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHiking, faPiggyBank} from "@fortawesome/free-solid-svg-icons";
//import CheckInsPaymentModal from "../../../../../../components/PaymentModals/CheckInsPaymentModal";
import classNames from 'classnames';
import {getNotifier} from "../../../../../services/notifierUtils";
import _ from "lodash";
import { get, pick } from "lodash/fp";
import {useSelector} from "react-redux";
import LoadingAnimation from "../../../../../components/LoadingAnimation/LoadingAnimation";
import {ApiContext} from "../../../../../services/api/api-config";
import {
    //canCancelCheckIn,
    getCheckOutFromCheckIn,
    isCheckInActive,
    //MINUTES_LIMIT_FOR_CANCEL
} from "../../../../../services/modelUtils/checkInUtils";
import moment from "moment";
import {serverDateTimeFormat} from "../../../../../services/timeUtils";
import {hasVisitPendingPayment, visitCancelCheckInSGroups} from "../../../../../services/modelUtils/visitUtils";
import Bill from "../Bill/Bill";
import useBoolean from "../../../../../hooks/useBoolean";
import OrderPaymentModal from "../../../../../components/PaymentModals/OrderPaymentModal";
import {SecurityContext} from '../../../../../services/SecurityManager';
import {
    getPrinterIdByKey,
    localStorageFrontDeskPrinterKey
} from "../../../../../services/modelUtils/orozcoTicketPrinterUtils";


const loadingId='@CheckOutModal';
const CheckOutModal=({onClose, guest, visit, checkIns, onFinish})=>{
    const { productsOtherHost } = getDishesOrderedByOtherHost(visit);
    const [cancelingCheckIn, startCanceling, stopCanceling] = useBoolean();
    const [cancelingReason, setCancelingReason] = useState('');

    const securityManager = useContext(SecurityContext);

    // --------- Configure messages for display -------------
    const personCount=checkIns.reduce( (acc, checkIn)=>acc+checkIn.activeAdults, 0);
    const isCompleteCheckOut=checkIns.length === _.filter(visit.checkIns, isCheckInActive).length;
    const canCancel=securityManager.canCancelCheckIn();//&& checkIns.reduce( (acc, checkIn)=>canCancelCheckIn(checkIn)&&acc, true);
    const shouldPay=hasVisitPendingPayment(visit);

    let message;
    if(shouldPay)
        message=`${guestFullName(guest)} tiene un pago pendiente.`;
    else if(isCompleteCheckOut && personCount > 1)
        message=`Estás por marcar la salida de ${guestFullName(guest)} y ${personCount>2?`sus ${personCount-1} acompañantes`:`su acompañante`}.`;
    else if(isCompleteCheckOut)
        message=`Estás por marcar la salida de ${guestFullName(guest)}.`;
    else
        message=`Estás por marcar la salida de ${personCount} acompañante${personCount===1?'':'s'} de ${guestFullName(guest).trim()},
         que corresponde${personCount>1?'n':''} a${checkIns.length>1?' los métodos':'l método'} de acceso ${_.map(checkIns, checkIn=>checkIn.loungeAccessMethod.name).join(', ')}.`;

    let secondMessage='¿Estás seguro que deseas hacerlo?';
    if(shouldPay)
        secondMessage='Cóbralo ahora para poder hacer el check out';
    else if(canCancel)
        secondMessage=`Puedes cancelar la reservación o hacer un check out normal. ¿Qué deseas hacer?`;

    //-------- Payment -----------
    const [chargeModal, startCharging, stopCharging]=useBoolean();

    // --------- Send check out to server -------------
    const api=useContext(ApiContext);

    const handleCheckOut=useCallback(()=>{
        stopCharging();
        const checkOuts=checkIns.map(getCheckOutFromCheckIn);
        const newCheckIns=visit.checkIns.map( oldCheckIn=>{
            const newCheckIn=_.find(checkOuts, checkOut=>checkOut.id===oldCheckIn.id);
            if(newCheckIn)
                return newCheckIn;
            return oldCheckIn.id;
        });

        api.visits.update({id: visit.id, params:{checkIns:newCheckIns}, loadingId})
            .then(()=>{
                onFinish();
                getNotifier().success('Check out correcto.');
            })
    },[checkIns, visit, api, onFinish, stopCharging]);

    // --------  Handle cancel check ins ------------

    const handleCancelCheckIn=useCallback(()=>{

        const newCheckIns=visit.checkIns.map( oldCheckIn=>{
            const newCheckIn=_.find(checkIns, checkOut=>checkOut.id===oldCheckIn.id);
            if(newCheckIn)
                return {
                    id:newCheckIn.id,
                    canceledDate:moment().format(serverDateTimeFormat),
                    cancellationReason: cancelingReason
                };
            return oldCheckIn.id;
        });
        const params = {checkIns:newCheckIns, sGroups: visitCancelCheckInSGroups};
        api.visits.update({id: visit.id, params, loadingId})
            .then(()=>{

                const printerId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);

                if(!printerId) {
                    return getNotifier().error('La impresora de recepción no se encuentra configurada');
                }
                else {
                    getNotifier().success('Imprimiendo ticket...');
                    _.forEach(checkIns, checkIn => {
                        api.orozcoPrints.create({
                            params: {
                                ticketPrinter: printerId,
                                checkIn: checkIn.id
                            }
                        }).then(() => getNotifier().success('Ticket impreso'))
                    });
                }

                onFinish();
                getNotifier().success('Check in cancelado.');
            })
    },[checkIns, visit, api, onFinish, cancelingReason]);


    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    return (
        <Modal
            onClose={onClose}
            title={'Check out'}
            className={classNames('CheckOutModal', chargeModal?'charging':'')}
        >
            <div className={'content'}>

                <div className='icons-container'>
                    <FontAwesomeIcon icon={shouldPay?faPiggyBank:faHiking} className='icon' />
                </div>

                <h2 className='main-desc'>{message}</h2>
                <h3 className='second-message'>{secondMessage}</h3>
                {cancelingCheckIn && (
                    <textarea
                        placeholder="Escribe la razón por la que cancelas esta visita..."
                        value={cancelingReason}
                        onChange={e=>setCancelingReason(e.target.value)}
                        className="CommentBox-textArea"
                    />
                )}
            </div>


            {!shouldPay && <>
                <Button onClick={onClose} disabled={loading}>No hacer nada</Button>

                {canCancel&&
                 <Button blue onClick={cancelingCheckIn?stopCanceling:startCanceling}>
                     {cancelingCheckIn?'Regresar':'Cancelar reservación'}

                 </Button>}

                {!cancelingCheckIn&&<Button blue onClick={handleCheckOut} disabled={loading}>{loading?<LoadingAnimation/>:'Hacer check out'}</Button>}

                {cancelingCheckIn&&<Button blue onClick={handleCancelCheckIn} disabled={loading || cancelingReason.length===0}>
                                        {loading?<LoadingAnimation/>:'Cancelar reservación'}
                                    </Button>}

            </>}

            {
                productsOtherHost.length > 0 && <div className="OtherHostDishes">
                    <div className="subTitle">
                        <p>Platillos ordenados por otro anfitrión</p>
                    </div>
                    <div className="list">
                    {productsOtherHost.map(function ({ id, quantity, orozcoProduct, createdBy }) {
                        return <div key={id} className="dish">
                            <div><strong>{quantity}</strong>&nbsp;{orozcoProduct.name}</div>
                            <div><em>{ createdBy?.employee.fullName }</em></div>
                        </div>
                    })}
                    </div>
                </div>
            }

            {shouldPay&&<>
                <Bill visit={visit} />
                <Button solid onClick={startCharging} disabled={loading}>{loading?<LoadingAnimation/>:'Saldar cuenta'}</Button>
                </>}

            { chargeModal &&
            <OrderPaymentModal
                onClose={stopCharging}
                visit={visit}
                onPay={handleCheckOut}
            />}

        </Modal>
    );
};

export default CheckOutModal;

function getDishesOrderedByOtherHost({ employeesAttendingVisit, orozcoVisitOrder }) {
    const setEmployees = new Set(employeesAttendingVisit.map(get(['employee', 'id'])));
    const products = get('orozcoVisitOrderProducts')(orozcoVisitOrder);

    const productsOtherHost = typeof products === 'undefined'
        ? []
        : products
      .filter(function(dish) {
            const ofOtherHost = !setEmployees.has(dish.createdBy.employee?.id);
            const notCancelled = !dish.canceledDate;
            return ofOtherHost && notCancelled;
      })
      .map(pick([
        'id',
        'orozcoProduct.name',
        'quantity',
        'createdBy.employee.fullName'
        ]));

    return { setEmployees, productsOtherHost };
}
