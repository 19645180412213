import React, {useState} from 'react';
import "./SessionTimeoutConfig.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {defaultSessionTime} from "../../../services/timeUtils";
import {getNotifier} from "../../../services/notifierUtils";

const SessionTimeoutConfig = () => {

    const time = localStorage.sessionTimeout || defaultSessionTime;
    const [value, setValue] = useState();

    const handleSave = ()=>{
        const secs = Number(value);
        if( !secs || secs < 10 ){
            return getNotifier().error("Ingresa un número válido.")
        }
        localStorage.sessionTimeout = secs*1000;
        window.location.reload();
    };

    return (
        <div className='config-block SessionTimeoutConfig'>
            <div className='config-title'>
                Tiempo de sesión
            </div>
            <div className='config-content'>
                <div className='left'>
                    <div className='icon'><FontAwesomeIcon icon={faClock}/></div>
                </div>
                <div className='right'>
                    <p className='sub-title'>Tiempo actual</p>
                    <h3 className='actual-name'>{time/1000} seg</h3>
                </div>
                <div className='bottom'>
                    <p>Cambiar tiempo en segundos</p>
                    <input className="davinci-input" value={value} onChange={e=>setValue(e.target.value)} />
                    <button className="Button main save" onClick={handleSave}>Guardar</button>
                </div>
            </div>
        </div>
    );
};

export default SessionTimeoutConfig;
