import {useEffect, useState, useMemo} from 'react';
import {localStorageKey} from "../services/modelUtils/cashRegisterUtils";

export default ()=>{

    const [cashRegisterString, setCashRegisterString] = useState(window.localStorage[localStorageKey]);

    useEffect(()=>{
        const changeHandler = (e)=>{

            if(e && e.key === localStorageKey)
                setCashRegisterString(e.newValue);
        };
        window.addEventListener('storage', changeHandler);

        return ()=>window.removeEventListener('storage', changeHandler);
    },[cashRegisterString]);

    const parsed=useMemo(()=>{
        try {
            return JSON.parse(cashRegisterString)
        }
        catch (e) {
            return null;
        }
    },[cashRegisterString]);

    return [parsed];
};
