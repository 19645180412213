import React, {useEffect, useContext, useMemo, useState, useCallback} from 'react';
import './AttendingVisitMap.scss';
import useActualLounge from "../../../../../hooks/useActualLounge";
import {ApiContext} from "../../../../../services/api/api-config";
import {
    addVisitsToLoungeMap, canAssignVisitToSeat,
    loungeMapEditorSGroups,
    prepareLoungeMapForRender,
} from "tgle-core/utils/loungeMapUtils";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import LoungeMap from 'tgle-core/components/LoungeMap';
import {initialViewPort} from "tgle-core/utils/konvaUtils";
import WaiterList from "../WaiterList/WaiterList";
import VisitsSideList from "../VisitsSideList/VisitsSideList";
import _ from 'lodash';
import {paths} from "../../../../../services/routes/appRoutes";

const getFreeSeats = map =>{
    const seats = _.filter(map, {type: 'seat'});
    return _.filter(seats, s=>!s.visit);
};
const getNearestSeat = ({x,y}, seats)=>{
    const seatsDist = _.map(_.map(seats, 'config'), sqDistTo({x,y}));
    const minDist = Math.min(...seatsDist);
    const minIndex = _.findIndex(seatsDist, d=>d===minDist);
    return seats[minIndex];
};

const getReplacedSeats = (seats, toReplaceId, seat)=>{
    const i = _.findIndex(seats, {id: toReplaceId});
    return [
        ...seats.slice(0, i),
        seat,
        ...seats.slice(i+1)
    ];
};

const sqDistTo = ({x,y})=>({x:xo, y:yo}) => (x-xo)*(x-xo)+(y-yo)*(y-yo);
const emptyArr=[];

const AttendingVisitMap = ({reloadVisits})=>{

    const api = useContext(ApiContext);
    const me = useSelector(({api}) => api.me);
    const history = useHistory();
    const [assigningIds, setAssigningIds] = useState([]);

    const lounge = useActualLounge();
    const [localLoungeMap, setLocalLoungeMap] = useState(null);
    const [first, setFirst] = useState(true);

    const visits = useSelector(({api}) => api.allVisits || null);
    useEffect(()=>{
        // This effect is not only to avoid making loungemap requests every time
        // visits is updated (on an interval) but also to avoid changing the state,
        // when a change is being made the local state may be rewriten from this update
        // giving a blinking effect.
        // The prefered approach is to handle everything locally.
        if(visits && first){
            setFirst(false);
            api.loungeMaps.get({params:{'lounge.id':lounge.id, sGroups: loungeMapEditorSGroups}})
                .then(ls=>setLocalLoungeMap(addVisitsToLoungeMap(visits, prepareLoungeMapForRender(ls[0]))));
        }
    },[api, lounge.id, visits, first]);

    const [viewPort, setViewPort] = useState(initialViewPort);
    const [selectedWaiter, setSelectedWaiter]=useState(me && me.employee && me.employee.id);// It's the employee id of the active waiter
    const handleWaiterSelection = useCallback((waiterId)=>{
        if(selectedWaiter === waiterId)
            setSelectedWaiter(null);
        else
            setSelectedWaiter(waiterId);
    },[selectedWaiter]);


    // ------- Visit assigning ------
    const unassignedVisits = useMemo(
        ()=>visits?_.filter(visits,
                            v=>!(v.seats && v.seats.length && v.seats.length >= v.activePersons) &&
                            !_.includes(assigningIds, v.id)
                           ):[], [assigningIds, visits]);

    const [selectedVisit, setSelectedVisit] = useState(null);

    const assignSeatToVisit = useCallback((visit, seat)=>{
        return api.seats.update({id: seat.id, params: {visit: visit.id}});
    },[api]);

    // ----------  Food bar selection ----------
    const [selectedFoodBar, setSelectedFoodBar]=useState(null);

    // ------ Map interaction handling ------

    const handleItemClick = useCallback((item)=>{
        if(item.type === 'food_bar'){
            if(!selectedFoodBar || selectedFoodBar.id !== item.id) {
                setSelectedFoodBar(item);
                setSelectedVisit(null);
            }
            else{
                history.push(paths.foodBarMenu.replace(':foodBarId', item.id));
            }
        }
        else if(item.type === 'seat'){
            setSelectedFoodBar(null);
            if(canAssignVisitToSeat(selectedVisit, item)){
                const assignVisit = ls=>{
                    const seatIndex = _.findIndex(ls, {id: item.id});
                    return [
                        ..._.slice(ls, 0, seatIndex),
                        {...ls[seatIndex], visit: selectedVisit},
                        ..._.slice(ls, seatIndex+1)
                    ];
                };
                const unassignVisit = ls => {
                    const seatIndex = _.findIndex(ls, {id: item.id});
                    return [
                        ..._.slice(ls, 0, seatIndex),
                        {...ls[seatIndex], visit: undefined},
                        ..._.slice(ls, seatIndex+1)
                    ];
                };
                setLocalLoungeMap(l=>({
                    ...l,
                    loungeMapItems: assignVisit(l.loungeMapItems)
                }));
                setSelectedVisit(v=>({...v, seats: [...v.seats, item]}));
                if(selectedVisit.seats.length+1>=selectedVisit.activePersons){
                    setAssigningIds(ids=>[...ids, selectedVisit.id]);
                }
                assignSeatToVisit(selectedVisit, item)
                    .then(reloadVisits)
                    .then(()=>setAssigningIds(ids=>_.filter(ids, x=>x!==selectedVisit.id)))
                    .catch(()=>{
                        setLocalLoungeMap(l=>({
                            ...l,
                            loungeMapItems: unassignVisit(l.loungeMapItems)
                        }));
                        setSelectedVisit(v=>({...v, seats: _.filter(v.seats, s=>s.id===item.id)}));
                    });
            }

            if(item.visit && (!selectedVisit || !selectedVisit.id !== item.visit.id)){
                setSelectedVisit(item.visit);
            }

            if(item.visit && selectedVisit && item.visit.id === selectedVisit.id) {
                history.push(paths.visitMenu.replace(':visitId', item.visit.id));
            }
        }
    },[reloadVisits, selectedVisit, assignSeatToVisit, history, selectedFoodBar]);

    // ------- ViewPort logic ----------
    useEffect(()=>{
        if(me&&me.employee)
        api.orozcoEmployeeProfiles.get({params:{'employee.id':me.employee.id}, customProp:'myOrozcoProfile'})
            .then((orozcoProfile)=>{
                if(orozcoProfile && orozcoProfile.length && orozcoProfile[0].viewPort) {
                    setViewPort(orozcoProfile[0].viewPort);
                }
            })

    },[api, me]);


    const orozcoProfile = useSelector(({api})=>(api.myOrozcoProfile||[])[0]);

    const saveViewPort = useCallback(_.debounce(viewPort=>{
        if(!orozcoProfile) return;
        api.orozcoEmployeeProfiles.update({id:orozcoProfile.id, params:{viewPort}, customProp:'myOrozcoProfile'});
    }, 2000),[api, orozcoProfile]);

    const handleViewPortChange=useCallback((viewPort, initialSetUp)=>{
        if(initialSetUp) return;
        setViewPort(viewPort);
        saveViewPort(viewPort);
    },[saveViewPort]);

    const [nearestCandidate, setNearestCandidate] = useState(null);

    // -----------  Seat re-assigning ------------------
    const getNearestFreeSeat = useCallback(({x, y}, including=[]) => {

        const seats = [...getFreeSeats(localLoungeMap.loungeMapItems), ...including];
        if(seats.length===0){
            return null;
        }
        return getNearestSeat({x, y}, seats);
    }, [localLoungeMap]);

    const reAssignNearestSeat = useCallback((coords, item)=>{
        const {visit, id} = item;
        const toAssign = getNearestFreeSeat(coords, [item]);
        if(toAssign.id===item.id || !item.visit){
            return Promise.resolve();
        }
        if(!!selectedVisit&&selectedVisit.id===visit.id){
            setSelectedVisit(v=>({
                ...v,
                seats: getReplacedSeats(
                    v.seats,
                    item.id,
                    toAssign
                )
            }));
        }
        const reassignedVisit = {...visit, seats: getReplacedSeats(visit.seats, item.id, toAssign)};
        setLocalLoungeMap(l=>{
            const toUnassignIndex = _.findIndex(l.loungeMapItems, {id});
            const toAssignIndex = _.findIndex(l.loungeMapItems, {id: toAssign.id});

            const setUnassigned = a=>[
                ..._.slice(a, 0, toUnassignIndex),
                {...a[toUnassignIndex], visit: undefined},
                ..._.slice(a, toUnassignIndex+1)
            ];
            const setAssigned = a=>[
                ..._.slice(a, 0, toAssignIndex),
                {...a[toAssignIndex], visit: reassignedVisit},
                ..._.slice(a, toAssignIndex+1)
            ];

            return {
                ...l,
                loungeMapItems: setUnassigned(setAssigned(l.loungeMapItems))
            };
        });
        return Promise.all([
            assignSeatToVisit({id: null}, {id}),
            assignSeatToVisit(visit, toAssign)
        ]).then(reloadVisits).catch((e)=>{
            setLocalLoungeMap(l=>{
                const toAssignIndex = _.findIndex(l.loungeMapItems, {id});
                const toUnassignIndex = _.findIndex(l.loungeMapItems, {id: toAssign.id});

                const setUnassigned = a=>[
                    ..._.slice(a, 0, toUnassignIndex),
                    {...a[toUnassignIndex], visit: undefined},
                    ..._.slice(a, toUnassignIndex+1)
                ];
                const setAssigned = a=>[
                    ..._.slice(a, 0, toAssignIndex),
                    {...a[toAssignIndex], visit},
                    ..._.slice(a, toAssignIndex+1)
                ];
                if(!!selectedVisit&&selectedVisit.id===visit.id){
                    setSelectedVisit(v=>({
                        ...v,
                        seats: getReplacedSeats(
                            v.seats,
                            toAssign.id,
                            item
                        )
                    }));
                }

                return {
                    ...l,
                    loungeMapItems: setUnassigned(setAssigned(l.loungeMapItems))
                };
            });
        });
    }, [selectedVisit, reloadVisits, assignSeatToVisit, getNearestFreeSeat]);

    const setNearestSeat = useCallback(({x, y}, item)=>{
        setNearestCandidate(getNearestFreeSeat({x, y}, [item]));
    }, [getNearestFreeSeat]);
    const unsetNearestSeat = useCallback(()=>{
        setNearestCandidate(null);
    }, []);
    const extraInfo = useMemo(() => ({
        visit: selectedVisit, waiter: selectedWaiter,
        setNearestSeat, nearestCandidate,
        unsetNearestSeat,
        setForceState: ()=>{}
    }), [selectedVisit, selectedWaiter, nearestCandidate, setNearestSeat, unsetNearestSeat]);

    const deselectEverything = useCallback(()=>{
        setSelectedFoodBar(null);
        setSelectedVisit(null);
    },[]);

    // ------------ Reservations marking ---------
    const reservations = useSelector(({api}) => api.mapReservations || emptyArr);

    return (
        <div className='AttendingVisitMap'>

            <WaiterList
                selectedWaiter={selectedWaiter}
                onSelectedWaiterChange={handleWaiterSelection}
            />

            {localLoungeMap &&
                <LoungeMap
                    loungeMap={localLoungeMap}
                    viewPort={viewPort}
                    onViewPortChange={handleViewPortChange}
                    className={unassignedVisits.length?'with-visits':''}
                    mapType={'davinci-map'}
                    onStageClick={deselectEverything}
                    onItemSelect={handleItemClick}
                    selectedVisit={selectedVisit}
                    selectedItem={selectedFoodBar}
                    onItemChange={reAssignNearestSeat}
                    extraInfo={extraInfo}
                    reservations={reservations}
                />
            }
            {!!unassignedVisits.length &&
                <VisitsSideList
                    visits={unassignedVisits}
                    onVisitSelect={setSelectedVisit}
                    selectedVisit={selectedVisit}
                />}

        </div>
    );
};
AttendingVisitMap.displayName='AttendingVisitMap';
export default AttendingVisitMap;
