import React from 'react';


export default function( status ){

    switch ((status||'').toLowerCase()) {
        case 'inair':
            return 'Despegó / Departed';
        case 'delayed':
            return <span style={{color:'red'}}>Demorado / Delayed</span>;
        case 'scheduled':
            return 'A tiempo / On time';
        default:
            return status;
    }
}
