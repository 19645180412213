import React,{useMemo} from 'react';
import useBackgroundBlur from "../../hooks/useBackgroundBlur";
import useEscapeKey from "../../hooks/useEscapeKey";
import {createPortal} from "react-dom";
import './FullscreenPicker.scss';
import classNames from 'classnames';

const FullscreenPicker=({onHide, children, className, ...props})=>{


    useBackgroundBlur();
    useEscapeKey(onHide);


    return createPortal(

        <div className={classNames('FullscreenPicker', className)}  tabIndex={-1} {...props}>
            <div className='background' onClick={onHide}/>
            <div className='FullscreenPicker-container animated zoomInUp faster'>
                {children}
            </div>
        </div>

        ,useMemo(()=>document.getElementsByTagName('body')[0],[]));
};

export default FullscreenPicker;
