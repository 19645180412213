import {useEffect} from 'react';
import {blurAppAction} from "../services/redux/appBlurredReducer";
import {useDispatch} from "react-redux";


export default (blurred=true)=>{

    const dispatch=useDispatch();

    useEffect(()=> {
        dispatch(blurAppAction(blurred));
        return ()=> {
            dispatch(blurAppAction(false));
        }
    },[dispatch, blurred]);
}
