import React,{useCallback} from 'react';
import SingleCheckInForm from "./SingleCheckInForm";
import useCallbackCreator from "use-callback-creator";
import Button from "../../../../components/Button/Button";
import {emptyCheckIn} from "../../../../services/modelUtils/checkInUtils";

/**
 * Conectar con reducer
 * Enviar estado de componente al objeto reducer
 * 
 */

/**
 * Componente que renderiza los inputs de los metodos de acceso 
 */
export default function CheckInsForm({checkIns, onChange}){

    /**
     * Este hook cambia el valor del CheckIn
     */
    const handleCheckInChange=useCallbackCreator((index, checkIn)=>{
        const newCheckIns=[...checkIns];
        newCheckIns[index] = checkIn;
        onChange(newCheckIns);
    },[checkIns, onChange]);

    /**
     * Agrega un nuevo select para un nuevo metodo de acceso
     */
    const handleAddCheckIn=useCallback(()=>{
        onChange([...checkIns, emptyCheckIn()]);
    },[onChange, checkIns]);

    /**
     * Remueve los metodos de acceso adicionales
     */
    const handleRemoveCheckIn=useCallback(()=>{
        if(checkIns.length < 2)
        {
            return
        } 
        const changed=[...checkIns]
        changed.splice(-1)
        onChange(changed)
    },[checkIns, onChange])

    return (
        <div>
            {checkIns.map( (checkIn, index)=>
                <SingleCheckInForm
                    key={index}
                    checkIn={checkIn}
                    onChange={handleCheckInChange(index)}
                />
            )}
            <div className="form-row centerItemInARow">
                <div className="form-column half-width centerItemInARow">
                    <Button onClick={handleAddCheckIn}>Agregar método de acceso</Button>
                </div>{' '}
                {checkIns.length>1&&
                <div className="form-column half-width">
                    <Button onClick={handleRemoveCheckIn}>Quitar un método de acceso</Button>
                </div>}
            </div>
        </div>
    )
}

