import React, {useState, useEffect, useCallback, useContext, useRef} from 'react';
import './AttendingVisits.scss';
import TopBarWaiter from "../../../components/TopBar/TopBarWaiter/TopBarWaiter";
import FixedMenu from "../../../components/FixedMenu/FixedMenu";
import RadioSelector from "../../../components/RadioSelector/RadioSelector";
import AttendingVisitList from "./Components/AttendingVisitList/AttendingVisitList";
import AttendingVisitMap from "./Components/AttendingVisitMap/AttendingVisitMap";
import classNames from 'classnames';
import {cardSGroups} from "../../../services/modelUtils/orozcoEmployeeProfileUtils";
import moment from "moment";
import {attendingVisitSGroups} from "../../../services/modelUtils/employeeAttendingVisitUtils";
import useActualLounge from "../../../hooks/useActualLounge";
import {ApiContext} from "../../../services/api/api-config";
import {mapReservationsSGroups} from "../../../services/modelUtils/reservationUtils";
import {serverDateTimeFormat} from "../../../services/timeUtils";
import {SecurityContext} from "../../../services/SecurityManager";

const screenTypeOptions = [{label: 'Mapa', value: 'map'}, {label: 'Lista', value: 'list'}];

const REFRESH_TIMEOUT = 30000;

const AttendingVisits = () => {

    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);

    const actualLounge = useActualLounge();
    const [screenType, setScreenType] = useState('map');
    const [reloadVisitsCount, setReloadVisitsCount] = useState(0);

    const loadWaitersTimeOutRef = useRef(0);
    const loadWaiters = useCallback(() => {
        clearTimeout(loadWaitersTimeOutRef.current);
        api.orozcoEmployeeProfiles.get({
            params: {
                sGroups: cardSGroups,
                activeLounge: actualLounge.id,
                'activeDate[after]': moment().subtract(8.5, 'hours').format('YYYY-MM-DD HH:mm'),
                pagination: false
            }
        }).then(() => {
            loadWaitersTimeOutRef.current = setTimeout(loadWaiters, REFRESH_TIMEOUT);
        }).catch(() => {
            loadWaitersTimeOutRef.current = setTimeout(loadWaiters, REFRESH_TIMEOUT);
        })
    }, [actualLounge, api]);

    const loadAllVisitsTimeOutRef = useRef(0);
    const loadAllVisits = useCallback(() => {
        clearTimeout(loadAllVisitsTimeOutRef.current);
        return api.visits.get({
            params: {
                sGroups: attendingVisitSGroups,
                lounge: actualLounge.id,
                'activePersons[gt]': 0,
                pagination: false,
                'order[tables.name]': 'DESC',
            },
            customProp: 'allVisits'
        }).then(() => {
            loadAllVisitsTimeOutRef.current = setTimeout(loadAllVisits, REFRESH_TIMEOUT);
        }).catch(() => {
            loadAllVisitsTimeOutRef.current = setTimeout(loadAllVisits, REFRESH_TIMEOUT);
        })
    }, [api, actualLounge]);

    const loadReservationsTimeOutRef = useRef(0);
    const loadReservations = useCallback(() => {
        if (!security.canListReservations())
            return;

        clearTimeout(loadReservationsTimeOutRef.current);
        return api.reservations.get({
            params: {
                sGroups: mapReservationsSGroups,
                lounge: actualLounge.id,
                pagination: false,
                "exists[seats]": true,
                "reservationDate[after]": moment().subtract(10, 'hour').format(serverDateTimeFormat),
                "reservationDate[before]": moment().add(1, 'hour').format(serverDateTimeFormat)
            },
            customProp: 'mapReservations'
        }).then(() => {
            loadReservationsTimeOutRef.current=setTimeout(loadReservations, REFRESH_TIMEOUT);
        }).catch(() => {
            loadReservationsTimeOutRef.current=setTimeout(loadReservations, REFRESH_TIMEOUT);
        })
    }, [api, actualLounge, security]);


    useEffect(() => {
        if (!actualLounge)
            return;

        loadWaiters();
        loadAllVisits();
        loadReservations();

        return ()=>{
            clearTimeout(loadWaitersTimeOutRef.current);
            clearTimeout(loadAllVisitsTimeOutRef.current);
            clearTimeout(loadReservationsTimeOutRef.current);
        }
    }, [actualLounge, loadWaiters, loadAllVisits, loadReservations]);

    const loadAllVisitsAndUpdateAlerts = useCallback(() => {
        setReloadVisitsCount(reloadVisitsCount + 1);
        loadAllVisits();
    }, [setReloadVisitsCount, reloadVisitsCount, loadAllVisits]);

    return (
        <div className={classNames("AttendingVisits", screenType)}>
            <FixedMenu reload={reloadVisitsCount} />
            <TopBarWaiter>
                <RadioSelector
                    options={screenTypeOptions}
                    onChange={setScreenType}
                    value={screenType}
                />
            </TopBarWaiter>

            {screenType === 'list' &&
            <AttendingVisitList/>}

            {screenType === 'map' &&
            <AttendingVisitMap
                reloadVisits={loadAllVisitsAndUpdateAlerts}
            />}

        </div>
    )
};
AttendingVisits.displayName = 'AttendingVisits';
export default AttendingVisits;
