import React,{useContext,useCallback} from 'react';
import {useSelector} from "react-redux";
import {ApiContext} from "../api/api-config";

export const LoungeContext = React.createContext(null);

export const occupationSGroups=[
    'place_read_id',
    'place_read_name',
    'lounge_read_lounge_map',
    'lounge_map_read',
];

export const occupationCustomProp='occupationLounge';

export const loungeSelectorFilters = {isLounge: true, isActive: true};

export const useActualLoungeOccupation=()=>{

    const actualLounge=useSelector(({api})=>api.discoverLounge);
    const api=useContext(ApiContext);

    const refresh= useCallback(() => {
        if (actualLounge)
            api.lounges.get({
                params: {sGroups: occupationSGroups, id: actualLounge.id},
                customProp: occupationCustomProp
            });
    }, [api, actualLounge]);

    const lounge=useSelector(({api})=>api[occupationCustomProp] && api[occupationCustomProp][0]);

    return{
        lounge,
        refreshOccupation:refresh
    }
};
