import React,{useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import './ConfigComponent.scss';
import TideEntitySelect from "../../../components/TideEntitySelect/TideEntitySelect";
import useActualCashRegister from "../../../hooks/useActualCashRegister";
import {localStorageKey} from "../../../services/modelUtils/cashRegisterUtils";

const CashRegisterConfig=()=>{

    const [actualCashRegister]=useActualCashRegister();


    const handleRegisterChange=useCallback((cashRegister)=>{

        const newValue=JSON.stringify(cashRegister);
        window.localStorage.setItem(localStorageKey, newValue);
        const event=new StorageEvent('storage', {key: localStorageKey, oldValue: actualCashRegister, newValue });
        window.dispatchEvent(event);

    },[actualCashRegister]);

    return (
        <div className='config-block CashRegisterConfig'>
            <div className='config-title'>
                Caja registradora
            </div>
            <div className='config-content'>
                <div className='left'>
                    <div className='icon'> <FontAwesomeIcon icon={faCashRegister} /></div></div>
                <div className='right'>
                    <p className='sub-title'>Caja actual</p>
                    <h3 className='actual-name'>{actualCashRegister? actualCashRegister.name : 'Sin registrar'}</h3>
                </div>
                <div className='bottom'>
                    <p>Cambiar de caja</p>
                    <TideEntitySelect
                        entity={'orozcoCashRegisters'}
                        onChange={handleRegisterChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CashRegisterConfig;
