import React,{useContext, useEffect, useCallback, useState, useMemo} from 'react';
import Modal from "../../../../../components/Modal/Modal";
import {ApiContext} from "../../../../../services/api/api-config";
import {useSelector} from "react-redux";
import MenuFoodCard from "../../../../Waiter/WaiterMenu/Components/MenuFoodCard/MenuFoodCard";
import './ExtraChargeModal.scss';
import _ from 'lodash';
import Button from "../../../../../components/Button/Button";
import {
    extraChargeModalSGroups,
    getAvailableCurrencies
} from "../../../../../services/modelUtils/orozcoProductUtils";
import useBoolean from "../../../../../hooks/useBoolean";
import PaymentFormOverlay from "../../../../../components/PaymentModals/PaymentFormOverlay/PaymentFormOverlay";
import {emptyOrozcoVisitOrder, updateProductsPaymentsSGroups} from "../../../../../services/modelUtils/orozcoVisitOrderUtils";
import ExtraChargeEntry from './ExtraChargeEntry';
import useCallbackCreator from 'use-callback-creator';
import {
    getPrinterIdByKey,
    localStorageFrontDeskPrinterKey
} from "../../../../../services/modelUtils/orozcoTicketPrinterUtils";
import {getNotifier} from "../../../../../services/notifierUtils";

const productsCustomProp='@ExtraChargeModal.orozcoProducts';

const overridenCurrency = selected=>{
    const changed = _.find(selected, ({currency})=>!!currency);
    if(!changed){
        return null;
    }
    return changed.currency;
};

const overridenPrice = entry=>
      entry.price!==undefined && entry.price!==undefined;

const ExtraChargeModal=({onClose, visit, onPay})=>{

    const api=useContext(ApiContext);

    useEffect(()=>{
        api.orozcoProducts.get({params:{pagination:false, isAvailable: true, isExtraCharge:true, sGroups:extraChargeModalSGroups}, customProp:productsCustomProp});
    },[api]);

    const products = useSelector(({api})=>api[productsCustomProp])||[];

    // ------ Selecting products -------
    const [selected, setSelected]=useState({});
    const selectProduct=useCallback((product)=>{
        setSelected({
            ...selected,
            [product.id]: {
                ...(selected[product.id]?
                    selected[product.id]:{product}
                   ),
                quantity:selected[product.id]? selected[product.id].quantity+1:1
            }});
    },[selected]);

    const removeEntry = useCallbackCreator((id)=>{
        setSelected(s=>{
            const ns = _.cloneDeep(s);
            delete ns[id];
            return ns;
        });
    }, []);

    const removeProduct=useCallback((product)=>{
        if(!selected[product.id])
            return;
        if(selected[product.id].quantity===1){
            removeEntry(product.id)();
            return;
        }
        setSelected({
            ...selected,
            [product.id]: {
                ...selected[product.id],
                quantity: selected[product.id].quantity-1
            }
        });
    },[selected, removeEntry]);

    // ------ Payment ---------
    const [paying, startPaying, stopPaying]=useBoolean();
    const productsForPayment=useMemo(
        ()=>
            _.map(selected, sel=>({
                ...sel.product,
                count: sel.quantity,
                prices: overridenPrice(sel)?
                    [{amount: (p=>parseInt(100*p/sel.quantity))(sel.price||0), currency: sel.currency}]:
                    sel.product.prices
            }))
    ,[selected]);

    const paymentSuccess = useCallback((vo, oldPayments, oldProducts)=>{
        const printerId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
        if(!printerId){
            getNotifier().error('La impresora de recepción no se encuentra configurada');
        }
        const newProducts = _.difference(
            _.map(vo.orozcoVisitOrderProducts, 'id'),
            oldProducts
        );
        const newPayments = _.difference(
            _.map(vo.orozcoVisitOrderPayments, 'id'),
            oldPayments
        );

        api.orozcoPrints.create({
            params: {
                ticketPrinter: printerId,
                orozcoVisitOrderProducts: newProducts,
                orozcoVisitOrderPayments: newPayments
            }
        });
        onPay();

    }, [onPay, api]);

    const handlePayment=useCallback((check)=>{
        //Set "payed" flag of the paid orderProducts
        let orozcoVisitOrderProducts=[];
        let oldProducts = [];
        console.log(visit);
        if(visit && visit.orozcoVisitOrder && visit.orozcoVisitOrder.orozcoVisitOrderProducts)
            oldProducts=_.map(visit.orozcoVisitOrder.orozcoVisitOrderProducts,'id');

        let newProducts = [];
        _.forEach(selected, (selection)=>{
            newProducts.push({
                orozcoProduct: selection.product.id,
                quantity: selection.quantity,
                shouldBePayed:true,
                //payed: true,  This is now set by the backend when you send payments along the products
                notes: selection.notes,
                ...(overridenPrice(selection)?{
                    price: parseInt(selection.price*100)||0,
                    currency: selection.currency
                }:{})
            });
        });
        orozcoVisitOrderProducts = [...oldProducts, ...newProducts];

        const oldPayments = visit.orozcoVisitOrder && visit.orozcoVisitOrder.orozcoVisitOrderPayments?
            _.map(visit.orozcoVisitOrder.orozcoVisitOrderPayments, 'id'):[];

        const newPayments=check.payments.map(payment=>({...payment, orozcoVisitOrder: visit.orozcoVisitOrder?visit.orozcoVisitOrder.id:undefined}));

        const orozcoVisitOrder= visit.orozcoVisitOrder?
            {id: visit.orozcoVisitOrder.id}:
            emptyOrozcoVisitOrder(visit);
        if(orozcoVisitOrder.visit && typeof orozcoVisitOrder.visit === 'object')
            orozcoVisitOrder.visit=orozcoVisitOrder.visit.id;

        orozcoVisitOrder.orozcoVisitOrderPayments=[...oldPayments, ...newPayments];
        orozcoVisitOrder.orozcoVisitOrderProducts=orozcoVisitOrderProducts;
        if(visit.orozcoVisitOrder)
            api.orozcoVisitOrders.update({id:visit.orozcoVisitOrder.id,params:{
                ...orozcoVisitOrder,
                sGroups: updateProductsPaymentsSGroups
            }})
            .then((vo)=>paymentSuccess(vo, oldPayments, oldProducts))
                .catch(onClose);
        else
            api.orozcoVisitOrders.create({params:{
                ...orozcoVisitOrder,
                sGroups: updateProductsPaymentsSGroups
            }})
            .then((vo)=>paymentSuccess(vo, oldPayments, oldProducts))
            .catch(onClose);


    },[api, visit, onClose, paymentSuccess, selected]);

    const availableCurrencies = useMemo(
        ()=>
            overridenCurrency(selected)!==null?
            [overridenCurrency(selected)]:
            getAvailableCurrencies(_.map(selected,'product')),
        [selected]);

    const changePrice = useCallbackCreator((id, value)=>{
        setSelected(s=>_.mapValues(s,v=>({
            ...v,
            currency: value?value.currency:undefined,
            price: v.product.id===id?(value?value.amount:undefined):v.price
        })));
    }, []);

    const changeNotes = useCallbackCreator((id, value)=>{
        setSelected(s=>({
            ...s,
            [id]: {...s[id], notes: value}
        }));
    }, []);


    return (
        <>
        <Modal
            onClose={onClose}
            title={'Cargos extra'}
            className='ExtraChargeModal'
        >
            <div className='cards-container'>
                {products.map(product=>
                    <MenuFoodCard
                        key={product.id}
                        product={product}
                        onSelect={selectProduct}
                        onNewProductOrder={selectProduct}
                        onRemove={removeProduct}
                        quantity={selected[product.id]?selected[product.id].quantity:0}
                        removeButton
                    />
                )}
            </div>
            {!!_.size(selected)&&
                <>
                    <hr/>
                    <ul className='selected-products-list'>
                        {_.map(selected, selection=>
                               <ExtraChargeEntry
                                   onRemove={removeEntry(selection.product.id)}
                                   onPriceChanged={changePrice(selection.product.id)}
                                   overridenPrice={{amount: selection.price, currency:selection.currency}}
                                   key={selection.product.id}
                                   product={selection.product}
                                   quantity={selection.quantity}
                                   notes={selection.notes}
                                   onNotesChanged={changeNotes(selection.product.id)}
                               />
                        )}
                    </ul>
                    <div className='buttons'>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button blue disabled={!!selected.length} onClick={startPaying}>Cobrar</Button>
                    </div>
                    {paying&&
                        <PaymentFormOverlay
                            availableCurrencies={availableCurrencies}
                            onClose={stopPaying}
                            products={productsForPayment}
                            onPay={handlePayment}
                            />
                    }
                </>
            }

        </Modal>

        </>
    )
};

export default ExtraChargeModal;
