import React,{useMemo, useCallback, useState} from 'react';
import './SwitchBtn.scss';

const SwitchBtn = ({onChange, value,disabled, defaultValue=false})=>{

    const id=useMemo(()=>Math.random(),[]);

    const [stateValue, setStateValue]=useState(defaultValue);

    if(typeof value === "undefined")
        value=stateValue;

    const changeHandler=useCallback(({target})=>{
        if(onChange && !disabled)
            onChange(target.checked);
        else
            setStateValue(target.checked);
    },[onChange, disabled]);

    return(
        <div className="container">
            <input disabled={disabled} className="che" id={`switch-${id}`} type="checkbox" onChange={changeHandler} checked={value}/>
            <label htmlFor={`switch-${id}`} className="radio-container"/>
        </div>
    )
};
export default SwitchBtn;
