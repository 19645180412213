import { useState, useCallback, useMemo } from "react";

const useInput = (initialValue='', effect) => {
    const [value, setValue] = useState(initialValue);

    const changeHandler=useCallback(e=>{
        if( effect ) effect(e);
        setValue(e.target.value);
    },[effect]);

    const reset=useCallback( ()=> setValue(""),[]);

    return useMemo(()=>({
        value,
        setValue,
        reset,
        bind: {
            value,
            onChange:changeHandler
        }
    }),[value, setValue, changeHandler, reset]);
};

export default useInput;
