import React from 'react';
import './KeyBoardNumeric.scss';
import {ReactComponent as LeftArrow} from "../../assets/images/icons/leftArrow.svg";

const KeyBoardNumeric = ({selectNumber,backSpaceHandler}) => {
    return (
            <div className="Login-keyWrapper">
                <div  className="Login-keyRow">
                    <button data-key = "7"  onClick={selectNumber} className="Login-key">
                        <p>7</p>
                    </button>
                    <button data-key = "8" onClick={selectNumber} className="Login-key">
                        <p>8</p>
                    </button>
                    <button data-key = "9" onClick={selectNumber} className="Login-key">
                        <p>9</p>
                    </button>
                </div>
                <div className="Login-keyRow">
                    <button data-key = "4" onClick={selectNumber} className="Login-key">
                        <p>4</p>
                    </button>
                    <button data-key = "5" onClick={selectNumber} className="Login-key">
                        <p>5</p>
                    </button>
                    <button data-key = "6" onClick={selectNumber} className="Login-key">
                        <p>6</p>
                    </button>
                </div>
                <div className="Login-keyRow">
                    <button data-key = "1" onClick={selectNumber} className="Login-key">
                        <p>1</p>
                    </button>
                    <button data-key = "2" onClick={selectNumber} className="Login-key">
                        <p>2</p>
                    </button>
                    <button data-key = "3" onClick={selectNumber} className="Login-key">
                        <p>3</p>
                    </button>
                </div>
                <div className="Login-keyRow">
                    <button data-key = "0" onClick={selectNumber} className="Login-key">
                        <p>0</p>
                    </button>
                    <button onClick={backSpaceHandler} className="Login-key Login-key-backSpace">
                        <LeftArrow/>
                    </button>
                </div>
            </div>
    )
};
export default KeyBoardNumeric