import React,{useCallback} from 'react';
import './SpecialProductModal.scss';
import Modal from "../../../../../components/Modal/Modal";
import Button from "../../../../../components/Button/Button";
import {ReactComponent as KeyBoardIcon} from "../../../../../assets/images/icons/keyboard.svg";
import Counter from "../../../../../components/Counter/Counter";
import {OROZCO_PRODUCT_SPECIAL_ID} from "../../../../../services/modelUtils/orozcoProductUtils";

const SpecialProductModal = ({onClose,quantity,setQuantity,onOpenKeyboard,value,onChange,onSave,isASpecialProductOnTheList}) => {

    const handleSave = useCallback(()=>{
        onSave({
            id:OROZCO_PRODUCT_SPECIAL_ID,
            quantity:quantity,
        });
        onClose();
    },[onSave,onClose,quantity]);

    return (
        <Modal
            title="Platillo especial"
            onClose={onClose}
            className={'ConfirmationModal'}
        >
            {isASpecialProductOnTheList !== -1 &&
            <p className="SpecialProductModal-warning">Ya hay un platillo especial agregado, deberas eliminarlo para agregar otro platillo especial diferente.</p>
            }

            <div className="SpecialProductModal-descriptionArea full-width">
                 <textarea  onChange={onChange} value={value}  className="CommentBox-textArea SpecialProductModal-textArea full-width" placeholder={"Descripción del platillo"}/>
                <button  onClick={onOpenKeyboard} className="SpecialProductModal-keyBoardIcon">
                    <KeyBoardIcon/>
                </button>
            </div>

            <div className="SpecialProductModal-counter full-width">
                <Counter
                    value={quantity}
                    onChange={setQuantity}
                />
            </div>
                <Button onClick={onClose}>Cancelar</Button>
                <Button disabled={!value} blue onClick={handleSave}>
                    Guardar
                </Button>
        </Modal>
    )
};
export default SpecialProductModal