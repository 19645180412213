import React, {useMemo} from 'react';
import useCallbackCreator from "use-callback-creator";
import CheckInExtraField from './CheckInExtraField';
import _ from 'lodash';

const CheckInExtraFieldsForm=({checkIn, onChange})=>{

    const checkInExtraFields=useMemo(()=>{
        if(!checkIn.loungeAccessMethod || !checkIn.loungeAccessMethod.config|| !checkIn.loungeAccessMethod.config.extraFields)
            return [];
        //Select fields that are one per check in
        return  checkIn.loungeAccessMethod.config.extraFields.filter( extraField=>!extraField.onePerCompanion && !extraField.onePerChild );
    },[checkIn.loungeAccessMethod]);
    const adultsExtraFields=useMemo(()=>{
        if(!checkIn.loungeAccessMethod || !checkIn.loungeAccessMethod.config|| !checkIn.loungeAccessMethod.config.extraFields)
            return [];
        //Select fields that are one per adult
        return  checkIn.loungeAccessMethod.config.extraFields.filter( extraField=>extraField.onePerCompanion );
    },[checkIn.loungeAccessMethod]);
    const childrenExtraFields=useMemo(()=>{
        if(!checkIn.loungeAccessMethod || !checkIn.loungeAccessMethod.config|| !checkIn.loungeAccessMethod.config.extraFields)
            return [];
        //Select fields that are one per adult
        return  checkIn.loungeAccessMethod.config.extraFields.filter( extraField=>extraField.onePerChild );
    },[checkIn.loungeAccessMethod]);

    const handleExtraFieldChange=useCallbackCreator((info, {target})=>{
        const [type, index, name]=info.split('~|~');

        const extraFields = checkIn.extraFields?{...checkIn.extraFields}:{};
        extraFields[name]= extraFields[name]?{...extraFields[name]}:{};

        if(type==='guest')
            extraFields[name].guest= target.value;
        else{
            if(!extraFields[name][type])
                extraFields[name][type] = [];
            else
                extraFields[name][type]=[...extraFields[name][type]];
            extraFields[name][type][index]=target.value;
        }


        onChange({...checkIn, extraFields});
    },[checkIn, onChange]);


    if(!checkIn || !checkIn.loungeAccessMethod)
        return null;

    //Render extra fields for each adult
    const adults=Number(checkIn.adultsNumber);
    const kids=Number(checkIn.kidsNumber);

    const adultsArray = [...new Array(adults-1)].map((i, k)=>k);
    const kidsArray = [...new Array(kids)].map((i, k)=>k);

    // Including guest
    const includingGuest=adultsExtraFields.filter(extraField=>extraField.includingGuest);

    return (
        <>
            {!!checkInExtraFields.length&&
            checkInExtraFields.map((extraField, i)=>
                <div className="form-row" key={i}>
                    <div className="form-column full-width">
                        <CheckInExtraField
                            title={extraField.name}
                            checkIn={checkIn}
                            handleExtraFieldChange={handleExtraFieldChange}
                            identifier={['guest', null, extraField]}
                        />
                    </div>
                </div>
            )}
            {_.chunk(includingGuest, 2).map((row, rowIndex)=>(
                <div className="form-row" key={'guest'+rowIndex}>
                    {row.map( (extraField, i)=>
                    <div className="form-column half-width" key={i}>
                        <CheckInExtraField
                            title={extraField.name+' - titular'}
                            checkIn={checkIn}
                            handleExtraFieldChange={handleExtraFieldChange}
                            identifier={['guest', null, extraField]}
                        />
                    </div>)}
                </div>
            ))}
            { adultsArray.map(i=>
                         _.chunk(adultsExtraFields,2).map((row, rowIndex)=>(
                             <div className="form-row" key={'adult'+rowIndex}>
                                {row.map( (extraField, fieldIndex)=>
                                    <div className="form-column half-width" key={fieldIndex}>
                                        <CheckInExtraField
                                            title={extraField.name+` - acompañante ${i+1}`}
                                            checkIn={checkIn}
                                            handleExtraFieldChange={handleExtraFieldChange}
                                            identifier={['companions', i, extraField]}
                                        />
                                    </div>)}
                            </div>
                         )))}
            { kidsArray.map(i=>
                         _.chunk(childrenExtraFields,2).map((row, rowIndex)=>(
                             <div className="form-row" key={'child'+rowIndex}>
                                {row.map( (extraField, fieldIndex)=>
                                    <div className="form-column half-width" key={fieldIndex}>
                                        <CheckInExtraField
                                            title={extraField.name+` - niño ${i+1} `}
                                            checkIn={checkIn}
                                            handleExtraFieldChange={handleExtraFieldChange}
                                            identifier={['kids', i, extraField]}
                                        />
                                    </div>)}
                            </div>
                         )))}
        </>
    );
};

export default CheckInExtraFieldsForm;
