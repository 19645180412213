import React from 'react';
import './NewReservationCommentBox.scss';
import Button from "../../../../components/Button/Button";

const NewReservationCommentBox = ({onOpenCommentBox,isCommentBoxOpen,value,name,onChange}) => {

    return (
        <div className="form-row-single centerItemInARow">
            <Button onClick={onOpenCommentBox} className="half-width">{isCommentBoxOpen?"Cerrar caja de comentarios":"Agregar comentario"}</Button>
            {isCommentBoxOpen &&
            <textarea onChange={onChange} placeholder="Escribe un comentario" value={value} name={name} className="CommentBox-textArea NewReservation-commentBox  full-width"/>
            }
        </div>
    )
};
export default NewReservationCommentBox
