
const notifierDummy={
    error:()=>{},
    success:()=>{},
    info:()=>{},
    warning:()=>{},
    isDummy: true
};

const ref={notifier: notifierDummy};

export const setNotifier=(notifier)=>{
    if(!ref.notifier.isDummy)
        return;

    const oldError=notifier.error.bind(notifier);
    notifier.error=(msg, title, opts={})=> {
        oldError(
            msg,
            title,
            {
                showAnimation: 'animated rubberBand faster',
                timeOut:5000,
                ...opts
            });};

    const oldSuccess=notifier.success.bind(notifier);
    notifier.success=(msg, title, opts={})=> {
        oldSuccess(
            msg,
            title,
            {
                showAnimation: 'animated rubberBand faster',
                timeOut:5000,
                ...opts
            });};

    const oldWarning=notifier.warning.bind(notifier);
    notifier.warning=(msg, title, opts={})=> {
        oldWarning(
            msg,
            title,
            {
                showAnimation: 'animated rubberBand faster',
                timeOut:10000,
                ...opts
            });};

    ref.notifier=notifier;
};

export const getNotifier=()=>ref.notifier;
