import React,{useContext, useCallback} from 'react';
import './MenuFoodCard.scss';
import MenuAmountSelect from "./MenuAmountSelect/MenuAmountSelect";
import {ApiContext} from "../../../../../services/api/api-config";
import className from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";
import {isPricedProduct, isPricedProductButCanBeFree} from "../../../../../services/modelUtils/orozcoProductUtils";


const MenuFoodCard = ({product,onSelect, quantity, onNewProductOrder, removeButton=false, onRemove, showNewOrderButton})=>{
    const api=useContext(ApiContext);


    let image;
    if(product.productPhoto)
        image={backgroundImage:`url(${api.appFiles.getFileUrl(product.productPhoto)})`};

    const handleSelect=useCallback(()=>{
        onSelect(product);
    },[onSelect, product]);

    const handleNewProductOrder=useCallback((e)=>{
        e.stopPropagation();
        onNewProductOrder(product);
    },[onNewProductOrder, product]);

    const handleRemove = useCallback((e)=>{
        e.stopPropagation();
        onRemove && onRemove(product);
    }, [onRemove, product]);

    return(
        <div onClick={handleSelect} className='MenuFoodCard'  style={image}>
                {isPricedProduct(product)&&
                <span className={className('money-sign', !isPricedProductButCanBeFree(product)?'red':'')}>
                    <FontAwesomeIcon icon={faDollarSign} />
                </span>}

                <p>{product.name}</p>
                <MenuAmountSelect quantity={quantity}/>

            {removeButton || !showNewOrderButton?
                <button className={className("remove-button",quantity?'open':'')} onClick={handleRemove}>
                    <FontAwesomeIcon icon={faMinus} />
                </button>
            :
                <button className={className("add-button",quantity?'open':'')} onClick={handleNewProductOrder}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            }
        </div>
    )
};
export default MenuFoodCard;
