import React,{useState,useCallback,useContext,useEffect,useMemo} from 'react';
import './VisitInfo.scss';
import Bill from "./Bill/Bill";
import classNames from 'classnames';
import Button from "../../../../components/Button/Button";
import FlightNumber from "./FlightNumber/FlightNumber";
import CommentBox from "./CommentBox/CommentBox";
import NumberOfVisits from "./NumberOfVisits/NumberOfVisits";
import {getNotifier} from "../../../../services/notifierUtils";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";
import LoungeAvailable from "../../../../components/TopBar/Components/LoungeAvailable/LoungeAvailable";
import useBoolean from "../../../../hooks/useBoolean";
import LoungeTransferModal from "./LoungeTransferModal/LoungeTransferModal";
import {isVisitActive, visitInfoSGroups, visitOrozcoHistorySGroups} from "../../../../services/modelUtils/visitUtils";
import {paths} from "../../../../services/routes/appRoutes";
import {Link, useHistory} from "react-router-dom";
import CheckInInfo from "./CheckInInfo/CheckInInfo";
import CheckInBreakDown from "./CheckInInfo/CheckInBreakDown/CheckInBreakDown";
import CheckInFormModal from "./CheckInInfo/CheckInFormModal/CheckInFormModal";
import _ from 'lodash';
import {activeCheckInsFromVisit} from "../../../../services/modelUtils/visitUtils";
import CheckOutModal from "./CheckOutModal/CheckOutModal";
import HostName from "./HostName/HostName";
import GuestVisitHistory from "./GuestVisitHistory/GuestVisitHistory";
import {employeeFullName} from "../../../../services/modelUtils/employeeUtils";
import OrderPaymentModal from "../../../../components/PaymentModals/OrderPaymentModal";
import {isCheckInExpired, orderCheckInsByReCheckIn} from '../../../../services/modelUtils/checkInUtils';
import ServiceAndExtraChargeAdder from "./ServiceAndExtraChargeAdder/ServiceAndExtraChargeAdder";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import {guestCommentsSGroups, guestProfileCommentsCustomProp} from "../../../../services/modelUtils/guestUtils";
import { SeatsList } from './SeatsList/SeatsList';



const VisitInfo = ({visitId, openMoreGuestInfo})=>{

    const api=useContext(ApiContext);
    const [visit, setVisit] = useState();
    const [visitWithOrder, setVisitWithOrder] = useState();

    // ------ Load Visit with check ins ----------
    const loadVisit = useCallback(()=>{
        if(!visitId) return;

        api.visits.get({params:{ id:visitId, sGroups:visitInfoSGroups, visitInfo: true }, customProp:'_'})
            .then( visits=> visits?.length === 1 && setVisit(visits[0]) );
        api.visits.get({params:{ id:visitId, sGroups:visitOrozcoHistorySGroups }, customProp:'_'})
            .then( visits=> visits?.length === 1 && setVisitWithOrder(visits[0]) );
    },[api, visitId]);
    useEffect(loadVisit, [loadVisit]);


    // -----------  New comments handling ------------
    const [comment, setComment]=useState('');
    const savingCommentId='@VisitInfo.comment.create';
    const handleCommentSave=useCallback(()=>{
        if(!comment)
            return getNotifier().error('Escribe tu comentario para que podamos guardarlo.');
        if(!visit || !visit.guest?.id)
            return;

        setComment('');
        api.comments.createForVisit({text:comment, visit: visit.id, loadingId: savingCommentId})
            .then(()=>{
                //reload comments on redux
                api.guests.get({params: { id: visit.guest.id, sGroups:guestCommentsSGroups }, customProp:guestProfileCommentsCustomProp });
            });

    },[api, comment, visit]);

    const loadingComment=useSelector(({loadingIds})=>!!loadingIds[savingCommentId]);

    // --------------- Lounge transfer ---------------
    const [loungeDropdownOpen,,,toggleLoungeDropdownOpen]=useBoolean();
    const [promptTransferLounge, setPromptTransferLounge]=useState(null);

    const cancelTransfer=useCallback(()=>setPromptTransferLounge(null),[]);

    const history = useHistory();
    const handleTransferDone=useCallback(()=>{
        history.replace(paths.frontDesk);
    },[history]);

    const orderedCheckIns = useMemo(
        ()=>visit? orderCheckInsByReCheckIn(visit.checkIns):[],
        [visit]);


    // -------- Check in details
    const [selectedCheckInIndex, setSelectedCheckInIndex]=useState(0);
    const [checkInTimeSelected,setCheckInTimeSelected] = useState('');
    const selectedCheckIn = orderedCheckIns?.[ selectedCheckInIndex ] || null;

    // -------- Adding check in
    const [addingCheckIn, startAddingCheckIn, stopAddingCheckIn]=useBoolean();

    // --------- Check out---------------
    const [checkingOut, startCheckingOut, stopCheckingOut]=useBoolean();
    const activeCheckIns=useMemo(()=>visit?activeCheckInsFromVisit(visit):[],[visit]);

    const handleCheckInChange=useCallback(()=>{
        loadVisit();
        stopAddingCheckIn();
        stopCheckingOut();
    },[loadVisit,stopAddingCheckIn, stopCheckingOut]);

    // ------ Charge -----
    const [chargeModal, showChargeModal, hideChargeModal]=useBoolean();
    const handlePayment=useCallback(()=>{
        hideChargeModal();
        loadVisit();
    },[loadVisit, hideChargeModal]);

    //---- display stuff ---
    const [displayCurrency, setDisplayCurrency]=useState('MXN');

    const hasUnexpiredCheckIn = _.reduce(visit?.checkIns||[], (r, c)=>
                                        r||!isCheckInExpired(c), false);
    const showWaiterMenuButton = visit&&visit?.employeesAttendingVisit[0]&&hasUnexpiredCheckIn;

    if(!visit)
        return <div className='center'><LoadingAnimation /></div>

    return(
        <div className={classNames('VisitInfo')}>
            <div className="VisitInfo-left animated slideInUp faster">
                <FlightNumber visitId={visitId} />
                <div className="VisitInfo-servicesAndCheckIn">
                    <div className='history-and-additions'>
                        <ServiceAndExtraChargeAdder
                            visit={visitWithOrder}
                            onChange={loadVisit}
                        />

                        <GuestVisitHistory
                            visit={visitWithOrder}
                            onChange={loadVisit}
                        />
                    </div>

                    <div className="VisitInfo-checkInInfo">
                        <CheckInInfo
                            checkIns={orderedCheckIns}
                            onCheckInSelectIndex={setSelectedCheckInIndex}
                            checkInTiming={setCheckInTimeSelected}
                            selectedCheckInId={selectedCheckIn && selectedCheckIn.id}
                        />
                        {selectedCheckIn &&
                        <CheckInBreakDown
                            checkIn={selectedCheckIn}
                            checkInTiming={checkInTimeSelected}
                            visit={visit}
                            guest={visit.guest}
                            onChange={loadVisit}
                        />}
                    </div>
                </div>
                <Button
                    className="VisitInfo-checkout"
                    solid
                    onClick={startCheckingOut}
                    disabled={!activeCheckIns.length}
                >{activeCheckIns.length?'Check-Out':'Visita terminada'}</Button>
            </div>
            {window.matchMedia("(min-width:1025px)").matches &&
            <div className="VisitInfo-right animated slideInRight faster">
                <Bill
                    visit={visitWithOrder}
                    currency={displayCurrency}
                    onCurrencyChange={setDisplayCurrency}
                    onPayClick={showChargeModal}
                />
                <NumberOfVisits guestId={visit?.guest?.id} />
                <HostName
                    hostName={visit && visit.employeesAttendingVisit[0] && employeeFullName(visit.employeesAttendingVisit[0].employee)}
                />
                
                <SeatsList visit={visit} />

                <div className="VisitInfo-btnSection">
                    <Link to={paths.visitMenu.replace(':visitId',visit.id)} className='VisitInfo-btn full-width'>
                        {showWaiterMenuButton&&
                        <Button
                        className="VisitInfo-btn full-width"
                        main
                        disabled={!isVisitActive(visit)}
                        >Ir a comanda</Button>}</Link>
                    <Button
                        className="VisitInfo-btn full-width"
                        main
                        onClick={startAddingCheckIn}
                        disabled={!isVisitActive(visit)}
                    >Nuevo Método de Acceso</Button>

                    <Button
                        className="VisitInfo-btn full-width transfer-btn"
                        disabled={!isVisitActive(visit)}
                        onClick={toggleLoungeDropdownOpen}
                    >
                        Transferir a sala
                        {loungeDropdownOpen &&
                        <LoungeAvailable
                            onSelect={setPromptTransferLounge}
                        />}
                    </Button>

                </div>
                <CommentBox
                    value={comment}
                    onChange={setComment}
                    onSave={handleCommentSave}
                    disabled={loadingComment}
                />
            </div>
            }
            {openMoreGuestInfo && window.matchMedia("(max-width:1024px)").matches &&
                <div className={classNames("VisitInfo-right-tabletMode",openMoreGuestInfo?'tabletModeOpen':'')}>
                    <div className="VisitInfo-right animated slideInRight faster">
                        <Bill
                            visit={visitWithOrder}
                            currency={displayCurrency}
                            onCurrencyChange={setDisplayCurrency}
                        />
                        <NumberOfVisits guestId={visit?.guest?.id} />
                        <HostName
                            hostName={visit && visit.employeesAttendingVisit[0] && employeeFullName(visit.employeesAttendingVisit[0].employee)}
                        />
                        <div className="VisitInfo-btnSection">
                            <Button
                                className="VisitInfo-btn full-width"
                                main
                                onClick={startAddingCheckIn}
                                disabled={!isVisitActive(visit)}
                            >Nuevo Check in</Button>

                            <Button
                                className="VisitInfo-btn full-width transfer-btn"
                                disabled={!isVisitActive(visit)}
                                onClick={toggleLoungeDropdownOpen}
                            >
                                Transferir a sala
                                {loungeDropdownOpen &&
                                <LoungeAvailable
                                    onSelect={setPromptTransferLounge}
                                />}
                            </Button>

                        </div>
                        <CommentBox
                            value={comment}
                            onChange={setComment}
                            onSave={handleCommentSave}
                            disabled={loadingComment}
                        />
                    </div>
                </div>
            }
            {addingCheckIn &&
            <CheckInFormModal
                onClose={stopAddingCheckIn}
                guest={visit?.guest}
                visit={visit}
                onFinish={handleCheckInChange}
            />}

            {promptTransferLounge &&
                <LoungeTransferModal
                guest={visit?.guest}
                lounge={promptTransferLounge}
                visit={visit}
                onCancel={cancelTransfer}
                onFinish={handleTransferDone}
                />}

            {checkingOut &&
                <CheckOutModal
                    onClose={stopCheckingOut}
                    guest={visit?.guest}
                    visit={visit}
                    checkIns={activeCheckIns}
                    onFinish={handleCheckInChange}
                />}

            { chargeModal &&
            <OrderPaymentModal
                onClose={hideChargeModal}
                visit={visitWithOrder}
                onPay={handlePayment}
            />}

        </div>
    )
};
export default VisitInfo;
