import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faMoneyBillAlt} from "@fortawesome/free-regular-svg-icons";
import {ReactComponent as MxFlag} from "../../../../assets/images/icons/mexicoFlag.svg";
import {moneyFormatter} from "../../../../services/currency/currencyUtils";
import {ReactComponent as UsaFlag} from "../../../../assets/images/icons/usaFlag.svg";
import {ReactComponent as EuFlag} from "../../../../assets/images/icons/euFlag.svg";
import './CashRegisterMoneyList.scss';
import classNames from 'classnames';

const CashRegisterMoneyList = ({details, ...props}) => {

    const amounts = details && details.reduce((amounts, detail) => {
        if (detail.paymentMethod)
            amounts[detail.currency + detail.paymentMethod.name] = detail.closingAmount;
        else {
            amounts[detail.currency + detail.orozcoPaymentMethod.name] = detail.closingAmount;
        }
        return amounts;
    }, {});

    return (
        <ul className={classNames('CashRegisterMoneyList amounts-list', props.className)}>
            <li>
                <FontAwesomeIcon icon={faCreditCard} className='payment-icon'/>{' '}
                <MxFlag className='flag'/>{' '}
                <span className='amount'>$ {amounts ? moneyFormatter(amounts['MXNTarjeta'] || 0) : '-'} MXN{' '}</span>
                <span className='money-type'>TARJETA</span>
            </li>
            <li>
                <FontAwesomeIcon icon={faMoneyBillAlt} className='payment-icon'/>{' '}
                <MxFlag className='flag'/>{' '}
                <span className='amount'>$ {amounts ? moneyFormatter(amounts['MXNEfectivo'] || 0) : '-'} MXN{' '}</span>
                <span className='money-type'>EFECTIVO</span>
            </li>
            <li>
                <FontAwesomeIcon icon={faMoneyBillAlt} className='payment-icon'/>{' '}
                <UsaFlag className='flag'/>{' '}
                <span className='amount'>$ {amounts ? moneyFormatter(amounts['USDEfectivo'] || 0) : '-'} USD{' '}</span>
                <span className='money-type'>EFECTIVO</span>
            </li>
            <li>
                <FontAwesomeIcon icon={faMoneyBillAlt} className='payment-icon'/>{' '}
                <EuFlag className='flag'/>{' '}
                <span className='amount'>$ {amounts ? moneyFormatter(amounts['EUREfectivo'] || 0) : '-'} EUR{' '}</span>
                <span className='money-type'>EFECTIVO</span>
            </li>
        </ul>
    )
};

export default CashRegisterMoneyList;
