import React, {useEffect} from 'react';
import Select from 'react-select';
import reactSelectStyles from '../../../../assets/styles/react-select-styles';
import RadioSelector from '../../../../components/RadioSelector/RadioSelector';
import './NewFlight.scss';

const flightTypeOptions = [{label:'Salida', value:'outbound'}, {label:'Llegada', value:'arrival'}];
const airlineMapper = a => ({
    value: a,
    label: a.name
});

const NewFlight = ({airlineOptions, number, destination, airline, isArrival, handleSimpleChange})=>{
    const discardNewFlight = ()=>{
        handleSimpleChange('flightNumber')(undefined);
        handleSimpleChange('flightDestination')(undefined);
        handleSimpleChange('flightAirline')(undefined);
        handleSimpleChange('flightIsArrival')(undefined);
    };
    useEffect(()=>{
        if(!airline){
            return;
        }
        if(!airline.code){
            return;
        } 
        if(number.slice(0, airline.code.length)!==airline.code){
            handleSimpleChange('flightNumber')(airline.code);
        }
    }, [number, airline, handleSimpleChange]);
    return (
        <div className='NewFlight'>
            <div className='form-row'>
                <div className='form-column half-width'>
                    <p>Aerolínea:</p>
                    <Select styles={reactSelectStyles}
                            options={airlineOptions.map(airlineMapper)}
                            placeholder='Escribe para buscar...'
                            value={airline?airlineMapper(airline):null}
                            onChange={({value})=>handleSimpleChange('flightAirline')(value)}
                            />
                </div>
                <div className='form-column half-width'>
                    <p>Destino:</p>
                    <input className='davinci-input'
                           value={destination}
                           onChange={e=>handleSimpleChange('flightDestination')(e.target.value)}
                           />
                </div>
            </div>
            <div className='form-row'>
                <div className='form-column half-width'>
                    <p>Número de vuelo:</p>
                    <input className='davinci-input'
                           value={number}
                           onChange={e=>handleSimpleChange('flightNumber')(e.target.value)}
                           />
                </div>
                <div className='form-column half-width'>
                    <p>Tipo de vuelo:</p>
                    <div>
                        <RadioSelector
                            options={flightTypeOptions}
                            value={isArrival?'arrival':'outbound'}
                            onChange={()=>handleSimpleChange('flightIsArrival')(!isArrival)}
                        />
                    </div>
                </div>
            </div>
            <button
                className='discard-btn'
                onClick={discardNewFlight}>Descartar y seleccionar vuelo de lista</button>
        </div>
    );
};

export default NewFlight;
