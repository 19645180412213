export const onlyText = (value)=> {
    return(
    value.replace(/[0-9$&+,:;{}=?°¡¿@#"|¬<>.\-^*()%!]/g, '')
    )
};

export const onlyNumbers = (value)=>{
    return(
        value.replace(/[A-z]/g, '')
    )
};

export const validEmail = (value)=>{
    if((/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/).test(value.currentTarget.value)){
        return true
    }
};


