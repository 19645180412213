import React from 'react';
import './ReCheckInArrow.scss';

const ReCheckInArrow = () => (
    <div className='ReCheckInArrow'>
        <div className='ReCheckInArrow-container'>
            <div className='ReCheckInArrow-top-arrow'/>
            <div className='ReCheckInArrow-bottom-arrow'/>
        </div>
    </div>

);

export default ReCheckInArrow;
