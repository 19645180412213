import React, {useMemo, useEffect, useContext} from 'react';
import './FullScreenEmployeeProfilePicker.scss';
import FullscreenPicker from "../FullscreenPicker/FullscreenPicker";
import WaiterCard from "../../scenes/Waiter/AttendingVisits/Components/WaiterCard/WaiterCard";
import _ from 'lodash';
import {ApiContext} from "../../services/api/api-config";
import useActualLounge from "../../hooks/useActualLounge";
import {cardSGroups} from "../../services/modelUtils/orozcoEmployeeProfileUtils";
import {useSelector} from "react-redux";
import moment from "moment";

const FullScreenEmployeeProfilePicker = ({onHide, onSelect, title, highlightMe}) => {
    const api = useContext(ApiContext);
    const actualLounge = useActualLounge();
    const employeeProfiles = useSelector(({api}) => api.orozcoEmployeeProfiles || []);

    useEffect(() => {
        api.orozcoEmployeeProfiles.get({
            params: {
                sGroups: cardSGroups,
                'activeDate[after]': moment().subtract(8.5, 'hours').format('YYYY-MM-DD HH:mm'),
                activeLounge: actualLounge.id,
                pagination: false
            }
        })
    }, [actualLounge, api]);

    const sortedProfiles = useMemo(() => {
        if (highlightMe && highlightMe.employee) {
            return _.sortBy(employeeProfiles, (profile) => {
                return (profile && profile.employee && profile.employee.id === highlightMe.employee.id)?0:1;
            })
        }else{
            return employeeProfiles;
        }
    }, [employeeProfiles, highlightMe]);


    return (
        <FullscreenPicker onHide={onHide} className='FullScreenEmployeePicker'>
            {title && <h1>{title}</h1>}
            {sortedProfiles && sortedProfiles.map((profile) => (
                <WaiterCard
                    highlight={highlightMe && highlightMe.employee && (highlightMe.employee.id === profile.employee.id)}
                    onClick={() => onSelect(profile)}
                    orozcoEmployeeProfile={profile} key={profile.id}/>
            ))}
        </FullscreenPicker>
    );

};

export default FullScreenEmployeeProfilePicker;
