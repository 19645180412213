import React from 'react';
import './FlightCards.scss';
import {minutesToTimeString} from "../../../services/timeUtils";
import flightStatusString from "../../../services/flightStatusString";
import className from 'classnames';
import EditDelayTimeModal from './EditDelayTimeModal';
import useBoolean from '../../../hooks/useBoolean';
import {ReactComponent as ClockIcon} from '../../../assets/images/icons/ClockIcon.svg';

const pad = n => `${n}`.padStart(2, '0');

const FlightCards = ({infoCard, onChange}) => {
    const [editingDelayTime, editDelayTime, stopEditingDelayTime] = useBoolean();
    const time = infoCard.currentFlightStatus?infoCard.currentFlightStatus.delayMinutes:0;
    return (
        <>
            <div className="FlightCards full-width">
                <div className={className("Flights-column FlightCards-statusIndicator",infoCard.status==="DELAYED"?'statusIndicatorFailed':'')}/>
                <div className="FlightCards-airline FlightCards-column">
                    <p>{infoCard.airline && infoCard.airline.name}</p>
                </div>
                <div className="FlightCards-flightNumber FlightCards-column">
                    <p>{infoCard.number}</p>
                </div>
                <div className="FlightCards-destination FlightCards-column">
                    <p>{infoCard.destination}</p>
                </div>
                <div className="FlightCards-time FlightCards-column">
                    <p>{minutesToTimeString(infoCard.departureTime)}</p>
                </div>
                <div className="FlightCards-gate FlightCards-column">
                    <p>{infoCard.gate}</p>
                </div>
                <div className="FlightCards-status FlightCards-column">
                    <p>{flightStatusString(infoCard.status)}</p>
                </div>
                <div className="FlightCards-delayTime FlightCards-column">
                    <button onClick={editDelayTime}>
                    {time?<>{pad(parseInt(time/60))}:{pad(time%60)}</>:<ClockIcon/>}
                    </button>
                </div>
            </div>
            {editingDelayTime&&
                <EditDelayTimeModal
                        onChange={onChange}
                        onClose={stopEditingDelayTime}
                        flight={infoCard}/>
                }
        </>
    )
};
export default FlightCards;
