const reactSelectStyles= {
    input:(provided)=>{
        return {
            ...provided,
            color:'white',
        }
    },
    option:(provided, state)=>{
        return {
            ...provided,
            backgroundColor: state.isFocused?'#FF8902':'black',
            borderRadius: 4,
            color:'white'
        }
    },
    control:(provided)=>{
        return {
            ...provided,
            backgroundColor: 'black',
            border: 'none',
            borderRadius: 11,
        };
    },
    singleValue:(provided)=>{
        return {
            ...provided,
            color:'white'
        }
    },
    menu:(provided)=>{
        return {
            ...provided,
            backgroundColor: 'black',
            borderRadius: 11,
            border:'1px solid #FF8902'
        }
    },
};

export default reactSelectStyles;
