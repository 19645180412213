import React from 'react';
import Modal from '../../../../../components/Modal/Modal';
import Button from '../../../../../components/Button/Button';
import LoadingAnimation from '../../../../../components/LoadingAnimation/LoadingAnimation';
import './CancelServiceModal.scss';
import {useSelector} from 'react-redux';

const CancelServiceModal = ({onClose, service, cancelService}) => {
    const loading = useSelector(({loadingIds})=>loadingIds[ '@GuestVisitHistory.visit.update']);
    return (
        <Modal
            title='Cancelación de servicio'
            onClose={onClose}
            className='CancelServiceModal'
        >
            <p>¿Seguro deseas cancelar el servicio "{service.name}"?</p>
            <div className='buttons'>
                <Button onClick={onClose}>No hacer nada</Button>
                <Button blue onClick={()=>cancelService().then(onClose)} disabled={loading}>{loading?<LoadingAnimation/>:'Cancelar servicio'}</Button>
            </div>
        </Modal>
    );
};

export default CancelServiceModal;
