
export const PREFERRED_CAMERA_KEY = "davinciPreferredCamera";

export const savePreferredCamera = ( deviceId )=>{
    window.localStorage[PREFERRED_CAMERA_KEY] = deviceId;
}

export const getPreferredCamera = ()=>{
    return window.localStorage[PREFERRED_CAMERA_KEY];
}

export const getPngFromVideoElement = ( videoEl )=>{
    const canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    canvas.width = videoEl.videoWidth;
    canvas.height = videoEl.videoHeight;
    context.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
    return  canvas.toDataURL('image/png');
}
