export const menuSGroups = [
    "orozco_product_category_read",
    "orozco_product_category_read_orozco_products",
        "orozco_product_read",
        "orozco_product_read_prices",
        "orozco_product_read_orozco_product_modifiers",
            "orozco_product_modifier_read",
        "orozco_product_read_product_photo",
            "app_file_read",
    "orozco_product_category_read_child_categories",
];
