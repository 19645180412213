import React from 'react';
import './VisitsSideList.scss';
import {guestFullName} from "../../../../../services/modelUtils/guestUtils";
import useCallbackCreator from "use-callback-creator";
import classNames from 'classnames';

const VisitsSideList = ({visits=[], onVisitSelect, selectedVisit})=>{

    const handleVisitSelect = useCallbackCreator((i)=>{
        if(selectedVisit && visits[i].id===selectedVisit.id)
            onVisitSelect(null);
        else
            onVisitSelect(visits[i]);
    }, [visits, selectedVisit]);

    return (
        <div className='VisitsSideList animated slideInRight'>
            <div className="AttendingVisits-name">
                <p>Huéspedes</p>
            </div>
            <div className='guest-cards'>
                {visits.map((visit, i)=>
                    <div className={classNames('visit-card', selectedVisit && selectedVisit.id===visit.id && 'selected')} key={visit.id} onClick={handleVisitSelect(i)}>
                        <p>
                            {guestFullName(visit.guest)}{' '}
                            {visit.activePersons > 1 && (((visit.seats && visit.seats.length)||'0') + '/' + visit.activePersons)}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VisitsSideList;
