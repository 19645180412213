import React, {useContext,useEffect,useCallback,useState} from 'react';
import './Flights.scss';
import FlightCards from "./FlightCards/FlightCards";
import {ApiContext} from "../../services/api/api-config";
import {ReactComponent as SearchIcon} from "../../assets/images/icons/Lupa-icon.svg";
import _ from "lodash";
import useApiSelector from "../../hooks/useApiSelector";
import useCallbackCreator from "use-callback-creator/src";
import moment from "moment";
import classNames from 'classnames';
import {ReactComponent as KeyBoardIcon} from "../../assets/images/icons/keyboard.svg";
import KeyBoardFull from "../../components/KeyBoardFull/KeyBoardFull";
import KeyBoardTouch from "../../components/KeyBoardTouch/KeyBoardTouch";
import useBoolean from "../../hooks/useBoolean";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import TopBarProfile from "../../components/TopBar/Components/TopBarProfile/TopBarProfile";
import {isRemoteDavinci} from "../../services/remoteDavinciUtils";




const Flights = () => {


    const time = [...Array(24)];
    const api=useContext(ApiContext);

    const [filter, setFilter]=useState('');
    const [activeHour, setActiveHour]=useState(moment().hour());
    const [showInactiveFlights , setShowInactiveFlights] = useState(true);

    const getFlights=useCallback((filter, hour)=>{
        if(!hour && !filter)
            hour = 0;
        let timeGte = hour * 60;
        let timeLte = (hour * 60) + 60;

        if(filter){
            timeGte = null;
            timeLte=null;
        }
         api.flights.get({params:{
                isArrival: false,
                pagination: false,
                'departureTime[gte]':timeGte,
                'departureTime[lt]':timeLte,
                'order[departureTime]':'ASC',
                 'isActive': showInactiveFlights,
                search:filter?filter.trim():undefined,
                sGroups: ['flight_read', 'flight_read_current_flight_status', 'airline_read']
        }});
    },[api, showInactiveFlights]);

    const flight=useApiSelector('flights');

    const loadFlightsDebounced=useCallback(_.debounce(getFlights, 500),[getFlights]);

    const handleFilterChange=useCallback(({target})=>{
        setActiveHour(null);
        setFilter(target.value);
    }, []);

    useEffect(()=>{
        loadFlightsDebounced(filter, activeHour);
    },[loadFlightsDebounced, filter, activeHour]);

    useEffect(()=>{
        if(filter==="" && activeHour === null){
            setActiveHour(moment().hour())
        }
        if(filter){
            setActiveHour(null);
        }
/*        flightsDebounced();*/
    },[filter,activeHour]);

    const handleButtonClick=useCallbackCreator((index)=>{
        setFilter("");
        setActiveHour(Number(index))
    },[]);

    const [openKeyboard,,closeKeyboard,toggleKeyBoard] = useBoolean();

    const getValueFromInput = useCallback((valueFromInput)=>{
        setFilter(valueFromInput);
        loadFlightsDebounced(valueFromInput);
    },[loadFlightsDebounced]);

    const showInactiveFlightsHandler = () => {
        if (showInactiveFlights === true) {
            setShowInactiveFlights(null);
        } else {
            setShowInactiveFlights(true);
        }
        getFlights();
    };

    return (
        <div className="Flights">
            <TopBarProfile
             className='flightTopBar'
             filterButton={<button className='filterButton' onClick={showInactiveFlightsHandler}>{showInactiveFlights ? "Mostrar Inactivos" : "Ocultar Inactivos"}</button>}
            />
            <FixedMenu
                bottomBar={isRemoteDavinci()}
            />
            {openKeyboard &&
                <KeyBoardFull>
                    <KeyBoardTouch
                        valueForInputs={getValueFromInput}
                        valueInInput={filter}
                        onClose={closeKeyboard}
                    />
                </KeyBoardFull>
            }
               <div className="Flights-header">
                    <input
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Buscar Vuelos"
                        className="Flights-header-input"
                    />
                    <div className="Flights-searchIcon">
                        <SearchIcon />
                    </div>
                    <div onClick={toggleKeyBoard} className="Flights-keyBoardIcon">
                        <KeyBoardIcon/>
                    </div>
               </div>
                           <div className="Flights-title full-width">
                               <div/>
                               <div className="Flights-airline Flights-column">
                                   <p>Aerolinea / Airline</p>
                               </div>
                               <div className="Flights-flightNumber Flights-column">
                                   <p>Vuelo / Flight</p>
                               </div>
                               <div className="Flights-destination Flights-column">
                                   <p>Destino / Destination</p>
                               </div>
                               <div className="Flights-time Flights-column">
                                   <p>Hora / Time</p>
                               </div>
                               <div className="Flights-gate Flights-column">
                                   <p>Puerta / Gate</p>
                               </div>
                               <div className="Flights-status Flights-column">
                                   <p>Estado / Status</p>
                               </div>
                               <div className="Flights-column">
                                   <p>Tiempo de demora</p>
                               </div>
                           </div>
                   <div className="Flights-cardsWrapper">
                       {
                           flight.map((flight)=>{
                                return(
                                    <FlightCards
                                        key={flight.id}
                                        infoCard={flight}
                                        onChange={getFlights}
                                    />
                                )
                           })

                       }
                   </div>
            <div className="Flights-hour">
                {
                    time.map((_,index)=>{
                        return(
                            <button className={classNames("Flights-hour-item", activeHour===index?'active':'')} onClick={handleButtonClick(index)} key={index}>
                                <p>{index===0?"00":index}</p>
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
};
Flights.displayName='Flights';
export default Flights
