import React,{useMemo,useState,useCallback} from 'react';
import PaymentFormOverlay from "./PaymentFormOverlay/PaymentFormOverlay";
import {getAvailableCurrencies} from "../../services/modelUtils/loungeAccessMethodsUtils";
import pluralize from 'pluralize';
import useBoolean from "../../hooks/useBoolean";


const CheckInsPaymentModal = ({onClose, checkIns, guest, onPay})=>{

    const [payedCheckIns, setPayedCheckIns]=useState([]);
    const payingCheckIn=payedCheckIns.length;

    const [hidden, hide, unHide]=useBoolean();

    const products = useMemo(()=>([{
            name: pluralize(checkIns[payingCheckIn].loungeAccessMethod.name, checkIns[payingCheckIn].adultsNumber),
            prices: checkIns[payingCheckIn].loungeAccessMethod.config.prices,
            count: checkIns[payingCheckIn].adultsNumber
        }])
    ,[checkIns, payingCheckIn]);

    const handlePayment=useCallback((check)=>{

        const newPayedCheckIns=[...payedCheckIns, {
            ...checkIns[payingCheckIn],
            orozcoCheckInPayments: check.payments,
            price: check.total,
            chargeCurrency:check.currency
        }];

        //Everything is payed
        if(payingCheckIn===(checkIns.length-1))
            return onPay(newPayedCheckIns);
        //Pay the next check in
        setPayedCheckIns(newPayedCheckIns);
        hide();
        setTimeout(unHide, 200);
    },[payedCheckIns, payingCheckIn, onPay, hide, unHide, checkIns]);

    if(hidden)
        return null;

    return (
        <PaymentFormOverlay
            availableCurrencies={getAvailableCurrencies(checkIns[payingCheckIn].loungeAccessMethod)}
            onClose={onClose}
            guest={guest}
            products={products}
            onPay={handlePayment}
        />
    )
};

export default CheckInsPaymentModal;
