import React, {useState, useEffect, useCallback} from 'react';
import './RecursiveMenu.scss';
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator/src";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faHome} from "@fortawesome/free-solid-svg-icons";
import classNames from 'classnames';

const RecursiveMenu=({options=[], onSelect, selected})=>{

    const [savedOptions, setSavedOptions]=useState(options);
    const [newOptions, setNewOptions]=useState(options);

    useEffect(()=>{

        const opts=_.sortBy(options, o=>o.name);
        setNewOptions(opts);
        const to = setTimeout(()=>{
            setSavedOptions(opts);
            setNewOptions(null);
        }, 250);
        return ()=>clearTimeout(to);
    },[options]);

    const handleSelect = useCallbackCreator((index)=>{

        if(index===null)
            onSelect(null);
        else if(savedOptions[index] && onSelect)
            onSelect(savedOptions[index]);

    },[savedOptions, onSelect]);

    const goBack = useCallback(()=>{
        if(!selected)
            onSelect(null);
        else if(!selected.childCategories?.length)
            onSelect( selected.parentCategory?.parentCategory || null );
        else
            onSelect(selected?.parentCategory || null)
    },[selected, onSelect]);

    return (
        <div className='RecursiveMenu'>
            <div className={classNames('menu-inner',newOptions?'adding-options-front':'')}>
                <div className='menu-item text-center' onClick={handleSelect(null)}><FontAwesomeIcon icon={faHome}/></div>
                {!!selected &&
                <div className='menu-item text-center' onClick={goBack}><FontAwesomeIcon icon={faArrowLeft}/></div>}
                {savedOptions.map((option, i)=>
                    <div
                        key={option.id}
                        className={classNames('menu-item', selected && selected.id===option.id?'selected':'')}
                        onClick={handleSelect(i)}>
                        {option.name}
                    </div>
                )}
            </div>
            {newOptions &&
            <div className='menu-inner adding-options-back'>
                <div className='menu-item text-center' onClick={handleSelect(null)}><FontAwesomeIcon icon={faHome}/></div>
                {!!selected &&
                <div className='menu-item text-center' onClick={goBack}><FontAwesomeIcon icon={faArrowLeft}/></div>}
                {newOptions.map((option, i)=>
                    <div
                        key={option.id}
                        className={classNames('menu-item', selected && selected.id===option.id?'selected':'')}
                        onClick={handleSelect(i)}>
                        {option.name}
                    </div>
                )}
            </div>}
        </div>
    );
};

export default RecursiveMenu;
