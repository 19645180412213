import React, {useEffect, useContext, useState, useMemo, useCallback} from 'react';
import {createPortal} from 'react-dom';
import './LoungeMapModal.scss';
import useBackgroundBlur from "../../hooks/useBackgroundBlur";
import {ApiContext} from "../../services/api/api-config";
import {
    addVisitsToLoungeMap,
    loungeMapEditorSGroups,
    prepareLoungeMapForRender
} from "tgle-core/utils/loungeMapUtils";
import {useSelector} from "react-redux";
import LoungeMap from 'tgle-core/components/LoungeMap';
import {initialViewPort} from "tgle-core/utils/konvaUtils";
import Button from "../Button/Button";
import _ from 'lodash';
import classNames from 'classnames';
import {attendingVisitSGroups} from "../../services/modelUtils/employeeAttendingVisitUtils";

const emptyArr = [];
const LoungeMapModal = ({loungeId, onClose, onSelect})=>{

    useBackgroundBlur();

    const api = useContext(ApiContext);

    useEffect(() => {

        api.loungeMaps.get({params:{'lounge.id':loungeId, sGroups: loungeMapEditorSGroups}, customProp:'loungeForModal'});

        api.visits.get({
            params: {
                sGroups: attendingVisitSGroups,
                lounge: loungeId,
                'activePersons[gt]': 0,
                pagination: false,
            },
            customProp: 'allVisitsMapModal'
        });
    }, [api, loungeId]);


    const visits = useSelector(({api}) => api.allVisitsMapModal || emptyArr);
    const loungeMap = useSelector(({api})=>api.loungeForModal||emptyArr)[0];

    const preparedLoungeMap = useMemo(()=>
        loungeMap && addVisitsToLoungeMap(visits, prepareLoungeMapForRender(loungeMap)),
        [visits, loungeMap]);

    const [viewPort, setViewPort] = useState(initialViewPort);
    const [selection, setSelection] = useState({seats:[], guest:{name:'Reservación'}});

    const handleSelect = useCallback((seat)=>{
        if( !onSelect || seat?.type !== "seat")
            return;
        setSelection(({seats: old})=>{
            const oldIndex = _.findIndex(old, oldSeat=>oldSeat.id===seat.id);
            if(oldIndex===-1)
                return {seats:[...old, seat], guest:{name:'Reservación'}};
            const newSelection = [...old];
            newSelection.splice(oldIndex, 1);
            return {seats:newSelection, guest:{name:'Reservación'}};
        });
    },[onSelect]);

    const handleSaveSelection=useCallback(()=>{
        onSelect(selection.seats);
    },[selection, onSelect]);

    return createPortal(
        <div className={classNames('LoungeMapModal', onSelect && "with-buttons")}>

            <div className='overlay' onClick={onClose}/>

            <div className='map-container'>
                {preparedLoungeMap &&
                    <LoungeMap
                        loungeMap={preparedLoungeMap}
                        viewPort={viewPort}
                        mapType={'davinci-map'}
                        onViewPortChange={setViewPort}
                        onItemSelect={handleSelect}
                        selectedVisit={selection}
                        hideText
                    />}
                {onSelect &&
                    <div className='buttons-container'>
                        <Button main onClick={onClose}>Cerrar</Button>
                        <Button solid onClick={handleSaveSelection}>Guardar selección</Button>
                    </div>}
            </div>
        </div>
    , document.body);
};

export default LoungeMapModal;
