import React,{useState,useEffect,useContext,useCallback} from 'react';
import './RemoteFrontDesk.scss';
import NewReservation from "../Components/NewReservation/NewReservation";
import {ApiContext} from "../../../services/api/api-config";
import {getNotifier} from "../../../services/notifierUtils";
import {guestCheckInSGroups} from "../../../services/modelUtils/guestUtils";
import FixedMenu from "../../../components/FixedMenu/FixedMenu";
import RemoteTopBar from "../../../components/TopBar/RemoteTopBar/RemoteTopBar";

const RemoteFrontDesk=()=>{

    const [search, setSearch]=useState(null);//Filters to search similar guests
    const [selectedGuest]=useState(null);//A similar guest was selected
    const [fullGuest, setFullGuest]=useState(null);//After the selection we fetch the complete data of the guest

    const api=useContext(ApiContext);

    const reloadSelectedGuest = useCallback(() => {
        if(selectedGuest)
            api.guests.get({params:{sGroups:guestCheckInSGroups ,id:selectedGuest.id}, customProp:'FrontDeskGuest'})
                .then(guests=>{
                    if(guests.length)
                        setFullGuest(guests[0]);
                    else
                        getNotifier().error('Error al cargar información de huésped. ID');
                });
        else
            setFullGuest(null);
    }, [api, selectedGuest]);

    useEffect(reloadSelectedGuest,[reloadSelectedGuest]);

    const [selectedReservation, setSelectedReservation]=useState(null);

    const clearState=useCallback(()=>{
        setFullGuest(null);
        setSelectedReservation(null);
        setSearch(null);
    },[]);

    return (
        <div className='RemoteFrontDesk'>
            <FixedMenu bottomBar/>
            <RemoteTopBar/>

            <NewReservation
                onSearchChange={setSearch}
                searching={search}
                guest={fullGuest}
                reservation={selectedReservation}
                onClearState={clearState}
                allowOverflow
                fullWidth
                loungeField
                seatSelector
            />
        </div>
    )
};

export default RemoteFrontDesk;

/*
<SimilarResults
                        search={search}
                        onGuestSelect={setSelectedGuest}
                        selectedGuest={fullGuest}
                        reloadSelectedGuest={reloadSelectedGuest}
                    />
 */
