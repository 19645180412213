import React from 'react';
import className from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {isPricedProduct, isPricedProductButCanBeFree} from "../../services/modelUtils/orozcoProductUtils";
import ProductCreatedBy from './components/ProductCreatedBy/ProductCreatedBy';

const OrderProductPaymentIcon=({visit, orderProduct, onlyBlameCreator = false})=>{

    if(orderProduct?.canceledDate || !orderProduct)
        return null;

    const isCheck = (orderProduct?.payed || !orderProduct?.shouldBePayed);

    return (
        <span className={className(
            'priced-icon',
            isCheck?'green':
            isPricedProductButCanBeFree(orderProduct.orozcoProduct)?'':'red'
        )}>
            <ProductCreatedBy visit={visit} orderProduct={orderProduct}/>
            {
                (isPricedProduct(orderProduct.orozcoProduct) && !onlyBlameCreator) &&
                <FontAwesomeIcon icon={isCheck?faCheck:faDollarSign}/>
            }
        </span>
    );
};

export default OrderProductPaymentIcon;
