import React, {useMemo, useEffect, useState, useCallback, useContext} from 'react';
import Select from 'react-select';
import './GuestForm.scss';
import FullscreenDatePickerInput from "../../../../components/FullscreenDatePicker/FullscreenDatePickerInput";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {ReactComponent as Women} from "../../../../assets/images/icons/woman.svg";
import {ReactComponent as Man} from "../../../../assets/images/icons/man.svg";
import reactSelectStyles from "../../../../assets/styles/react-select-styles";
import {useSelector} from 'react-redux';
import _, { isEmpty } from 'lodash';
import {ApiContext} from '../../../../services/api/api-config';
import RadioSelector from "../../../../components/RadioSelector/RadioSelector";
import NewFlight from '../../../FrontDesk/Components/NewFlight/NewFlight';
import SelectDateTimePicker from "../../../../components/SelectDateTimePicker/SelectDateTimePicker";
import {isRemoteDavinci} from "../../../../services/remoteDavinciUtils";

/**
 * Configuraciones iniciales del componente
 *
 */
const flightTypeOptions = [{label:'Salida', value:'outbound'}, {label:'Llegada', value:'arrival'}];
const flightOptionMapper = f=>({
    value: f,
    label: f?`${f.airline?f.airline.name:'AEROLÍNEA NO REGISTRADA'} - ${f.number} - ${f.destination}`:''
});

/**
 * Propiedades que se exportan del componente
 */
export const guestFormFlightsProp = '@GuestForm.flights';

/**
 * ?
 */
const alwaysTrue=()=>true

/**
 * Componente GuestForm
 *
 * Componente para agregar un nuevo CheckIn
 */
export default function GuestForm({
    handleInputChange,
    constantRandom,
    handleSimpleChange,
    verifyEmail,
    checkInBtn,
    makeMexicoFirst,
    editingGuestInfo,
    showWarningMessage,
    form
})
{
    /**
     * Primero listamos todos los hooks useState
     */
    const [flightType, setFlightType] = useState('outbound');
    const [inputValue, setInputValue] = useState('');
     /**
      * Agrupamos los hooks useSelector
      */
    const flights = useSelector(({api})=>api.flights||[]);
    const airlines = useSelector(({api})=>api.airlines?api.airlines.filter(v=>!!v.name):[]);

    const flightsLoading = useSelector(({loadingIds})=>!!loadingIds[guestFormFlightsProp]);

    /**
     * Agrupamos los hooks useSelector
     */
    const api = useContext(ApiContext);

     /**
     * Agrupamos variables de configuracion
     */
     const {checkIns} = form;
    const loungeFlights =useMemo(()=>{
         return (checkIns && !isEmpty(checkIns))
             ? checkIns.reduce((a, {loungeAccessMethod}) => {
                 if(loungeAccessMethod?.config?.flights){
                     const flights = loungeAccessMethod?.config?.flights;
                     a = [...new Set([...a, ...flights])];
                     return a;
                 }
                 return a;
             }, [])
             : [];
     }, [checkIns]);


    const showingAddFlight = (
        typeof form.flightNumber !== 'undefined' &&
        typeof form.flightDestination !== 'undefined' &&
        typeof form.flightAirline !== 'undefined' &&
        typeof form.flightIsArrival !== 'undefined'
    );

    const flightOptions = useMemo(()=>{
        const filtered = _.filter(_.map(flights, flightOptionMapper), ({label})=>_.includes(label.toLowerCase(), inputValue.toLowerCase()));

        if(filtered.length ===0 && !flightsLoading){
            return [{
                value: 'customFlight',
                label: 'No se encontró el vuelo. ¿Desea agregarlo?'
            }];
        }
        return filtered;
    }, [flights, inputValue, flightsLoading]);

    // If a checkin with constrained flights is selected, check if a not available flight is selected and delete it
    const flight=form?.flight;

    /**
     * Agrupamos los hooks useCallBack
     */
    const onFlightsInputChange = useCallback((e)=>{
        setInputValue(e.trim());
    }, []);

    const onFlightChange = useCallback(o=>{
        if(o.value==='customFlight'){
            handleSimpleChange('flightNumber')('');
            handleSimpleChange('flightDestination')('');
            handleSimpleChange('flightAirline')(airlines[0]||null);
            handleSimpleChange('flightIsArrival')(false);
            handleSimpleChange('flight')(null);
            return;
        }
        handleSimpleChange('flight')(o?.value||null);

    }, [airlines, handleSimpleChange]);

    const retrieveFlights = useCallback(_.debounce((search, flightType)=>{
        const params = {isArrival: flightType==='arrival', isActive: true}
        if(!isEmpty(loungeFlights))
            params.id = loungeFlights;
        else {
            delete params.id;
            params.search = search;
        }
        api.flights.get({ params, loadingId: guestFormFlightsProp});
    }, 200), [api, flightType, loungeFlights]);

    /**
     * Ejecutamos el hook useEffect
     */
    useEffect(()=>{
        api.airlines.get({
            pagination: false
        }).then(as=>{
            if(showingAddFlight){
                handleSimpleChange('flightAirline')(as[0]);
            }
        })
        retrieveFlights(inputValue, flightType)

        const constrainedCheckIns = _.filter(checkIns, check=>check.loungeAccessMethod?.config?.flights?.length);
        //if there's an unconstrained access method we do not apply the filters
        if(!constrainedCheckIns.length || constrainedCheckIns.length !== checkIns?.length || !flight)
            return;

            const availableIds = _.flatten(constrainedCheckIns.map(check=>check.loungeAccessMethod?.config?.flights));

            if(availableIds.find(element => String(element) === String(flight.id)))
            {
                handleSimpleChange('flight')(flight);
            }
            else
            {
                handleSimpleChange('flight')(null);
            }

    }, [api, showingAddFlight, retrieveFlights, inputValue, flightType, checkIns, flight, handleSimpleChange]);

    return (
 <div className='GuestForm'>
            <div className="form-row">
                <div className="form-column half-width">
                    <p>Nombres:</p>
                    <input
                        type="text"
                        className="davinci-input toUpperCase"
                        onChange={handleInputChange}
                        value={form.name||''}
                        data-searchable="true"
                        name='name'
                        onFocus={showWarningMessage}
                        autoComplete={constantRandom('name')} />
                </div>
                <div className="form-column half-width">
                    <p>Apellidos:</p>
                    <input
                        className="davinci-input toUpperCase"
                        onChange={handleInputChange}
                        value={form.patLastName||''}
                        data-searchable="true"
                        name='patLastName'
                        autoComplete={constantRandom('patLastName')}/>
                </div>
            </div>
            <div className="form-row">

                <div className="form-column half-width">
                    <p>Fecha de Nacimiento:</p>
                    <FullscreenDatePickerInput
                        inputClassName='davinci-input'
                        value={form.birthday||null}
                        onChange={handleSimpleChange('birthday')}
                    />
                </div>

                <div className="form-column half-width genderSelector">
                    <div className="PaymentFormOverlay-payment-method">
                        <div className="PaymentFormOverlay-payment-method-creditCard women">
                            <input
                                className="womenOption"
                                id="women"
                                type="radio"
                                name="gender"
                                checked={!!(form && form.gender==='F')}
                                value='F'
                                onChange={handleInputChange}
                            />
                            <label className="womenLabel" htmlFor="women">
                                <Women/>
                                Mujer
                            </label>
                        </div>
                        <div className="PaymentFormOverlay-payment-method-cash men">
                            <input
                                className="menOption"
                                id="men"
                                type="radio"
                                name="gender"
                                checked={!!(form && form.gender==='M')}
                                value='M'
                                onChange={handleInputChange}
                            />
                            <label className="menLabel" htmlFor="men">
                                <Man/>
                                Hombre
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="form-row">
                <div className="form-column half-width">
                    <p>Teléfono:</p>
                    <input maxLength={10} className="davinci-input"  onChange={handleInputChange} value={form.phone||''} name='phone' autoComplete={constantRandom('phone')}/>
                </div>
                <div className="form-column half-width">
                    <p>Correo: {checkInBtn && <span className="input-alert">Ingresa un correo valido</span>}</p>
                    <input  onBlur={verifyEmail} className={"toLowerCase davinci-input " + (checkInBtn?'animated shake fast':'')}  onChange={handleInputChange} value={form.email||''} name='email' autoComplete={constantRandom('email')}/>
                </div>
            </div>
            <div className="form-row">
                <div className="form-column half-width">
                    <p>País:</p>
                    <TideEntitySelect
                        entity='countries'
                        value={form['country']}
                        onChange={handleSimpleChange('country')}
                        labelCreator={(c)=>c.spanishName}
                        filterEntities={makeMexicoFirst}
                        filterLocal
                    />

                </div>
                <div className="form-column half-width">
                    <p>Ciudad:</p>
                    <input className="davinci-input toUpperCase"  onChange={handleInputChange} value={form.city||''} name='city' autoComplete={constantRandom('city')}/>
                </div>
            </div>
     {!editingGuestInfo &&
     <>
         {isRemoteDavinci() &&
         <div className="form-row-single">
             <div className='form-column full-width'>
                 <p>Fecha de reservación:</p>
                 <SelectDateTimePicker onChange={handleSimpleChange('reservationDate')} value={form?.reservationDate}/>
             </div>
         </div>}
         <div className="form-row-single">
             {(!showingAddFlight) ?
                 <>
                     <div className='form-row flight-type-row'>
                         <div className='form-column-half-width'>
                             <p>Vuelo:</p>
                         </div>
                         <div className='form-column-half-width'>
                             <RadioSelector
                                 options={flightTypeOptions}
                                 onChange={setFlightType}
                                 value={flightType}
                             />
                         </div>
                     </div>
                     <Select
                         options={flightOptions}
                         styles={reactSelectStyles}
                         name='flightsSelect'
                         onInputChange={onFlightsInputChange}
                         onChange={onFlightChange}
                         value={form.flight?flightOptionMapper(form.flight):null}
                         placeholder='Escribe para buscar...'
                         filterOption={alwaysTrue}
                         isLoading={flightsLoading}
                     />
                 </>
                 :<NewFlight
                     number={form.flightNumber}
                     destination={form.flightDestination}
                     airline={form.flightAirline}
                     isArrival={form.flightIsArrival}
                     airlineOptions={airlines}
                     handleSimpleChange={handleSimpleChange} />}

         </div>
     </>
     }
      </div>
    );
}
