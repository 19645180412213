import {useMemo} from 'react';
import useActualLounge from "./useActualLounge";

export default ({localStorageKey})=>{

    const cashRegisterString = window.localStorage[localStorageKey];
    const actualLounge = useActualLounge();
    const parsed=useMemo(()=>{
        try {
            return JSON.parse(cashRegisterString)
        }
        catch (e) {
            if( actualLounge?.config?.printers && actualLounge.config.printers[localStorageKey])
                return JSON.parse(actualLounge.config.printers[localStorageKey]);
            return null;
        }
    },[cashRegisterString, actualLounge,localStorageKey ]);

    return [parsed];
};
