import React,{useCallback} from 'react';
import './CommentBox.scss';
import Button from "../../../../../components/Button/Button";
import useClearableState from "../../../../../hooks/useClearableState";
import classNames from 'classnames';

const CommentBox = ({value, onChange, onSave, disabled})=>{

    const handleChange=useCallback((e)=>{
        onChange(e.target.value.toUpperCase());
    },[onChange]);

    const [animation, setAnimation]=useClearableState('');
    const handleSave=useCallback(()=>{
        if(value)
            setAnimation('animated bounce', 1000);
        onSave();
    },[value, onSave, setAnimation]);

    return(
        <div className={classNames("CommentBox full-width", animation)}>
            <textarea
                placeholder="Comentarios"
                className="CommentBox-textArea full-width"
                value={value}
                onChange={handleChange}
            />
            <Button className="CommentBox-btn full-width"
                    main
                    onClick={handleSave}
                    disabled={disabled}>
                Guardar comentario
            </Button>
        </div>
    )
};
export default CommentBox;
