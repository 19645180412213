import './ProductCreatedBy.scss';
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as RestaurantWaiter} from './icons/waiter.svg';
import ToolTip from 'react-portal-tooltip'

const ProductCreatedBy = ({visit, orderProduct}) => {
    //Initializers
    const iconRef = useRef(null);
    const tooltipRef = useRef(null);
    const [tooltipStatus, setTooltipStatus] = useState(false);

    //Getting current host:
    const currentHost = visit?.employeesAttendingVisit[0]?.employee;

    if(!currentHost)
        return null;

    const orderProductAuthor = orderProduct?.createdBy?.employee;

    const authorName =
        ((currentHost?.id && orderProductAuthor?.id) && (currentHost?.id !== orderProductAuthor?.id))
        ? orderProductAuthor?.fullName || 'Desconocído'
        : null;

    if(!authorName) return null;

    return (
        <div className="ProductCreatedBy">
            <RestaurantWaiter
                ref={iconRef}
                className="icon"
                onMouseEnter={() => setTooltipStatus(true)}
                onMouseLeave={() => setTooltipStatus(false)}
            />
            {(iconRef.current) &&
                <ToolTip
                    ref={tooltipRef}
                    active={tooltipStatus}
                    position="top"
                    arrow="center"
                    parent={iconRef.current}
                >
                    {authorName}
                </ToolTip>
            }
        </div>
    );
};
ProductCreatedBy.propTypes = {
    orderProduct: PropTypes.object.isRequired
}
export default ProductCreatedBy;
