export const listSGroups = [
    'orozco_print_read',
    'orozco_print_read_orozco_payments',
    'orozco_payment_read',
    'orozco_payment_read_orozco_cash_register',
    'orozco_cash_register_read_lounge',
    'orozco_cash_register_read_name',
    'lounge_read_name',
    'orozco_payment_read_created_by',
    'orozco_print_read_created_by',
    'orozco_print_read_is_reprint',
    'orozco_print_read_is_canceled',
    'creation_date',
    'orozco_payment_read_type',
    'user_read_username',
    'user_read_employee',
    'employee_read_full_name',
    'orozco_payment_read_orozco_payment_method',
    'orozco_payment_method_read_name',
    'orozco_payment_read_orozco_cash_closing_detail'
];
