import React,{useCallback} from 'react';
import './Counter.scss';

const Counter = ({value, onChange, disabled,valueTotal, maxValue=10000000}) => {

    const plusBtnHandler = useCallback(()=>{
        if(value>=maxValue)
            return;
        onChange(value + 1);
    },[value, onChange, maxValue]);

    const minusBtnHandler = useCallback(()=>{
        if(value>0){
            onChange(value - 1);
        }
    },[value, onChange]);

    return (
        <div className="Counter">
            <div className="Counter-minus">
                <button  disabled={disabled} onClick={minusBtnHandler}>{disabled|| value===0?'':'-'}</button>
            </div>
            <div className="Counter-text">
                {valueTotal > 0?
                <p>{(value === valueTotal)?valueTotal:(`${value} / ${valueTotal}`)}</p>
                :
                <p>{value}</p>
                }
            </div>
            <div className="Counter-plus">
                <button  disabled={disabled} onClick={plusBtnHandler}>{disabled|| value===maxValue?'':'+'}</button>
            </div>
        </div>
    );
};
export default Counter;
