import {ReactComponent as MxFlag} from "../../assets/images/icons/mexicoFlag.svg";
import {ReactComponent as UsaFlag} from "../../assets/images/icons/usaFlag.svg";
import {ReactComponent as EuFlag} from "../../assets/images/icons/euFlag.svg";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';


export const convertCurrencies=(from, to, amount, exchangeRates)=>{
    if(!from || !to || !exchangeRates )
        throw new Error(`Bad convert currencies call from: ${from} to: ${to}`);

    if(!amount) return 0;

    if(from===to) return amount;


    if(!exchangeRates[to])
        throw new Error('Unknown exchange rate for currency '+to);
    if(!exchangeRates[from])
        throw new Error('Unknown exchange rate for currency '+from);

    return amount*getCurrenciesRatio(from, to, exchangeRates);
};

export const getCurrenciesRatio=(from, to, exchangeRates)=>(exchangeRates[from]/exchangeRates[to]);

export const getPriceInCurrency=(prices, currency, exchangeRates)=>{
    if (!prices || !prices.length || !currency) {
        throw new Error('Missing parameters to getPriceInCurrency');
    }

    const exact=_.find(prices, price=>price.currency===currency);
    if(exact)
        return exact.amount;

    const pesosPrice = _.find(prices, price=>price.currency==='MXN');


    if(pesosPrice)
        return convertCurrencies('MXN', currency, pesosPrice.amount, exchangeRates);

    const dollarsPrice = _.find(prices, price=>price.currency==='USD');
    if(dollarsPrice)
        return convertCurrencies('USD', currency, dollarsPrice.amount, exchangeRates);

    return convertCurrencies(prices[0].currency, currency, prices[0].amount, exchangeRates);
};

export const moneyFormatter= ( money, divider=100, currency ) =>{
    if( isNaN( Number(money) ) )
        return money;

    const realMoney = Math.round((money/divider) * 100) / 100;
    return (realMoney).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + (currency? ` ${currency}`:'');
};


export const iconForCurrency = (currency)=>{
    switch (currency) {
        case 'MXN':
            return <MxFlag/>;
        case 'USD':
            return <UsaFlag/>;
        case 'EUR':
            return <EuFlag/>;
        default:
            return <FontAwesomeIcon icon={faFlag} />
    }
};

export const nameForCurrency = (currency)=>{
    switch (currency) {
        case 'MXN':
            return 'Pesos';
        case 'USD':
            return 'Dólares';
        case 'EUR':
            return 'Euros';
        default:
            return currency;
    }
};

export const allAvailableCurrencies=[
    'MXN',
    'USD',
    'EUR'
];
