import React from 'react'
import './SeatsList.scss';

export const SeatsList = ({visit}) => {
    return (
        <div className='SeatsList'>
            <p className='SeatsList-title'>
                Asientos: <span className='SeatsList-text'>{ visit?.seats?.map((s) => s?.name ).join(', ') }</span>
            </p>
        </div>
    )
}
