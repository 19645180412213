import React from 'react';
import logo from '../../assets/images/logo-tgle-blanco.png';

const Splash=()=>{
    return (
        <div className='Splash'>
            <img src={logo} alt='Logo' className='splash-logo'/>
        </div>
    );
};

export default Splash;
