import Login from "../../scenes/Login/Login";
import ServerSelection from "../../scenes/ServerSelection/ServerSelection";

export const paths={
    login:'/login',
    serverSelection: '/servidor',
};

const notLoggedRoutes=[
    {path:paths.login, component: Login},
    {path:paths.serverSelection, component: ServerSelection, name:'Servidor', hidden: true},
];

export default notLoggedRoutes;
