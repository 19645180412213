import React from 'react';
import Select from 'react-select';
import reactSelectStyles from "../../../../assets/styles/react-select-styles";

const ExtraField = ({value, onChange, extraField, title}) => {
    const options = [
        {label: 'Selecciona...', value: null},
        ...(extraField.multipleChoiceOptions||[]).map(value=>({label: value, value}))
    ];

    return (
        <>
            <p>{title}:</p>
        {extraField.multipleChoice?(
            <Select
                styles={reactSelectStyles}
                options={options}
                placeholder='Selecciona...'
                onChange={target=>onChange({target})}
                value={value?{value, label: value}:null}
            />
        ):(
            <input
                type="text"
                className="davinci-input"
                onChange={onChange}
                value={value||''}
            />
        )}
        </>

    );

};

export default ExtraField;
