import {useRef, useCallback} from 'react';

export default ()=>{
    const randoms=useRef({});

    return useCallback((id)=>{
        if(!randoms.current[id])
            randoms.current[id]=Math.random();
        return randoms.current[id];
    },[]);
};
