import React,{useCallback,useEffect} from 'react';
import './CheckInInfoCard.scss';
import ProgressBar from "../../../../../../components/ProgressBar/ProgressBar";
import className from 'classnames';
import moment from "moment";
import {getCheckInExpirationBarPercentage, getCheckInStatusComponent} from "../../../../../../services/modelUtils/checkInUtils";
import ReCheckInArrow from './ReCheckInArrow';
import {getLoungeAccessMethodNameIncludingParents} from "../../../../../../services/modelUtils/loungeAccessMethodsUtils";


const CheckInInfoCard = ({checkIn, onSelect, selected,checkInTiming}) => {

      useEffect(()=>{
          if(selected){
              checkInTiming(getCheckInExpirationBarPercentage(checkIn));
          }
      },[checkIn,checkInTiming,selected]);


    const handleSelect=useCallback(()=>{
        onSelect(checkIn);
        checkInTiming(getCheckInExpirationBarPercentage(checkIn));
    },[onSelect, checkIn,checkInTiming]);

    return (
        <div onClick={handleSelect} className="CheckInInfoCard">
            <div className={className("full-width CheckInInfoCard-cardWrapper ", selected?'selected':'')}>
                <div className="CheckInInfoCard-accessMethod CheckInInfoCard-column">
                    <p>{getLoungeAccessMethodNameIncludingParents(checkIn.loungeAccessMethod)}</p>
                </div>
                <div className="CheckInInfoCard-time CheckInInfoCard-column">
                    <p>{moment(checkIn.createdDate).format('HH:mm')}</p>
                </div>
                <div className="CheckInInfoCard-status CheckInInfoCard-column">
                    <p>{getCheckInStatusComponent(checkIn)}</p>
                </div>
                <div className="CheckInInfoCard-numberCompanions CheckInInfoCard-column">
                    <p>{checkIn.adultsNumber-1}</p>
                </div>
            </div>
            {checkIn.reCheckInFrom &&
            <ReCheckInArrow/>
            }
            <ProgressBar
                max="1"
                value={getCheckInExpirationBarPercentage(checkIn)}
                redBar
                customWidth
            />

        </div>
    )
};
export default CheckInInfoCard
