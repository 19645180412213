import React,{useCallback, useContext} from 'react';
import Button from "../../../../../components/Button/Button";
import './ServiceAndExtraChargeAdder.scss';
import useBoolean from "../../../../../hooks/useBoolean";
import AddServiceModal from "../AddServiceModal/AddServiceModal";
import ExtraChargeModal from "../ExtraChargeModal/ExtraChargeModal";
import {SecurityContext} from '../../../../../services/SecurityManager';

const ServiceAndExtraChargeAdder=({visit, onChange})=>{

    const security = useContext(SecurityContext);
    const [addingService, startAddingService, stopAddingService]=useBoolean();
    const [addingExtraCharge, startAddingExtraCharge, stopAddingExtraCharge]=useBoolean();

    const handlePayment=useCallback(()=>{
        stopAddingExtraCharge();
        onChange();
    },[onChange, stopAddingExtraCharge]);
    return (
        <div className='ServiceAndExtraChargeAdder'>
            {security.canCreateAndUpdateExtraCharges() && <Button onClick={startAddingExtraCharge}>Cargo extra</Button>}
            <Button onClick={startAddingService}>Nuevo servicio</Button>

            {addingService&&
            <AddServiceModal
                visit={visit}
                onClose={stopAddingService}
                onChange={onChange}
            />}

            {addingExtraCharge&&
            <ExtraChargeModal
                visit={visit}
            onClose={stopAddingExtraCharge}
            onPay={handlePayment}
            />}

        </div>
    );
};

export default ServiceAndExtraChargeAdder;
