import React, {useMemo} from 'react';
import './ProgressBar.scss';
import classNames from 'classnames';



const ProgressBar = ({max, value, icon, customWidth, title,progressBarFromLounge}) => {


    const hasWarnings = useMemo(() => {
        if(!progressBarFromLounge){
        return (value < .3);
        }
    }, [value,progressBarFromLounge]);

    const fullTime = useMemo(()=>{
        if(!progressBarFromLounge){
        return(value > .8 && value <= 1)
        }
    },[value,progressBarFromLounge]);

    const mediumTime = useMemo(()=>{
        if(!progressBarFromLounge){
        return(value <= .8 && value > .3)
        }
    },[value,progressBarFromLounge]);


    return (
        <div title={title} className={classNames("ProgressBar-loadingTimer ",progressBarFromLounge?"loungeProgressBar":'')}>
            {icon &&
            <img className="ProgressBar-progress-icon" src={icon} alt=""/>
            }
            <progress
                className={classNames("ProgressBar-progress",hasWarnings ? 'hasWarning':mediumTime?'mediumTime':fullTime?'fullTime':'' , customWidth ? 'customWidth' : '')}
                max={max} value={value}/>
        </div>
    )
};
export default ProgressBar;