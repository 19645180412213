import React, {useState, useEffect} from 'react';
import {isPricedProduct} from '../../../../../services/modelUtils/orozcoProductUtils';
import {moneyFormatter} from "../../../../../services/currency/currencyUtils";

const ExtraChargeEntry = ({overridenPrice, product, quantity, onPriceChanged, notes='', onRemove, onNotesChanged}) => {
    const [changingPrice, setChangingPrice] = useState(false);
    const [addingNotes, setAddingNotes] = useState(false);
    const amount = overridenPrice?(overridenPrice.amount===''?'':(overridenPrice.amount||0)):0;
    const currency = overridenPrice?overridenPrice.currency||'MXN':'MXN';
    useEffect(()=>{
        if(!changingPrice){
            onPriceChanged(undefined);
            return;
        }
        onPriceChanged({amount: 0, currency: 'MXN'});
    }, [changingPrice, onPriceChanged]);
    const onAmountChanged = e => {
        const value = e.target.value;
        const amount = value===''?value:e.target.value.replace(/[^0-9.]/g,'').replace(/\.[^.]*\./g, '');
        onPriceChanged({amount, currency});
    };
    const onCurrencyChanged = e => {
        const currency = e.target.value;
        onPriceChanged({amount, currency});
    };
    return (
            <li className='selected-product-item'>
                <div className="main-row">
                    <button className='remove-entry' onClick={onRemove}>&times;</button> &nbsp;
                    <span className='quantity'>{quantity}</span>{' '}
                    {product.name}
                    {(isPricedProduct(product)&&!changingPrice) &&
                        <p className='prices'>${
                            product.prices.map(
                                price=>moneyFormatter(price.amount*quantity, 100, price.currency)).join(' / ')
                        }</p>
                    }

                    {changingPrice&&(
                        <div className='edit-price'>
                            <input value={amount} onChange={onAmountChanged} type='text' className='davinci-input'/>
                            <select value={currency} onChange={onCurrencyChanged} className='davinci-input'>
                                <option value='MXN'>MXN</option>
                                <option value='USD'>USD</option>
                                <option value='EUR'>EUR</option>
                            </select>
                        </div>
                    )}
                </div>
                {addingNotes&&
                <div className='main-row'>
                    <textarea value={notes} onChange={e=>onNotesChanged(e.target.value)} className='davinci-input notes-input'/>
                </div>
                }
                <div className="control-change">
                {product.canHavePriceChanged&&<p onClick={()=>setChangingPrice(x=>!x)} className='change-price'>{changingPrice?'Cancelar':'Cambiar precio'}</p>}
                </div>
                <div className='control-change'>
                    <p onClick={()=>{
                        if(addingNotes){
                            onNotesChanged('');
                        }
                        setAddingNotes(x=>!x);
                    }} className='change-price'>{addingNotes?'Quitar comentario':'Agregar comentario'}</p>
                </div>
            </li>

    );
};

export default ExtraChargeEntry;
