import React,{useCallback,useContext} from 'react';
import {guestFullName} from "../../../../../services/modelUtils/guestUtils";
import Modal from "../../../../../components/Modal/Modal";
import './LoungeTransferModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKaaba } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Arrow} from '../../../../../assets/images/icons/rightArrow.svg';
import Button from "../../../../../components/Button/Button";
import {ApiContext} from "../../../../../services/api/api-config";
import {getNotifier} from "../../../../../services/notifierUtils";
import {useSelector} from "react-redux";
import LoadingAnimation from "../../../../../components/LoadingAnimation/LoadingAnimation";


const LoungeTransferModal= ({onFinish, onCancel, guest, lounge, visit})=>{

    const api=useContext(ApiContext);

    const loading=useSelector(({loadingIds})=>!!loadingIds['@LoungeTransferModal']);
    const executeTransfer=useCallback(()=>{

        api.visits.update({id: visit.id, params:{id: visit.id, lounge:lounge.id}, loadingId:'@LoungeTransferModal'})
            .then(()=>{
                getNotifier().success('Transferencia exitosa');
                onFinish && onFinish();
            });

    },[onFinish, lounge, visit, api]);

    return (
        <Modal
            title={'Transferencia de sala'}
            onClose={onCancel}
            className={'LoungeTransferModal'}
        >
            <div className='icons-container'>
                <FontAwesomeIcon icon={faUser} className='icon' />
                <Arrow className='center-arrow'/>
                <FontAwesomeIcon icon={faKaaba} className='icon' />
            </div>

            <h2>Transferencia de {guestFullName(guest)} a {lounge.name}</h2>

            <p className='question'>¿Estás seguro que deseas enviar al huésped a otra sala?</p>
            <Button onClick={onCancel} disabled={loading}>Cancelar</Button>
            <Button blue onClick={executeTransfer} disabled={loading}>
                {loading ?
                    <LoadingAnimation/> :
                    'Sí, transferir'
                }
            </Button>
        </Modal>
    );
};

export default LoungeTransferModal;
