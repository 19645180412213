export const BLUR_APP_REDUCER='BLUR_APP_REDUCER';

export const blurAppAction=(blurred)=>{
    return {
        type: BLUR_APP_REDUCER,
        payload:blurred
    }
};

export default (state=false, action)=>{
    if(action.type===BLUR_APP_REDUCER)
        return !!action.payload;
    return state;
}
