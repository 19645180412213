import _ from 'lodash';
import {copyPropertyOrNull} from "../formUtils";
import {isVisitActive} from "./visitUtils";
import moment from "moment";


export const guestFullName=guest=>guest?`${guest.name||''} ${guest.patLastName||''} ${guest.matLastName||''}`:'';

export const editGuest=(guest)=>({
    id:guest.id,
    name:guest.name,
    patLastName:guest.patLastName,
    matLastName:guest.matLastName,
    phone:guest.phone,
    email:guest.email,
    city:guest.city,
    country:guest.country,
    gender:guest.gender,
    birthday:!guest.birthday?"":moment(guest.birthday),
});

export const prepareEditGuestInfoForServer = (guestEdited)=>{

    if(!guestEdited.name)
        throw new Error("Ingresa el nombre");

    if(!guestEdited.patLastName)
        throw new Error("Ingresa el apellido");

    if(!guestEdited.birthday)
        throw new Error("Ingresa la fecha de nacimiento");

        return {...guestEdited, "country":guestEdited.country?guestEdited.country.id:null};

};

export const searchSGroups=['guest_read_id', 'guest_read_name', 'guest_read_birthday', 'guest_read_pat_last_name', 'guest_read_mat_last_name','guest_read_email' ];

export const guestFromPlainReservation=(reservation)=>{
    if(!reservation) return {};

    const guest={};

    guest.name=reservation.name?_.startCase(reservation.name.toLowerCase()):'';
    guest.patLastName=reservation.lastNames?_.startCase(reservation.lastNames.toLowerCase()):'';

    copyPropertyOrNull(reservation, guest, 'email');
    copyPropertyOrNull(reservation, guest, 'phone');

    return guest;
};

export const getAllGuestComments=(guest)=>{
    if(!guest || !guest.visits || !guest.visits.length)
        return [];

    return guest.visits.reduce( (acc, visit)=>{
        if(visit.cassette && visit.cassette.comments && visit.cassette.comments.length)
            return [...acc, ...visit.cassette.comments];
        return acc;
    } ,[]);
};

export const isGuestInLounge=(guest)=>{
    if(!guest || !guest.visits || !guest.visits.length)
        return false;

    const activeVisit = _.find(guest.visits, (visit)=>isVisitActive(visit));

    if(activeVisit)
        return activeVisit.lounge;
    return false;
};

export const guestProfileCommentsCustomProp = 'GuestProfileComments';

export const guestProfileSGroups = [
    'guest_read_id',
    'guest_read_name',
    'guest_read_mat_last_name',
    'guest_read_pat_last_name',
    'guest_read_birthday',
    'guest_read_profile_image',
    'guest_read_non_grata',
    'guest_read_vip_since',
    'guest_read_non_grata_since',
    'guest_read_non_grata_reason',
    'guest_read_non_grata_level',
    'guest_read_vip_reason',
    "guest_read_visits",
        "visit_read_id",
        "visit_read_first_check_in_date",
        "visit_read_active_persons",
        "visit_read_lounge",
            "lounge_read_name",
        "visit_read_check_ins",
            "check_in_read_check_out_date",
            "check_in_read_lounge_access_method",
                "lounge_access_method_read_name",
                "lounge_access_method_read_lounge_parent_access_methods",
        "visit_read_orozco_visit_order",
            "orozco_visit_order_read_orozco_visit_order_products",
                "orozco_visit_order_product_read_quantity",
                "orozco_visit_order_product_read_orozco_product",
                    "orozco_product_read_name",
];

export const guestCheckInSGroups = [
    'guest_read_id',
    'guest_read_name',
    'guest_read_mat_last_name',
    'guest_read_pat_last_name',
    'guest_read_birthday',
    'guest_read_gender',
    'guest_read_phone',
    'guest_read_email',
    'guest_read_city',
    'guest_read_country',
        'country_read',
];

export const fullSGroups=[
    'guest_read_id',
    'guest_read_name',
    'guest_read_mat_last_name',
    'guest_read_pat_last_name',
    'guest_read_birthday',
    'guest_read_profile_image',
    'guest_read_non_grata',
    'guest_read_vip_since',
    'guest_read_non_grata_since',
    'guest_read_non_grata_reason',
    'guest_read_non_grata_level',
    'guest_read_vip_reason',
    "guest_read_visits",
        "visit_read_id",
        "visit_read_folio",
        "visit_read_active_persons",
        "visit_read_lounge",
            "place_read_id",
            "place_read_name",
        "visit_read_first_check_in_date",
        "visit_read_flight",
            "flight_read",
                "airline_read_id",
                "airline_read_name",
                "airline_read_code",
        "visit_read_service_visits",
            "service_visit_read_id",
            "service_visit_read_service",
                "service_read_id",
                "service_read_name",
        "visit_read_check_ins",
            "check_in_read_id",
            "check_in_read_folio",
            "check_in_read_adults_number",
            "check_in_read_kids_number",
            "check_in_read_active_adults",
            "check_in_read_active_kids",
            "check_in_read_extra_fields",
            "check_in_read_created_date",
            "check_in_read_canceled_date",
            "check_in_read_check_out_date",
            "check_in_read_re_check_in_from",
            "check_in_read_expiration_date",
            "check_in_read_lounge_access_method",
                "lounge_access_method_read_id",
                "lounge_access_method_read_name",
                "lounge_access_method_read_max_adults",
                "lounge_access_method_read_max_kids",
                "lounge_access_method_read_config",
                "lounge_access_method_read_lounge_parent_access_methods",
            "check_in_read_orozco_check_in_payments",
        // "visit_read_cassette",
        //     "cassette_read",
        //     "cassette_read_comments",
        "visit_read_employees_attending_visit",
            "employee_attending_visit_read_id",
            "employee_attending_visit_read_employee",
                "employee_read_id",
                "employee_read_name",
                "employee_read_pat_last_name",
                "employee_read_mat_last_name",
        "visit_read_orozco_visit_order",
            "orozco_visit_order_read_id",
            "orozco_visit_order_read_scheduled_service",
            "orozco_visit_order_read_orozco_visit_order_products",
                "orozco_visit_order_product_read",
                "orozco_visit_order_product_read_created_by",
                "user_read_id",
                "user_read_employee",
                "employee_read_full_name",
                "orozco_visit_order_product_read_created_date",
                "orozco_visit_order_product_read_canceled_date",
                "orozco_visit_order_product_read_orozco_product",
                    "orozco_product_read",
                    "orozco_product_read_prices",
                "orozco_visit_order_product_read_orozco_product_modifiers",
                    "orozco_product_modifier_read",
            "orozco_visit_order_read_orozco_visit_order_payments",
                "orozco_payment_read",
];

export const guestCommentsSGroups = [
    "guest_read_visits",
        "visit_read_id",
        "visit_read_cassette",
            "cassette_read",
            "cassette_read_comments",
];

export const verifyIfIsBirthday = (guestBirthday)=> {
    if(!guestBirthday)
        return false;
    const currentDay = moment();
    const birthday = moment(guestBirthday);
    currentDay.year(2020);
    birthday.year(2020);
    const difference = currentDay.diff(birthday,'days');


    return (difference >= -2 && difference <= 3)
};
