import React from 'react';
import './TopBarWaiter.scss';
import TopBarClock from "../Components/TopBarClock/TopBarClock";
import TopBarProfile from "../Components/TopBarProfile/TopBarProfile";
import TopBarGuestTable from "../Components/TopBarGuestTable/TopBarGuestTable";
import WaiterIcon from "../../../assets/images/waiter.png";
import useBoolean from "../../../hooks/useBoolean";

const TopBarWaiter = ({inClientOrder, visit, onChange, children})=>{

    const [waiterTableAndGuestName,,,toggleWaiterTableAndGuestName] = useBoolean();

    return(
    <div className='TopBarWaiterCommand'>
        <div className="TopBarWaiterCommand-title">
            <p className="TopBarWaiterCommand-bigTitle">OROZCO</p>
        </div>

        {children}

        {inClientOrder && window.matchMedia("(min-width:1026px)").matches &&
            <div>
                <TopBarGuestTable
                    visit={visit}
                    onChange={onChange}
                />
            </div>
        }
        {inClientOrder && window.matchMedia("(max-width:1025px)").matches &&
            <>
                <div onClick={toggleWaiterTableAndGuestName} className="TopBarWaiterCommand-tabletVersion">
                    <div className="TopBarWaiterCommand-tabletVersion-icon" style={{backgroundImage:`url(${WaiterIcon})`}}/>
                </div>
            {waiterTableAndGuestName &&
                <div className="TopBarWaiterCommand-topBarGuestTableTabletVersion animated fadeInLeft">
                    <TopBarGuestTable
                    visit={visit}
                    />
                </div>
            }
            </>
        }
        <div className="TopBarWaiterCommand-profile">
            <div className="TopBarWaiterCommand-time">
                <TopBarClock/>
            </div>
            <TopBarProfile/>
        </div>
    </div>
    )
};
TopBarWaiter.displayName='TopBarWaiter';
export default TopBarWaiter;
