import React,{useState,useCallback} from 'react';
import './CancelCheckInModal.scss';
import Modal from "../../../../../components/Modal/Modal";
import Button from "../../../../../components/Button/Button";

const CancelCheckInModal = ({onClose}) => {
    const [cancelTextAreaValue,setCancelTextAreaValue] = useState("");
    const handleTextArea = useCallback((e)=>{
        setCancelTextAreaValue(e.currentTarget.value);
    },[]);
    return (
        <Modal
            onClose={onClose}
            title={'Cancelar CheckIn'}
        >
            <div className="CancelCheckInModal-comments">
                <textarea onChange={handleTextArea} value={cancelTextAreaValue} placeholder="Cuéntanos por que deseas cancelar" className="CommentBox-textArea CancelCheckInModal-textArea full-width"/>
                <Button disabled={!cancelTextAreaValue} >Cancelar CheckIn</Button>
            </div>
        </Modal>
    )
};
export default CancelCheckInModal;