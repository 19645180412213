import React,{useCallback, useState} from 'react';
import "./RemoteTopBar.scss";
import useBoolean from "../../../hooks/useBoolean";
import {ReactComponent as OrangeArrow} from "../../../assets/images/icons/orangeArrow.svg";
import LoungeAvailable from "../Components/LoungeAvailable/LoungeAvailable";
import TopBarClock from "../Components/TopBarClock/TopBarClock";
import TopBarExchangeRate from "../Components/TopBarExchangeRate/TopBarExchangeRate";
import TopBarProfile from "../Components/TopBarProfile/TopBarProfile";
import LoungeMapModal from "../../LoungeMapModal/LoungeMapModal";

const RemoteTopBar = ()=>{

    const [isOpenLoungeOccupation,,closeLoungeOccupation,isOpenToggle] = useBoolean();

    const [showingMap, setShowingMap]=useState(null);
    const stopShowingMap = useCallback(()=>setShowingMap(null), []);
    const showLoungeMap = useCallback((lounge)=>{
        closeLoungeOccupation();
        setShowingMap(lounge.id);
    },[closeLoungeOccupation]);

    return(
        <>
            <div className='RemoteTopBar animated fadeInDown faster'>
                <div className='availability-container'>
                    <div className="TopBar-disponibilidad">
                        <p>Disponibilidad en salas</p>
                        <button onClick={isOpenToggle} className="TopBar-disponibilidad-drop">
                            <OrangeArrow/>
                        </button>
                        {isOpenLoungeOccupation &&
                        <LoungeAvailable
                            onSelect={showLoungeMap}
                        />}
                    </div>
                </div>
                <div className='center-widgets'>
                    <TopBarClock/>

                    <TopBarExchangeRate/>
                </div>

                <TopBarProfile disableInactivityCheck/>
            </div>

            {showingMap &&
            <LoungeMapModal
                loungeId={showingMap}
                onClose={stopShowingMap}
            />}

        </>
    )
};

RemoteTopBar.displayName='RemoteTopBar';

export default RemoteTopBar;
