import React, {useCallback, useEffect, useState} from 'react';
import {ReactComponent as ConfettiIcon} from "../../../../../assets/images/icons/confettiIcon.svg";
import BalloonsConfig from "../../../../../components/Balloons/BalloonsConfig";
import Balloons from "../../../../../components/Balloons/Balloons";
import {verifyIfIsBirthday} from "../../../../../services/modelUtils/guestUtils";

const BirthdayAnimation = ({ guest }) => {

    const [renderBirthdayAnimation,setRenderBirthdayAnimation] = useState(false);

    const cancelBirthdayAnimation = useCallback(()=>{
        setRenderBirthdayAnimation(false);
    },[]);

    useEffect(()=>{

        if(!guest || !guest.birthday || !verifyIfIsBirthday(guest.birthday) )
            return setRenderBirthdayAnimation(false);

        let timeout1, timeout2;
        timeout1 = setTimeout(()=>{
            setRenderBirthdayAnimation(true);
            timeout2 = setTimeout(()=>{
                setRenderBirthdayAnimation(false);
                window.document.removeEventListener("click", cancelBirthdayAnimation);
            },10000)
        },2000);

        window.document.addEventListener("click", cancelBirthdayAnimation);

        return ()=>{
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            window.document.removeEventListener("click", cancelBirthdayAnimation);
        };
    },[guest, cancelBirthdayAnimation]);

    if(!renderBirthdayAnimation || !guest)
        return null;

    return (
        <div className={"BirthdayAnimation"} >
            <div className="confettiIcon animated fadeInUp">
                <ConfettiIcon/>
            </div>
            <div className="confettiIcon2 animated fadeInUp">
                <ConfettiIcon/>
            </div>
            {BalloonsConfig.map((balloon,i)=>
                <Balloons
                    key={i}
                    config ={balloon}
                />
            )}
        </div>
    );
};

export default BirthdayAnimation;
