import React, {useCallback, useContext, useEffect, useState} from 'react';
import './FlightNumber.scss';
import plane from '../../../../../assets/images/avion.png';
import editIcon from '../../../../../assets/images/icons/edit.svg';
import classNames from 'classnames';
import {minutesToTimeString} from "../../../../../services/timeUtils";
import useBoolean from "../../../../../hooks/useBoolean";
import EditFlightModal from "./EditFlightModal/EditFlightModal";
import {ApiContext} from "../../../../../services/api/api-config";
import {visitFlightSGroups} from "../../../../../services/modelUtils/visitUtils";


const FlightNumber = ({visitId})=>{

    const api = useContext(ApiContext);
    const [flight, setFlight] = useState();

    const loadFlight = useCallback(()=>{
        api.visits.get({ params:{ id:visitId, sGroups: visitFlightSGroups }, customProp:'_' })
            .then( visits=>visits?.length===1&&setFlight(visits[0].flight) );
    },[api, visitId]);
    useEffect(loadFlight, [loadFlight]);

    const [editFlightModal,openEditFlightModal,closeEditFlightModal] = useBoolean();

    if(!flight)
        return <div className="FlightNumber"><p>Sin Información</p></div>;

  return(
      <>
      <div className="FlightNumber" onClick={openEditFlightModal}>
            <img className="FlightNumber-planeImg" src={plane} alt="plane"/>
            <img className="FlightNumber-planeImg isEditingFlight" src={editIcon} alt="edit"/>
            <p className="FlightNumber-text">{flight.airline?flight.airline.name:'Aerolínea desconocida'}</p>
            <p className="FlightNumber-text">{flight.number}</p>
            <p className="FlightNumber-text">{flight.destination}</p>
            <p className="FlightNumber-text">{(flight.departureTime || flight.departureTime===0)?minutesToTimeString(flight.departureTime):'SIN HORA'}</p>
            <p className="FlightNumber-text">{flight.gate || 'Sin puerta'}</p>
            <div className={classNames("FlightNumber-status", flight.status!=='delayed'?'noProblem':'problem')}/>
      </div>

          {editFlightModal &&
                <EditFlightModal
                    reload={loadFlight}
                    flight={flight}
                    visitId={visitId}
                    closeFlightEditModal={closeEditFlightModal}
                />
          }
      </>
  )
};
export default FlightNumber;
