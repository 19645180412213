import React, {useContext} from 'react';
import TopBar from '../../components/TopBar/TopBar';
import './Configuration.scss';
import CashRegisterConfig from "./components/CashRegisterConfig";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import TicketPrinterConfig from "./components/TicketPrinterConfig";
import {
    localStorageFrontDeskPrinterKey,
    localStorageBarPrinterKey,
    localStorageDotcomPrinterKey,
    localStorageCocinaPrinterKey,
} from "../../services/modelUtils/orozcoTicketPrinterUtils"
import {SecurityContext} from "../../services/SecurityManager";
import SessionTimeoutConfig from "./components/SessionTimeoutConfig";

const Configuration = () => {

    const security=useContext(SecurityContext);

    return (
        <div className='Configuration'>
            <FixedMenu/>
            <TopBar/>
            <div className="main-content">

                {security.canAdminCashRegisters()&&
                <CashRegisterConfig/>}

                {security.canAdminPrinters()&&
                    <>
                        <TicketPrinterConfig
                            title={'Impresora de Recepción'}
                            storageKey={localStorageFrontDeskPrinterKey}
                        />

                        <TicketPrinterConfig
                            title={'Impresora de Cocina'}
                            storageKey={localStorageCocinaPrinterKey}
                        />

                        <TicketPrinterConfig
                            title={'Impresora de Bar'}
                            storageKey={localStorageBarPrinterKey}
                        />

                        <TicketPrinterConfig
                            title={'Impresora de Dotcom'}
                            storageKey={localStorageDotcomPrinterKey}
                        />
                </>}

                {security.canAdminSessionTimeout()&&
                <SessionTimeoutConfig/>}

            </div>

        </div>
    )
};
Configuration.displayName='Configuration';
export default Configuration;
