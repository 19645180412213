import React, {useCallback} from 'react';
import './SelectDateTimePicker.scss';
import moment from "moment";
import _ from 'lodash';
import {pad} from "../../services/numberUtils";

const minDefault = moment();
const maxDefault = moment().add(1, 'year');

/**
 *
 * @param minDate {moment.Moment}
 * @param maxDate {moment.Moment}
 * @param value {moment.Moment}
 * @param onChange {function}
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDateTimePicker = ({ minDate=minDefault, maxDate=maxDefault, value, onChange }) => {

    const years = _.range(minDate.year(), maxDate.year()+1);
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago','Sep','Oct','Nov','Dic'];
    const days = _.range(1, (value?.daysInMonth()||30)+1);

    const hours = _.range(0,24);
    const minutes = _.range(0, 60);

    const handleChange = useCallback(({target})=>{
        const date = value?.clone() || moment();
        date[target.dataset.part](target.value);
        onChange(date);
    },[onChange, value]);

    return (
        <div className={"SelectDateTimePicker"}>
            <div className='date-portion'>
                <select className='davinci-input left' value={value?.date()||''} data-part='date' onChange={handleChange}>
                    {days.map(day=><option key={day} value={day}>{day}</option>)}
                </select>
                <select className='davinci-input center' value={value?.month()||''} data-part='month' onChange={handleChange}>
                    {months.map((month, i)=><option key={month} value={i}>{month}</option>)}
                </select>
                <select className='davinci-input right' value={value?.year()||''} data-part='year' onChange={handleChange}>
                    {years.map(year=><option key={year} value={year}>{year}</option>)}
                </select>
            </div>
            <div className='spacer'/>
            <div className='time-portion'>
                <select className='davinci-input left' value={value?.hour()||''} data-part='hour' onChange={handleChange}>
                    {hours.map(hour=><option key={hour} value={hour}>{pad(hour)}</option>)}
                </select>
                <span className='time-points'>:</span>
                <select className='davinci-input right' value={value?.minute()||''} data-part='minute' onChange={handleChange}>
                    {minutes.map(minute=><option key={minute} value={minute}>{pad(minute)}</option>)}
                </select>
            </div>
        </div>
    );
};

export default SelectDateTimePicker;
