import React, {useContext, useEffect, useState} from 'react';
import './PendingBill.scss';
import {ReactComponent as SearchIcon} from "../../../../../assets/images/icons/Lupa-icon.svg";
import {Link} from "react-router-dom";
import {paths} from "../../../../../services/routes/appRoutes";
import {guestFullName} from "../../../../../services/modelUtils/guestUtils";
import {ApiContext} from "../../../../../services/api/api-config";
import useActualLounge from "../../../../../hooks/useActualLounge";
import {
    filterVisitsByGuestFullName,
    visitPendingBillSGroups
} from "../../../../../services/modelUtils/visitUtils";
import {useSelector} from "react-redux";
import useInput from "../../../../../hooks/useInput";


const emptyArr=[];

const  PendingBill = () => {

    const api=useContext(ApiContext);

    const actualLounge=useActualLounge();

    useEffect(()=>{
        if(!actualLounge)
            return;
        api.visits.get({params:{
                sGroups:visitPendingBillSGroups,
                'orozcoVisitOrder.orozcoVisitOrderProducts.payed': false,
                'orozcoVisitOrder.orozcoVisitOrderProducts.shouldBePayed': true,
                'activePersons[gt]':0,
                /*'createdDate[after]':moment().subtract(2,'days'),*/
                itemsPerPage:80,
                lounge: actualLounge.id,
            },customProp:'lastEntriesVisitsWithCharge'});
    },[api, actualLounge]);

    const visits=useSelector(({api})=>api.lastEntriesVisitsWithCharge||emptyArr);
    const [filteredVisits, setFilteredVisits]=useState(visits);

    const filterIn=useInput('');

    useEffect(()=>{

        if(filterIn.value.trim())
            setFilteredVisits( filterVisitsByGuestFullName(visits, filterIn.value) );
        else
            setFilteredVisits( visits );
    }, [filterIn.value, visits]);

    return (
        <div className="PendingBill">
            <div className="PendingBill-header">
                <input
                    placeholder="Cobros Pendientes"
                    className="PendingBill-header-input full-width"
                    {...filterIn.bind}

                />
                <SearchIcon/>
            </div>
            <div className="PendingBill-content">
               {visits.length < 1 ?
                    <p className="PendingBill-noInfo">No hay cobros pendientes</p>
                    :
                    <ul className="PendingBill-list">
                        {
                            filteredVisits.map((visit) => {
                                return (
                                    <Link to={paths.frontDeskGuest
                                        .replace(':visitId',visit.id)}
                                          key={visit.id}>
                                        <li className="PendingBill-list-item">
                                            <p>{guestFullName(visit.guest).toUpperCase()}</p>
                                        </li>
                                    </Link>
                                )
                            })
                        }
                    </ul>
                }
            </div>
        </div>
    )
};
export default PendingBill
