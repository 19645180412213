import React,{useCallback,useState,useEffect} from 'react';
import './KeyBoardTouch.scss';
import {ReactComponent as LeftArrow} from "../../assets/images/icons/leftArrow.svg";
import {ReactComponent as SendIcon} from "../../assets/images/icons/sendIcon.svg";





const KeyBoardTouch = ({valueForInputs,valueInInput,onClose}) => {

    const [inputValue,setInputValue] = useState("");

    useEffect(()=>{
        if(valueInInput){
            setInputValue(valueInInput)
        }
    },[valueInInput]);

    const handleKey = useCallback((e)=>{
        setInputValue(inputValue + e.currentTarget.dataset.key);
    },[inputValue]);

    const backSpaceHandler = useCallback(()=>{
            const inputUserText = inputValue.substring(0,inputValue.length - 1);
        setInputValue(inputUserText)
    },[inputValue]);

    const inputHandler = useCallback((e)=>{
        setInputValue(e.currentTarget.value)
    },[]);

    const sendValueToInput = useCallback(()=>{
        valueForInputs(inputValue)
    },[inputValue,valueForInputs]);

    return (
            <div className="KeyBoardTouch">
                <div onClick={onClose} className="cross-wrapper KeyBoardTouch-cross">
                    <div className="cross"/>
                </div>
                <div className="KeyBoardTouch-input">
                    <input
                        type="text"
                        placeholder=""
                        className="KeyBoardTouch-text"
                        value={inputValue}
                        onChange={inputHandler}
                    />
                    <button onClick={()=>{sendValueToInput();onClose()}} className="KeyBoardTouch-sendBtn">
                        <SendIcon/>
                    </button>
                </div>
                <div className="KeyBoardTouch-keys">
                    <div className="KeyBoardTouch-letters">
                                <div className="KeyBoardTouch-first-line">
                                    <button data-key = "q" onClick={handleKey} className="key q"><p>Q</p></button>
                                    <button data-key = "w" onClick={handleKey} className="key w"><p>W</p></button>
                                    <button data-key = "e" onClick={handleKey} className="key e"><p>E</p></button>
                                    <button data-key = "r" onClick={handleKey} className="key r"><p>R</p></button>
                                    <button data-key = "t" onClick={handleKey} className="key t"><p>T</p></button>
                                    <button data-key = "y" onClick={handleKey} className="key y"><p>Y</p></button>
                                    <button data-key = "u" onClick={handleKey} className="key u"><p>U</p></button>
                                    <button data-key = "i" onClick={handleKey} className="key i"><p>I</p></button>
                                    <button data-key = "o" onClick={handleKey} className="key o"><p>O</p></button>
                                    <button data-key = "p" onClick={handleKey} className="key p"><p>P</p></button>
                                    <button data-val="backspace" onClick={backSpaceHandler}  className="key backspace"><LeftArrow/></button>
                                </div>
                                <div className="KeyBoardTouch-second-line">
                                    <button data-key = "a" onClick={handleKey} className="key a"><p>A</p></button>
                                    <button data-key = "s" onClick={handleKey} className="key s"><p>S</p></button>
                                    <button data-key = "d" onClick={handleKey} className="key d"><p>D</p></button>
                                    <button data-key = "f" onClick={handleKey} className="key f"><p>F</p></button>
                                    <button data-key = "g" onClick={handleKey} className="key g"><p>G</p></button>
                                    <button data-key = "h" onClick={handleKey} className="key h"><p>H</p></button>
                                    <button data-key = "j" onClick={handleKey} className="key j"><p>J</p></button>
                                    <button data-key = "k" onClick={handleKey} className="key k"><p>K</p></button>
                                    <button data-key = "l" onClick={handleKey} className="key l"><p>L</p></button>
                                    <button data-key = "@" onClick={handleKey} className="key at"><p>@</p></button>
                                </div>
                                <div className="KeyBoardTouch-third-line">
                                    <button data-key = "ñ" onClick={handleKey} className="key ñ"><p>Ñ</p></button>
                                    <button data-key = "z" onClick={handleKey} className="key z"><p>Z</p></button>
                                    <button data-key = "x" onClick={handleKey} className="key x"><p>X</p></button>
                                    <button data-key = "c" onClick={handleKey} className="key c"><p>C</p></button>
                                    <button data-key = "v" onClick={handleKey} className="key v"><p>V</p></button>
                                    <button data-key = "b" onClick={handleKey} className="key b"><p>B</p></button>
                                    <button data-key = "n" onClick={handleKey} className="key n"><p>N</p></button>
                                    <button data-key = "m" onClick={handleKey} className="key m"><p>M</p></button>
                                    <button data-key = "_" onClick={handleKey} className="key m"><p>_</p></button>
                                    <button data-key = "-" onClick={handleKey} className="key m"><p>-</p></button>
                                    <button data-key = "." onClick={handleKey} className="key m"><p>.</p></button>
                                </div>
                                <div className="KeyBoardTouch-space-bar">
                                    <button data-key = " " onClick={handleKey} className="key space"/>
                                </div>
                     </div>
                    <div className="KeyBoardTouch-numbers">
                        <div className="KeyBoardTouch-numbersRow">
                            <button data-key = "1" onClick={handleKey} className="key uno"><p>1</p></button>
                            <button data-key = "2" onClick={handleKey} className="key dos"><p>2</p></button>
                            <button data-key = "3" onClick={handleKey} className="key tres"><p>3</p></button>
                        </div>
                        <div className="KeyBoardTouch-numbersRow">
                            <button data-key = "4" onClick={handleKey} className="key cuatro"><p>4</p></button>
                            <button data-key = "5" onClick={handleKey} className="key cinco"><p>5</p></button>
                            <button data-key = "6" onClick={handleKey} className="key seis"><p>6</p></button>
                        </div>
                        <div className="KeyBoardTouch-numbersRow">
                            <button data-key = "7" onClick={handleKey} className="key siete"><p>7</p></button>
                            <button data-key = "8" onClick={handleKey} className="key ocho"><p>8</p></button>
                            <button data-key = "9" onClick={handleKey} className="key nueve"><p>9</p></button>
                        </div>
                        <div className="KeyBoardTouch-numbersRow">
                            <button data-key = "0" onClick={handleKey} className="key cero"><p>0</p></button>
                        </div>
                    </div>
                </div>
            </div>
    )
                };
export default KeyBoardTouch;