import React from 'react';
import './HostName.scss';



const HostName = ({hostName})=>{
    return(
        <div className="HostName">
            {hostName ?
                <>
                    <p>Atendió</p>
                    <p className="HostName-text">{hostName}</p>
                </> :
                <p className="HostName-text">Sin anfitrión asignado</p>
            }
        </div>
    )
};
export default HostName;
