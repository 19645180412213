import moment from 'moment';


export const localStorageKey='OCR_LOCAL';

export const getActualShiftForClosing=()=>{
    const hour= moment().hour();
    if(hour >= 3 && hour < 11)
        return 'N';
    if(hour >= 11 && hour < 19)
        return 'M';
    return 'V'
};