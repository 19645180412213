import React, {useState, useCallback, useEffect, useContext, useMemo} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import './FoodBarMenu.scss';
import TopBarWaiter from "../../../components/TopBar/TopBarWaiter/TopBarWaiter";
import _ from 'lodash';
import useTideEntity from "../../../hooks/useTideEntity";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import {ReactComponent as LeftArrow} from '../../../assets/images/icons/leftArrow.svg';
import FixedMenu from "../../../components/FixedMenu/FixedMenu";
import OrozcoMenu from "../components/OrozcoMenu/OrozcoMenu";
import OrderResume from "../WaiterMenu/Components/OrderResume/OrderResume";
import moment from "moment";
import {serverDateFormat} from "../../../services/timeUtils";
import {useSelector} from "react-redux";
import {getPrinterIdByPrintArea} from "../../../services/modelUtils/orozcoTicketPrinterUtils";
import {getNotifier} from "../../../services/notifierUtils";
import {ApiContext} from "../../../services/api/api-config";
import {
    orozcoFoodBarOrderMenuSGroups,
    prepareOrozcoFoodBarProductsForServer
} from "../../../services/modelUtils/foodBarUtils";
import useBoolean from '../../../hooks/useBoolean';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';

const recentlyOrdered = product =>
      moment(product.createdDate)
      .add(20, 'minutes')
      .isAfter(moment());
const emptyArr=[];
const FoodBarMenu = () => {

    // ------  Initialize general vars ---------
    const history = useHistory();
    const api = useContext(ApiContext);
    const notifier = getNotifier();
    const {foodBarId}=useParams();

    // ------------  Load stuff  --------------
    // Food bar
    const [foodBar, loadingFoodBar, notFound] = useTideEntity('orozcoFoodBars', foodBarId);
    // FoodBarProducts
    const customProp='food-bar-'+foodBarId;
    const loadFoodBarOrders = useCallback(()=>{
        api.orozcoFoodBarOrders.get({
            customProp,
            params:{
                foodBar:foodBarId,
                'order[createdDate]':'DESC',
                'createdDate[after]':moment().subtract(1,'day').format(serverDateFormat),
                sGroups: orozcoFoodBarOrderMenuSGroups,
            }});
    },[api, foodBarId, customProp]);
    useEffect(loadFoodBarOrders, [loadFoodBarOrders]);

    const foodBarOrders = useSelector(({api})=>api[customProp]||emptyArr);
    const foodBarOrderProducts = useMemo(()=>_.flatMap(foodBarOrders, fbo=>fbo.orozcoFoodBarOrderProducts ),[foodBarOrders]);

    const [warningDuplicates, warnDuplicates, closeDuplicatesWarning] = useBoolean();
    const [possibleDuplicates, setPossibleDuplicates] = useState([]);

    // ------- Handle product adding --------
    const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
    const handleProductSelect = useCallback((product)=>{
        const old = _.findIndex(selectedOrderProducts, op=>op.orozcoProduct.id===product.id);
        if(old!==-1){
            const newOrderProducts = [...selectedOrderProducts];
            newOrderProducts[old]={...newOrderProducts[old], quantity: newOrderProducts[old].quantity + 1};
            setSelectedOrderProducts(newOrderProducts);
        }
        else {
            setSelectedOrderProducts([...selectedOrderProducts, {orozcoProduct: product, quantity:1}]);
        }
    },[selectedOrderProducts]);
    // ------- Handle product removing by index --------
    const handleRemoveByIndex = useCallback((index)=>{
        const newOrderProducts = [...selectedOrderProducts];
        newOrderProducts.splice(index, 1);
        setSelectedOrderProducts(newOrderProducts);
    },[selectedOrderProducts]);

    // ------- Handle product removing by product --------
    const handleProductRemove = useCallback((product)=>{
        const index = _.findIndex(selectedOrderProducts, op=>op.orozcoProduct.id===product.id);
        if(index===-1)
            return;

        const newOrderProducts = [...selectedOrderProducts];
        if(newOrderProducts[index].quantity === 1)
            newOrderProducts.splice(index, 1);
        else
            newOrderProducts[index] = {...newOrderProducts[index], quantity: newOrderProducts[index].quantity-1};
        setSelectedOrderProducts(newOrderProducts);
    },[selectedOrderProducts]);

    // -------- print stuff --------
    const printFoodBarProducts = useCallback((orozcoFoodBarProducts)=>{

        const prints = _.groupBy(orozcoFoodBarProducts, 'orozcoProduct.printArea');
        _.each(prints, (orozcoFoodBarProductGroup, printArea)=> {
            const printerId = getPrinterIdByPrintArea(printArea);
            if(!printerId){
                notifier.error('La impresora de '+printArea+' no se encuentra configurada');
                return;
            }
            api.orozcoPrints.create({
                params: {
                    ticketPrinter: getPrinterIdByPrintArea(printArea),
                    orozcoFoodBarProducts: _.map(orozcoFoodBarProductGroup,(prod)=>prod.id)
                }
            }).then(()=>notifier.success('Comanda impresa en '+printArea))
        });

    },[api, notifier]);

    const handleToggleAskedByKid = useCallback(i=>{
        setSelectedOrderProducts(s=>[
            ...s.slice(0,i),
            {...s[i], askedByKid: !s[i].askedByKid},
            ...s.slice(i+1)
        ]);
    }, []);

    // ------  Send order to server -------
    const sendLoadingId = '@FoodBarMenu.order';
    const sendOrder = useCallback(()=>{
        const orozcoFoodBarOrderProducts = prepareOrozcoFoodBarProductsForServer(selectedOrderProducts);
        api.orozcoFoodBarOrders.create({
            params:{
                orozcoFoodBar:foodBarId,
                orozcoFoodBarOrderProducts
            },
            loadingId:sendLoadingId
        })
            .then(()=>{
                setSelectedOrderProducts([]);
                loadFoodBarOrders();
                printFoodBarProducts(selectedOrderProducts);
            });

    }, [api, foodBarId, selectedOrderProducts, printFoodBarProducts, loadFoodBarOrders]);

    const sendOrderCheckingDuplicates = useCallback(()=>{

        const orozcoFoodBarOrderProducts = prepareOrozcoFoodBarProductsForServer(selectedOrderProducts);
        const pDuplicates = _.uniqBy(_.filter(
            _.filter(foodBarOrderProducts, recentlyOrdered),
            p=>_.find(orozcoFoodBarOrderProducts,
                      ({orozcoProduct})=>orozcoProduct===p.orozcoProduct.id)
        ), 'orozcoProduct.id');
        if(pDuplicates.length>0){
            setPossibleDuplicates(pDuplicates);
            warnDuplicates();
            return;
        }
        sendOrder();

    },[warnDuplicates, sendOrder, foodBarOrderProducts,  selectedOrderProducts]);

    const sendingOrder = useSelector(({loadingIds})=>!!loadingIds[sendLoadingId]);

    // -------  Handling modifiers ---------

    const toggleModifier = (modifiers, modifierId)=>
        _.includes(modifiers, modifierId)?
            _.filter(modifiers, m=>m!==modifierId):
            [...modifiers, modifierId];

    const modifyProduct = (product, modifierId)=>
        ({
            ...product,
            orozcoProductModifiers: toggleModifier(product.orozcoProductModifiers||[], modifierId)
        });
    const onModifierSelected = (modifierId, productIndex) => () =>
        setSelectedOrderProducts([
            ...selectedOrderProducts.slice(0, productIndex),
            modifyProduct(selectedOrderProducts[productIndex], modifierId),
            ...selectedOrderProducts.slice(productIndex+1)
        ]);

    const [comment, setComment]=useState('');


    return (
        <>
            <div className="FoodBarMenu unselectable">
                <FixedMenu/>
                <TopBarWaiter/>


                <div className="WaiterMenu-content">
                    <div className="WaiterMenu-left">
                        <div className="WaiterMenu-name">
                            <button className='back-arrow-btn' onClick={history.goBack}><LeftArrow/></button>

                            {loadingFoodBar&&
                            <LoadingAnimation white/>}
                            {foodBar&&<>
                                <p className="WaiterMenu-visitGuestName">
                                    {_.upperCase(foodBar.name)}
                                </p>
                            </>}
                            {notFound&&"-"}
                        </div>
                        {notFound?
                            <h1>Error. Barra no encontrada</h1>:
                            <OrozcoMenu
                                handleProductSelect={handleProductSelect}
                                handleProductRemove={handleProductRemove}
                                selectedOrderProducts={selectedOrderProducts}
                                withSpecialProduct={false}
                                canAddMultipleProductOrders={false}
                            />}
                    </div>
                    <div className="WaiterMenu-right">
                        <div className="WaiterMenu-orderResumyTitle">
                            <p>Últimos pedidos</p>
                        </div>
                        <OrderResume
                            selectedOrderProducts={selectedOrderProducts}
                            serverOrderProducts={foodBarOrderProducts}
                            onSend={sendOrderCheckingDuplicates}
                            loading={sendingOrder}
                            onSelectedProductRemove={handleRemoveByIndex}
                            onRePrint={printFoodBarProducts}
                            onModifierSelected={onModifierSelected}
                            commentText ={comment}
                            onChangeCommentText ={setComment}
                            onToggleAskedByKid={handleToggleAskedByKid}
                        />

                    </div>
                </div>

            </div>
            {warningDuplicates&&(
                <Modal
                    title='Mismo producto reciente'
                    onClose={closeDuplicatesWarning}
                    className='WarningModal'
                    >
                    {possibleDuplicates.length===1?'El siguiente platillo ya ha sido pedido en los últimos 20 minutos':'Los siguientes platillos ya han sido pedidos en los últimos 20 minutos'}
                    <br/>
                    <ul className='productsList'>
                        {possibleDuplicates.map(p=>(
                        <li key={p.id}>{p.orozcoProduct.name}</li>
                    ))}
                    </ul>
                    <br/>
                    ¿Desea continuar?
                    <div className='buttons-row'>
                        <Button onClick={closeDuplicatesWarning}>Cancelar</Button>
                        <Button onClick={()=>{sendOrder();closeDuplicatesWarning();}} blue>Continuar</Button>
                    </div>
                </Modal>
            )}
        </>
    )
};
FoodBarMenu.displayName='FoodBarMenu';
export default FoodBarMenu;
