import moment from "moment";

export const flightsFilter = (obj, str) => {
    if (!obj || !obj.data || !str)
        return false;

    const {number, airline} = obj.data;
    if (number.toLowerCase().indexOf(str.toLowerCase()) !== -1)
        return true;
    else
        return (airline.name||'').toLowerCase().indexOf(str.toLowerCase()) !== -1
};

export const getNextFlightDate = (flight) => {
    const now = moment();
    const flightHour = Math.floor(flight.departureTime / 60);
    const flightMinute = flight.departureTime % 60;

    let nextFlightDate = moment().hour(flightHour).minute(flightMinute);
    let minutes = -now.diff(nextFlightDate, 'minutes');

    if (minutes < 0) {
        nextFlightDate.add(1, 'day');
    }
    return nextFlightDate;
};

export const getNextFlightExpirationBarPercentage = (flight) => {
    if (!flight || !flight.departureTime)
        return 1;

    const now = moment();
    const nextFlightDate = getNextFlightDate(flight);
    let minutes = -now.diff(nextFlightDate, 'minutes');
    if (minutes > 1400)
        minutes = 0;

    return minutes > 120 ? 1 : minutes < 0 ? 0 : minutes / 120;
};
