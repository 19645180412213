import React,{useEffect, useState,useMemo, useCallback, useContext} from 'react';
import {createPortal} from 'react-dom';
import './PaymentFormOverlay.scss';
import {ReactComponent as UsaFlag} from '../../../assets/images/icons/usaFlag.svg'
import {ReactComponent as MxFlag}  from '../../../assets/images/icons/mexicoFlag.svg'
import {ReactComponent as EuFlag}  from '../../../assets/images/icons/euFlag.svg'
import {ReactComponent as CashIcon}  from '../../../assets/images/icons/cash.svg'
import {ReactComponent as CreditCardIcon}  from '../../../assets/images/icons/creditCard.svg'
import {ReactComponent as DollarIcon}  from '../../../assets/images/icons/dollarSymbol.svg'
import moment from "moment";
import CurrencyFormat from 'react-currency-format';
import Button from "../../../components/Button/Button";
import useBackgroundBlur from "../../../hooks/useBackgroundBlur";
import CurrencySelector from "../../CurrencySelector/CurrencySelector";
import {guestFullName} from "../../../services/modelUtils/guestUtils";
import _ from 'lodash';
import useExchangeRates from "../../../hooks/useExchangeRates";
import {
    allAvailableCurrencies,
    convertCurrencies,
    getPriceInCurrency,
    moneyFormatter
} from "../../../services/currency/currencyUtils";
import {
    emptyPayment,
    setMainCurrencyAmount,
    hasUnroundedCashPayments,
    roundCashPayments,
} from "../../../services/modelUtils/paymentUtils";
import useActualCashRegister from "../../../hooks/useActualCashRegister";
import useCallbackCreator from "use-callback-creator/src";
import {getNotifier} from "../../../services/notifierUtils";
import classNames from 'classnames';
import Select from 'react-select';
import reactSelectStyles from "../../../assets/styles/react-select-styles";
import {useSelector} from "react-redux";
import {ApiContext} from "../../../services/api/api-config";
import OrderProductPaymentIcon from '../../OrderProductPaymentIcon/OrderProductPaymentIcon';
import {localStorageFrontDeskPrinterKey, getPrinterIdByKey} from "../../../services/modelUtils/orozcoTicketPrinterUtils";

const selectStyles = {
    ...reactSelectStyles,
    menuList:(styles)=>({...styles, maxHeight: 220 })
};

/**
 * @typedef PriceTag
 * @type {Object}
 * @property {number} amount in cents
 * @property {string} currency in which price is set
 */

/**
 * @typedef HostData
 * @type {Object}
 * @property {number} id
 * @property {string} name
 * @property {string} patLastName
 * @property {string} matLastName
 * @property {string} fullName
 */

/**
 * @typedef HostOrderingProduct
 * @type {Object}
 * @property {number} id
 * @property {HostData} employee
 */

/**
 * @typedef ProductOrderData
 * @type {Object}
 * @property {string} id unique product order identifier
 * @property {boolean} askedByKid
 * @property {?Date} canceledDate
 * @property {boolean} payed
 * @property {number} price
 * @property {number} quantity
 * @property {boolean} shouldBePayed
 * @property {Date} createdDate when product was ordered
 * @property {?string} currency
 * @property {?any} data
 * @property {?string} notes
 * @property {HostOrderingProduct} createdBy
 * @property {OrozcoProduct} orozcoProduct
 */

/**
 * @typedef OrozcoProduct
 * @type {Object}
 * @property {string} id identifier in the catalog
 * @property {string} name
 * @property {boolean} canBeFreeMeal
 * @property {boolean} canHavePriceChanged
 * @property {string} description
 * @property {boolean} isAvailable
 * @property {boolean} isExtraCharge
 * @property {?number} realCost
 * @property {string} printArea where ticket for order is printed
 * @property {PriceTag[]} prices
 */

/**
 * @typedef ProductCheckout
 * @type {Object}
 * @property {string} name of dish
 * @property {number} count of dishes this product ordered
 * @property {PriceTag[]} prices
 * @property {ProductOrderData} fullProductData
 */

/**
 * Dialog to check order before cancel or payment.
 * @param {function} onClose
 * @param {string[]} availableCurrencies
 * @param {*} guest
 * @param {ProductCheckout[]} products
 * @param {function} onPay handlePayment
 * @returns {{children: *, implementation: *, containerInfo: *, $$typeof: (symbol|number), key: (null|string)}}
 * @constructor
 */
const PaymentFormOverlay = ({onClose, availableCurrencies, guest, products, onPay})=>{
    useBackgroundBlur();

    const api=useContext(ApiContext);

    //Currency initialized like: There are no available currencies? null : Is the MXN currency available? MXN finally first available currency
    const [currencyTotal, setCurrencyTotal] = useState(()=>
        !availableCurrencies|| !availableCurrencies.length?
            null:
            _.find(availableCurrencies, 'MXN')?'MXN':
                availableCurrencies[0]
    );

    const [exchangeRates, loadingExchangeRates] = useExchangeRates();

    const pricedProducts = useMemo(function getProductsWithPrice() {
        return products
          .filter(productShouldBePayed)
          .map(function getProductsWithAmount(product) {
            if (!loadingExchangeRates && product.prices.length === 0) {
                console.warn(`Product ${product.name} without prices`);
            }
            const price = loadingExchangeRates
              ? null
              : getPriceInCurrency(product.prices, currencyTotal, exchangeRates) * product.count;

            return { ...product, price };
        });
    }, [products, currencyTotal, exchangeRates, loadingExchangeRates]);

    //-------------- Adding payments ----------------
    const [cashRegister]=useActualCashRegister();
    const frontDeskPrinterId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
    const [payments, setPayments]=useState([]);

    //const api=useContext(ApiContext);

    const onPaymentAdd=useCallback((e)=>{
        setPayments([...payments, emptyPayment(cashRegister, e.currentTarget.dataset.type)]);
    },[payments, cashRegister]);

    const onPaymentChange=useCallbackCreator((id, val)=>{
        const [type, index]=id.split('-');
        const newPayments=[...payments];
        const newPayment={...newPayments[index]};

        const value= val && val.target? val.target.value : val;

        if(type==='amount')
        {
            newPayment.amount=value.replace(/,/g,'');
        }
        else
        {
            newPayment[type]=value;
        }
        setMainCurrencyAmount(newPayment, currencyTotal, exchangeRates);
        newPayments[index]=newPayment;
        setPayments(newPayments);

    },[payments, currencyTotal, exchangeRates]);

    const handleMainCurrencyChange=useCallback((newCurrency)=>{
        setCurrencyTotal(newCurrency);
        setPayments(payments.map(payment=>setMainCurrencyAmount(payment, newCurrency, exchangeRates, true)));
    },[payments, exchangeRates]);

    const totalRequired=pricedProducts.reduce((acc,prod)=>(acc+prod.price), 0);

    const totalGiven=payments.reduce((acc,pay)=>(acc+pay.mainCurrencyAmount), 0);

    const totalLeft=  Math.round( (totalRequired-totalGiven)*100)/100;

    const fillTotalInPayment=useCallbackCreator((index)=>{

        if(totalLeft<=0) return;

        const payment={...payments[index]};
        if(!payment || !payment.currency)
            return getNotifier().error('Selecciona una divisa para este pago');

        if(payment.amount)
            payment.amount = Number(payment.amount) + convertCurrencies(currencyTotal, payment.currency, totalLeft, exchangeRates)/100;
        else
            payment.amount = convertCurrencies(currencyTotal, payment.currency, totalLeft, exchangeRates)/100;

        setMainCurrencyAmount(payment, currencyTotal, exchangeRates);
        payment.amount = Math.round( payment.amount*100)/100;

        const newPayments=[...payments];
        newPayments[index]=payment;
        setPayments(newPayments);

    },[payments, totalLeft, currencyTotal, exchangeRates]);

    /////////////////////////////////////////////////////////////////
    // Change quantity to be rounded when the payment method is cash
    useEffect(()=>{
        if(hasUnroundedCashPayments(payments)){
            setPayments(roundCashPayments(payments));
        }
    }, [payments]);
    /////////////////////////////////////////////////////////////////



    /**
     * Hook que asigna los valores de la petición al API
     *
     * Esta función genera los parámetros necesarios para realizar la petición al API para guardar información sobre
     * los pagos realizados
     *
     * Contiene un método validador de parámetros
     */
    const handlePayment=useCallback(()=>{

        const errored = _.find(payments, pay=>pay.paymentMethod === 'card' && pay.amount && (!pay.voucherNumber || !pay.bank || !pay.creditCardNumber || (pay.creditCardNumber.length < 4) || (pay.creditCardNumber.length > 5)) );

        if(errored)
        {
            if(errored.creditCardNumber.length < 4)
                return getNotifier().error('El número de tarjeta debe ser mayor a 4 dígitos');
            if(errored.creditCardNumber.length > 5)
                return getNotifier().error('El número de tarjeta debe ser menor a 5 dígitos');

        return getNotifier().error('Debes agregar el banco, número de autorización y el número de tarjeta a los pagos con tarjeta.');
        }

        //if()

        setAnimation('animated bounceOutUp faster');
        //Wait for the animation to end
        const preparedPayments=_.filter(payments, pay=>pay.amount)
            .map(pay=> {
                return(
                    {...pay,
                        amount: Math.round(pay.amount*100),
                        chargeCurrency:currencyTotal,
                        paymentCurrency:pay.currency,
                        bank: pay.bank? pay.bank.value : undefined
                    })
            });

        setTimeout(()=>onPay({
            payments:preparedPayments,
            total: totalRequired,
            currency: currencyTotal
        }), 400);
    },[onPay, payments, totalRequired, currencyTotal]);

    const [animation, setAnimation]=useState('animated bounceInDown faster');

    // ----- Bank options -----
    useEffect(()=>{
        api.banks.get({params:{pagination:false, 'order[name]':'ASC'}});
    },[api]);
    const rawBanks = useSelector(({api})=>api.banks);
    const banks = useMemo(()=>(rawBanks||[]).map( b=>({label:b.name, value:b.id}) ), [rawBanks] );

    const hasCashRegisterAndPrinter = useMemo(() => (cashRegister&&frontDeskPrinterId), [cashRegister, frontDeskPrinterId]);

    const getValidationMessage = useCallback(() => {
        if(!cashRegister && !frontDeskPrinterId) return <h2>Ninguna caja registradora ni impresora de tickets en este dispositivo. Contacta a un administrador.</h2>;
        if(!cashRegister) return <h2>No hay caja registradora configurada en este dispositivo. Contacta a un administrador.</h2>;
        if(!frontDeskPrinterId) return <h2>No hay impresora de tickets configurada en este dispositivo. Contacta a un administrador.</h2>;

    }, [cashRegister, frontDeskPrinterId]);

    return createPortal(
        <>
            <div className='PaymentFormOverlay-overlay' onClick={onClose}/>
            <div className='PaymentFormOverlay-container'>
                <div  className={classNames("PaymentFormOverlay", animation)}>
                    <div onClick={onClose} className="PaymentFormOverlay-closeCross"/>
                    <div className="PaymentFormOverlay-currency">
                        <div className="PaymentFormOverlay-flag">
                            <div className="PaymentFormOverlay-icons">
                                <UsaFlag/>
                            </div>
                            <p>$ {exchangeRates && exchangeRates.USD?moneyFormatter(exchangeRates.USD, 1):'-'}</p>
                        </div>
                        <div className="PaymentFormOverlay-flag">
                            <div className="PaymentFormOverlay-icons">
                                <EuFlag/>
                            </div>
                            <p>$ {exchangeRates && exchangeRates.EUR?moneyFormatter(exchangeRates.EUR, 1):'-'}</p>
                        </div>
                    </div>
                    {!hasCashRegisterAndPrinter ? getValidationMessage() :
                    <div className="PaymentFormOverlay-order">
                        <div className="PaymentFormOverlay-title full-width">
                            <p>{guestFullName(guest)}</p>
                            <p className="PaymentFormOverlay-date">-</p>
                            <p className="PaymentFormOverlay-date">{moment().format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="PaymentFormOverlay-content">
                            <div className="PaymentFormOverlay-info full-width">
                                <div className="PaymentFormOverlay-info-item-charge">
                                    <CurrencySelector
                                        className='full-width'
                                        availableCurrencies={availableCurrencies}
                                        value={currencyTotal}
                                        onChange={handleMainCurrencyChange}
                                    />
                                </div>
                                <div className="PaymentFormOverlay-info-companions full-width">
                                    <div className="PaymentFormOverlay-info-companions-names">
                                        <div className="PaymentFormOverlay-title companions-title full-width">
                                            <p>Concepto</p>
                                        </div>
                                        <ul className="PaymentFormOverlay-info-companions-list">
                                            {pricedProducts.map((product,i)=>
                                                <li className="PaymentFormOverlay-info-companions-list-items concept" key={i}>
                                                    <div style={ {display:"flex", justifyContent:"space-between"} }>
                                                        <div
                                                            className="PaymentFormOverlay-info-companions-number"
                                                        >
                                                            <p>{product.count}</p>
                                                        </div>
                                                        <p>{product.name}</p>
                                                    </div>
                                                    <OrderProductPaymentIcon
                                                        onlyBlameCreator
                                                        orderProduct={product.fullProductData}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="PaymentFormOverlay-info-companions-bill">
                                        <div className="PaymentFormOverlay-title companions-title full-width">
                                            <p>Precio</p>
                                        </div>
                                        <ul  className="PaymentFormOverlay-info-companions-list full-width">
                                            {pricedProducts.map((product,i)=>
                                                <li className="PaymentFormOverlay-info-companions-list-items" key={i}>
                                                    <p className="PaymentFormOverlay-info-bill"><DollarIcon/> {moneyFormatter(product.price)} <span>{currencyTotal}</span></p>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className='total-amount'>
                                    TOTAL $ {moneyFormatter(totalRequired)} {currencyTotal}
                                </div>
                                <div className="PaymentFormOverlay-billBreakDown">
                                    {payments.map( (payment, i)=>
                                        <React.Fragment key={i}>
                                            <div className="PaymentFormOverlay-info-cashRows full-width">
                                                <div className="PaymentFormOverlay-info-cashRows-icon">
                                                    {payment.paymentMethod==='cash'&&
                                                    <CashIcon/>}
                                                    {payment.paymentMethod==='card'&&
                                                    <CreditCardIcon/>}
                                                </div>
                                                <div className="PaymentFormOverlay-info-cashRows-inputAmount">
                                                    <DollarIcon/>
                                                    <CurrencyFormat
                                                        placeholder="0"
                                                        thousandSeparator={true}
                                                        onChange={onPaymentChange(`amount-${i}`)}
                                                        value={payment.amount}
                                                    />
                                                </div>
                                                <div className="PaymentFormOverlay-info-cashRows-select">
                                                    {payment.paymentMethod==='cash'&&
                                                    <CurrencySelector
                                                        availableCurrencies={allAvailableCurrencies}
                                                        value={payment.currency}
                                                        onChange={onPaymentChange(`currency-${i}`)}
                                                        placeholder={'Divisa de cobro'}
                                                    />}
                                                    {payment.paymentMethod==='card'&&
                                                    <>
                                                        <MxFlag/>
                                                        <p>MXN</p>
                                                    </>}
                                                </div>
                                                <div className="PaymentFormOverlay-info-cashRows-convertedAmount">
                                                    $ {payment.currency? moneyFormatter(payment.mainCurrencyAmount):'-'}&nbsp;
                                                    {currencyTotal}
                                                </div>
                                                <div className="PaymentFormOverlay-info-cashRows-btn">
                                                    <Button className="full-width" onClick={fillTotalInPayment(i)}>Total</Button>
                                                </div>
                                            </div>
                                            {payment.paymentMethod==='card'&&
                                            <div className='voucher-row'>
                                                <input
                                                    className='voucher-input'
                                                    placeholder='Número de autorización'
                                                    value={payment.voucherNumber||''}
                                                    onChange={onPaymentChange(`voucherNumber-${i}`)}
                                                />
                                                <Select
                                                    options={banks}
                                                    value={payment.bank}
                                                    onChange={onPaymentChange(`bank-${i}`)}
                                                    className={'bank-select'}
                                                    placeholder={'Banco'}
                                                    styles={selectStyles}
                                                />
                                                <input
                                                    className={'voucher-input card-number-input'}
                                                    placeholder={'Número de tarjeta'}
                                                    value={payment.creditCardNumber || ''}
                                                    onChange={onPaymentChange(`creditCardNumber-${i}`)}
                                                    maxLength={'5'}
                                                />
                                            </div>

                                            }
                                        </React.Fragment>
                                    )}
                                    {!!payments.length &&
                                    <div className='total-payed-container'>
                                        <hr/>
                                        <p>
                                            <span className='total-payed-item'>Pagado $ {moneyFormatter(totalGiven)} {currencyTotal}</span>
                                            {!!totalLeft&&
                                            <span className='total-payed-item'>{totalLeft>0?'Falta':'Cambio'} $ {moneyFormatter(Math.abs(totalLeft))} {currencyTotal}</span>}
                                        </p>
                                    </div>}
                                </div>
                                <div className="PaymentFormOverlay-payment full-width">
                                    <div className="PaymentFormOverlay-payment-method">
                                        <div className="PaymentFormOverlay-payment-method-creditCard">
                                            <button onClick={onPaymentAdd} data-type='card'>
                                                <CreditCardIcon/>
                                                Tarjeta
                                            </button>
                                        </div>
                                        <div className="PaymentFormOverlay-payment-method-cash">
                                            <button onClick={onPaymentAdd} data-type='cash'>
                                                <CashIcon/>
                                                Efectivo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        className="full-width"
                                        disabled={loadingExchangeRates||totalLeft>0}
                                        solid
                                        onClick={handlePayment}
                                    >Pagar</Button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    , document.body);
};
export default PaymentFormOverlay;

function productShouldBePayed(product) {
    return product.fullProductData
      ? _.get(product, 'fullProductData.shouldBePayed')
      : product.prices.length;
}
