import React, {useContext, useEffect} from 'react';
import classNames from 'classnames';
import {
    getAllGuestComments,
    guestCommentsSGroups,
    guestProfileCommentsCustomProp
} from "../../../services/modelUtils/guestUtils";
import {ApiContext} from "../../../services/api/api-config";
import {useSelector} from "react-redux";

const GuestProfileComments = ({ guest, haveImg, isVip }) => {

    const api = useContext(ApiContext);

    useEffect(()=>{
        if(!guest)
            return;
        api.guests.get({params: { id: guest.id, sGroups:guestCommentsSGroups }, customProp:guestProfileCommentsCustomProp });
    },[api, guest]);

    const commentGuest = useSelector( s=>s.api.GuestProfileComments?.[0] );
    const comments = commentGuest ? getAllGuestComments(commentGuest) : [];

    return (
        <div className={classNames("full-width GuestProfile-comments", haveImg?'commentsSize':"")}>
            <div className={"GuestProfile-comments-title " + (isVip?"isVipBorder":'')}>
                <p className={isVip?"isVipTitles":''}>Comentarios</p>
            </div>
            <div className="GuestProfile-comments-chat">
                {comments.length < 1 ?
                    <p className="GuestProfile-noInfo">No hay Comentarios</p>
                    :
                    [...comments].reverse().map((comment,i) => {
                        return (
                            <div className="GuestProfile-chatBox" key={comment.id||i}>
                                <p>{comment.text}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default GuestProfileComments;
