import React,{useEffect, useCallback, useState} from 'react';
import './TopBar.scss';
import {ReactComponent as OrangeArrow} from "../../assets/images/icons/orangeArrow.svg";
import TopBarProfile from "./Components/TopBarProfile/TopBarProfile";
import TopBarClock from "./Components/TopBarClock/TopBarClock";
import LoungeAvailable from "./Components/LoungeAvailable/LoungeAvailable";
import useBoolean from "../../hooks/useBoolean";
import {useActualLoungeOccupation} from "../../services/modelUtils/loungeUtils";
import {ReactComponent as PlusIcon} from "../../assets/images/icons/plusIcon.svg";
import TopBarExchangeRate from "./Components/TopBarExchangeRate/TopBarExchangeRate";
import LoungeMapModal from "../LoungeMapModal/LoungeMapModal";


const TopBar = ()=>{

    const [isOpenLoungeOccupation,,closeLoungeOccupation,isOpenToggle] = useBoolean();

    const {lounge, refreshOccupation}=useActualLoungeOccupation();

    useEffect(()=>{
        refreshOccupation();
        const interval=setInterval(refreshOccupation, 30000);
        return ()=>clearInterval(interval);
    },[refreshOccupation]);

    const occupied= (lounge && lounge.loungeMap && lounge.loungeMap.occupiedSeats) || 0;
    const total= (lounge && lounge.loungeMap && lounge.loungeMap.totalSeats) || 0;

    const [subMenu,,,ToggleSubMenu] = useBoolean();

    const [showingMap, setShowingMap]=useState(null);
    const stopShowingMap = useCallback(()=>setShowingMap(null), []);
    const showLoungeMap = useCallback((lounge)=>{
        closeLoungeOccupation();
        setShowingMap(lounge.id);
    },[closeLoungeOccupation]);

    return(
        <>
            <div className='TopBar animated fadeInDown faster'>
                {window.matchMedia("(max-width: 1025px)").matches &&
                <div onClick={ToggleSubMenu} className="TopBar-plusIcon">
                    <PlusIcon/>
                </div>
                }
                {window.matchMedia("(min-width:1026px)").matches &&
                    <>
                    <div className="TopBar-ocupacion">
                        <p className="TopBar-ocupacion-text">{lounge && lounge.name}</p>
                        <p className="TopBar-progress-score">{lounge? `${occupied} / ${total}`:'-'}</p>
                        <progress className="TopBar-progress" max="1" value={total? occupied/total : 0} />
                    </div>
                    <div className="TopBar-disponibilidad">
                    <p>Disponibilidad en otras salas</p>
                    <button onClick={isOpenToggle}  className="TopBar-disponibilidad-drop">
                    <OrangeArrow/>
                    </button>
                {isOpenLoungeOccupation &&
                    <LoungeAvailable
                        onSelect={showLoungeMap}
                    />}
                    </div>
                    </>
                }
                <TopBarClock/>

                <TopBarExchangeRate/>

                <TopBarProfile/>
            </div>
            {subMenu && window.matchMedia("(max-width: 1025px)").matches &&
            <div className="TopBar-subMenu animated fadeInDown faster">
                <div className="TopBar-ocupacion">
                    <p className="TopBar-ocupacion-text">{lounge && lounge.name}</p>
                    <p className="TopBar-progress-score">{lounge? `${occupied} / ${total}`:'-'}</p>
                    <progress className="TopBar-progress" max="1" value={total? occupied/total : 0} />
                </div>
                <div className="TopBar-disponibilidad">
                    <p>Disponibilidad en otras salas</p>
                    <button onClick={isOpenToggle} onBlur={closeLoungeOccupation}  className="TopBar-disponibilidad-drop">
                        <OrangeArrow/>
                    </button>
                    {isOpenLoungeOccupation &&
                    <LoungeAvailable/>}
                </div>
            </div>
            }

            {showingMap &&
                <LoungeMapModal
                    loungeId={showingMap}
                    onClose={stopShowingMap}
                />}

        </>
    )
};
TopBar.displayName='TopBar';
export default  TopBar;
