import React, {useEffect, useContext, useState, useMemo} from 'react';
import './OrozcoMenu.scss';
import RecursiveMenu from "../../WaiterMenu/Components/RecursiveMenu/RecursiveMenu";
import {OROZCO_PRODUCT_SPECIAL_ID} from "../../../../services/modelUtils/orozcoProductUtils";
import MenuFoodCard from "../../WaiterMenu/Components/MenuFoodCard/MenuFoodCard";
import SpecialMenuCard from "../../WaiterMenu/Components/SpecialMenuCard/SpecialMenuCard";
import {useSelector} from "react-redux";
import _ from "lodash";
import {menuSGroups} from "../../../../services/modelUtils/orozcoProductCategoryUtils";
import {ApiContext} from "../../../../services/api/api-config";

const emptyArr = [];
const OrozcoMenu = ({
                        canAddMultipleProductOrders=true,
                        handleNewProductOrder,
                        handleProductRemove,
                        handleProductSelect,
                        openSpecialProductModal,
                        selectedOrderProducts,
                        withSpecialProduct=true
                    })=>{

    const api=useContext(ApiContext);

    // ----------- Load menu ---------------------
    const categories = useSelector(({api}) => api.orozcoProductCategories || emptyArr);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryList, setCategoryList]=useState([]);

    // -- Create a reference from each child category to its parent ---
    useEffect(()=>{
        const setParentReference = (cats, parent)=> cats && _.forEach(cats, cat=>{
            cat.parentCategory = parent;
            setParentReference(cat.childCategories, cat);
        });
        setParentReference(categories, null);
    }, [categories]);

    useEffect(()=>{
        let cList;
        if(selectedCategory?.childCategories?.length)
            cList=selectedCategory.childCategories;
        else if(!selectedCategory && categories)
            cList=categories;

        if(cList) {
            cList = _.filter(cList, cat =>//filter categories, select only if:
                cat.isActive && ( //The category is active and
                cat.orozcoProducts?.reduce((anyAvailable, prod)=>(anyAvailable || prod.isAvailable), false) ||//There is any available product inside or:
                cat.childCategories?.length)//It has child categories inside
            );
            setCategoryList(cList);
        }

    },[categories, selectedCategory]);

    useEffect(() => {
        api.orozcoProductCategories.get({params: { 'parentCategory[exists]':false, isActive:true, sGroups: menuSGroups }});
    }, [api]);

    const [products, setProducts]=useState([]);
    useEffect(()=>{
        if(selectedCategory)
            setProducts(_.filter(selectedCategory.orozcoProducts, 'isAvailable'));
        else
            setProducts([]);
    },[selectedCategory]);

    // ------ Vars for display --------

    const quantityByProductId=useMemo(()=>
            selectedOrderProducts.reduce( (acc, orderProduct)=>{
                acc[orderProduct.orozcoProduct.id]=orderProduct.quantity;
                return acc;
            },{}),
        [selectedOrderProducts]);

    return (
        <div className='OrozcoMenu'>
            <RecursiveMenu
                options={categoryList}
                selected={selectedCategory}
                onSelect={setSelectedCategory}/>

            <div className="WaiterMenu-cards">

                {
                    products.map((product) => {
                        if(product.id === OROZCO_PRODUCT_SPECIAL_ID || product.isExtraCharge)
                            return null;
                        return (
                            <MenuFoodCard
                                onSelect={handleProductSelect}
                                showNewOrderButton={canAddMultipleProductOrders}
                                onRemove={handleProductRemove}
                                onNewProductOrder={handleNewProductOrder}
                                product={product}
                                key={product.id}
                                quantity={quantityByProductId[product.id]}
                            />
                        )
                    })
                }
                {withSpecialProduct &&
                <SpecialMenuCard
                    onSelect={openSpecialProductModal}
                    quantity={0}
                />}

            </div>
        </div>
    );
};

export default OrozcoMenu;
