import React, {useEffect, useContext} from 'react';
import './FullScreenTablePicker.scss';
import FullscreenPicker from "../FullscreenPicker/FullscreenPicker";
import {ApiContext} from "../../services/api/api-config";
import useActualLounge from "../../hooks/useActualLounge";
import {fullScreenTablePickerScreenSGroups} from "../../services/modelUtils/tableUtils";
import {useSelector} from "react-redux";
import CardButton from "../CardButton/CardButton";

const FullScreenTablePicker = ({onHide, onSelect, title}) => {
    const api = useContext(ApiContext);
    const actualLounge = useActualLounge();
    const tables = useSelector(({api}) => api.tables|| []);

    useEffect(() => {
        api.tables.get({
            params: {
                sGroups: fullScreenTablePickerScreenSGroups,
                loungeMap: actualLounge.loungeMap.id
            }
        })
    }, [actualLounge, api]);


    return (
        <FullscreenPicker onHide={onHide} className='FullScreenTablePicker'>
            {title && <h1>{title}</h1>}
            {tables && tables.map((table, index) => (
                <CardButton key={index}
                    entity={table}
                    accessor={"name"}
                    onClick={onSelect}
               />
            ))}
        </FullscreenPicker>
    );

};

export default FullScreenTablePicker;
