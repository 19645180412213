import React, {useEffect, useContext, useState} from 'react';
import {ApiContext} from "../../services/api/api-config";
import {isRemoteDavinci} from "../../services/remoteDavinciUtils";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import './Alarms.scss';
import TopBar from "../../components/TopBar/TopBar";
import moment from "moment";

const Alarms = () => {
    const api = useContext(ApiContext);
    const [visitsWithoutDrink, setVisitsWithoutDrinks] = useState();
    const [visitsWithoutSeat, setVisitsWithoutSeat] = useState();

    useEffect(() => {
        api.personsWithoutDrinks.getPersonsWithoutDrinks().then((res) => {
            setVisitsWithoutDrinks(res);
        });
        api.personsWithoutSeat.getPersonsWithoutSeat().then((res) => {
            setVisitsWithoutSeat(res);
        });        
    }, [api]);

    return (
        <div className='Alarms'>
            <FixedMenu
                bottomBar={isRemoteDavinci()}
            />
            <TopBar/>
            <div className="AlarmsHeader">
                <h1>ALARMAS</h1>
            </div>

            <div className="row AlarmsRow">
                <div className='col-md-6'>
                    <h2 className='text-center mb-3'>
                        Huéspedes sin comanda
                    </h2>
                    <div className="AlarmsTableContainer">
                        <table className="AlarmsTable">
                            <thead>
                                <tr className="AlarmsTableHeaders">
                                    <th>Nombre</th>
                                    <th>Tiempo dentro de la sala</th>
                                </tr>
                            </thead>
                            <tbody>
                            {visitsWithoutDrink?.map((visit, key) => {
                                const patLastName = visit?.guest?.patLastName ? visit?.guest?.patLastName : '';
                                const matLastName = visit?.guest?.matLastName ? visit?.guest?.matLastName : '';
                                const diffDateInMinutes = (moment().diff(visit?.firstCheckInDate, 'minutes'));
                                    return (
                                        <tr key={key}>
                                            <td>{`${visit?.guest?.name} ${patLastName} ${matLastName}`}</td>
                                            <td>{`${diffDateInMinutes} mins. dentro de la sala `}</td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='col-md-6'>
                    <h2 className='text-center mb-3'>
                        Huéspedes sin asiento asignado
                    </h2>
                    <div className="AlarmsTableContainer">
                        <table className="AlarmsTable">
                            <thead>
                                <tr className="AlarmsTableHeaders">
                                    <th>Nombre</th>
                                    <th>Tiempo dentro de la sala</th>
                                </tr>
                            </thead>
                            <tbody>
                            {visitsWithoutSeat?.map((visit, key) => {
                                const patLastName = visit?.guest?.patLastName ? visit?.guest?.patLastName : '';
                                const matLastName = visit?.guest?.matLastName ? visit?.guest?.matLastName : '';
                                const diffDateInMinutes = (moment().diff(visit?.firstCheckInDate, 'minutes'));
                                    return (
                                        <tr key={key}>
                                            <td>{`${visit?.guest?.name} ${patLastName} ${matLastName}`}</td>
                                            <td>{`${diffDateInMinutes} mins. dentro de la sala `}</td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Alarms;
