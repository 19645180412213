import React, {useContext, useEffect, useState, useRef} from 'react';
import './TopBarProfile.scss';
import BoyImg from "../../../../assets/images/boy-placeholder.png";
import GirlImg from "../../../../assets/images/girl-placeholder.png";
import useBoolean from "../../../../hooks/useBoolean";
import LogOut from "../LogOut/LogOut";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";
import {employeeFullName} from "../../../../services/modelUtils/employeeUtils";
import {createFileUrl} from "../../../../services/modelUtils/appFileUtils";
import _ from 'lodash';
import classNames from 'classnames';
import {defaultSessionTime} from "../../../../services/timeUtils";

const isDev = process.env.REACT_APP_BUILD === 'dev';

const INACTIVITY_LOGOUT_TIME = isDev ? 60000000 : (localStorage.sessionTimeout || defaultSessionTime);

const INACTIVITY_BAR_INTERVAL = 200;

const INACTIVITY_RESET_EVENTS = ['mousemove', 'click', 'mousedown', 'touchstart', 'keypress'];

const TopBarProfile = ({className, disableInactivityCheck, filterButton}) => {

    const [isOpen, , closeLogOut, toggleLogOut] = useBoolean();
    const api = useContext(ApiContext);

    const me = useSelector(({api}) => api.me);

    let photo = BoyImg;
    if (me && me.employee && me.employee.photo)
        photo = createFileUrl(me.employee.photo);
    else if (me && me.employee && me.employee.sex === 'F')
        photo = GirlImg;

    const style = {backgroundImage: `url(${photo})`};

    const mounted = useRef(true);
    useEffect(() => {

        if (disableInactivityCheck)
            return;

        const resetTimeout = _.throttle(() => {
            if (mounted.current)//Prevent bug. Throttle would execute the method after un-mounting
                setTimeLeft(INACTIVITY_LOGOUT_TIME)
        }, 500);

        _.forEach(INACTIVITY_RESET_EVENTS, event => document.addEventListener(event, resetTimeout));

        return () => {
            mounted.current = false;
            _.forEach(INACTIVITY_RESET_EVENTS, event => document.removeEventListener(event, resetTimeout));
        }
    }, [disableInactivityCheck]);

    const [timeLeft, setTimeLeft] = useState(INACTIVITY_LOGOUT_TIME);

    useEffect(() => {

        if (disableInactivityCheck)
            return;

        const intervalId = setInterval(() => {
            setTimeLeft((oldTime) => {
                if (oldTime < 0)
                    api.logout();
                return oldTime - INACTIVITY_BAR_INTERVAL
            });
        }, INACTIVITY_BAR_INTERVAL);

        return () => clearTimeout(intervalId)
    }, [api, disableInactivityCheck]);

    return (
        <div className={classNames("TopBar-profile", className)}>
            {filterButton ?
                <div className="pull-left">
                    {filterButton}
                </div>
                : null}
            <div className="TopBar-profile">
                <div className="TopBar-profile-wrapper">
                    <p className="TopBar-profile-name">{me && me.employee ? employeeFullName(me.employee) : ''}</p>
                    {!disableInactivityCheck &&
                    <p className="TopBar-profile-session">
                        <span className='session-bar' style={{width: `${timeLeft / INACTIVITY_LOGOUT_TIME * 100}%`}}/>
                        Sesión Activa
                    </p>}
                </div>
                <div className="TopBar-profile-img">
                    <div className="TopBar-profile-bgImg" style={style}/>
                </div>
                {isOpen &&
                <LogOut
                    isOpen={isOpen}
                    onClose={closeLogOut}
                />
                }
                <button onClick={toggleLogOut} className="DotMenu">
                    <div className="DotMenu-dot"/>
                </button>
            </div>
        </div>
    )
};
export default TopBarProfile;
