import React,{useCallback} from 'react';
import './CardButton.scss';

const CardButton = ({entity, accessor, onClick}) => {

    const handleClick = useCallback(()=>{
        onClick(entity);
    },[entity, onClick]);

    return (
        <div onClick={handleClick} className={"CardButton animated fadeIn"}>
            <p className={"text"}>{entity[accessor]}</p>
        </div>
    )
};
export default CardButton;