import {useState, useCallback} from 'react';

export default (defaultValue)=>{
    const [state, setState]=useState(defaultValue);

    const setAndClear=useCallback((value, timeout)=>{
        setState(value);
        if(timeout)
            return setTimeout(()=>setState(defaultValue), timeout);
        return 0;
    },[setState, defaultValue]);

    return [state, setAndClear]
};
