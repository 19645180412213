import React,{useEffect,useState,useContext} from 'react';
import './LoungeAvailable.scss';
import ProgressBar from "../../../ProgressBar/ProgressBar";
import classNames from 'classnames';
import {ApiContext} from "../../../../services/api/api-config";
import {occupationSGroups} from "../../../../services/modelUtils/loungeUtils";
import {useSelector} from "react-redux";
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator";


const LoungeAvailable = ({onSelect})=>{

    const api=useContext(ApiContext);
    const [animateOpen,setAnimateOpen] = useState(false);

    useEffect(()=>{
        setAnimateOpen(true);

        api.lounges.get({params: {sGroups: occupationSGroups, isLounge: true, isActive: true},
            customProp: 'LoungeAvailable.Lounges'})

    },[api]);

    const lounges=useSelector(({api})=>api['LoungeAvailable.Lounges'] || []);
    const actualLounge=useSelector(({api})=>api.discoverLounge || {});

    const withoutActual= _.filter(lounges,(l)=>l.id!==actualLounge.id);

    const handleSelect=useCallbackCreator((index)=>{
        if(onSelect)
            onSelect(withoutActual[index]);
    },[withoutActual]);


    return(
        <div className={classNames("LoungeAvailable",animateOpen?'animateOpen':'animateClose', onSelect?'selectable':'')}>
            <ul className="LoungeAvailable-list">
                <li className="LoungeAvailable-list-item">
                    <p>Sala</p>
                    <p>Disponibilidad</p>
                </li>
                {
                    withoutActual.map((lounge,i) => (
                            <li key={lounge.id} className="LoungeAvailable-list-item lounge-item" onClick={handleSelect(i)}>
                                <p>{lounge.name}</p>
                                <div className="LoungeAvailable-availableBar">
                                    <p>{lounge.loungeMap && `${lounge.loungeMap.occupiedSeats} / ${lounge.loungeMap.totalSeats}`}</p>
                                    <ProgressBar
                                        progressBarFromLounge
                                        max="1"
                                        value={(lounge.loungeMap && lounge.loungeMap.totalSeats &&`${lounge.loungeMap.occupiedSeats/lounge.loungeMap.totalSeats}`)||0}
                                    />
                                </div>
                            </li>
                        ))
                }
            </ul>
        </div>
    )
};
export default LoungeAvailable;
