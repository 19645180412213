import React, {useState, useCallback, useEffect} from 'react';
import FullscreenDatePicker from "./FullscreenDatePicker";
import classNames from 'classnames';
import {ReactComponent as CalendarIcon} from "./assets/calendar.svg";
import useBoolean from "../../hooks/useBoolean";
import moment from "moment";
import {pad} from "../../services/numberUtils";


const FullscreenDatePickerInput = ({className, inputClassName, value, onChange})=>{

    const [isPickerOpen, openPicker, closePicker]=useBoolean();
    const [errored, setErrored, notErrored]=useBoolean();

    const [input, setInput]=useState('');


    const handleInputChange=useCallback(({target})=>{

        //Open picker with a space hit
        if(target.value && target.value.substr(-1)===' ')
            openPicker();

        let newVal=target.value.replace(/[^0-9]/g, '').substr(0,8);
        const size=newVal.length;

        const error=()=>{setErrored(); onChange(null)};

        if(!size)
            error();
        else if(size!==8)
            error();
        else{
            const date=Number(newVal.substr(0,2));
            const month=Number(newVal.substr(2,2));
            const year=Number(newVal.substr(4,4));
            if(year && (year>2020 || year<1900))
                error();
            else if(month && (month<1||month>12))
                error();
            else if(date && (date<1||date>moment(`${year}-${month}`).daysInMonth()))
                error();
            else {
                notErrored();
                onChange(moment(`${year}-${pad(month)}-${pad(date)}`,'YYYY-MM-DD'));
            }
        }

        if(size>2)
            newVal=newVal.substr(0, 2) + '/' + newVal.substr(2);
        if(size>4)
            newVal=newVal.substr(0, 5) + '/' + newVal.substr(5);

        setInput(newVal);

    },[notErrored, setErrored, onChange, openPicker]);



    useEffect(()=>{
        notErrored();
        if(value)
            setInput(moment(value).format('DD/MM/YYYY'));
        else
            setInput('');
    },[value, notErrored]);

    return (
        <div className={classNames('FullscreenDatePickerInput', className, errored?'errored':'')}>
            <input className={inputClassName} placeholder='dd/mm/yyyy' value={input} onChange={handleInputChange}/>
            <CalendarIcon className='calendar-icon' onClick={openPicker}/>
            {isPickerOpen&&
            <FullscreenDatePicker
                isInEditableSection
                onHide={closePicker}
                value={value}
                onChange={onChange}
            />}
        </div>
    );
};

export default FullscreenDatePickerInput;
