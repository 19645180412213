import React, {useState} from 'react';
import "./ServerSelection.scss"
import Button from "../../components/Button/Button";

const ServerSelection = ()=>{

    const [customServer, setCustomServer] = useState("");

    const changeServer = (server)=>{
        localStorage.host=server;
        window.location.href = "/";
    }

    return (
      <div className='ServerSelection'>

          <p>Servidor actual: {localStorage.host||"https://s1.tgle.mx"}</p>

          <div className='servers-container'>
              <div className="server-block">
                  <p className='server-name'>Servidor principal</p>
                  <p className='server-address'>https://s1.tgle.mx</p>
                  <Button main onClick={()=>changeServer("https://s1.tgle.mx")}>Conectar</Button>
              </div>
              <div className="server-block">
                  <p className='server-name'>Servidor de respaldo</p>
                  <p className='server-address'>https://s2.tgle.mx</p>
                  <Button main  onClick={()=>changeServer("https://s2.tgle.mx")}>Conectar</Button>
              </div>
              <div className="server-block">
                  <p className='server-name'>Servidor manual</p>
                  <input className='davinci-input no-upper' placeholder='ingresa la dirección' value={customServer} onChange={e=>setCustomServer(e.target.value)}/>
                  <Button main onClick={()=>changeServer(customServer)}>Conectar</Button>
              </div>
          </div>

      </div>
    );
};

export default ServerSelection;
