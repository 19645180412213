import React,{useEffect,useState,useCallback,useContext} from 'react';
import './LogOut.scss';
import className from 'classnames';
import TideEntitySelect from "../../../TideEntitySelect/TideEntitySelect";
import {employeeFullName} from "../../../../services/modelUtils/employeeUtils";
import Button from "../../../Button/Button";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";


const LogOut = ({isOpen, onClose})=>{


    const [animateOpen,setAnimateOpen] = useState(false);
    useEffect(()=>{
        setAnimateOpen(isOpen);
        return() =>{
            setAnimateOpen(false);
        }
    },[animateOpen,isOpen]);

    const switchUser=useCallback((user)=>{
        window.localStorage.switchUser=user.username;
        window.location.reload();
    },[]);

    const stopSwitching=useCallback(()=>{
        delete window.localStorage.switchUser;
        window.location.reload();
    },[]);

    const api=useContext(ApiContext);

    const logOut=useCallback(()=>{
        api.logout();
    },[api]);

    const me = useSelector(({api})=>api.me);
    const isSuperAdmin = window.localStorage.switchUser || (me && me.role && me.role.name === 'SUPER_ADMIN');

    return(
        <>
        <div className='LogOut-back-overlay' onClick={onClose}/>
        <div onClick={!isSuperAdmin?logOut:undefined} className={className("LogOut",animateOpen?'animateOpen':'')}>
            <div className="LogOut-plica"/>
            {isSuperAdmin&&
                <>
                <TideEntitySelect
                    entity='users'
                    filterBy='search'
                    labelCreator={labelCreator}
                    additionalApiOptions={apiOptions}
                    onChange={switchUser}
                    />
                    <Button onClick={stopSwitching}>Un-switch</Button>
                </>}
            <p className='log-out-text' onClick={isSuperAdmin?logOut:undefined}>Cerrar Sesión</p>
        </div>
        </>
    )
};
export default LogOut;

const apiOptions={preventUserSwitch:true};
const labelCreator=(u)=>employeeFullName(u.employee);
