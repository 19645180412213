
export const localStorageFrontDeskPrinterKey = 'FRONT_DESK_PRINTER';
export const localStorageBarPrinterKey = 'BAR_PRINTER';
export const localStorageCocinaPrinterKey = 'COCINA_PRINTER';
export const localStorageDotcomPrinterKey = 'DOTCOM_PRINTER';

export const printAreas = {
    cocina: 'cocina',
    bar: 'bar',
    dotcom: 'dotcom'
};

export const getPrinterIdByKey = (key) => {

    if (getPrinterByKey(key)) {
        return getPrinterByKey(key).id;
    }
    return null;
};

export const getPrinterByKey = (key) => {

    const actualLounge = JSON.parse(window.localStorage.lounge);

    if (window.localStorage[key])
        return JSON.parse(window.localStorage[key]);
    else if (actualLounge && actualLounge.config && actualLounge.config.printers && actualLounge.config.printers[key])
        return JSON.parse(actualLounge.config.printers[key]);

    return null;
};


export const getPrinterIdByPrintArea = (printArea) => {
    switch (printArea) {
        case printAreas.cocina:
            return getPrinterIdByKey(localStorageCocinaPrinterKey);
        case printAreas.bar:
            return getPrinterIdByKey(localStorageBarPrinterKey);
        case printAreas.dotcom:
            return getPrinterIdByKey(localStorageDotcomPrinterKey);
        default:
            break;
    }
};



