import { combineReducers } from 'redux';
import {reducer as api, loadingReducer as loading, loadingByIdReducer as loadingIds} from 'tide-api';
import appBlurred from '../appBlurredReducer';
import latestAppVersion from '../latestAppVersionReducer';

const rootReducer = combineReducers({
    api,
    loading,
    loadingIds,
    appBlurred,
    latestAppVersion
});

export default rootReducer;
