import React,{useContext, useEffect, useCallback} from 'react';
import './SimilarResults.scss';
import {ReactComponent as CheckIcon} from '../../../../assets/images/icons/CheckIcon.svg';
import GuestProfile from "../../../../components/GuestProfile/GuestProfile";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";
import {guestFullName, searchSGroups} from "../../../../services/modelUtils/guestUtils";
import LoadingAnimation from "../../../../components/LoadingAnimation/LoadingAnimation";
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator/src";
import moment from "moment";
import PendingBill from "./PendingBill/PendingBill";
import {isRemoteDavinci} from "../../../../services/remoteDavinciUtils";


const loadingId='@SimilarResults';
const SimilarResults = ({reloadSelectedGuest, search, onGuestSelect, selectedGuest})=>{

    const api=useContext(ApiContext);

    const loadGuests=useCallback(
        _.debounce(
        (params)=>api.guests.get({params:{...params, sGroups:searchSGroups}, loadingId}), 500),
        []);

    useEffect(()=>{
        //Si no hay ningún filtro o estamos viendo a un guest, no hacemos búsqueda
        if(selectedGuest || !search || !_.reduce(search, (acc, val)=>acc||val, false))
            return api.clearProperty('guests');
        loadGuests(search);
    },[api, search, loadGuests, selectedGuest]);

    const guests=useSelector(({api})=>api.guests||[]);

    const guestsMeta=useSelector(({api})=>api.guestsMeta||[]);

    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const selectGuest=useCallbackCreator((id)=>{
        const guest= _.find(guests, guest=>guest.id===id);
        if(guest)
            onGuestSelect(guest);
    },[guests]);

    const clearGuest=useCallback(()=>onGuestSelect(null),[onGuestSelect]);

    return(
        <>
            <div className='SimilarResults'>
                {!guests.length && !selectedGuest && !isRemoteDavinci() &&
                <PendingBill/>
                }

                <div className="SimilarResults-title">
                    <p>{selectedGuest?'Perfil de Huésped':'Resultados Similares'}</p>
                    {selectedGuest&&
                        <div onClick={clearGuest} className="cross-wrapper">
                            <div className="cross"/>
                        </div>
                    }
                </div>

                {!selectedGuest ?
                    <div className="SimilarResults-content">

                        {(!!guests.length)&&<p className='result-count'>{guestsMeta.totalItems} resultados</p>}

                        {loading&&
                        <div className='loading-container'>
                            <LoadingAnimation/>
                        </div>}

                        {!guests.length&&
                            <p className='no-results'>No hay resultados</p>}

                        <ul className="SimilarResults-list">
                            {
                                guests && !!guests.length && guests.map(guest => {
                                    return (
                                        <li
                                            key={guest.id}
                                            className="SimilarResults-list-item"
                                            onClick={selectGuest(guest.id)}
                                        >
                                            <p>{guestFullName(guest).toUpperCase()}</p>
                                            <div className="SimilarResults-list-checkIcon">
                                                <CheckIcon/>
                                            </div>
                                            <span className="SimilarResults-list-email">{guest.email}</span>
                                            <span className="SimilarResults-list-email">{guest.birthday?moment(guest.birthday).format('DD/MM/YYYY'):''}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                :
                    <GuestProfile
                        guestId={selectedGuest.id}
                        onChange={reloadSelectedGuest}
                    />}

            </div>
        </>
    )
};
export default SimilarResults;
