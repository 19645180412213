import React, {useState, useCallback, useContext} from 'react';
import {ApiContext} from '../../../services/api/api-config';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';
import {getNotifier} from '../../../services/notifierUtils';
import {useSelector} from 'react-redux';
import './EditDelayTimeModal.scss';

const maxM = 60*24;
const mod = t => (t%(maxM)+(maxM))%(maxM);
const pad = n => `${n}`.padStart(2, '0');

const loadingId = '@EditDelayTimemodal.flightDelayStatus';
const EditDelayTimeModal = ({onChange, onClose, flight})=>{
    const api = useContext(ApiContext);
    const [time, setTime] = useState(flight.currentFlightStatus?flight.currentFlightStatus.delayMinutes:0);
    const notifier = getNotifier();
    const saveDelay = useCallback(()=>{
        api.flightDelayStatuses.create({
            params: {
                flight: flight.id,
                delayMinutes: time
            },
            loadingId
        }).then(()=>{onClose();onChange();})
            .catch(()=>notifier.error('No se puedo guardar el cambio.'));
    }, [api, flight.id, time, onClose, onChange, notifier]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
    return (
        <Modal
            onClose={onClose}
            title={'Tiempo de demora del vuelo '+flight.number}
            className='EditDelayTimeModal'>
            <p>Tiempo de demora del vuelo {flight.number} de {flight.airline.name||flight.airline.code}</p>
            <div className='control-container'>
                <div className='time-picker'>
                    <div className='time-col'>
                        <button onClick={()=>setTime(t=>mod(t+60))}>+</button>
                        <p>{pad(parseInt(time/60))}</p>
                        <button onClick={()=>setTime(t=>mod(t-60))}>-</button>
                    </div>
                    <div className='time-col'>:</div>
                    <div className='time-col'>
                        <button onClick={()=>setTime(t=>mod(t+1))}>+</button>
                        <p>{pad(time%60)}</p>
                        <button onClick={()=>setTime(t=>mod(t-1))}>-</button>
                    </div>
                </div>
            </div>
            <Button disabled={loading} blue onClick={saveDelay}>Guardar</Button>
        </Modal>
    );
};

export default EditDelayTimeModal;
