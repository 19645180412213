import React, {useMemo} from 'react';
import WaiterCard from "../WaiterCard/WaiterCard";
import _ from "lodash";
import {useSelector} from "react-redux";
import {SCOPE_ALL_VISITS, SCOPE_UNASSIGNED_VISITS} from "../AttendingVisitList/AttendingVisitList";

const WaiterList = ({onSelectedWaiterChange, selectedWaiter, showScopes})=>{

    const me = useSelector(({api}) => api.me);
    const profiles = useSelector(({api}) => api.orozcoEmployeeProfiles || []);
    const visits = useSelector(({api}) => api.visits || []);

    const sortedProfiles = useMemo(() => {
        return _.sortBy(profiles, (profile) => {
            return (me.employee && profile.employee.id === me.employee.id) ? 0 : 1;
        })
    }, [profiles, me]);


    const unassignedCount = useMemo(() => {
        return _.filter(visits, (visit) => visit.employeesAttendingVisit && visit.employeesAttendingVisit.length === 0).length;
    }, [visits]);

    const allCount = useMemo(() => {
        return visits.length;
    }, [visits]);

    return (
        <div className="AttendingVisits-left">
            <div className="AttendingVisits-name">
                <p>Anfitrión</p>
            </div>
            <div className="AttendingVisits-cards">

                {showScopes&&<>
                <div onClick={()=>onSelectedWaiterChange(SCOPE_UNASSIGNED_VISITS)}
                     className={(selectedWaiter === SCOPE_UNASSIGNED_VISITS ? 'selected' : '') + " WaiterCard unassigned-visits animated fadeIn faster"}>
                    <p className="WaiterCard-text">Por asignar</p>
                    <div className='badges-container'>
                        {!!unassignedCount ?
                            <div className="badge visits-count-badge animated fadeIn">
                                {unassignedCount}
                            </div> : null}
                    </div>

                </div>

                    <div onClick={()=>onSelectedWaiterChange(SCOPE_ALL_VISITS)}
                     className={(selectedWaiter === SCOPE_ALL_VISITS ? 'selected' : '') + " WaiterCard all-visits animated fadeIn faster"}>
                    <p className="WaiterCard-text">Todos</p>

                    <div className='badges-container'>
                        {!!allCount &&
                        <div className="badge visits-count-badge animated fadeIn">
                            {allCount}
                        </div>
                        }
                    </div>
                </div>
                </>}

                {sortedProfiles.map((profile) =>
                    <WaiterCard
                        isSelected={selectedWaiter === profile.employee.id}
                        onClick={onSelectedWaiterChange}
                        orozcoEmployeeProfile={profile} key={profile.id}
                        showActiveVisits={true}
                    />
                )}
            </div>
        </div>
    );
};
export default WaiterList;
