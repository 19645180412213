/**
 *
 * @returns {{valid: boolean, errors: string[]}}
 * @param bcbpString string
 */
import {getNotifier} from "./notifierUtils";

export const bcbpValidate = ( bcbpString, bcbpObject )=>{

    const result = { valid: true, errors: [], original: bcbpString };

    //"M1EHRLICH/MARK EABC9876MEXSFOUAL1853 216F035A0001 100"

    if( typeof bcbpString !== "string")
        return { valid: false, errors: ["No string provided"] };

    if( bcbpString[0] !== "M" ){
        result.valid = false;
        result.errors.push("First character should be M");
    }

    if( !Number(bcbpString[1]) ){
        result.valid = false;
        result.errors.push("Second character should be number greater than 0");
    }

    if( typeof bcbpObject !== "object"){
        result.valid = false;
        result.errors.push("Parsed input is not an object");
        return result;
    }

    if( !bcbpObject.legs?.length ){
        result.valid = false;
        result.errors.push("Flight has no legs");
        return result;
    }

    if( !bcbpObject.legs[0].operatingCarrierDesignator ){
        result.valid = false;
        result.errors.push("Flight has no operating carrier");
    }

    if( !bcbpObject.legs[0].flightNumber ){
        result.valid = false;
        result.errors.push("Flight has no flight number");
    }

    return result;
};

/**
 *
 * @param bcbpObject
 * @param api
 * @returns {Promise<unknown>}
 */
export const bcbpToCheckIn = ( bcbpObject, api )=>{

    return new Promise((res)=>{
        const data = {};

        if(!bcbpObject)
            return res(data);

        let [ patLastName, name ] = bcbpObject.passengerName.split("/");
        if(!name) {
            name = patLastName;
            patLastName = "";
        }

        data.name = name;
        data.patLastName = patLastName;

        if(!bcbpObject.legs?.length) {
            getNotifier().error("No se encontró ningún vuelo en el pase de abordar");
            return res(data);
        }

        if( bcbpObject.legs[0].seatNumber ){
            data.checkIns = [{ extraFields: {Asiento: { guest: bcbpObject.legs[0].seatNumber.replace(/^0+/, "")}}}];
        }

        const flightDetails = {
            airlineCode: bcbpObject.legs[0].operatingCarrierDesignator,
            number: bcbpObject.legs[0].flightNumber.replace(/^0+/, "")
        }

        api.flights.findWithAlias( { params:flightDetails, customProp:'_' } )
            .then( flights=>{
                if( flights?.length ){
                    data.flight = flights[0];
                }
                else {
                    getNotifier().warning(`Vuelo con número ${flightDetails.airlineCode}${flightDetails.number} no encontrado.`);
                }
                res(data);
            })
            .catch(e=>{
                getNotifier().error(`Hubo un error buscando el vuelo número ${flightDetails.airlineCode}${flightDetails.number}. Erro: `+e?.message);
                res(data);
            });
    });
}
