import React, {useState, useCallback, useContext, useMemo, useEffect} from 'react';
import './GuestProfile.scss';
import _ from 'lodash';
import SwitchBtn from "../SwitchBtn/SwitchBtn";
import GuestImg from "../../assets/images/GuestImg.png";
import classNames from 'classnames';
import {guestFullName, guestProfileSGroups, isGuestInLounge} from "../../services/modelUtils/guestUtils";
import moment from "moment";
import {displayDateFormat} from "../../services/timeUtils";
import {ApiContext} from "../../services/api/api-config";
import useCallbackCreator from "use-callback-creator/src";
import {ReactComponent as BirthdayIcon} from "../../assets/images/icons/birthdayIcon.svg";
import useBoolean from "../../hooks/useBoolean";
import NonGrataAndVipModal from "./NonGrataAndVipModal/NonGrataAndVipModal";
import {getNotifier} from "../../services/notifierUtils";
import {verifyIfIsBirthday} from "../../services/modelUtils/guestUtils";
import {getLoungeAccessMethodNameIncludingParents} from "../../services/modelUtils/loungeAccessMethodsUtils";
import {SecurityContext} from '../../services/SecurityManager';
import GuestProfileComments from "./GuestProfileComments/GuestProfileComments";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";



const GuestProfile = ({guestId, guestInfoSize, selectableVisits, onVisitSelect,onChange})=>{
    const style={backgroundImage: `url(${GuestImg})`};
    const api=useContext(ApiContext);
    const security = useContext(SecurityContext);
    const [guest, setGuest] = useState();
    const [updatingGuest, setUpdatingGuest] = useState(false);
    const [nonGrataModal,openNonGrataModal,closeNonGrataModal] = useBoolean();
    const [vipModal,openVipModal,closeVipModal] = useBoolean();

    const loadGuest = useCallback(()=>{
        if(!guestId)
            return;
        // fromGuestProfile param only to identify the request in the chrome console
        api.guests.get({ params:{id: guestId, sGroups: guestProfileSGroups, fromGuestProfile:true }, customProp:'_' })
            .then( guests=>guests?.length===1 &&setGuest(guests[0]) );
    },[api, guestId]);
    useEffect(loadGuest, [loadGuest]);

    const notifier=getNotifier();

    const isNonGrata = guest?.nonGrataSince !== null;
    const isVip = guest?.vipSince !== null;

    const [haveImg] = useState(false);

    const activeLounge= isGuestInLounge(guest);

    const visits=useMemo(()=>((guest && [...guest.visits])||[]).sort( (visitA, visitB)=>moment(visitA.firstCheckInDate).isBefore(visitB.firstCheckInDate)?1:-1 ), [guest]);

    const handleVisitSelect=useCallbackCreator((index)=>{
        onVisitSelect(visits[index]);
    },[visits, onVisitSelect]);

    const favDishes = useMemo(() => {
        let products = [];
        for (let i = 0; i < visits?.length; i++) {
            if (visits[i].orozcoVisitOrder) {
                visits[i].orozcoVisitOrder.orozcoVisitOrderProducts.forEach((product) => {
                    products.push(product.orozcoProduct.name);
                })
            }
        }

        const orderedProducts = _.reduce(products, (acc, index) => { acc[index] = (acc[index] || 0) + 1; return acc; }, {});
        const arrayOfSortedProducts = _.sortBy(Object.keys(orderedProducts),[(a, b) => {
            return orderedProducts[b] - orderedProducts[a];
        }]);
        return _.slice(arrayOfSortedProducts, 0, 5);
    }, [visits]);

    const closeNonGrataAndVipModal = ()=>{
        closeNonGrataModal();
        closeVipModal();
    };

    const updateGuest = useCallback(params => async () => {
        if(!guest) return;
        setUpdatingGuest(true);
        try {
            await api.guests.update({
                id: guest.id,
                params
            });
            if(onChange) onChange();
            notifier.success('Huésped actualizado');
        } finally{
            setUpdatingGuest(false);
        }
    }, [api, guest, notifier, onChange]);

    if(!guest) {
        return (
            <div className={classNames('GuestProfile GuestProfile-content center')}>
                <LoadingAnimation/>
            </div>);
    }

    return(
        <>
            <div className={classNames('GuestProfile GuestProfile-content animated slideInLeft faster',isNonGrata?'non-grata':isVip?'isVip':'',guestInfoSize?'full-height':'')}>
                {verifyIfIsBirthday(guest.birthday) &&
                    <div className="BirthdayIcon">
                        <BirthdayIcon/>
                    </div>
                }
                <div className={classNames("full-width GuestProfile-header", haveImg?"headerSize":"")}>
                    {haveImg?
                        <div className="GuestProfile-img" style={style}/>
                    :""}
                    <p className={"GuestProfile-name " + (isVip?"isVipText":'')}>{guestFullName(guest).toUpperCase()}</p>
                    <p className={"GuestProfile-birthday " + (isVip?"isVipText":'')}>{guest.birthday?moment(guest.birthday).format(displayDateFormat) : 'Sin cumpleaños'}</p>
                </div>
                <div className="GuestProfile-bottom full-width">
                    {activeLounge&&
                    <div className='active-lounge'><span>Actualmente en {activeLounge.name}</span></div>}
                    {(isNonGrata || !isVip)&&(
                        <>
                            <div className="GuestProfile-personBehave">
                                <p className={isVip?"isVipText":''}>Persona non grata{(isNonGrata&&!!guest.nonGrataLevel)?': L'+guest.nonGrataLevel:''}</p>
                                {(!isNonGrata || security.canUpdateGuestNonGrata()) &&
                                <SwitchBtn disabled={updatingGuest}
                                        value={isNonGrata}
                                        onChange={isNonGrata?
                                                    updateGuest({nonGrataSince: null, nonGrataReason: null}):
                                                    openNonGrataModal}
                                />}
                            </div>
                            {guest.nonGrataReason &&
                            <div>
                                <p className='behaviour-reason'>"{guest.nonGrataReason}"</p>
                            </div>
                            }
                        </>
                    )}
                    {(isVip || !isNonGrata)&&(
                        <>
                            <div className="GuestProfile-personStatus">
                                <p className={isVip?"isVipText":''}>Huésped VIP</p>
                                <SwitchBtn disabled={updatingGuest}
                                        value={isVip}
                                        onChange={isVip?
                                                    updateGuest({vipSince: null, vipReason: null, vipLevel: null}):
                                                    openVipModal}
                                />
                            </div>
                            {guest.vipReason &&
                            <div>
                                <p className={isVip?"behaviour-reason isVipText":'behaviour-reason'}>"{guest.vipReason}"</p>
                            </div>
                            }
                        </>
                    )}
                    <div className="GuestProfile-Access full-width">
                        <div className={"GuestProfile-Acces-last full-width "  + (isVip?"isVipBorder":'')}>
                            <p className={isVip?"isVipTitles":''}>Últimos Accesos</p>
                        </div>
                        <div className={classNames("GuestProfile-listContent", selectableVisits?'selectable':'')}>
                            {!visits || visits.length <1 ?
                                <p className="GuestProfile-noInfo">No hay Accesos</p>
                        :
                            <ul className="GuestProfile-list">
                                {
                                    visits.map((visit,i) =>
                                        visit.checkIns && visit.checkIns.length?
                                            <li className="GuestProfile-list-item" key={visit.id} onClick={selectableVisits? handleVisitSelect(i):undefined}>
                                                <p className={isVip?"isVipText":''}>{visit.checkIns[0] && getLoungeAccessMethodNameIncludingParents(visit.checkIns[0].loungeAccessMethod)}</p>
                                                <p className={isVip?"isVipText":''}>{moment(visit.firstCheckInDate).format(displayDateFormat)}</p>
                                            </li>
                                            :null)
                                }
                            </ul>
                            }
                        </div>
                    </div>
                    <div className="GuestProfile-services full-width">
                        {/* <div className="GuestProfile-services-left">
                            <div className={"GuestProfile-services-title "  + (isVip?"isVipBorder":'')}>
                                <p className={isVip?"isVipTitles":''}>Serv. Favoritos</p>
                            </div>
                            <div className="GuestProfile-services-card">
                                <div className="GuestProfile-card">
                                    <p>Sin info</p>
                                </div>
                                {
                                    services.map((s,i)=>{
                                        return(
                                            <div className="GuestProfile-card" key={i}>
                                                <p className={isVip?"isVipText":''}>Spa</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        <div className="GuestProfile-services-right">
                            <div className={"GuestProfile-services-title " + (isVip?"isVipBorder":'')}>
                                <p className={isVip?"isVipTitles":''}>Comida Favorita</p>
                            </div>
                            <div className="GuestProfile-food-card">
                                { favDishes?.length > 0 ?
                                    favDishes?.map((f,i)=>{
                                        return(
                                            <div className="GuestProfile-card" key={i}>
                                                <p className={isVip?"isVipText":''}>{f}</p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="GuestProfile-card">
                                        <p className={isVip?"isVipText":''}>Sin información</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <GuestProfileComments guest={guest} haveImg={haveImg} isVip={isVip} />
                </div>
                { (nonGrataModal || vipModal)&&
                  <NonGrataAndVipModal
                      nonGrataModal={nonGrataModal}
                      closeNonGrataAndVipModal={closeNonGrataAndVipModal}
                      updateGuest={updateGuest}
                  />
                }
            </div>
        </>
    )
};
export default GuestProfile;
