import {createWorker} from 'tesseract.js';
import {parse} from "mrz";
import {getNotifier} from "./notifierUtils";
import _ from 'lodash';
import moment from 'moment';

export const MRZ_TYPES = {
    PASSPORT: 'PASSPORT',
    IDENTIFICATION: 'IDENTIFICATION',
}

const BASE_CROPPIE_CONFIG = {
    enableExif: false,
    showZoomer: false,
    enableResize: true,
    enforceBoundary: true,
    enableOrientation: true,
    mouseWheelZoom: true,
}
export const CROPPIE_CONFIG = {
    [MRZ_TYPES.PASSPORT]:{
        ...BASE_CROPPIE_CONFIG,
        viewport: { width: 758, height: 110 },
        boundary: { width: 758, height: 427 },
        //points are used for the croppie bind function
        //points are [topLeftX, topLeftY, bottomRightX, bottomRightY]
        //topLeftX and bottomRightX define the zoom
        //topLeftY defines the vertical position
        //bottomRightY is ignored since the rectangle aspect ratio is given by the viewport size
        points: [330, 675, 1625, 10],
    },
    [MRZ_TYPES.IDENTIFICATION]:{
        ...BASE_CROPPIE_CONFIG,
        viewport: { width: 758, height: 160 },
        boundary: { width: 758, height: 427 },
        points: [530, 490, 1410, 600],
    }
};

export const recognizeMzrInImage = async ( image, mrzType )=>{

    const lineLength = mrzType === MRZ_TYPES.PASSPORT?44:30;
    const lineCount = mrzType === MRZ_TYPES.PASSPORT?2:3;

    const worker = createWorker({langPath:'https://davinci.tgle.mx/tesseract'});
    await worker.load();
    await worker.loadLanguage('ocrb');
    await worker.initialize('ocrb');
    await worker.setParameters({
        tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789<',
    })
    return worker.recognize(image)
        .finally(async () => {
            await worker.terminate();
        })
        .then((result) => {
            const response = {
                valid: true,
                ocrResult: result,
                lines: result.data.lines.map(line => line.text.replace(/\s/g, "").slice(0,lineLength)),
            };
            response.lines = response.lines.slice(0,lineCount);
            try{
                response.data = parse(response.lines);
            }
            catch (e){
                response.error = e;
                response.valid = false;
            }

            return response;
        })
}

export const mzrToCheckIn = ( mrzObject, api )=>{

    return new Promise((res)=>{
        const data = {};

        if(!mrzObject)
            return res(data);

        data.name = mrzObject.fields?.firstName;
        data.patLastName = mrzObject.fields?.lastName;
        data.patLastName = mrzObject.fields?.lastName;
        //Si es una INE de México, los valores de sexo no son estándar
        // En vez de M=hombre y F=Mujer como en to do el mundo, tenemos H=hombre y M=Mujer
        if( mrzObject.fields?.nationality === "MEX" && mrzObject.fields?.documentCode === "ID" ){
            const rawSex = _.find( mrzObject.details, det=>det.field==='sex' );
            if( rawSex?.ranges?.length && rawSex.ranges[0]?.raw === "H")
                data.gender = "M";
            else if( rawSex?.ranges?.length && rawSex.ranges[0]?.raw === "M")
                data.gender = "F";
        }
        else if( mrzObject.fields?.sex ){
            data.gender = mrzObject.fields.sex === "male" ? "M" : "F";
        }

        if( mrzObject.fields?.birthDate ){
            data.birthday = moment( mrzObject.fields.birthDate, "YYMMDD" );
        }

        if(!mrzObject.fields?.nationality)
            res(data);

        api.countries.get( { params:{ iso3Code: mrzObject.fields.nationality  } } )
            .then( countries=>{
                if( countries?.length )
                    data.country = countries[0];
                res(data);
            })
            .catch(e=>{
                getNotifier().error(`Hubo un error buscando el país del huésped ${mrzObject.fields.nationality}. Erro: `+e?.message);
                res(data);
            });

    });

};
