import React, {useMemo} from 'react';
import ExtraField from './ExtraField';
import _ from 'lodash';

const CheckInExtraField = ({handleExtraFieldChange, checkIn, title, identifier}) => {

    const [who, which, extraField] = identifier;

    const fieldIdentifier=`${who}~|~${which===null?'NA':which}~|~${extraField.name}`;

    const fieldValues = useMemo(()=>checkIn.extraFields?.[extraField.name]||{},
        [checkIn, extraField]);

    const configurations = checkIn.loungeAccessMethod.config.extraFields;
    const config = _.find(configurations, ({name})=>name===extraField.name);

    const value = useMemo(()=>{
        if(which===null){
            return fieldValues[who];
        }
        if(fieldValues[who]){
            return fieldValues[who][which];
        }
        return null;
    }, [fieldValues, which, who]);
    return <ExtraField value={value} onChange={handleExtraFieldChange(fieldIdentifier)} extraField={config} title={title}/>;
};

export default CheckInExtraField;
