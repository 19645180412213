import React, {useState, useContext} from 'react';
import './GuestVisitHistory.scss';
import {ApiContext} from '../../../../../services/api/api-config';
import {getVisitAvailableFreeFreeMeals} from "../../../../../services/modelUtils/visitUtils";
import {getPricedMealsThatCanBeFreeCountFromVisitOrder} from "../../../../../services/modelUtils/orozcoVisitOrderUtils";
import classNames from 'classnames';
import OrderProductPaymentIcon from "../../../../../components/OrderProductPaymentIcon/OrderProductPaymentIcon";
import CancelServiceModal from '../CancelServiceModal/CancelServiceModal';
import _ from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {OROZCO_PRODUCT_SPECIAL_ID} from "../../../../../services/modelUtils/orozcoProductUtils";

const GuestVisitHistory = ({visit, onChange})=>{
    const api = useContext(ApiContext);

    const [serviceBeingCanceled, setServiceBeingCanceled] = useState(null);
    const services = (visit?.serviceVisits || []).map(s=>({...s.service, serviceVisitId: s.id}));

    const allProducts=(visit?.orozcoVisitOrder?.orozcoVisitOrderProducts)||[];

    const orderProducts=_.filter(allProducts, product=>!product.orozcoProduct.isExtraCharge);
    const extraCharges=_.filter(allProducts, product=>product.orozcoProduct.isExtraCharge);



    const availableMeals=getVisitAvailableFreeFreeMeals(visit);

    const usedMeals=getPricedMealsThatCanBeFreeCountFromVisitOrder(visit && visit.orozcoVisitOrder);
    const cancelService = service => () =>
        api.serviceVisits.delete({
            id: service.serviceVisitId,
            loadingId: '@GuestVisitHistory.visit.update'
        }).then(()=>onChange&&onChange());

    return(
        <div className="GuestVisitHistory">
            <div className="title full-width">Historial</div>

            {!services.length && !orderProducts.length && !extraCharges.length &&
            <p className='nothing-here'>Sin movimientos</p>}

            <div className="content">
                {!!services.length&&
                <div className="left">
                    <div className="subTitle">
                        <p>Servicios</p>
                    </div>
                    <div className="cards">
                        <ul className="list">

                            {services.map((s,i)=>{
                                    return(
                                        <li className="item" key={i}>
                                            {s.name}
                                            <button  onClick={()=>setServiceBeingCanceled(s)} className="cancel-item">
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>}
                {!!orderProducts.length&&
                <div className="right">
                    <div className="subTitle dishes-block">
                        <p>Platillos Adquiridos {usedMeals}/{availableMeals}</p>
                    </div>
                    <div className="cards">
                        <ul className="list">
                            {
                                orderProducts.map(orderProduct=>{
                                    return(
                                        <li className={classNames("item food", orderProduct.canceledDate?'canceled':'')} key={orderProduct.id}>
                                            <div className='main-description'>
                                                <p><strong>{orderProduct.quantity}</strong> {orderProduct.orozcoProduct.name}</p>
                                                <OrderProductPaymentIcon visit={visit} orderProduct={orderProduct} />
                                            </div>
                                            {orderProduct.orozcoProduct.id === OROZCO_PRODUCT_SPECIAL_ID && !!orderProduct.data?.description &&
                                            <div className='single-modifier'>{orderProduct.data?.description}</div>}
                                            {!!orderProduct.orozcoProductModifiers?.length &&
                                            <div className='food-modifiers'>
                                                {orderProduct.orozcoProductModifiers.map(mod=><p key={mod.id} className='single-modifier'>{mod.name}</p>)}
                                            </div>}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>}
                {!!extraCharges.length&&
                <div className="right">
                    <div className="subTitle dishes-block">
                        <p>Cargos extra</p>
                    </div>
                    <div className="cards">
                        <ul className="list">
                            {
                                extraCharges.map(orderProduct=>{
                                    return(
                                        <li className={classNames("item food", orderProduct.canceledDate?'canceled':'')} key={orderProduct.id}>
                                            <p><strong>{orderProduct.quantity}</strong> {orderProduct.orozcoProduct.name}</p>
                                            <OrderProductPaymentIcon orderProduct={orderProduct} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>}
                {!!serviceBeingCanceled&&
                 <CancelServiceModal
                     service={serviceBeingCanceled}
                     cancelService={cancelService(serviceBeingCanceled)}
                     onClose={()=>setServiceBeingCanceled(null)}/>}
            </div>
        </div>
    )
};
export default GuestVisitHistory;
