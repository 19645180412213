import React from 'react';
import './Balloons.scss';

const Balloons = ({config}) => {
    const bgColor = {backgroundColor:config.color};
    const triangleColor = {borderBottomColor:config.color};
    const pos ={left:config.position,animationDelay:config.delay};
    return (
        <div className="Balloons" style={pos}>
            <div className="Balloons-top" style={bgColor}>
            </div>
            <div className="Balloons-triangle" style={triangleColor}>
            </div>
            <div className="Balloons-rope">
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
            </div>
        </div>
    )
};
export default Balloons