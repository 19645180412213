import React, {useCallback, useMemo} from 'react';
import './VisitCard.scss';
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import Plane from '../../../../../assets/images/avion.png'
import CheckIn from '../../../../../assets/images/icons/CheckIcon.svg';
import Waiter from '../../../../../assets/images/waiter.png';
import {getCheckInExpirationBarPercentage} from "../../../../../services/modelUtils/checkInUtils";
import {getNextFlightExpirationBarPercentage, getNextFlightDate} from "../../../../../services/modelUtils/flightsUtil";
import moment from 'moment';
import {displayDateTimeFormat} from '../../../../../services/timeUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble, faTimes} from "@fortawesome/free-solid-svg-icons";


const VisitCard = ({visit, showWaiterName, onClick}) => {




    const handleClick=useCallback(()=>{
        onClick(visit);
    },[onClick, visit]);



    const hasWarnings = useMemo(() => {
        return (getNextFlightExpirationBarPercentage(visit.flight) < .3)
            || (!!visit.checkIns[0].expirationDate && (getCheckInExpirationBarPercentage(visit.checkIns[0]) < .3 ));
    }, [visit]);


    return (

        <div className={"VisitCard fadeIn " + (hasWarnings ? 'has-warning' : '')}>
            <div className="full-width" onClick={handleClick}>
                <div className="VisitCard-info full-width">
                    <div className="VisitCard-info-titleBar">
                        <p className="VisitCard-table">Lugar</p>
                        <p className="VisitCard-clientName">Huésped</p>
                        <p className="VisitCard-persons">Personas</p>
                    </div>
                    <div className="VisitCard-info-content">
                        <p className="VisitCard-table single-number">
                            {visit.seats.length === 0?
                                <FontAwesomeIcon icon={faTimes} />:
                            visit.seats.length < visit.activePersons?
                                <FontAwesomeIcon icon={faCheck} />:
                                <FontAwesomeIcon icon={faCheckDouble} />
                            }
                        </p>
                        <div className="VisitCard-clientName">
                            {visit.guest.fullName ? (visit.guest.fullName).toUpperCase():''}
                            <br/>
                            <div className="VisitCard-details full-width">

                                <ProgressBar
                                    max="1"
                                    title={getNextFlightDate(visit.flight).format(displayDateTimeFormat)}
                                    value={getNextFlightExpirationBarPercentage(visit.flight)}
                                    icon={Plane}
                                    customWidth="80%"
                                />
                                <ProgressBar
                                    max="1"
                                    title={visit.checkIns[0].expirationDate ? moment(visit.checkIns[0].expirationDate).format(displayDateTimeFormat) : "Sin expiración"}
                                    value={getCheckInExpirationBarPercentage(visit.checkIns[0])}
                                    icon={CheckIn}
                                    customWidth="80%"
                                />
                                {showWaiterName &&
                                <div className='waiter'>
                                    <img src={Waiter} alt="Anfitrión"/>
                                    <span>{visit.employeesAttendingVisit[0] ? visit.employeesAttendingVisit[0].employee.fullName : 'Sin asignar'}</span>
                                </div>
                                }
                            </div>

                        </div>
                        <p className="VisitCard-table single-number">{visit.activePersons}</p>
                    </div>
                </div>
             </div>
        </div>
    )
};
export default VisitCard;
