import React from 'react';
import './Button.scss';
import classNames from 'classnames';

const Button = ({
                    className,
                    disabled,
                    solid,
                    main=true,
                    children,
                    blue,
                    onClick,
                    ...props})=>{

    const classes = classNames(
        'Button',
        main?'main':'',
        solid?'solid':'',
        blue?'blue':'',
        className
    );

    return(
        <button className={classes} disabled={disabled} onClick={onClick} {...props}>{children}</button>
    )
};
export default Button
