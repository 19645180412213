import React, {useEffect, useState, useCallback, useContext} from 'react';
import './FrontDeskGuest.scss';
import TopBar from "../../components/TopBar/TopBar";
import {useParams, useHistory, Link} from 'react-router-dom';
import VisitInfo from "./Components/VisitInfo/VisitInfo";
import GuestProfile from "../../components/GuestProfile/GuestProfile";
import moment from "moment";
import {guestFullName} from "../../services/modelUtils/guestUtils";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import {paths} from "../../services/routes/appRoutes";
import {useActualLoungeOccupation} from "../../services/modelUtils/loungeUtils";
import {ReactComponent as UserIcon} from "../../assets/images/icons/userIcon.svg";
import {ReactComponent as RightArrow} from "../../assets/images/icons/rightArrow.svg";
import {ReactComponent as LeftArrow} from "../../assets/images/icons/leftArrow.svg";
import {ReactComponent as PlusIcon} from "../../assets/images/icons/plusIcon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/icons/editIcon.svg";
import useBoolean from "../../hooks/useBoolean";
import classNames from 'classnames';
import EditInfoModal from "./Components/EditInfoModal/EditInfoModal";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import {frontDeskVisitSGroups} from '../../services/modelUtils/visitUtils';
import BirthdayAnimation from "./Components/VisitInfo/BirthdayAnimation/BirthdayAnimation";
import {ApiContext} from "../../services/api/api-config";
import {useSelector} from "react-redux";

const FrontDeskGuest = ()=>{

    const { visitId }=useParams();
    const api = useContext(ApiContext);
    const [visit, setVisit] = useState();
    const [notFound, setNotFound] = useState(false);

    // Load the basic visit data
    const loadingId = "FrontDeskGuest.visit";
    const loadData = useCallback(()=>{
        if( visitId?.length < 30 )//It's not an UUID, may be the path placeholder or trash
            return;
        api.visits.get({ params: { id:visitId, sGroups: frontDeskVisitSGroups }, loadingId, customProp:'_' })
            .then( visits=>visits?.length > 0? setVisit(visits[0]): setNotFound(true ));
    },[api, visitId]);
    useEffect(loadData, [loadData])

    const loading = useSelector( s=>!!s.loadingIds[loadingId] );

    const history=useHistory();
    const {refreshOccupation}=useActualLoungeOccupation();
    const handleChange=useCallback(()=>{
        loadData();
        refreshOccupation();
    },[loadData, refreshOccupation]);

    const [guestInfo,,,toggleOpenGuestInfo] = useBoolean();
    const [moreGuestInfo,,,toggleMoreGuestInfo] = useBoolean();
    const [editModal,openEditModal,closeEditModal] = useBoolean();

    return(
       <div className="FrontDeskGuest">
           <FixedMenu className='frontDeskGuest'/>

           <BirthdayAnimation guest={visit?.guest} />

           <TopBar/>

           {loading && <div className='text-center'><LoadingAnimation/></div>}
           {notFound && <h2>Huésped no encontrado</h2>}}

           {visit && visit?.guest &&
                <div className="FrontDeskGuest-content">
                   <div className="FrontDeskGuest-titleBar">
                       <div className="FrontDeskGuest-title animated slideInDown">
                           <button className='back-arrow-btn' onClick={history.goBack}><LeftArrow/></button>
                           <p>Huésped {guestFullName(visit.guest).toUpperCase()}</p>
                           <p className="FrontDeskGuest-date">-</p>
                           <p className="FrontDeskGuest-date">
                               Visita del {moment(visit.createdDate).format('DD/MM/YYYY')}{' '}
                               { visit.folio }
                           </p>
                           <Link to={paths.frontDesk}>
                           <div className="cross-wrapper">
                               <div className="cross"/>
                           </div>
                           </Link>
                       </div>
                   </div>
                   <div className="FrontDeskGuest-sections">
                       {window.matchMedia("(max-width:1024px)").matches &&
                           <>
                                 <div onClick={toggleOpenGuestInfo} className={classNames("FrontDeskGuest-userInfo",guestInfo?'userInfoOpen':'')}>
                                   <UserIcon/>
                                     {!guestInfo ?
                                         <RightArrow/>

                                         : <LeftArrow/>
                                     }
                                 </div>
                                <div onClick={toggleMoreGuestInfo} className="FrontDeskGuest-moreInfo">
                                    {!moreGuestInfo ?
                                   <LeftArrow/>
                                   :
                                        <RightArrow/>
                                    }
                                   <PlusIcon/>
                                 </div>
                               <div className={classNames("FrontDeskGuest-left-tabletVersion",(guestInfo)?'tabletVersionOpen':'')}>
                                   <div className="FrontDeskGuest-left">
                                       <div onClick={openEditModal} className={"FrontDeskGuest-editIcon"}>
                                           <EditIcon/>
                                       </div>
                                       <GuestProfile
                                           guestId={visit.guest.id}
                                           guestInfoSize
                                           selectableVisits
                                           onChange={handleChange}
                                       />
                                   </div>
                               </div>
                           </>
                       }
                       {window.matchMedia("(min-width:1025px)").matches &&
                       <div className="FrontDeskGuest-left">
                           <div onClick={openEditModal} className={"FrontDeskGuest-editIcon"}>
                               <EditIcon/>
                           </div>
                           <GuestProfile
                               guestId={visit.guest.id}
                               guestInfoSize
                               selectableVisits
                               onChange={handleChange}
                           />
                       </div>
                       }
                       <div className="FrontDeskGuest-right">
                           {visitId&&
                           <VisitInfo
                               reload={loadData}
                               visitId={visitId}
                               onChange={handleChange}
                               openMoreGuestInfo={moreGuestInfo}
                           />}
                       </div>
                   </div>
               </div>}
           {editModal &&
               <EditInfoModal
                   reload={loadData}
                   closeEditModal={closeEditModal}
                   guest={visit.guest}
               />

           }
       </div>
    )
};

FrontDeskGuest.displayName = 'FrontDeskGuest';

export default FrontDeskGuest;
