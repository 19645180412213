import React, {useCallback,useState,useMemo} from 'react';
import './CheckBox.scss';
import className from 'classnames';

const CheckBox = ({value,onChange,isModifier,children,defaultValue=false})=>{

    const [checkedValue, setCheckedValue]=useState(defaultValue);

    if(typeof value === "undefined"){
        value=checkedValue
    }

    const checkedHandler = useCallback((event)=>{
            if(onChange){
                onChange(event.checked);
            }else{
                setCheckedValue(event.checked)
            }
    },[onChange]);

    const id=useMemo(()=>Math.random(),[]);

    return(
        <div className="CheckBoxContainer">
            <label htmlFor={`check-${id}`} className="Check">
                <input id={`check-${id}`}  onChange={checkedHandler} type="Checkbox" checked={value}/>
                <div className={className("Box",isModifier?"modifier":"square")}><p>{children}</p></div>
            </label>
        </div>
    )
};
export default CheckBox;
