import React, {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './_BoardingPassReader.scss';
import {BrowserMultiFormatReader, BarcodeFormat, DecodeHintType} from '@zxing/library';
import {getNotifier} from "../../../../services/notifierUtils";
import Select from "react-select";
import reactSelectStyles from "../../../../assets/styles/react-select-styles";
import { decode } from 'bcbp';
import useBoolean from "../../../../hooks/useBoolean";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBug} from "@fortawesome/free-solid-svg-icons";
import {bcbpToCheckIn, bcbpValidate} from "../../../../services/bcbpUtils";
import {ApiContext} from "../../../../services/api/api-config";
import {getPreferredCamera, savePreferredCamera} from "../../../../services/cameraUtils";

const hints = new Map();
const formats = [ BarcodeFormat.AZTEC, BarcodeFormat.PDF_417, BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];
//const formats = [ BarcodeFormat.AZTEC ];

hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

window.bcbp = decode;

const BoardingPassReader = ({ onScan }) => {

    const api = useContext(ApiContext);
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState();
    const reader = useRef(null);
    const onScanRef = useRef(onScan);

    const [foundText, setFoundText] = useState('');
    const [foundData, setFoundData] = useState('');
    const [readerResult, setReaderResult] = useState('');

    //For debugging
    const [debug,,,toggleDebug] = useBoolean();
    const [readCount, setReadCount] = useState(0);
    // End debugging code

    //Since we don't want to reload the barcode reader if the callback changes,
    // we save to a ref so the scan callback calls it from there
    useEffect(()=>{
        onScanRef.current = onScan;
    },[onScan]);

    // ------- Initialize reader and get cameras
    const readerCallback = useCallback((result)=>{
        setReadCount(c=>++c);
        if(result) {
            setReaderResult(result);
            const bcbpText = result.getText();
            const bcbpObject = decode(result.getText())
            setFoundText( bcbpText );
            setFoundData( bcbpObject );
            if( bcbpValidate(bcbpText, bcbpObject).valid ) {
                reader.current && reader.current.stopStreams();
                bcbpToCheckIn( bcbpObject, api ).then(onScanRef.current);
            }
        }
    },[api]);

    const videoElementId="boarding-pass-video";

    useLayoutEffect(()=>{
        reader.current = new BrowserMultiFormatReader(hints);
        reader.current.listVideoInputDevices()
            .then(setVideoDevices)
            .catch((e)=>{
                getNotifier().error("Hubo un error al conectarse a la cámara: "+e?.message);
            });

        reader.current.decodeFromVideoDevice( getPreferredCamera(), videoElementId, readerCallback);

        return ()=> reader.current?.stopStreams();
    },[readerCallback]);

    // ------- Handle reader initialization and barcode read callback
    const handleCameraSelect = useCallback((device)=>{
        setSelectedCamera(device);
        reader.current.stopStreams();
        savePreferredCamera(device.deviceId);
        reader.current.decodeFromVideoDevice(device.deviceId,videoElementId, readerCallback);
    },[readerCallback]);

    const validationResult = bcbpValidate(foundText, foundData);

    return (
        <div className={"BoardingPassReader"}>
            <Select
                styles={reactSelectStyles}
                options={videoDevices}
                placeholder={videoDevices.length?"Selecciona una cámara":"No hay cámaras disponibles"}
                isSearchable={false}
                value={selectedCamera}
                onChange={handleCameraSelect}
            />

            <div className="video-container">
                <video id={videoElementId} width="758" />
                {debug &&
                <div className="debug-window">
                    Lecturas: {readCount}<br/>
                    Código: {foundText}<br/>
                    Válido: {validationResult.valid?"Sí":"No"}<br/>
                    Info:<br/>
                    <pre>{JSON.stringify(foundData, null, 2)}</pre>
                    Errores:<br/>
                    <pre>{JSON.stringify(validationResult.errors, null, 2)}</pre>
                    {readerResult?.resultPoints?.length &&
                    readerResult.resultPoints.map( (point, i)=>
                        point && <div key={i} className="data-point" style={{top: point.y, left:point.x}}  />
                    )}
                </div>}
                <button onClick={toggleDebug} className="debug-btn"><FontAwesomeIcon icon={faBug}  /></button>
            </div>
        </div>
    );
};

export default BoardingPassReader;
