import moment from 'moment';
import React, {useState, useCallback, useContext, useEffect} from 'react';
import './NewReservation.scss';
import {ReactComponent as EditIcon} from "../../../../assets/images/icons/edit.svg";
import useConstantRandoms from "../../../../hooks/useConstantRandoms";
import useCallbackCreator from 'use-callback-creator';
import Button from "../../../../components/Button/Button";
import {
    emptyVisit, lastEntriesSGroups,
    prepareVisitForServer,
    visitFormFromGuest,
    visitFormFromReservation, visitHasPayedAccessMethod
} from "../../../../services/modelUtils/visitUtils";
import {
    createReservationFromVisitForm,
    nearReservationsApiConfig, REMOTE_RESERVATION_SOURCE
} from "../../../../services/modelUtils/reservationUtils";
import _ from 'lodash';
import CheckInsForm from "../CheckInsForm/CheckInsForm";
import {getNotifier} from "../../../../services/notifierUtils";
import {ApiContext} from "../../../../services/api/api-config";
import {
    LoungeContext,
    loungeSelectorFilters,
    useActualLoungeOccupation
} from "../../../../services/modelUtils/loungeUtils";
import classNames from 'classnames';
import {useSelector} from "react-redux";
import {guestFullName, isGuestInLounge} from "../../../../services/modelUtils/guestUtils";
import CheckInsPaymentModal from "../../../../components/PaymentModals/CheckInsPaymentModal";
import {isPayedCheckIn} from "../../../../services/modelUtils/checkInUtils";
import NewReservationCommentBox from "../NewReservationCommentBox/NewReservationCommentBox";
import useBoolean from "../../../../hooks/useBoolean";
import {onlyNumbers, onlyText, validEmail} from "../../../../services/inputUtils";
import GuestForm, { guestFormFlightsProp } from "../../../FrontDeskGuest/Components/GuestForm/GuestForm";
import {
    getPrinterIdByKey,
    localStorageFrontDeskPrinterKey
} from "../../../../services/modelUtils/orozcoTicketPrinterUtils";
import {isRemoteDavinci} from "../../../../services/remoteDavinciUtils";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import LoungeMapModal from "../../../../components/LoungeMapModal/LoungeMapModal";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocumentReaderModal from "../../../../components/DocumentReaderModal/DocumentReaderModal";
import {useTimer} from '../../../../hooks/useTimer';

const checkInLoadingId = '@NewReservation.visit.create';

const NewReservation = ({
                            onSearchChange,
                            searching,
                            onClearState,
                            guest,
                            reservation,
                            allowOverflow,
                            fullWidth,
                            loungeField,
                            seatSelector,
                            onFinishCapture,
                            startCheckin
                        }) => {

    const [form, setForm] = useState(emptyVisit());
    const [animated, setAnimated] = useState('');
    const notifier = getNotifier();
    const {milliseconds, reset, stop, start} = useTimer();

    useEffect(() => {
        if(startCheckin) start();
    }, [start, startCheckin]);

    const handleInputChange = useCallback(({target}) => {
        let value;
        switch(target.name)
        {
            case 'patLastName':
            case 'name':
                value = onlyText(target.value).toUpperCase()
                break;

            case 'phone':
                value = onlyNumbers(target.value)
                break;

            case 'text':
                value = target.value.toUpperCase()
                break;

            default:
                value = target.value
                break;

        }
        setForm({...form, [target.name]: value })

        if (target.dataset && target.dataset.searchable)
            onSearchChange({...searching, [target.name]: target.value});


    }, [form, searching, onSearchChange]);



    const showWarningMessage = useCallback(() => {

        notifier.warning('¡Haz contacto visual y sonríe!');

    }, [notifier]);


    const handleFormClear = useCallback(() => {
        reset();
        onClearState();
        setForm(emptyVisit());
        onFinishCapture();
    }, [onClearState, reset, onFinishCapture]);



    const handleSimpleChange = useCallbackCreator((prop, value) => {
        setForm(form=>({...form, [prop]: value}));
    }, []);

    //This is a workaround to prevent autocomplete, cause most browsers won't let you turn it off
    const constantRandom = useConstantRandoms();

    const api = useContext(ApiContext);

    const actualLounge = useContext(LoungeContext);

    const {refreshOccupation} = useActualLoungeOccupation();

    // --------   Handle payments  -------
    const [chargingCheckIns, setChargingCheckIns] = useState(null);
    const stopChargingCheckIns = useCallback(() => setChargingCheckIns(null), []);

    // --------   Send visit to the server  -------

    const sendVisitToServer = useCallback((visitForm) => {
        try {
            const seconds = parseInt(milliseconds/1000);
            const visit = prepareVisitForServer(visitForm, seconds);

            visit.lounge = actualLounge.id;
            
            api.visits.create({
                params: {
                    ...visit,
                    sGroups: lastEntriesSGroups
                }, loadingId: checkInLoadingId, customProp: 'lastEntriesVisits'
            })
                .then((visit) => {
                    handleFormClear();
                    refreshOccupation();
                    if (visit.checkIns[0].loungeAccessMethod === "7") {
                        notifier.warning('¡Konbanwa, Irasshaimase!');
                    } else {
                        notifier.warning('¡Da la bienvenida al huésped reforzando su apellido!');
                    }
                    notifier.success('Acceso guardado');
                    setAnimated('animated bounceOutUp');
                    setTimeout(() => setAnimated(''), 1000);

                    visit.checkIns.forEach((checkIn) => {
                        if (isPayedCheckIn(checkIn)) {

                            const printerId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
                            if(!printerId){
                                notifier.error('La impresora de recepción no se encuentra configurada');
                                return;
                            }

                            notifier.success('Imprimiendo ticket...');
                            api.orozcoPrints.create({
                                params: {
                                    ticketPrinter: printerId,
                                    checkIn: checkIn.id
                                }
                            }).then(() => notifier.success('Ticket impreso'))
                        }
                    });
                    //imprimir
                    if(reservation){
                        api.reservations.get(nearReservationsApiConfig());
                    }
                });
        } catch (e) {
            notifier.error(e.message);
        }

    }, [actualLounge, api, reservation, handleFormClear, refreshOccupation, notifier, milliseconds]);

    // --- Create reservation instead of visit when using remote davinci ------
    const sendReservationToServer = useCallback((visitForm) => {
        try {
            const reservation = createReservationFromVisitForm(visitForm);
            api.reservations.create({params: reservation, loadingId: checkInLoadingId})
                .then(()=>{
                    getNotifier().success("Reservación enviada");
                    handleFormClear();
                });
        }
        catch (e){
            getNotifier().error(e.message);
        }
    },[api, handleFormClear]);


    const handleCheckIn = useCallback(() => {
        const seconds = parseInt(milliseconds/1000);

        if (guest && guest.nonGrataSince) {
            return getNotifier().error('Imposible realizar checkIn a huéspedes non Gratos');
        }

        const activeLounge = isGuestInLounge(guest);
        if (activeLounge)
            return getNotifier().error('Este huésped ya está dentro de la sala ' + activeLounge.name);

        if(isRemoteDavinci()){
            sendReservationToServer(form);
            return;
        }

        if (visitHasPayedAccessMethod(form)){
            //Filter only payed check ins for payment
            try {
                prepareVisitForServer(form, seconds);
            } catch (e) {
                return notifier.error(e.message);
            }
            return setChargingCheckIns({
                guest: form,
                checkIns: _.filter(form.checkIns, isPayedCheckIn)
            });
        }

        stop();
        sendVisitToServer({reservation, ...form});

    }, [guest, form, sendVisitToServer, reservation, sendReservationToServer, notifier, stop, milliseconds]);

    const handleSuccessfulPayment = useCallback((checkIns) => {
        setChargingCheckIns(null);
        const allCheckIns = form.checkIns.map(
            formCheckIn => {
                const payed = _.find(checkIns, (payedCheckIn) => payedCheckIn.localId === formCheckIn.localId);
                if (payed)
                    return payed;
                return formCheckIn;
            });
        sendVisitToServer({...form, checkIns: allCheckIns});
    }, [form, sendVisitToServer]);

    // --------   Pre fill info from guest  -------
    useEffect(() => {
        if(!!guest && !reservation){
            setForm( form=>_.merge(form,  visitFormFromGuest(guest)) );
        }
    }, [guest, reservation]);

    const loading = useSelector(({loadingIds}) => !!loadingIds[checkInLoadingId]);

    // --------   Pre fill info from reservation  -------
    const accessMethods = useSelector(({api}) => api.SingleCheckInLoungeAccessMethods);
    const fullFlights = useSelector(({api}) => api.FullFlights);

    useEffect(() => {
        setForm(visitFormFromReservation(reservation, accessMethods));
    }, [reservation, accessMethods, fullFlights]);

    const [commentBox, , , toggleCommentBox] = useBoolean();
    const [checkInBtn, setCheckInBtnBlock, setCheckInBtn] = useBoolean();

    const verifyEmail = useCallback((value) => {
        if (validEmail(value) || value.currentTarget.value === "") {
            setCheckInBtn()
        } else {
            setCheckInBtnBlock()
        }
    }, [setCheckInBtn, setCheckInBtnBlock]);

    const showReservationNotes = (() => {
        if(!reservation){
            return false;
        }
        if(reservation.notes){
            return true;
        }
        return false;
    })();

    // --- open seat selector ----
    const [showMap,,closeMap,toggleShowMap] = useBoolean();
    const onSeatSelection = useCallback((seats)=>{
        setForm(form=>({...form, seats}));
        closeMap();
    },[closeMap]);

    // ---- Loading Flights from children components ----
    const guestFormLoadingFlights = useSelector(({loadingIds}) => !!loadingIds[guestFormFlightsProp]);

    // ----- Document reader handling --------
    const [isDocumentReaderOpen, openDocumentReader, closeDocumentReader] = useBoolean();
    const onScan = useCallback((scannedData)=>{
        setForm((form) => {
            if(form?.checkIns?.length)
                form.checkIns[0].isScanned = true;
            
            return _.merge({}, form, scannedData);
        });

        const {name, patLastName} = scannedData;
        onSearchChange({...searching, name, patLastName});

    },[onSearchChange, searching]);

    return (
        <div className={classNames("NewReservation", animated, fullWidth && 'full-width')}>
            <div className="NewReservation-title full-width">
                <p>Crear Nuevo Registro{guest ? `- ${guestFullName(guest).toUpperCase()}` : ''}</p>
                <div className="timer">{moment.utc(milliseconds).format('mm:ss')}</div>
                <EditIcon />
            </div>

            {!!form?.checkIns?.length && form.checkIns[0].loungeAccessMethod &&
            <div className="NewReservation-byDoc form-column full-width">
                <Button  solid onClick={openDocumentReader}>
                    Escanear documento <FontAwesomeIcon className='NewReservation-byDoc-icon' icon={faCamera}/>
                </Button>
            </div>}

            <div className={classNames("NewReservation-form full-width", allowOverflow && 'no-height')}>
                {reservation?.source === REMOTE_RESERVATION_SOURCE &&
                <p className='remote-disclaimer'>Reservación de Da Vinci ON-THE-GO</p>}

                {!!reservation?.createdBy?.employee?.fullName &&
                <><p>Reservación enviada por: {reservation?.createdBy?.employee?.fullName}</p><br/></>}

                {!!reservation?.seats?.length &&
                <><p>Asientos reservados: {_.map(reservation.seats,'name').join(', ')}</p><br/></>}

                {showReservationNotes && (
                    <div className="NewReservation-reservation-notes">
                        <p>Notas del huésped: <span className="reservation-notes">"{reservation.notes}"</span></p>
                    </div>
                )}

                {loungeField &&
                <div className='lounge-field'>
                    <p>Sala:</p>
                    <TideEntitySelect
                        entity={"lounges"}
                        value={form.lounge}
                        onChange={handleSimpleChange("lounge")}
                        additionalFilters={loungeSelectorFilters}
                        filterLocal
                    />
                </div>}
                <GuestForm
                    handleInputChange={handleInputChange}
                    constantRandom={constantRandom}
                    handleSimpleChange={handleSimpleChange}
                    verifyEmail={verifyEmail}
                    checkInBtn={checkInBtn}
                    makeMexicoFirst={makeMexicoFirst}
                    showWarningMessage={showWarningMessage}
                    form={form}
                />
                <CheckInsForm
                    checkIns={form.checkIns}
                    onChange={handleSimpleChange('checkIns')}
                />
                {seatSelector &&
                <div className='form-row centerItemInARow'>
                    <div className='form-column half-width'>
                        <Button onClick={toggleShowMap}>Asignar asientos a reservación</Button>
                    </div>
                </div>}
                <NewReservationCommentBox
                    value={form.text || ''}
                    name='text'
                    isCommentBoxOpen={commentBox}
                    onOpenCommentBox={toggleCommentBox}
                    onChange={handleInputChange}
                />

                <hr/>
                <br/>
                <div className="form-row">
                    <div className="form-column half-width">
                        <Button className='clear-btn' onClick={handleFormClear} disabled={loading}>Borrar todo</Button>
                    </div>
                    <div className="form-column half-width">
                        <Button solid onClick={handleCheckIn} disabled={loading || checkInBtn || guestFormLoadingFlights}>{"Check in"}</Button>
                    </div>
                </div>
            </div>

            {chargingCheckIns &&
            <CheckInsPaymentModal
                checkIns={chargingCheckIns.checkIns}
                onClose={stopChargingCheckIns}
                guest={chargingCheckIns.guest}
                onPay={handleSuccessfulPayment}
            />}

            {showMap && form?.lounge?.id &&
            <LoungeMapModal
                loungeId={form.lounge.id}
                onClose={toggleShowMap}
                onSelect={onSeatSelection}
            />}

            {isDocumentReaderOpen && <DocumentReaderModal onScan={onScan} onClose={closeDocumentReader} />}

        </div>
    )

};
export default NewReservation;

const makeMexicoFirst = (countries = []) =>
    _.sortBy(countries, country => country.iso2Code === 'MX' ? 0 : 1);
