import {convertCurrencies, getCurrenciesRatio} from "../currency/currencyUtils";
import _ from 'lodash/fp';

export const emptyPayment=(cashRegister, paymentMethod)=>({
    currency:paymentMethod==='card'?'MXN':'',
    amount:'',
    mainCurrencyAmount:0,
    exchangeRate:'',
    paymentMethod,
    orozcoCashRegister: cashRegister.id,
    orozcoPaymentMethod: getPaymentMethodIdOrDie(paymentMethod),
});

export const hasUnroundedCashPayments = _.flow(
    _.filter({paymentMethod:'cash'}),
    _.filter(({amount})=>{
        if(!amount){
            return false;
        }
        return (parseInt(amount*100))%100!==0;
    }),
    _.size, _.negate(_.eq(0))
);

const getRoundedPayment = p=>({
    ...p,
    amount: Math.ceil(parseFloat(p.amount))
});

export const roundCashPayments = _.map(p=> p.paymentMethod!=='cash'?p:getRoundedPayment(p));

export const paymentMethodsIds={
    'cash':'OrozcoPaymentMethod-cash',
    'card':'OrozcoPaymentMethod-card',
};

const getPaymentMethodIdOrDie=(paymentMethod)=>{
    if(!paymentMethodsIds[paymentMethod])
        throw new Error(`No id found for payment method ${paymentMethod}`);
    return paymentMethodsIds[paymentMethod];
};

export const setMainCurrencyAmount=(payment, mainCurrency, exchangeRates, regeneratePayment=false)=>{

    if(regeneratePayment)
        payment={...payment};

    payment.mainCurrencyAmount=payment.currency && payment.amount?
        convertCurrencies(payment.currency, mainCurrency, payment.amount, exchangeRates)*100
        :0;
    payment.exchangeRate= payment.currency?getCurrenciesRatio(payment.currency, mainCurrency, exchangeRates):0;
    return payment;
};

export const preparePaymentForServer=(payment)=>{
    if(!payment)
        throw new Error('Error no se encontró el pago. Contacta a soporte técnico');

    const prepared={...payment};

    if(!payment.orozcoCashRegister)
        throw new Error('Pago sin caja registradora enviado.');

    if(!payment.orozcoPaymentMethod)
        throw new Error('Pago sin tipo enviado.');

    if(typeof payment.orozcoCashRegister === 'object')
        prepared.orozcoCashRegister=payment.orozcoCashRegister.id;

    if(typeof payment.orozcoPaymentMethod === 'object')
        prepared.orozcoPaymentMethod=payment.orozcoPaymentMethod.id;

    delete prepared.mainCurrencyAmount;
    delete prepared.paymentMethod;

    return prepared;
};
