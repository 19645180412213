import React,{useState,useCallback,useContext} from 'react';
import Modal from "../../../../../../components/Modal/Modal";
import {guestFullName} from "../../../../../../services/modelUtils/guestUtils";
import SingleCheckInForm from "../../../../../FrontDesk/Components/CheckInsForm/SingleCheckInForm";
import {
    emptyCheckIn,
    isPayedCheckIn,
    prepareCheckInForServer
} from "../../../../../../services/modelUtils/checkInUtils";
import './CheckInFormModal.scss';
import Button from "../../../../../../components/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusSquare, faRedoAlt, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import CheckInsPaymentModal from "../../../../../../components/PaymentModals/CheckInsPaymentModal";
import classNames from 'classnames';
import {getNotifier} from "../../../../../../services/notifierUtils";
import _ from "lodash";
import {useSelector} from "react-redux";
import LoadingAnimation from "../../../../../../components/LoadingAnimation/LoadingAnimation";
import {ApiContext} from "../../../../../../services/api/api-config";
import moment from "moment";
import {
    getPrinterIdByKey,
    localStorageFrontDeskPrinterKey
} from "../../../../../../services/modelUtils/orozcoTicketPrinterUtils";


const loadingId='@CheckInFormModal';
const payedCheckIns = cs => _.filter(cs, isPayedCheckIn);
const CheckInFormModal=({onClose, guest, visit, oldCheckIn, onFinish})=>{
    const [checkIn, setCheckIn]=useState(emptyCheckIn());
    const [chargingCheckIn, setChargingCheckIn]=useState(null);
    const stopCharging=useCallback(()=>setChargingCheckIn(null),[]);
    const api=useContext(ApiContext);


    const sendCheckIn=useCallback((checkIn)=>{
        const preparedVisit={};

        try {
            const newCheckIn = prepareCheckInForServer(checkIn);
            if(oldCheckIn)
                newCheckIn.reCheckInFrom=oldCheckIn.id;


            preparedVisit.id=visit.id;
            preparedVisit.checkIns = _.map(visit.checkIns,'id');
            const closeIndexReCheckIn = _.indexOf(preparedVisit.checkIns,newCheckIn.reCheckInFrom);
            preparedVisit.checkIns[closeIndexReCheckIn] = {id:newCheckIn.reCheckInFrom,isActive:false,checkOutDate:moment().format('YYYY-MM-DDTHH:mm:ss')};
            preparedVisit.checkIns.push(newCheckIn);

            api.visits.update({id: preparedVisit.id, params:preparedVisit, loadingId})
                .then(v=>{
                    onFinish(v);
                    getNotifier().success('Check in correcto.');
                    const printerId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
                    if(!printerId){
                        getNotifier().error('La impresora de recepción no se encuentra configurada');
                    }
                    const previousCheckIns = _.map(payedCheckIns(visit.checkIns), 'id');
                    const newCheckIns = _.map(payedCheckIns(v.checkIns), 'id');

                    const toPrint = _.difference(newCheckIns, previousCheckIns);
                    if(toPrint.length>0){
                        getNotifier().success('Imprimiendo ticket...');
                    }
                    for(const checkIn of toPrint){
                        api.orozcoPrints.create({
                            params: {
                                ticketPrinter: printerId,
                                checkIn
                            }
                        }).then(()=>getNotifier().success('Ticket impreso'));
                    }

                });
        }
        catch (e) {
            getNotifier().error(e.message);
        }
    },[api, onFinish, oldCheckIn, visit]);

    const handleCheckInClick=useCallback(()=>{
        if(isPayedCheckIn(checkIn))
            return setChargingCheckIn([checkIn]);
        sendCheckIn(checkIn);
    },[checkIn, sendCheckIn]);

    const handleFinishPayment=useCallback(([checkIn])=>{
        setChargingCheckIn(null);
        sendCheckIn(checkIn);
    },[sendCheckIn]);


    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    return (
        <Modal
            onClose={onClose}
            title={'Check in'}
            className={classNames('CheckInFormModal', chargingCheckIn?'charging':'')}
        >
            <div className={'content'}>

                <div className='icons-container'>
                    <FontAwesomeIcon icon={faUserCheck} className='icon' />
                    {oldCheckIn ?
                        <FontAwesomeIcon icon={faRedoAlt} className='icon'/> :
                        <FontAwesomeIcon icon={faPlusSquare} className='icon'/>
                    }
                </div>

                <h2 className='main-desc'>{oldCheckIn?'Re':'Nuevo'} check in de {guestFullName(guest)}</h2>

                <div className='form-container'>
                    <SingleCheckInForm
                        isReCheckIn={oldCheckIn}
                        checkIn={checkIn}
                        onChange={setCheckIn}
                    />
                </div>
            </div>
            <Button onClick={onClose} disabled={loading}>Cancelar</Button>
            <Button blue onClick={handleCheckInClick} disabled={loading}>{loading?<LoadingAnimation/>:'Guardar'}</Button>

            {chargingCheckIn &&
            <CheckInsPaymentModal
                onClose={stopCharging}
                checkIns={chargingCheckIn}
                guest={guest}
                onPay={handleFinishPayment}
            />}

        </Modal>
    );
};

export default CheckInFormModal;
