import React from "react";
import FrontDesk from "../../scenes/FrontDesk/FrontDesk";
import FrontDeskGuest from "../../scenes/FrontDeskGuest/FrontDeskGuest";
import AttendingVisits from "../../scenes/Waiter/AttendingVisits/AttendingVisits";
import WaiterMenu from "../../scenes/Waiter/WaiterMenu/WaiterMenu";
import Flights from "../../scenes/Flights/Flights";
import Configuration from "../../scenes/Configuration/Configuration";
import {ReactComponent as BillIcon} from "../../assets/images/icons/billIcon.svg";
import {ReactComponent as Plane} from "../../assets/images/icons/plane.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister, faCogs, faDesktop, faBell} from "@fortawesome/free-solid-svg-icons";
import CashRegister from "../../scenes/CashRegister/CashRegister";
import FoodBarMenu from "../../scenes/Waiter/FoodBarMenu/FoodBarMenu";
import {isRemoteDavinci} from "../remoteDavinciUtils";
import RemoteFrontDesk from "../../scenes/FrontDesk/RemoteFrontDesk/RemoteFrontDesk";
import Alarms from "../../scenes/Alarms/Alarms";
import ServerSelection from "../../scenes/ServerSelection/ServerSelection";


export const paths={
    frontDesk:'/front-desk',
    frontDeskGuest:'/front-desk/visit/:visitId',
    waiter:'/waiter',
    visitMenu:'/waiter/visit/:visitId',
    foodBarMenu:'/waiter/food-bar/:foodBarId',
    flights:'/flights',
    cashRegister:'/cash-register',
    configuration:'/configuration',
    alarms: '/alarms',
    serverSelection: '/servidor',
};

const getAppRoutes =(securityManager)=>{

    if(isRemoteDavinci()) {
        return [
            {path:paths.frontDesk, component: RemoteFrontDesk, name:'Recepción', icon:<FontAwesomeIcon icon={faDesktop} />},
            {path:paths.frontDesk+'o', component: FrontDesk, name:'Recepción', icon:<FontAwesomeIcon icon={faDesktop} />, hidden: true},
            {path:paths.flights, component: Flights, name:'Vuelos', icon:<Plane/>}
        ];
    }

    let routes = [];

    if(securityManager.canSeeFrontDesk())
        routes.push({path:paths.frontDesk, component: FrontDesk, name:'Recepción', icon:<FontAwesomeIcon icon={faDesktop} />});

    if(securityManager.canSeeOrozco())
        routes= [...routes,
            {path:paths.waiter, component: AttendingVisits, name:'Comandas', icon:<BillIcon/>},
            {path:paths.visitMenu, component: WaiterMenu, hidden: true},
            {path:paths.foodBarMenu, component: FoodBarMenu, hidden: true},
            {path:paths.alarms, component: Alarms, icon: <FontAwesomeIcon icon={faBell} />},
            ];

    routes = [...routes,
        {path:paths.flights, component: Flights, name:'Vuelos', icon:<Plane/>},
        {path:paths.frontDeskGuest, component: FrontDeskGuest, hidden:true},
        ];

    if(securityManager.canCloseCashRegister())
        routes.push({path:paths.cashRegister, component: CashRegister, name:'Caja', icon: <FontAwesomeIcon icon={faCashRegister}/>});

    if(securityManager.canAdminCashRegisters() || securityManager.canAdminPrinters() || securityManager.canAdminSessionTimeout())
        routes.push({path:paths.configuration, component: Configuration, name:'Configuración', icon: <FontAwesomeIcon icon={faCogs}/>});

    routes.push({path:paths.serverSelection, component: ServerSelection, name:'Servidor', hidden: true});

    return routes;
};

export default getAppRoutes;
