import React from 'react';
import './LoadingAnimation.scss';
import classNames from 'classnames';


const LoadingAnimation = ({white})=>{

    const className=classNames(
        "LoadingAnimation spinner",
        white?'white':'')
    ;

    return(
    <div className={className}>
        <div className="bounce1"/>
        <div className="bounce2"/>
        <div className="bounce3"/>
    </div>
    )
};
export default LoadingAnimation;
