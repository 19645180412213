import Button from "../../../../../components/Button/Button";
import React,{useState,useCallback, useEffect, useContext} from "react";
import './OrderResume.scss';
import '../../../../../components/ConfirmationModal/ConfirmationModal.scss';
import className from 'classnames';
import CheckBox from "../../../../../components/CheckBox/CheckBox";
import useBoolean from "../../../../../hooks/useBoolean";
import moment from "moment";
import useCallbackCreator from "use-callback-creator/src";
import _ from 'lodash';
import Modal from "../../../../../components/Modal/Modal";
import {getVisitAvailableFreeFreeMeals} from "../../../../../services/modelUtils/visitUtils";
import {getPricedMealsThatCanBeFreeCountFromVisitOrder} from "../../../../../services/modelUtils/orozcoVisitOrderUtils";
import {OROZCO_PRODUCT_SPECIAL_ID} from "../../../../../services/modelUtils/orozcoProductUtils";
import OrderProductPaymentIcon from "../../../../../components/OrderProductPaymentIcon/OrderProductPaymentIcon";
import {ReactComponent as KeyBoardIcon} from "../../../../../assets/images/icons/keyboard.svg";
import KeyBoardFull from "../../../../../components/KeyBoardFull/KeyBoardFull";
import KeyBoardTouch from "../../../../../components/KeyBoardTouch/KeyBoardTouch";
import {ReactComponent as CrossIcon} from "../../../../../assets/images/icons/crossIcon.svg";
import {ReactComponent as CommentIcon} from "../../../../../assets/images/icons/comments.svg";
import KidImage from '../../../../../assets/images/kid.png';
import classNames from 'classnames';
import {SecurityContext} from '../../../../../services/SecurityManager';
import 'font-awesome/css/font-awesome.min.css';
import {getNotifier} from "../../../../../services/notifierUtils";


export default function OrderResume({
                         selectedOrderProducts,
                         serverOrderProducts,
                         onSend,
                         loading,
                         onSelectedProductRemove,
                         onOrderProductsCancel,
                         /*onCheckOut,*/
                         onSelectSpecial,
                         visit,
                         onRePrint,
                         onModifierSelected,
                         commentText,
                         onChangeCommentText,
                         onToggleAskedByKid,
                         onToggleAskedByMainGuest,
                         onAddCommentToDish,
}){

    const notifier = getNotifier();
    const security = useContext(SecurityContext);
    const [openConfirmationModal,,closeConfirmationModal,confirmationModalHandler]=useBoolean();
    const [isKeyBoardOpen,,closeKeyBoard,toggleKeyBoard] = useBoolean();

    const handleSelectedRemove=useCallback((i)=>{
        setDishIndexModifier(null);
        onSelectedProductRemove(i);
    },[onSelectedProductRemove]);

    const sendOrder = useCallback(()=>{
        onSend();
    }, [onSend]);

    // State for the comment when you cancel a product
    const [cancelProductsComment, setCancelProductsComment] = useState('');

    // ---------- Selection handlers ----------
    const [serverSelected, setServerSelected]=useState({});
    const handleCheckChange=useCallbackCreator((index)=>{
        setServerSelected({...serverSelected, [index]:!serverSelected[index]});
    },[serverSelected]);

    useEffect(()=>{
        setServerSelected({});
    },[serverOrderProducts]);

    const anySelected=_.reduce(serverSelected, (acc, sel)=>sel||acc, false);

    // ---------- Canceling --
    const handleProductsCancel=useCallback(()=>{
        if (!cancelProductsComment) {
            notifier.error('Tienes que agregar un comentario para cancelar.');
            return;
        }
        closeConfirmationModal();
        const orderProducts=[];
        _.forEach(serverSelected, (isSelected, id)=>{
            if(isSelected)
                orderProducts.push(_.find(serverOrderProducts, prod=>prod.id===id));
        });
        if(orderProducts.length)
            onOrderProductsCancel(orderProducts, cancelProductsComment);
    },[closeConfirmationModal, serverSelected, serverOrderProducts, onOrderProductsCancel, cancelProductsComment, notifier]);

    const availableMeals=getVisitAvailableFreeFreeMeals(visit);

    const usedMeals=
        getPricedMealsThatCanBeFreeCountFromVisitOrder(visit && visit.orozcoVisitOrder)+
        getPricedMealsThatCanBeFreeCountFromVisitOrder({orozcoVisitOrderProducts:selectedOrderProducts});




    const [dishIndexModifier,setDishIndexModifier] = useState(null);

    const handleToggleProductModifier = useCallback((e,orderProduct)=>{
        const sameModifierIndex = parseInt(e.currentTarget.dataset.index);
        if(orderProduct.orozcoProduct.id === OROZCO_PRODUCT_SPECIAL_ID)
            onSelectSpecial(orderProduct,e.currentTarget.dataset.index);

        if(sameModifierIndex === dishIndexModifier)
            setDishIndexModifier(null);
        else
            setDishIndexModifier(sameModifierIndex);

    },[dishIndexModifier,onSelectSpecial]);

    const handleRePrintProducts=()=>{
        const selectedIdsArray = _.keys(_.pickBy(serverSelected, (p)=>p===true));
        const selectedOrozcoProducts = _.filter(serverOrderProducts, (prod)=>selectedIdsArray.indexOf(prod.id)!==-1?prod:null);
        onRePrint(selectedOrozcoProducts);
    };
    const modifierSelected = (productIndex, modifier)=>
          _.includes(selectedOrderProducts[productIndex].orozcoProductModifiers || [],
                     modifier);

    const handleCommentText = useCallback((e)=>{
        onChangeCommentText(e.currentTarget.value);
    },[onChangeCommentText]);

    const handleCancelProductsComment = useCallback((e) => {
        setCancelProductsComment(e.currentTarget.value);
    }, [setCancelProductsComment]);

    const getValueFromInput = useCallback((valueForInput)=>{
        onChangeCommentText(valueForInput);
    },[onChangeCommentText]);

    const [isCommentsOpen,openComments,closeComments] = useBoolean();
    const [textBox,openTextBox,closeTextBox] = useBoolean();
    const [commentDishSelected,setCommentDishSelected] = useState(null);

    const [commentsHistory,setCommentsHistory] = useState([]);
    const handleCommentsHistory = useCallback(()=>{
        openComments();
        if(!!visit && !!visit.orozcoVisitOrder && !!visit.orozcoVisitOrder.orozcoVisitOrderProducts){
            setCommentsHistory(_.uniqBy(_.filter(visit.orozcoVisitOrder.orozcoVisitOrderProducts,n=>n.notes!== null),'notes'));
        }
    },[openComments,visit]);

    const handlerOpenTextBox = useCallbackCreator((i)=>{
        openTextBox();
        setCommentDishSelected(i);
        if(selectedOrderProducts[i].notes){
            onChangeCommentText(selectedOrderProducts[i].notes)
        }else{
            onChangeCommentText('')
        }
    },[selectedOrderProducts]);

    return(
        <div className="OrderResume">
            <button onClick={handleCommentsHistory} className='openComments'>Ver comentarios</button>
            <div className="OrderResume-title">
                <p>Tu pedido</p>

                {!!availableMeals&&
                <p>{usedMeals}/{availableMeals}</p>}
            </div>
            <div className="OrderResume-listWrapper">
                <ul className="OrderResume-list">
                    {!selectedOrderProducts.length && !serverOrderProducts.length &&
                        <p className="OrderResume-emptyList">No hay órdenes</p>}

                    {selectedOrderProducts.map((orderProduct,i)=>{
                        const name = orderProduct.orozcoProduct.id===OROZCO_PRODUCT_SPECIAL_ID?orderProduct.data.description:orderProduct.orozcoProduct.name;
                        return(
                            <li key={i} className={className("product-row animated slideInRight faster", false ? 'opacity' : '')}>
                                <div  className="OrderResume-orderInfo">
                                    <div className="OrderResume-checkBox">
                                        <button className='deleteDish'
                                                onClick={()=>handleSelectedRemove(i)}>
                                            <CrossIcon/>
                                        </button>
                                    </div>
                                    <div data-index={i} onClick={(e)=>{handleToggleProductModifier(e,orderProduct)}} className="product-desc">
                                        <p title={name}>
                                            <span className='quantity'>{orderProduct.quantity}</span>{' '}
                                            {name}
                                        </p>
                                    </div>

                                    <button onClick={handlerOpenTextBox(i)} className={classNames('comment-btn', selectedOrderProducts[i].notes?'dishWithNotes':'')}>
                                        <CommentIcon/>
                                    </button>

                                        <button onClick={() => onToggleAskedByKid(i)}
                                             className={classNames('kidImgWrapper', selectedOrderProducts[i].askedByKid ? 'kidImgSelected' : '')}>
                                            <img src={KidImage} className='kidImg' alt='kid'/>
                                        </button>

                                        <div onClick={() => onToggleAskedByMainGuest(i)}>
                                            <i className={classNames('fa', selectedOrderProducts[i].askedByMainGuest ? 'fa-star' : 'fa-star-o')}
                                               style={{fontSize: "1.5rem"}}/>
                                        </div>
                                </div>

                                {(dishIndexModifier === i && orderProduct.orozcoProduct.orozcoProductModifiers &&
                                    orderProduct.orozcoProduct.orozcoProductModifiers.length > 0) &&
                                <div className="OrderResume-modifier animated fadeInLeft delay-.5s faster">
                                    {orderProduct.orozcoProduct.orozcoProductModifiers.map( (modifier,j)=>
                                        <div key={j} className="OrderResume-modifier-item">
                                              <CheckBox
                                                  onChange={onModifierSelected(modifier.id, i)}
                                                  value={modifierSelected(i, modifier.id)}
                                                  isModifier
                                              >{modifier.name}</CheckBox>
                                        </div>
                                    )}
                                </div>
                                }
                            </li>
                            )
                        })
                    }
                    {serverOrderProducts.map((orderProduct)=>{
                        const name=orderProduct.orozcoProduct.id===OROZCO_PRODUCT_SPECIAL_ID && orderProduct.data?orderProduct.data.description:orderProduct.orozcoProduct.name;
                        return(
                            <li key={orderProduct.id} className={className("product-row animated slideInRight faster", selectedOrderProducts.length ? 'opacity' : '', orderProduct.canceledDate?'canceled':'')}>
                                <div  className="OrderResume-orderInfo">
                                    <div className="OrderResume-checkBox">
                                        {!orderProduct.canceledDate&&
                                        <CheckBox
                                            onChange={handleCheckChange(orderProduct.id)}
                                            value={serverSelected[orderProduct.id]}
                                        />}
                                    </div>
                                    <div className="product-desc">
                                        <p title={name}>
                                            <span className='quantity'>{orderProduct.quantity}</span>{' '}
                                            {name}
                                        </p>
                                    </div>
                                    {orderProduct.askedByKid &&
                                    <div className='kidImgWrapper'>
                                        <img src={KidImage} className='kidImg' alt='kid'/>
                                    </div>
                                    }
                                    {orderProduct.askedByMainGuest &&
                                    <div style={{marginRight: ".5rem"}}>
                                        <i className="fa fa-star"
                                           style={{fontSize: "1.5rem"}}/>
                                    </div>
                                    }
                                    <span className={"product-desc-info"}>
                                        <OrderProductPaymentIcon orderProduct={orderProduct}/>
                                        {moment(orderProduct.createdDate).format('HH:mm')}
                                        {orderProduct.canceledDate&&(' - '+moment(orderProduct.canceledDate).format('HH:mm'))}
                                    </span>
                                </div>
                                {orderProduct &&
                                orderProduct.orozcoProductModifiers &&
                                !!orderProduct.orozcoProductModifiers.length &&
                                <div className={className("OrderResume-modifier animated fadeInLeft delay-.5s faster",orderProduct.canceledDate?'canceled':'')}>
                                    {orderProduct.orozcoProductModifiers.map( (modifier,i)=>
                                        <div key={i} className="OrderResume-modifier-item"><p>{modifier.name}</p></div>
                                    )}
                                </div>}
                            </li>
                            )
                        })
                    }
                </ul>
            </div>
            {textBox &&
                <Modal
                title='Agregar comentario al platillo'
                onClose={closeTextBox}
                >
                    <div className='Order-comments-wrapper full-width'>
                        <textarea onChange={handleCommentText} value={commentText}  placeholder='Comentarios' className="CommentBox-textArea Order-comments full-width"/>
                        <button onClick={toggleKeyBoard} className='keyBoard-btn'>
                            <KeyBoardIcon/>
                        </button>
                    </div>
                    <div className='commentsModal-btn'>
                        <Button onClick={closeTextBox} blue className='half-width'>Cancelar</Button>
                        <Button  disabled={!commentText} onClick={()=>onAddCommentToDish(commentDishSelected,commentText,closeTextBox)} main solid className='half-width'>Agregar comentario</Button>
                    </div>
                </Modal>
            }
            {isCommentsOpen &&
            <div className='Order-comments-history full-width'>
                <div onClick={closeComments} className='crossIcon'>
                    <CrossIcon/>
                </div>
                <ul>
                    {
                        commentsHistory.map((n,i)=>(
                            <li key={i}>{`${n.orozcoProduct.name}  :  ${n.notes} `}</li>
                        )
                        )
                    }
                </ul>
            </div>
            }
            <div className={'bottom-buttons-wrap'}>
                <div className="context-actions-buttons">

                    {anySelected&&<>
                     {security.canCancelVisitOrderProducts()&&
                        <Button
                            className='full-width'
                            disabled={loading}
                            onClick={confirmationModalHandler}>
                            Cancelar productos
                        </Button>
                    }

                        <Button className='full-width' onClick={handleRePrintProducts}>
                            Reimprimir productos
                        </Button>
                    </>}

                </div>

                <div onClick={sendOrder} className="OrderResume-addItem">
                    <Button disabled={!selectedOrderProducts.length || loading} className="full-width">Enviar</Button>
                </div>
            </div>
            {openConfirmationModal &&
            <Modal
                title="Cancelar productos"
                onClose={closeConfirmationModal}
                className={'ConfirmationModal'}
            >
                <h3 className='question'>¿Estás seguro que deseas cancelar los productos?</h3>
                <p>Ya fueron enviados a cocina.</p>
                <textarea value={cancelProductsComment}
                          onChange={handleCancelProductsComment}
                          placeholder='Comentario...'
                          maxLength='500'
                          className="CommentBox-textArea full-width mt-3"/>
                <Button onClick={closeConfirmationModal}>No hacer nada</Button>
                <Button blue onClick={handleProductsCancel}>
                    Cancelar productos
                </Button>
            </Modal>
            }
            {isKeyBoardOpen &&
            <KeyBoardFull onHide={closeKeyBoard}>
                <KeyBoardTouch
                    valueForInputs={getValueFromInput}
                    valueInInput={commentText}
                    onClose={closeKeyBoard}
                />
            </KeyBoardFull>
            }
        </div>
    )
}
;

//export default OrderResume;
