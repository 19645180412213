import React, {useContext, useEffect} from 'react';
import './TicketesLists.scss';
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";
import {listSGroups} from '../../../../services/modelUtils/orozcoPrintUtils';
import useActualLounge from "../../../../hooks/useActualLounge";
import {moneyFormatter} from "../../../../services/currency/currencyUtils";
import moment from 'moment';
import {getNotifier} from "../../../../services/notifierUtils";
import useActualCashRegister from "../../../../hooks/useActualCashRegister";

const customProp = 'TicketsList.orozcoPrints';
const TicketsList = () => {
    const api = useContext(ApiContext);
    const orozcoPrints = useSelector(({api}) => api[customProp] || []);
    const lounge = useActualLounge();
    const [actualCashRegister] = useActualCashRegister();

    useEffect(() => {
        api.orozcoPrints.get({
            params: {
                sGroups: listSGroups,
                'orozcoPayments[exists]': true,
                'orozcoPayments.orozcoCashClosingDetail[exists]': false,
                'order[createdDate]': 'DESC',
                'orozcoPayments.orozcoCashRegister': actualCashRegister.id
            },
            customProp
        });

    }, [api, lounge, actualCashRegister]);


    const rePrint = orozcoPrint=> () =>{
        api.orozcoPrints.update({
            id: orozcoPrint.id,
            params: {sGroups: listSGroups},
            customProp
        }).then(()=>{
            getNotifier().success('Ticket reimpreso');
        }).catch(()=>{
            getNotifier().error('Error al imprimir ticket');
        });
    };

    return (
        <div className='TicketsList'>

            <div className='header'>
                <div className='folio'>
                    Folio
                </div>
                <div className='date'>
                    Fecha
                </div>
                <div className='concept'>
                    Concepto
                </div>
                <div className='printed-by'>
                    Creado por
                </div>
                <div className='total'>
                    Pagos
                </div>
                <div className='actions'>
                    Acciones
                </div>
            </div>
            <div className='tickets-container'>

                {orozcoPrints.map((orozcoPrint) =>
                    <div className='ticket' key={orozcoPrint.id}>
                        <div className='folio'>
                            {orozcoPrint.folioNumber}
                        </div>
                        <div className='date'>
                            {moment(orozcoPrint.createdDate).format('DD/MM/YYYY HH:mm')}
                        </div>
                        <div className='concept'>
                            {paymentType(orozcoPrint)}
                        </div>
                        <div className='printed-by'>
                                  {orozcoPrint.createdBy.employee?orozcoPrint.createdBy.employee.fullName:'Usuario '+orozcoPrint.createdBy.username}
                        </div>
                        <div className='total'>
                            {orozcoPrint.orozcoPayments.map((orozcoPayment) => (
                                <div id={orozcoPayment.id} key={orozcoPayment.id}>
                                    {moneyFormatter(orozcoPayment.amount)} {orozcoPayment.paymentCurrency}
                                </div>
                            ))}
                        </div>

                        <div className='actions'>
                            <button className='Button main d-inline' onClick={rePrint(orozcoPrint)}>Reimprimir</button>
                            &nbsp;
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const paymentType = (orozcoPrint)=>{
    if(!orozcoPrint.orozcoPayments || orozcoPrint.orozcoPayments.length===0)
        return  'Desconocido';

   const types = {
       'visit_order': 'Producto o platillo adicional',
       'check_in': 'Check In'
   };
   return types[orozcoPrint.orozcoPayments[0].type]?types[orozcoPrint.orozcoPayments[0].type]:'Desconocido';
};

export default TicketsList;
