import moment from "moment";
import {
    emptyCheckIn,
    getCheckInAvailableFreeMeals,
    isPayedCheckIn,
    prepareCheckInForServer,
    checkInFromLoungeAccessMethod,
    activeCheckIns,
    isCheckInExpired
} from "./checkInUtils";
import {copyPropertyIdOrNull, copyPropertyOrNull} from "../formUtils";
import {guestFromPlainReservation, guestFullName} from "./guestUtils";
import _ from 'lodash';
import {
    getPricedMealsThatCanBeFreeFromVisitOrder,
    getPricedMealsThatShouldBePayedFromVisitOrder,
    removeFreeProductsFromOrder
} from "./orozcoVisitOrderUtils";
import {getPriceInCurrency} from "../currency/currencyUtils";
import {flattenLoungeAccessMethodsTree} from "./loungeAccessMethodsUtils";
import {isRemoteDavinci} from "../remoteDavinciUtils";

/**
 * Initialize an empty object for the visit form
 *
 * @returns {{ checkIns: [*]}}
 */
export const emptyVisit=()=>({
    checkIns:[ emptyCheckIn() ],
    reservationDate: isRemoteDavinci()? moment():undefined
});


/**
 * Create a visit form object pre-filling the inputs with a guest info
 * @param guest
 * @returns {{adultsNumber: number, childrenNumber: number, checkIns: *[]}}
 */
export const visitFormFromGuest=(guest={})=>{
    const form={...guest};
    if(form.birthday)
        form.birthday=moment(form.birthday);
    if(form.id){
        form.guestId=form.id;
        delete form.id;
    }
    if(form.matLastName){
        form.patLastName+=' '+form.matLastName;
        form.matLastName = '';
    }
    return form;
};

/**
 * Create a visit form object pre-filling the inputs with a reservation info
 * @param reservation
 * @returns {{adultsNumber: number, childrenNumber: number, checkIns: *[]}}
 */
export const visitFormFromReservation=(reservation, loungeAccessMethodCatalogue)=>{

    if(!reservation)
        return emptyVisit();

    const form=visitFormFromGuest(reservation.guest? reservation.guest : guestFromPlainReservation(reservation) );

    copyPropertyOrNull(reservation, form, 'flight');

    // Reservation may have many access methods, we'll add them
    // as check-ins
    if(reservation.checkInsData?.length){
        form.checkIns = [];
        _.forEach(reservation.checkInsData, checkInData=>{
            const loungeAccessMethod = _.find(flattenLoungeAccessMethodsTree(loungeAccessMethodCatalogue), lam=>lam.id===checkInData.loungeAccessMethod);
            //console.log(loungeAccessMethod, reservation, loungeAccessMethodCatalogue);
            if(loungeAccessMethod){
                form.checkIns.push( _.cloneDeep({...checkInData, loungeAccessMethod}));
            }
        });
    }
    else if(reservation.loungeAccessMethods?.length) {
        const checkIns = _.map(
            reservation.loungeAccessMethods,
            checkInFromLoungeAccessMethod
        );

        if (!reservation || !reservation.loungeAccessMethods || !reservation.loungeAccessMethods.length)
            checkIns.push(emptyCheckIn());

        form.checkIns = checkIns;
    }

    return form;
};

/**
 * Prepares the visit form object for saving it to the server
 * @param visitForm
 * @returns {{guest: {}}}
 */
export const prepareVisitForServer =(visitForm, seconds)=>{
    if(!visitForm)
        throw new Error('Error desconocido. Forma de visita no encontrada.');

    const visit={guest:{}};

    if(visitForm.guestId)
        visit.guest.id=visitForm.guestId;

    if(!visitForm.name || !visitForm.name.trim())
        throw new Error('Ingresa el nombre del huésped');

    if(!visitForm.patLastName || !visitForm.patLastName.trim())
        throw new Error('Ingresa el apellido del huésped');

    visit.guest.name=visitForm.name.trim();
    visit.guest.patLastName=(visitForm.patLastName||'').trim();
    visit.guest.matLastName='';

    copyPropertyIdOrNull(visitForm, visit, 'reservation');

    if(visitForm.birthday && visitForm.birthday instanceof moment)
        visit.guest.birthday=visitForm.birthday.format('YYYY-MM-DD');

    if(!visitForm.birthday)
        throw new Error('Ingresa La fecha de nacimiento');

    copyPropertyOrNull(visitForm, visit.guest, 'gender');
    copyPropertyOrNull(visitForm, visit.guest, 'phone');
    copyPropertyOrNull(visitForm, visit.guest, 'email');
    copyPropertyOrNull(visitForm, visit.guest, 'city');

    copyPropertyIdOrNull(visitForm, visit.guest, 'country');

    if(visitForm.phone && visitForm.phone.length>0 && visitForm.phone.length < 10){
        throw new Error('El número telefónico debe ser de 10 dígitos');
    }

    if(!visitForm.flight){
        if(!!visitForm.flightNumber ||
           !!visitForm.flightDestination ||
           !!visitForm.flightAirline ||
           !!visitForm.flightIsArrival
          ){
            if(!visitForm.flightAirline){
                throw new Error('Selecciona la aerolínea del huésped');
            }
            if(!visitForm.flightDestination){
                throw new Error('Ingresa el destino del huésped');
            }
            if(visitForm.flightNumber===visitForm.flightAirline.code){
                throw new Error('Ingresa el número de vuelo del huésped');
            }
        }
        else {
            throw new Error('Debes seleccionar el vuelo del huésped');
        }
    }
    if(visitForm.flight){
        visit.flight = visitForm.flight.id;
    } else {
        visit.flight = {
            number: visitForm.flightNumber,
            destination: visitForm.flightDestination,
            airline: visitForm.flightAirline.id,
            isArrival: visitForm.flightIsArrival
        };
    }

    if(visitForm.text){
    visit.cassette = {
        comments:[
                {text:visitForm.text}
                ]
    };
    }

    visit.checkIns=visitForm.checkIns && visitForm.checkIns.map((visit) => prepareCheckInForServer(visit, seconds));

    return visit;
};

export const prepareEditVisitForServer = (editVisitForm)=>{
    if(!editVisitForm)
        throw new Error('Error desconocido. Forma de visita no encontrada.');

    const editVisit={};

    if(editVisitForm.id)
        editVisit.id=editVisitForm.id;

    if(!editVisitForm.name || !editVisitForm.name.trim())
        throw new Error('Ingresa el nombre del huésped');

    editVisit.name=editVisitForm.name.trim();
    editVisit.patLastName=(editVisitForm.patLastName||'').trim();

    if(editVisitForm.birthday && editVisitForm.birthday instanceof moment)
        editVisit.birthday=editVisitForm.birthday.format('YYYY-MM-DD');

    copyPropertyOrNull(editVisitForm, editVisit, 'phone');
    copyPropertyOrNull(editVisitForm, editVisit, 'email');
    copyPropertyOrNull(editVisitForm, editVisit, 'city');

    copyPropertyIdOrNull(editVisitForm, editVisit, 'country');


    return editVisit;
};

export const visitHasPayedAccessMethod=(visit)=>{
    if(!visit || !visit.checkIns || !visit.checkIns.length)
        return false;

    return visit.checkIns.reduce( (acc, checkIn)=>isPayedCheckIn(checkIn)||acc, false );
};


/**
 * It searches for all the visits which 'name' is a substring of visit's guest name
 * @param visits
 * @param name
 * @returns {Array}
 */
export const filterVisitsByGuestFullName=(visits, name)=>
    _.filter(visits, visit=>_.upperCase(guestFullName(visit.guest)).indexOf(_.upperCase(name))!==-1);

export const filterActiveVisits = visits =>
    _.filter(visits, isVisitActive);

export const getVisitAvailableFreeFreeMeals=(visit)=>{
    if(!visit || !visit.checkIns || !visit.checkIns.length)
        return 0;

    return visit.checkIns.reduce( (acc, checkIn)=>acc+getCheckInAvailableFreeMeals(checkIn),0 );
};

export const hasVisitPendingPayment=(visit)=>{
    /*
    //Antes se revisaba si había pedido cualquier cosa que se tuviera que pagar
    //Ahora se puede pagar a media visita, entonces se revisa si hay algo que requiera pago y NO haya sido pagado
    if( getPricedMealsThatShouldBePayedFromVisitOrder(visit && visit.orozcoVisitOrder).length )
        return true;

    const availableMeals=getVisitAvailableFreeFreeMeals(visit);
    const usedMeals=getPricedMealsThatCanBeFreeFromVisitOrder(visit && visit.orozcoVisitOrder);

    return availableMeals < usedMeals.length;
    */
    if(!visit || !visit.orozcoVisitOrder || !visit.orozcoVisitOrder.orozcoVisitOrderProducts)
        return false;

    const mealsToPay=visit.orozcoVisitOrder.orozcoVisitOrderProducts.filter(
        (orderProduct)=>orderProduct.shouldBePayed && !orderProduct.payed
    );

    return !!mealsToPay.length;
};

export const getVisitPayAmount=(visit, currency, exchangeRates)=>{

    const availableMeals=getVisitAvailableFreeFreeMeals(visit);
    const usedMeals=getPricedMealsThatCanBeFreeFromVisitOrder(visit && visit.orozcoVisitOrder);

    const payedMeals=getPricedMealsThatShouldBePayedFromVisitOrder(visit && visit.orozcoVisitOrder);


    let total=0;
    if(payedMeals.length)
        total += payedMeals.reduce(
            (acc, orderProduct)=>
                acc + (orderProduct.quantity * getPriceInCurrency(orderProduct.orozcoProduct.prices, currency, exchangeRates)),0);

    const freebiesToCharge = removeFreeProductsFromOrder(usedMeals, availableMeals);
    if(freebiesToCharge)
        total += freebiesToCharge.reduce(
            (acc, orderProduct)=>
                acc + (orderProduct.quantity * getPriceInCurrency(orderProduct.orozcoProduct.prices, currency, exchangeRates)),0);

    return total;
};

export const getVisitUnpaidAmount=(visit, currency, exchangeRates)=> {
    if(!visit.orozcoVisitOrder){
        return 0;
    }

    const mealsToPay=visit.orozcoVisitOrder.orozcoVisitOrderProducts.filter(
        (orderProduct)=>orderProduct.shouldBePayed && !orderProduct.payed && !orderProduct.canceledDate
    );

    let total=0;
    if(mealsToPay.length)
        total += mealsToPay.reduce(
            (acc, orderProduct)=>
                acc + (orderProduct.quantity * getPriceInCurrency(orderProduct.orozcoProduct.prices, currency, exchangeRates)),0);
    return total;
};

export const activeCheckInsFromVisit = visit =>
    activeCheckIns(visit.checkIns);

export const isVisitActive = visit =>{
    if(!visit)
        return false;
    if( visit.activePersons )
        return true;
    if(!visit.checkIns)
        return false;
    return activeCheckInsFromVisit(visit).length !== 0;
};

export const getExpiredCheckInsFromVisit = (visit) => {
    if(!visit?.checkIns?.length)
        return [];
    return _.filter( visit.checkIns, isCheckInExpired );
}

/**
 * Serialization groups to use in the LastEntries component
 * @type {*[]}
 */
export const visitPendingBillSGroups=[
    'visit_read_id',
    'visit_read_guest',
        'guest_read_id',
        'guest_read_name',
        'guest_read_pat_last_name',
        'guest_read_mat_last_name'
];

/**
 * Serialization groups to use in the LastEntries component
 * @type {*[]}
 */
export const lastEntriesSGroups=[
    'visit_read_id',
    'visit_read_first_check_in_date',
    'visit_read_guest',
    'guest_read_id',
    'guest_read_name',
    'guest_read_pat_last_name',
    'guest_read_mat_last_name',
    'visit_read_active_persons',
    'visit_read_check_ins',
    'check_in_read_check_out_date',
    // 'visit_read_active_persons',
    // "visit_read_orozco_visit_order",
    // 'orozco_visit_order_read_id',
    // 'orozco_visit_order_read_orozco_visit_order_products',
    // 'orozco_visit_order_product_read',
    // 'orozco_payment_read',
    // 'orozco_visit_order_product_read_orozco_product',
    // 'orozco_product_read'
];

export const visitFlightSGroups=[
    "visit_read_first_check_in_date",
    "visit_read_flight",
    "flight_read",
    "airline_read_id",
    "airline_read_name",
    "airline_read_code",
];

export const visitFullSGroups=[
    "visit_read_id",
    "visit_read_guest",
        'guest_read',
        'guest_read_country',
            'country_read',
        'guest_read_lounge_access_methods',
        'guest_read_profile_image',
        'guest_read_non_grata',
        'guest_read_non_grata_since',
        'guest_read_vip_since',
    "visit_read_lounge",
        "place_read_id",
        "place_read_name",
    "visit_read_first_check_in_date",
    "visit_read_flight",
        "flight_read",
            "airline_read_id",
            "airline_read_name",
            "airline_read_code",
    "visit_read_check_ins",
        "check_in_read_id",
        "check_in_read_adults_number",
        "check_in_read_kids_number",
        "check_in_read_active_adults",
        "check_in_read_active_kids",
        "check_in_read_extra_fields",
        "check_in_read_created_date",
        "check_in_read_canceled_date",
        "check_in_read_check_out_date",
        "check_in_read_expiration_date",
        "check_in_read_lounge_access_method",
            "lounge_access_method_read_id",
            "lounge_access_method_read_name",
            "lounge_access_method_read_max_adults",
            "lounge_access_method_read_max_kids",
            "lounge_access_method_read_config",
    "visit_read_cassette",
        "cassette_read",
        "cassette_read_comments",
    "visit_read_employees_attending_visit",
        "employee_attending_visit_read_id",
        "employee_attending_visit_read_employee",
        "employee_read_id",
        "employee_read_name",
        "employee_read_pat_last_name",
        "employee_read_mat_last_name",
    "visit_read_orozco_visit_order",
        "orozco_visit_order_read_id",
        "orozco_visit_order_read_scheduled_service",
        "orozco_visit_order_read_orozco_visit_order_products",
            "orozco_visit_order_product_read",
            "orozco_visit_order_product_read_canceled_date",
            "orozco_visit_order_product_read_orozco_product",
                "orozco_product_read",
        "orozco_visit_order_product_read_orozco_product_modifier",
            "orozco_product_modifier_read",
    "orozco_visit_order_read_notes"
];

export const menuScreenSGroups=[
    "visit_read_id",
    "visit_read_guest",
        'guest_read',
        'guest_read_non_grata',
    "visit_read_lounge",
        "place_read_id",
        "place_read_name",
    "visit_read_check_ins",
        "check_in_read_id",
        "check_in_read_adults_number",
        "check_in_read_kids_number",
        "check_in_read_active_adults",
        "check_in_read_active_kids",
        "check_in_read_created_date",
        "check_in_read_canceled_date",
        "check_in_read_check_out_date",
        "check_in_read_expiration_date",
        "check_in_read_lounge_access_method",
            "lounge_access_method_read_id",
            "lounge_access_method_read_name",
            "lounge_access_method_read_config",
    "visit_read_employees_attending_visit",
        "employee_attending_visit_read_id",
        "employee_attending_visit_read_employee",
            "employee_read_id",
            "employee_read_name",
            "employee_read_pat_last_name",
            "employee_read_mat_last_name",
    "visit_read_orozco_visit_order",
        "orozco_visit_order_read_id",
        "orozco_visit_order_read_scheduled_service",
        "orozco_visit_order_read_orozco_visit_order_products",
            "orozco_visit_order_product_read",
            "orozco_visit_order_product_read_created_date",
            "orozco_visit_order_product_read_created_by",
            "user_read_id",
            "user_read_employee",
            "employee_read_full_name",
            "orozco_visit_order_product_read_canceled_date",
            "orozco_visit_order_product_read_orozco_product",
                "orozco_product_read",
                    "orozco_product_read_prices",
                    "orozco_product_read_orozco_product_modifiers",
                        "orozco_product_modifier_read",
            "orozco_visit_order_product_read_orozco_product_modifiers",
                "orozco_product_modifier_read",
        'orozco_visit_order_read_orozco_visit_order_payments',
            'orozco_payment_read',
    "orozco_visit_order_read_notes",
    "visit_read_tables",
        "table_read",
        "lounge_map_item_read"
];

export const visitCancelCheckInSGroups = [
    "visit_read_id",
    "visit_read_check_ins",
        "check_in_read",
        "check_in_read_orozco_check_in_payments",
            "orozco_payment_read",
];

export const frontDeskVisitSGroups = [
    "visit_read_id",
    "visit_read_folio",
    "visit_read_guest",
        "guest_read_id",
        "guest_read_name",
        "guest_read_pat_last_name",
        "guest_read_mat_last_name",
        "guest_read_birthday",
        "guest_read_gender",
        "guest_read_phone",
        "guest_read_email",
        "guest_read_city",
        "guest_read_country",
            "country_read",
    "visit_read_created_date",
];

export const visitInfoSGroups = [
    "visit_read_id",
    "visit_read_seats",
        "lounge_map_item_read_name",
    "visit_read_check_ins",
        "check_in_read",
        "check_in_read_created_date",
        "check_in_read_extra_fields",
        "check_in_read_lounge_access_method",
            "lounge_access_method_read_id",
            "lounge_access_method_read_name",
            "lounge_access_method_read_config",
            "lounge_access_method_read_lounge_parent_access_methods",
    "visit_read_guest",
        "guest_read_id",
        "guest_read_name",
        "guest_read_pat_last_name",
        "guest_read_mat_last_name",
    "visit_read_employees_attending_visit",
        "employee_attending_visit_read_id",
        "employee_attending_visit_read_employee",
            "employee_read_id",
            "employee_read_name",
            "employee_read_pat_last_name",
            "employee_read_mat_last_name",
];

export const visitOrozcoHistorySGroups = [
    "visit_read_id",
    "visit_read_employees_attending_visit",
        "employee_attending_visit_read_id",
        "employee_attending_visit_read_employee",
            "employee_read_id",
            "employee_read_name",
            "employee_read_pat_last_name",
            "employee_read_mat_last_name",
    "visit_read_orozco_visit_order",
        "orozco_visit_order_read_id",
        "orozco_visit_order_read_scheduled_service",
        "orozco_visit_order_read_orozco_visit_order_products",
            "orozco_visit_order_product_read",
            "orozco_visit_order_product_read_created_date",
            "orozco_visit_order_product_read_created_by",
            "user_read_id",
            "user_read_employee",
            "employee_read_full_name",
            "orozco_visit_order_product_read_canceled_date",
            "orozco_visit_order_product_read_orozco_product",
                "orozco_product_read",
                    "orozco_product_read_prices",
                    "orozco_product_read_orozco_product_modifiers",
                        "orozco_product_modifier_read",
            "orozco_visit_order_product_read_orozco_product_modifiers",
                "orozco_product_modifier_read",
        'orozco_visit_order_read_orozco_visit_order_payments',
            'orozco_payment_read',
        "orozco_visit_order_read_notes",
    "visit_read_check_ins",
        "check_in_read_active_adults",
        "check_in_read_active_kids",
        "check_in_read_active_canceled_date",
        "check_in_read_lounge_access_method",
            "lounge_access_method_read_name",
            "lounge_access_method_read_config",
    "visit_read_guest",
        "guest_read_id",
        "guest_read_name",
        "guest_read_pat_last_name",
        "guest_read_mat_last_name",
];

export const customPropVisit = '@Generic.CurrentVisit';
