import React,{useMemo} from 'react';
import useEscapeKey from "../../hooks/useEscapeKey";
import {createPortal} from "react-dom";
import './KeyBoardFull.scss';
import classNames from 'classnames';

const KeyBoardFull=({onHide, children, className, ...props})=>{
    useEscapeKey(onHide);
    return createPortal(
        <div className={classNames('KeyBoardFull', className)}  tabIndex={-1} {...props}>
            <div className='KeyBoardFull-container animated fadeInUp faster'>
                {children}
            </div>
        </div>

        ,useMemo(()=>document.getElementsByTagName('body')[0],[]));
};

export default KeyBoardFull;
