/**
 * This copies 'property' from one object to another. Checking that everything exists or setting null un toObj.property
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param mapToProperty string Change the property name in the receiver to this
 */
export const copyPropertyOrNull=(fromObj, toObj, property, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || typeof fromObj[property]==='undefined')
        toObj[mapToProperty||property]=null;

    toObj[mapToProperty||property]=fromObj[property];
};

/**
 * This tries to copy
 * @param fromObj
 * @param toObj
 * @param property
 */
export const copyPropertyIdOrNull=(fromObj, toObj, property)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || !fromObj[property])
        toObj[property]=null;
    else if(typeof fromObj[property] === 'object')
        toObj[property]=fromObj[property].id;
    else
        toObj[property]=fromObj[property];
};

/**
 * This tries to copy
 * @param fromObj
 * @param toObj
 * @param property
 * @param errorMessage
 */
export const copyPropertyIdOrThrow =(fromObj, toObj, property, errorMessage)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || !fromObj[property] || !fromObj[property].id)
        throw new Error(errorMessage);

    toObj[property]=fromObj[property].id;
};
