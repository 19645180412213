import React, {useEffect, useContext, useCallback, useState} from 'react';
import TopBar from "../../components/TopBar/TopBar";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import './CashRegister.scss';
import Button from "../../components/Button/Button";
import useBoolean from "../../hooks/useBoolean";
import CloseRegisterModal from "./components/CloseRegisterModal/CloseRegisterModal";
import useActualCashRegister from "../../hooks/useActualCashRegister";
//import {getActualShiftForClosing} from "../../services/modelUtils/cashRegisterUtils";
import {ApiContext} from "../../services/api/api-config";
import {useSelector} from "react-redux";
import moment from "moment";
import CashRegisterMoneyList from "./components/CashRegisterMoneyList/CashRegisterMoneyList";
import {getPrinterIdByKey, localStorageFrontDeskPrinterKey} from "../../services/modelUtils/orozcoTicketPrinterUtils";
import {getNotifier} from "../../services/notifierUtils";
import TicketsList from "./components/TicketsList/TicketsList";
import {SecurityContext} from "../../services/SecurityManager";


const currentMoneyCustomProp = '@CashRegister.currentMoney';
const shiftCashCLosingProp = '@CashRegister.shiftCashClosing';
const CashRegister = () => {

    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const [cashRegister] = useActualCashRegister();
    const [closing, startClosing, stopClosing] = useBoolean();

    //const shift = getActualShiftForClosing();
    //const shiftComplete = {M: 'MATUTINO', V: 'VESPERTINO', N: 'NOCTURO'}[shift];

    const [reloadState, setReloadState] = useState(0);
    const reload = useCallback(() => setReloadState((old) => old + 1), []);

    useEffect(() => {
        if (!cashRegister)
            return;
        api.orozcoCashRegisters.currentMoney({id: cashRegister.id, customProp: currentMoneyCustomProp});
        api.orozcoCashClosings.currentShiftCashClosing({customProp: shiftCashCLosingProp});
    }, [api, cashRegister, reloadState]);


    const closeDetails = useSelector(({api}) => api[currentMoneyCustomProp]);
    const lastCashClosing = useSelector(({api}) => api[shiftCashCLosingProp]);


    const printCashClosing = useCallback((cashClosing) => {
        const printerId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
        const params = {
            ticketPrinter: printerId,
            orozcoCashClosing: cashClosing.id
        };
        api.orozcoPrints.create({
            params
        }).then(() => getNotifier().success('Cierre de caja impreso en recepción'));
    }, [api]);

    const handleCashClose = useCallback((cashClosing) => {
        printCashClosing(cashClosing);
        stopClosing();
        reload();
    }, [reload, stopClosing, printCashClosing]);

    return (
        <div className='CashRegister'>
            <FixedMenu/>
            <TopBar/>
            {cashRegister ?
                <div>
                    <div className='content'>
                        <div className='header'>
                            <h1 className='center'>
                                <FontAwesomeIcon icon={faCashRegister}/>{' '}
                                Caja registradora {cashRegister.name}
                            </h1>
                        </div>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='side-block amounts-block'>
                                        <h3>Cantidad actual</h3>
                                        <CashRegisterMoneyList details={closeDetails && closeDetails.details}/>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='side-block close-block'>
                                        <h3>Cierre de caja</h3>

                                        <div className='row'>
                                            <div className='col-12'>
                                                <p><b>ÚLTIMO CERRE</b></p>
                                                {lastCashClosing?
                                                <>
                                                    <p>Turno - {lastCashClosing.shift}</p>
                                                    <p>Fecha {moment(lastCashClosing.createdDate).format('HH:mm DD/MM/YYYY')}</p>
                                                    <CashRegisterMoneyList
                                                        className=""
                                                        details={lastCashClosing.orozcoCashClosingDetails}/>
                                                </>: <p>Sin cerrar caja</p>
                                                }
                                            </div>
                                        </div>

                                        <div className='btn-container'>
                                            <Button solid onClick={startClosing} disabled={!closeDetails}>Cerrar
                                                caja</Button>
                                        </div>
                                    </div>
                                </div>

                                {security.canListOrozcoPrints() ?
                                    <div className='col-12 col-md-12'>
                                        <div className='side-block close-block'>
                                            <h3>Pagos</h3>
                                            <TicketsList/>
                                        </div>
                                    </div> : null
                                }
                            </div>

                        </div>
                    </div>

                    {closing &&
                    <CloseRegisterModal onClose={stopClosing} closeDetails={closeDetails} cashRegister={cashRegister}
                                        onSave={handleCashClose}/>}
                </div> :
                <div className='content'>
                    <h2>Es necesario configurar una caja, solicita la ayuda de un administrador del sistema</h2>
                </div>
            }
        </div>
    );
};

CashRegister.displayName = "CashRegister";
export default CashRegister;
