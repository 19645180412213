import React from 'react';
import './WaiterCard.scss';
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const WaiterCard = ({orozcoEmployeeProfile, onClick, isSelected, showActiveVisits, highlight}) => {

    const handleClick = () => {
        onClick(orozcoEmployeeProfile.employee.id);
    };

    return (
        <div onClick={handleClick}
             className={"WaiterCard animated fadeIn" + (isSelected ? ' selected' : '') + (highlight ? ' WaiterCard-highlight' : '')}>
            <p className={"WaiterCard-text "}>{orozcoEmployeeProfile.employee.fullName}</p>
            <div className='badges-container'>
                {showActiveVisits &&
                <div className="badge visits-count-badge">
                    {orozcoEmployeeProfile.attendingVisitsNumber}
                </div>
                }
                {orozcoEmployeeProfile.employee.isEating ?

                    <div className="badge eating-badge">
                        <FontAwesomeIcon icon={faUtensils}/>
                    </div>
                    : null}
            </div>
        </div>
    )
};
export default WaiterCard;

