import React, {useContext, useState, useCallback} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import './WaiterMenu.scss';
import TopBarWaiter from "../../../components/TopBar/TopBarWaiter/TopBarWaiter";
import OrderResume from "./Components/OrderResume/OrderResume";
import {ApiContext} from "../../../services/api/api-config";
import {getNotifier} from "../../../services/notifierUtils";
import {useSelector} from "react-redux";
import _ from 'lodash';
import useTideEntity from "../../../hooks/useTideEntity";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import {guestFullName} from "../../../services/modelUtils/guestUtils";
import {
    customPropVisit,
    menuScreenSGroups,
    getExpiredCheckInsFromVisit
} from "../../../services/modelUtils/visitUtils";
import Button from "../../../components/Button/Button";
import {ReactComponent as LeftArrow} from '../../../assets/images/icons/leftArrow.svg';
import {paths} from "../../../services/routes/appRoutes";
import {
    cancelingVisitOrderProductsSGroups,
    emptyOrozcoVisitOrder,
    prepareOrozcoVisitOrderForServer, setPayableFlagOnSelectedOrderProducts
} from "../../../services/modelUtils/orozcoVisitOrderUtils";

import moment from "moment";
import {serverDateTimeFormat} from "../../../services/timeUtils";
import CheckOutModal from "../../FrontDeskGuest/Components/VisitInfo/CheckOutModal/CheckOutModal";
import useBoolean from "../../../hooks/useBoolean";
import SpecialProductModal from "./Components/SpecialProductModal/SpecialProductModal";
import KeyBoardFull from "../../../components/KeyBoardFull/KeyBoardFull";
import KeyBoardTouch from "../../../components/KeyBoardTouch/KeyBoardTouch";
import {OROZCO_PRODUCT_SPECIAL_ID} from "../../../services/modelUtils/orozcoProductUtils";
import FixedMenu from "../../../components/FixedMenu/FixedMenu";
import {getPrinterIdByPrintArea, getPrinterIdByKey, localStorageFrontDeskPrinterKey} from "../../../services/modelUtils/orozcoTicketPrinterUtils";
import OrozcoMenu from "../components/OrozcoMenu/OrozcoMenu";
import Modal from "../../../components/Modal/Modal";
import {SecurityContext} from "../../../services/SecurityManager";

const requestConfig={requestParams:{sGroups:menuScreenSGroups}, customProp: customPropVisit};
const tideEntityOptions = {alwaysLoad: true};
const sendingOrderId='@WaiterMenu.sendingOrder';
const WaiterMenu = () => {

    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const notifier = getNotifier();
    const history=useHistory();

    const [specialProductDescription,setSpecialProductDescription] = useState("");
    const [specialProductIndex,setSpecialProductIndex] = useState(null);
    const [commentText,setCommentText] = useState('');
    const [shouldBePayModal,openShouldBePayModal,closeShouldBePayModal] = useBoolean();
    // ------- Load Visit ------
    const {visitId}=useParams();
    const [visit, loadingVisit, notFound, {reload}] = useTideEntity('visits', visitId, requestConfig, tideEntityOptions);

    // -------------------- Handle product add -------------------
    const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);

    const handleProductSelect=useCallback(product=>{
        const newProduct = product.id === OROZCO_PRODUCT_SPECIAL_ID?
              {askedByKid: false, askedByMainGuest: false, orozcoProduct: product, quantity: product.quantity, data: {description: specialProductDescription}}:
              {askedByKid: false, askedByMainGuest: false, orozcoProduct: product, quantity: 1};

        if(product.id === OROZCO_PRODUCT_SPECIAL_ID){
            // This is triggered when the special product modal is closed...

            // When a special product was edited when the order was clicked,
            // the state "specialProductIndex" is set with the corresponding index on the order
            if(specialProductIndex!==null){
                // In such case we update that index
                setSelectedOrderProducts(ps=>[
                    ...ps.slice(0, specialProductIndex),
                    newProduct,
                    ...ps.slice(specialProductIndex+1)
                ]);
                // we'll reset this flag afterwards (not sure if needed)
                setSpecialProductIndex(null);
                return;
            }
            // If it was not an edition then it'll be appended
            setSelectedOrderProducts(ps=>[...ps, newProduct]);
            return;
        }

        setSelectedOrderProducts(ps=>{
            if(ps.length === 0){
                return [newProduct];
            }
            const lastProduct = ps[ps.length-1];
            // the quantity of an existent product will be incremented if
            // it has no modifiers and there was a previous product of
            // the same type added to the order
            if(newProduct.orozcoProduct.orozcoProductModifiers.length === 0){
                const productIndex = _.findIndex(
                    ps,
                    ({orozcoProduct: {id}})=>id===newProduct.orozcoProduct.id
                );
                if(productIndex !== -1){
                    const previousProduct = ps[productIndex];
                    return [
                        ...ps.slice(0, productIndex),
                        {...previousProduct, quantity: previousProduct.quantity+1},
                        ...ps.slice(productIndex+1)
                    ];
                }
            }

            // or if it was the last one added to the order
            if(lastProduct.orozcoProduct.id === product.id){
                return [
                    ...ps.slice(0, -1),
                    {...lastProduct, quantity: lastProduct.quantity+1}
                ];
            }

            // if not, it'll be appended to the order
            return [...ps, newProduct];
        });
    },[specialProductIndex, specialProductDescription]);

    const handleNewProductOrder = useCallback(product=>{
        setSelectedOrderProducts(ps=>[
            ...ps,
            {orozcoProduct: product, quantity: 1}
        ]);
    }, []);

    // ------- Handle remove --------------

    const handleRemoveByIndex=useCallback((index)=>{
        const newSelection=[...selectedOrderProducts];
        newSelection.splice(index, 1);
        setSelectedOrderProducts(newSelection);
    },[selectedOrderProducts]);

    const handleToggleAskedByKid = useCallback(i=>{
        setSelectedOrderProducts(s=>[
            ...s.slice(0,i),
            {...s[i], askedByKid: !s[i].askedByKid},
            ...s.slice(i+1)
        ]);
    }, []);

    const handleToggleAskedByMainGuest = useCallback(i=>{
        setSelectedOrderProducts(s=>[
            ...s.slice(0,i),
            {...s[i], askedByMainGuest: !s[i].askedByMainGuest},
            ...s.slice(i+1)
        ]);
    }, []);

    const addCommentToDish = useCallback((i,commentForDish,closeTextBox)=>{
      setSelectedOrderProducts(s=>[
           ...s.slice(0,i),
           {...s[i],notes: commentForDish},
           ...s.slice(i+1)
       ]);
        closeTextBox()
    },[]);



    const printVisitOrderProducts = useCallback((orozcoVisitOrderProducts, isReprint)=>{
        const nonPayedOrozcoProducts = _.filter(orozcoVisitOrderProducts, (prod)=>!prod.shouldBePayed);
        const prints = _.groupBy(nonPayedOrozcoProducts, 'orozcoProduct.printArea');
        _.each(prints, (nonPayedOrozcoProducts, printArea)=> {
            const printerId = getPrinterIdByPrintArea(printArea);
            if(!printerId){
                notifier.error('La impresora de '+printArea+' no se encuentra configurada');
                return;
            }

            api.orozcoPrints.create({
                params: {
                    ticketPrinter: getPrinterIdByPrintArea(printArea),
                    orozcoVisitOrderProducts: _.map(nonPayedOrozcoProducts,(prod)=>prod.id),
                    isReprint: !!isReprint
                }
            }).then(()=>notifier.success('Comanda impresa en '+printArea))

        });

    },[api, notifier]);


    const onRePrintOrderProducts = useCallback((orozcoVisitOrderProducts)=>{
        const isReprint = true;
        printVisitOrderProducts(orozcoVisitOrderProducts, isReprint);
    }, [printVisitOrderProducts]);







    // ----------   Send order to server -------
    const sendOrder=useCallback  ( ()=>{
        const order=emptyOrozcoVisitOrder(visit);
        const modifierChecker = _.map(selectedOrderProducts, (select )=> (Number(select.orozcoProduct.requiredModifier) > (select.orozcoProductModifiers?.length||0))); //Output: [true, false, true] 'true' whenever the product modifiers are less than requiried

        if(visit.orozcoVisitOrder && visit.orozcoVisitOrder.orozcoVisitOrderProducts)
            order.orozcoVisitOrderProducts=_.map(visit.orozcoVisitOrder.orozcoVisitOrderProducts,'id');
        if(visit.employeesAttendingVisit.length < 1)
        {
            getNotifier().error('Debes seleccionar a un anfitrión para continuar');
            return;
        }

        if(modifierChecker.includes(true)) {
            const index = modifierChecker.indexOf(true);
            getNotifier().error(`El platillo ${selectedOrderProducts[index].orozcoProduct.name} requiere de ${selectedOrderProducts[index]?.orozcoProduct?.requiredModifier} modificadores para poder continuar`);
            return;
        }

        const flaggedSelectedOrderProducts=setPayableFlagOnSelectedOrderProducts(selectedOrderProducts, visit, visit.orozcoVisitOrder);
        order.orozcoVisitOrderProducts=[...order.orozcoVisitOrderProducts, ...flaggedSelectedOrderProducts];

            /*        if(visit.orozcoVisitOrder && visit.orozcoVisitOrder.orozcoVisitOrderProducts && commentText){
            ///// We'll paste the comment to every new product (the ones that are objects)
            order.orozcoVisitOrderProducts = order.orozcoVisitOrderProducts.map(p=>{
                if(typeof p === 'string'){
                    return p;
                }
                return {
                    ...p,
                    notes: commentText
                };
            });
        }*/


        const prepared = prepareOrozcoVisitOrderForServer(order);

        const finish=(newOrozcoVisitOrder)=>{
            //Get ids of new inserted products
            closeShouldBePayModal();
            const newVisitOrderProductIds =
                _.without(
                _.map(newOrozcoVisitOrder.orozcoVisitOrderProducts,(prod)=>prod.id),
                ..._.map(visit.orozcoVisitOrder?visit.orozcoVisitOrder.orozcoVisitOrderProducts:[], (prod)=>prod.id)
            );

            const newOrozcoVisitOrderProducts =
                _.filter(newOrozcoVisitOrder.orozcoVisitOrderProducts, (prod)=>newVisitOrderProductIds.indexOf(prod.id)!==-1?prod:null);


            printVisitOrderProducts(newOrozcoVisitOrderProducts);
            reload();
            setSelectedOrderProducts([]);
            setCommentText('');
        };

        if(!visit.orozcoVisitOrder)
            api.orozcoVisitOrders.create({params: prepared ,loadingId:sendingOrderId}).then(finish);
        else
            api.orozcoVisitOrders.update({id: visit.orozcoVisitOrder.id, params: prepared, loadingId:sendingOrderId}).then(finish);

    },[selectedOrderProducts, visit, reload, api, printVisitOrderProducts,closeShouldBePayModal]);

    // ----------- Should be pay Modal ---------
    const shouldBePay = useCallback(()=>{

        if(getExpiredCheckInsFromVisit(visit).length === visit?.checkIns?.length )
            return getNotifier().error("Todos los check ins han expirado.");

        const pricedProducts = _.find(_.map(setPayableFlagOnSelectedOrderProducts(selectedOrderProducts, visit, visit.orozcoVisitOrder),(product)=> (product.shouldBePayed)),(item)=> item=== true);
        if(pricedProducts){
            openShouldBePayModal()
        }else{
            sendOrder()
        }

    },[selectedOrderProducts,openShouldBePayModal,sendOrder,visit]);

    // ---------- canceling products on server ---------
    const handleOrderProductsCancel=useCallback((orderProducts, cancelProductsComment)=>{
        const oldOrderProducts=visit.orozcoVisitOrder.orozcoVisitOrderProducts;
        //Set cancelled date of cancelled products
        let newOrderProducts=oldOrderProducts.map( oldOrderProd=>{
            const isCanceled =_.find(orderProducts, orderProd=>orderProd.id===oldOrderProd.id);
            if(isCanceled)
                return {
                id: isCanceled.id,
                    payed:oldOrderProd.payed,
                    canceledDate:moment().format(serverDateTimeFormat),
                    shouldBePayed: false,
                    canceledProductsComment: cancelProductsComment
            };
            else
                return {
                id:oldOrderProd.id,
                    payed:oldOrderProd.payed,
                    quantity:oldOrderProd.quantity,
                    orozcoProduct:oldOrderProd.orozcoProduct,
                    canceledDate:oldOrderProd.canceledDate};
            });
        //Re-calculate free meals flags
        newOrderProducts=setPayableFlagOnSelectedOrderProducts(newOrderProducts, visit);
        //remove orozcoProducts to send only ids
        newOrderProducts=newOrderProducts.map(orderProduct=>
            ({
                ...orderProduct,
                orozcoProduct:
                    orderProduct.orozcoProduct?
                        orderProduct.orozcoProduct.id:
                        undefined
            }));

        const prepared={id: visit.orozcoVisitOrder.id, orozcoVisitOrderProducts: newOrderProducts, sGroups: cancelingVisitOrderProductsSGroups};
        api.orozcoVisitOrders.update({id: visit.orozcoVisitOrder.id, params: prepared, loadingId:sendingOrderId, customProp: requestConfig.customProp})
            .then((newVisitOrder)=>{
                reload();
                const frontDeskPrinterId = getPrinterIdByKey(localStorageFrontDeskPrinterKey);
                if(!frontDeskPrinterId){
                    getNotifier().error('La impresora de recepción no se encuentra configurada');
                    return;
                }

                //The server may have created new negative payments if a canceled product was already paid
                //If it has, we look for them to print them.
                //Find new payments comparing old payments array with the new payments array.
                const newPayments = _.filter(newVisitOrder.orozcoVisitOrderPayments||[], (newPayment)=>{
                    return !_.find(visit?.orozcoVisitOrder?.orozcoVisitOrderPayments||[], oldPayment=>newPayment.id===oldPayment.id);
                });

                //Impresión en recepción
                api.orozcoPrints.create({params: {
                        ticketPrinter: frontDeskPrinterId,
                        orozcoVisitOrderProducts: _.map(orderProducts, 'id'),
                        orozcoVisitOrderPayments: _.map(newPayments, 'id'),
                        isCanceled: true
                    }
                });

                const prints = _.groupBy(orderProducts, 'orozcoProduct.printArea');
                _.each(prints, (orozcoProducts, printArea) => {
                    const printerId = getPrinterIdByPrintArea(printArea);
                    if (!printerId) {
                        notifier.error('La impresora de ' + printArea + ' no se encuentra configurada');
                        return;
                    }

                    api.orozcoPrints.create({
                        params: {
                            ticketPrinter: getPrinterIdByPrintArea(printArea),
                            orozcoVisitOrderProducts: _.map(orderProducts, 'id'),
                            isCanceled: true
                        }
                    }).then(() => notifier.success('Cancelación impresa en ' + printArea))
                });
        });
    },[api, visit, reload, notifier]);

    // ------ Vars for display --------

    const sendingOrder=useSelector(({loadingIds})=>loadingIds[sendingOrderId]);

    // ------- Do check out -----------
    const [checkingOut, setCheckingOutTrue, stopCheckingOut]=useBoolean();
    const startCheckingOut=useCallback(()=>{
        if(!visit)
            return;
        setCheckingOutTrue();
    },[setCheckingOutTrue, visit]);

    const [specialProduct,openSpecialProduct,closeSpecialProduct] = useBoolean();
    const [keyBoard,,closeKeyboard,toggleKeyBoard] = useBoolean();

    const getValueFromInput = useCallback((valueForInputs)=>{
        setSpecialProductDescription(valueForInputs)
    },[]);

    const handleSpecialProductInput = useCallback((e)=>{
        setSpecialProductDescription(e.currentTarget.value);
    },[]);
    const [specialDishAmount,setSpecialDishAmount] = useState(1);

    const openSpecialProductModal = useCallback(()=>{
        setSpecialProductIndex(null);
        openSpecialProduct()
    },[openSpecialProduct]);

    const openSpecialProductModalWithOrderResumeInfo = useCallback((specialProductInfo,index)=>{
        openSpecialProduct();
        setSpecialProductDescription(specialProductInfo.data.description);
        setSpecialDishAmount(specialProductInfo.quantity);
        setSpecialProductIndex(index);
    },[openSpecialProduct]);

    const toggleModifier = (modifiers, modifierId)=>
          _.includes(modifiers, modifierId)?
          _.filter(modifiers, m=>m!==modifierId):
          [...modifiers, modifierId];

    const modifyProduct = (product, modifierId)=>
          ({
              ...product,
              orozcoProductModifiers: toggleModifier(product.orozcoProductModifiers||[], modifierId)
          });

    const onModifierSelected = (modifierId, productIndex) => () =>
          setSelectedOrderProducts([
              ...selectedOrderProducts.slice(0, productIndex),
              modifyProduct(selectedOrderProducts[productIndex], modifierId),
              ...selectedOrderProducts.slice(productIndex+1)
          ]);

    return (
        <div className="WaiterMenu unselectable">
            <FixedMenu/>
            {specialProduct &&
                <SpecialProductModal
                isASpecialProductOnTheList={_.findIndex(selectedOrderProducts, selProd=>selProd.orozcoProduct.name==="Platillo especial")}
                onSave={handleProductSelect}
                onClose={closeSpecialProduct}
                onOpenKeyboard={toggleKeyBoard}
                value={specialProductDescription}
                onChange={handleSpecialProductInput}
                quantity={specialDishAmount}
                setQuantity={setSpecialDishAmount}
                />
            }
            {keyBoard &&
            <KeyBoardFull onHide={closeKeyboard}>
                <KeyBoardTouch
                    valueForInputs={getValueFromInput}
                    valueInInput={specialProductDescription}
                    onClose={closeKeyboard}
                />
            </KeyBoardFull>
            }
            <TopBarWaiter
                visit={visit}
                inClientOrder
                onChange={reload}
            />
            <div className="WaiterMenu-content">
                <div className="WaiterMenu-left">
                        <div className="WaiterMenu-name">
                            <button className='back-arrow-btn' onClick={history.goBack}><LeftArrow/></button>

                            {loadingVisit&&
                                <LoadingAnimation white/>}
                            {visit&&<>
                            <Link to={paths.frontDeskGuest
                                .replace(':visitId',visit.id)}>
                                    <Button>Detalle de visita</Button>
                                </Link>
                                <p className="WaiterMenu-visitGuestName">
                                    {guestFullName(visit.guest).toUpperCase()}
                                </p>
                            </>}
                            {notFound&&"-"}
                        </div>
                    {notFound?
                        <h1>Error. Visita no encontrada</h1>:
                        <OrozcoMenu
                            handleNewProductOrder={handleNewProductOrder}
                            handleProductSelect={handleProductSelect}
                            openSpecialProductModal={openSpecialProductModal}
                            selectedOrderProducts={selectedOrderProducts}
                            withSpecialProduct={security.canOrderSpecialProducts()}
                        />}
                </div>
                <div className="WaiterMenu-right">
                    <div className="WaiterMenu-orderResumyTitle">
                        <p>Resumen del Pedido</p>
                    </div>
                    <OrderResume
                        selectedOrderProducts={selectedOrderProducts}
                        serverOrderProducts={(visit && visit.orozcoVisitOrder &&visit.orozcoVisitOrder.orozcoVisitOrderProducts)||[]}
                        onSend={shouldBePay}
                        loading={sendingOrder}
                        onSelectedProductRemove={handleRemoveByIndex}
                        onToggleAskedByKid={handleToggleAskedByKid}
                        onToggleAskedByMainGuest={handleToggleAskedByMainGuest}
                        onAddCommentToDish={addCommentToDish}
                        onOrderProductsCancel={handleOrderProductsCancel}
                        onCheckOut={startCheckingOut}
                        visit={visit}
                        onSelectSpecial={openSpecialProductModalWithOrderResumeInfo}
                        onRePrint={onRePrintOrderProducts}
                        onModifierSelected={onModifierSelected}
                        commentText ={commentText}
                        onChangeCommentText ={setCommentText}
                    />
                </div>
            </div>

            {checkingOut &&
            <CheckOutModal
                onClose={stopCheckingOut}
                visit={visit}
                guest={visit.guest}
                checkIns={visit.checkIns}
                onFinish={history.goBack}
            />}
            {shouldBePayModal &&
                <Modal
                    title='Comanda con cargo extra'
                    onClose={closeShouldBePayModal}
                >
                    <h2>Esta comanda genera un cargo extra ¿Enviar de todos modos?</h2>
                    <div className='shouldPayModal-btn'>
                        <Button onClick={closeShouldBePayModal} blue className='half-width'>Cancelar</Button>
                        <Button onClick={sendOrder} disabled={sendingOrder} main solid className='half-width'>Enviar</Button>
                    </div>
                </Modal>
            }

        </div>
    )
};
WaiterMenu.diplayName='WaiterMenu';
export default WaiterMenu;
