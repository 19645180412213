import React,{useContext, useEffect, useState, useCallback, useMemo} from 'react';
import './NearReservations.scss';
import {ReactComponent as SearchIcon} from "../../../../assets/images/icons/Lupa-icon.svg";
import {ApiContext} from "../../../../services/api/api-config";
import useApiSelector from "../../../../hooks/useApiSelector";
import {
    getGuestName,
    nearReservationsApiConfig, REMOTE_RESERVATION_SOURCE,
    reservationStatus
} from "../../../../services/modelUtils/reservationUtils";
import moment from 'moment';
import _ from 'lodash';
import useCallbackCreator from "use-callback-creator/src";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faSyncAlt} from "@fortawesome/free-solid-svg-icons";

const NearReservations = ({onSelect})=>{

    const api=useContext(ApiContext);

    const [filter, setFilter]=useState('');

    const loadReservations=useCallback((filter)=>{
        api.reservations.get(nearReservationsApiConfig({filter}));
    },[api]);

    const loadReservationsDebounced=useCallback(_.debounce(loadReservations, 500),[loadReservations]);

    useEffect(()=>loadReservations(),[loadReservations]);

    const reservations=useApiSelector('reservations');

    const handleFilterChange=useCallback(({target})=>{
        setFilter(target.value);
        loadReservationsDebounced(target.value)
    }, [loadReservationsDebounced]);

    const localFilter=useMemo(()=>_.filter(reservations, res=>res.status===reservationStatus.REQUESTED),[reservations]);

    const handleReservationSelect=useCallbackCreator((index)=>{
        onSelect && onSelect(localFilter[index]);
    },[localFilter]);

    const refresh = useCallback(()=>{
        handleFilterChange({target:{value:''}});
    },[handleFilterChange]);

    return(
        <div className="NearReservations">
            <div className="NearReservations-header">
                <input
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder="Próximas Reservas"
                    className="NearReservations-header-input"
                />
                <SearchIcon />
            </div>
            <div className="NearReservations-content">
                {localFilter.length < 1 ?
                    <p className="LastEntries-noInfo">No hay entradas</p>
                    :
                    <ul className="NearReservations-list">
                        {
                            localFilter.map((reservation,i) => {
                                return (
                                    <li className="NearReservations-list-item" key={reservation.id} onClick={handleReservationSelect(i)}>
                                        <p>
                                            {reservation.source === REMOTE_RESERVATION_SOURCE && <FontAwesomeIcon icon={faStar} />}{' '}
                                            {_.startCase(getGuestName(reservation.guest? reservation.guest : reservation).toLowerCase())}
                                        </p>
                                        <p>{moment(reservation.reservationDate).format('HH:mm')}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
            </div>
            <button className='sync-button' onClick={refresh}><FontAwesomeIcon icon={faSyncAlt}/></button>
        </div>
    )
};
export default NearReservations;
