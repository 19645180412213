import React, {useContext, useMemo, useState} from 'react';
import {ReactComponent as LeftArrow} from "../../../../../assets/images/icons/leftArrow.svg";
import VisitCard from "../VisitCard/VisitCard";
import FullScreenEmployeeProfilePicker
    from "../../../../../components/FullScreenEmployeeProfilePicker/FullScreenEmployeeProfilePicker";
import {ApiContext} from "../../../../../services/api/api-config";
import {getNotifier} from "../../../../../services/notifierUtils";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {paths} from "../../../../../services/routes/appRoutes";
import _ from "lodash";
import WaiterList from "../WaiterList/WaiterList";
import {getProperty} from "../../../../../services/jsUtils";

export const SCOPE_ALL_VISITS = 'all';
export const SCOPE_UNASSIGNED_VISITS = 'unassigned';

const AttendingVisitList = ()=>{


    const api = useContext(ApiContext);
    const notifier = getNotifier();
    const history = useHistory();


    const me = useSelector(({api}) => api.me);
    const visits = useSelector(({api}) => api.allVisits || []);
    const [selectedVisitsScope, setSelectedVisitsScope] = useState((me.employee && me.employee.id) || SCOPE_UNASSIGNED_VISITS);

    const [selectingProfile, setSelectingProfile] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);

    const onVisitClick = (visit) => {
        setSelectedVisit(visit);
        if (visit.employeesAttendingVisit.length === 0)
            setSelectingProfile(true);
        else if(visit.seats.length === 0 &&
            (visit.checkIns?.[0]?.loungeAccessMethod?.id !== 'd811dafb-7450-11ea-8764-72d41b4cbb53' &&
            visit?.flight?.id !== 'VUELO_PLATILLOS_CLABORADORES')
        ){
            getNotifier().error("Primero debes asignarle un lugar en el mapa");
        }
        else {
            history.push(paths.visitMenu.replace(':visitId', visit.id));
        }
    };

    const onProfileSelected = (profile) => {
        api.employeeAttendingVisits.create({
            params: {
                visit: selectedVisit.id,
                employee: profile.employee.id
            }
        }).then(() => {
            notifier.warning("¡Da la bienvenida al huésped por su apellido y sonríe cálidamente");
            notifier.success("Anfitrión asignado");
            setSelectingProfile(false);
            setSelectedVisit(null);
        });
    };

    const showingVisits = useMemo(() => {
        switch (selectedVisitsScope) {
            case SCOPE_ALL_VISITS:
                return [...visits];
            case SCOPE_UNASSIGNED_VISITS:
                return _.filter(visits, (visit) => visit.employeesAttendingVisit.length === 0);
            default:
                return _.filter(visits, (visit) => getProperty(visit, 'employeesAttendingVisit', '0', 'employee', 'id')===selectedVisitsScope);
        }
    }, [selectedVisitsScope, visits]);

    return (
        <div className='AttendingVisitList'>
            <div className="AttendingVisits-content">
                <WaiterList
                    onSelectedWaiterChange={setSelectedVisitsScope}
                    selectedWaiter={selectedVisitsScope}
                    showScopes={true}
                />
                <div className="AttendingVisits-middle">
                    <div className="AttendingVisits-guest">
                        <button className='back-arrow-btn' onClick={history.goBack}><LeftArrow/></button>
                        <p>Huéspedes</p>
                    </div>
                    <div className="AttendingVisits-clients">

                        {!showingVisits.length &&
                        <h3 className='no-guests-msg'>Ningún huésped por aquí</h3>}

                        {showingVisits.map((visit, i) =>
                            <VisitCard
                                visit={visit}
                                onClick={onVisitClick}
                                showWaiterName={selectedVisitsScope === SCOPE_ALL_VISITS}
                                key={i}/>
                        )}
                    </div>
                </div>
            </div>

            {selectingProfile &&
            <FullScreenEmployeeProfilePicker
                onHide={() => setSelectingProfile(false)}
                onSelect={onProfileSelected}
                highlightMe={me}
                title={"Selecciona un anfitrión para asignarlo a " + selectedVisit.guest.name}
            />}
        </div>
    );
};
AttendingVisitList.displayName='AttendingVisitList';
export default AttendingVisitList;
