import React,{useState,useEffect,useMemo,useCallback,useContext} from 'react';
import './CheckInBreakDown.scss';
import Counter from "../../../../../../components/Counter/Counter";
import Button from "../../../../../../components/Button/Button";
import moment from "moment";
import useBoolean from "../../../../../../hooks/useBoolean";
import CheckInFormModal from "../CheckInFormModal/CheckInFormModal";
import {isPayedCheckIn, isCheckInActive, isCheckInExpired} from "../../../../../../services/modelUtils/checkInUtils";
import CheckOutModal from "../../CheckOutModal/CheckOutModal";
import {getNotifier} from "../../../../../../services/notifierUtils";
import {ApiContext} from "../../../../../../services/api/api-config";
import {useSelector} from "react-redux";
import LoadingAnimation from "../../../../../../components/LoadingAnimation/LoadingAnimation";
import CancelCheckInModal from "../../CancelCheckInModal/CancelCheckInModal";
import EditCheckInModal from './EditCheckInModal';
import CheckInsPaymentModal from '../../../../../../components/PaymentModals/CheckInsPaymentModal';
import _ from 'lodash';

const getExtraFields = (fields, newFields)=>{
    let r = _.clone(fields)||{};
    for(const fieldName in newFields){
        if(newFields[fieldName].companions){
            r[fieldName] = {
                ...(r[fieldName]?r[fieldName]:{}),
                companions: (
                    r[fieldName]?(r[fieldName].companions||[]):[]
                ).concat(newFields[fieldName].companions)
            };
        }
        if(newFields[fieldName].kids){
            r[fieldName] = {
                ...(r[fieldName]?r[fieldName]:{}),
                kids: (
                    r[fieldName]?(r[fieldName].kids||[]):[]
                ).concat(newFields[fieldName].kids)
            };
        }
    }
    return r;
};

const getNewCheckIns=(checkIn, checkIns, changedAdults, changedKids, newExtraFields={})=>{
    return _.map(checkIns, oldCheckIn=>{
        if(oldCheckIn.id===checkIn.id){
            let orozcoCheckInPayments;
            if(checkIn.orozcoCheckInPayments&&checkIn.orozcoCheckInPayments.length>0){
                orozcoCheckInPayments = [
                    ...oldCheckIn.orozcoCheckInPayments.map(({id})=>id),
                    ...checkIn.orozcoCheckInPayments
                ];
            }
            return {
                id:checkIn.id,
                activeAdults:changedAdults+1,
                activeKids:changedKids,
                adultsNumber:checkIn.adultsNumber<changedAdults?changedAdults:undefined,
                kidsNumber:checkIn.kidsNumber<changedKids?changedKids:undefined,
                ...!getExtraFields(checkIn.extraFields, newExtraFields)?{}:{
                    extraFields: getExtraFields(checkIn.extraFields, newExtraFields)
                },
                ...!!orozcoCheckInPayments?{orozcoCheckInPayments}:{}
            }
        };
        return oldCheckIn.id;
    });
};

const loadingCompanionsId='@CheckInBreakDown.changingCompanions';
const CheckInBreakDown = ({checkIn, guest, visit,checkInTiming, onChange}) => {
    const isActive=isCheckInActive(checkIn);
    const canEdit = isActive && !isCheckInExpired(checkIn);

    // ------- editing companions -----------------
    const [changedAdults, setChangedAdults]=useState(isActive?(checkIn.activeAdults-1):(checkIn.adultsNumber-1));
    useEffect(()=>{
        setChangedAdults(isActive?(checkIn.activeAdults-1):(checkIn.adultsNumber-1));
    },[checkIn, isActive]);

    const [changedKids, setChangedKids]=useState(isActive?checkIn.activeKids:checkIn.kidsNumber);
    useEffect(()=>{
        setChangedKids(isActive?checkIn.activeKids:checkIn.kidsNumber);
    },[checkIn, isActive]);

    const [editingCurrentCheckIn, editCurrentCheckIn, closeCheckInEdit] = useBoolean();

    // --------- Check out---------------
    const [checkingOut, startCheckingOut, stopCheckingOut]=useBoolean();
    const [cancelCheckIn, , stopCancelingCheckIn]=useBoolean();
    const wrapCheckIn=useMemo(()=>[checkIn],[checkIn]);

    // ------- Re check in -----------------
    const [reChecking, startReChecking, stopReChecking]=useBoolean();

    const handleCheckInChange=useCallback(()=>{
        onChange();
        stopReChecking();
        stopCheckingOut();
    },[onChange,stopReChecking, stopCheckingOut]);

    // ----------------------------------

    const [chargingCheckIns, setChargingCheckIns] = useState(null);
    const stopChargingCheckIns = useCallback(()=>setChargingCheckIns(null), []);

    const [extraFields, setExtraFields] = useState(null);

    // ---------- companions change ----------------
    const api=useContext(ApiContext);
    const adultsToAdd = Math.max((changedAdults+1)-checkIn.activeAdults, 0);
    const kidsToAdd = Math.max(changedKids-checkIn.activeKids, 0);
    const checkInHasExtraFields = checkIn.loungeAccessMethod.config?
          ((checkIn.loungeAccessMethod.config.extraFields||[]).length>0):false;

    const editCompanions = useCallback(newExtraFields => {
        if(isPayedCheckIn(checkIn)&&adultsToAdd>0){
            setExtraFields(newExtraFields);
            setChargingCheckIns([{...checkIn, adultsNumber: adultsToAdd}]);
            return Promise.resolve();
        }
        const newCheckIns = getNewCheckIns(checkIn, visit.checkIns, changedAdults, changedKids, newExtraFields);
         return api.visits.update({id: visit.id, params:{checkIns:newCheckIns}, loadingId:loadingCompanionsId})
            .then(()=>{
                onChange();
                getNotifier().success('Acompañantes actualizados.');
            });
    }, [adultsToAdd, onChange, api, checkIn, changedAdults, changedKids, visit]);

    const handleNewNumbers=useCallback(()=>{
        if((adultsToAdd>0 || kidsToAdd>0)&&checkInHasExtraFields){
            editCurrentCheckIn();
            return;
        }
        if(isPayedCheckIn(checkIn)&&adultsToAdd>0){
            setExtraFields(null);
            setChargingCheckIns([{...checkIn, adultsNumber: adultsToAdd}]);
            return;
        }
        const newCheckIns = getNewCheckIns(checkIn, visit.checkIns, changedAdults, changedKids);
        api.visits.update({id: visit.id, params:{checkIns:newCheckIns}, loadingId:loadingCompanionsId})
            .then(()=>{
                onChange();
                getNotifier().success('Acompañantes actualizados.');
            })
    },[api, checkInHasExtraFields, editCurrentCheckIn, adultsToAdd, kidsToAdd, changedAdults, changedKids, checkIn, onChange, visit]);

    const loadingCompanions=useSelector(({loadingIds})=>!!loadingIds[loadingCompanionsId]);

    const handleSuccessfulPayment = useCallback((checkIns)=>{
        const checkIn = checkIns[0];
        const newCheckIns = getNewCheckIns(checkIn, visit.checkIns, changedAdults, changedKids, extraFields);
        api.visits.update({id: visit.id, params:{checkIns:newCheckIns}, loadingId:loadingCompanionsId})
           .then(()=>{
               onChange();
               getNotifier().success('Acompañantes actualizados.');
           })
    }, [extraFields, api, onChange, changedKids, changedAdults, visit]);

    return (
        <div className="CheckInBreakDown animate fadeInDownBig">
            <div className="CheckInBreakDown-timeWrapper">
                {checkInTiming <= .125 &&
                <div className="CheckInBreakDown-timeCheckIn">
                    <p className="CheckInBreakDown-title">Check In</p>
                    <p className="CheckInBreakDown-title-text">{moment(checkIn.createdDate).format('HH:mm')}</p>

                        {(isCheckInActive(checkIn) && !checkIn.checkOutDate && !!checkIn.expirationDate) &&
                        <Button onClick={startReChecking}>Re-check in</Button>}

                </div>
                }
                <div className="CheckInBreakDown-timeCheckOut">
                    {!!(!checkIn.checkOutDate && checkIn.expirationDate) &&
                    <>
                        <p className="CheckInBreakDown-title">Expiración</p>
                        <p className="CheckInBreakDown-title-text">{moment(checkIn.expirationDate).format('HH:mm')}</p>
                    </>}
                    {!!(!checkIn.checkOutDate && !checkIn.expirationDate) &&
                    <>
                        <p className="CheckInBreakDown-title">Expiración</p>
                        <p className="CheckInBreakDown-title-text">Ilimitado</p>
                    </>}
                    {checkIn.checkOutDate &&
                    <>
                        <p className="CheckInBreakDown-title">Check Out</p>
                        <p className="CheckInBreakDown-title-text">{moment(checkIn.checkOutDate).format('HH:mm')}</p>
                    </>}

                    {isActive && !checkIn.checkOutDate &&
                    <Button onClick={startCheckingOut}>Check out</Button>}
                </div>
            </div>
            <div className="CheckInBreakDown-companionsController">
                <div className="CheckInBreakDown-companions">
                    <p>Acompañantes</p>
                </div>
                <Counter
                    disabled={!canEdit}
                    valueTotal={checkIn.adultsNumber-1}
                    value={changedAdults}
                    onChange={setChangedAdults}
                    maxValue={checkIn.loungeAccessMethod.maxAdults}
                />
                {canEdit && changedAdults!==checkIn.activeAdults-1 &&
                <Button onClick={handleNewNumbers} disabled={loadingCompanions}>{loadingCompanions?<LoadingAnimation/>:'Guardar'}</Button>}
            </div>

            <div className="CheckInBreakDown-companionsController">
                <div className="CheckInBreakDown-companions">
                    <p>Niños</p>
                </div>
                <Counter
                    disabled={!canEdit}
                    valueTotal={checkIn.kidsNumber}
                    value={changedKids}
                    onChange={setChangedKids}
                    maxValue={checkIn.loungeAccessMethod.maxKids}
                />
                {canEdit && changedKids!==checkIn.activeKids &&
                <Button onClick={handleNewNumbers} disabled={loadingCompanions}>{loadingCompanions?<LoadingAnimation/>:'Guardar'}</Button>}
            </div>
            {checkIn?.folio && <p className="folio">Folio: {checkIn.folio}</p>}

            {reChecking &&
                <CheckInFormModal
                    onClose={stopReChecking}
                    guest={guest}
                    visit={visit}
                    oldCheckIn={checkIn}
                    onFinish={handleCheckInChange}
                />}

            {chargingCheckIns &&
             <CheckInsPaymentModal
                 checkIns={chargingCheckIns}
                 onClose={stopChargingCheckIns}
                 guest={guest}
                 onPay={handleSuccessfulPayment}
             />}

            {checkingOut &&
                <CheckOutModal
                    onClose={stopCheckingOut}
                    guest={guest}
                    visit={visit}
                    checkIns={wrapCheckIn}
                    onFinish={handleCheckInChange}
                />
            }
            {cancelCheckIn &&
                <CancelCheckInModal
                    onClose={stopCancelingCheckIn}
                />
            }
            {editingCurrentCheckIn&&
                <EditCheckInModal
                    onClose={closeCheckInEdit}
                    onSave={editCompanions}
                    checkIn={checkIn}
                    adultsToAdd={adultsToAdd}
                    kidsToAdd={kidsToAdd}
                />
            }
        </div>
    );
};

export default CheckInBreakDown;
