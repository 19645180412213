import {pad} from "./numberUtils";

export const defaultSessionTime = 60000;

export const serverDateFormat='YYYY-MM-DD';

export const serverDateTimeFormat='YYYY-MM-DDTHH:mm';

export const displayDateFormat='DD/MM/YYYY';
export const displayDateTimeFormat='DD/MM/YYYY HH:mm';

export const minutesToTimeString=(minutes)=>{
    if(!minutes)
        return '00:00 AM';

    let hours=Math.floor(minutes/60);
    let amPm='AM';
    if(hours>=12){
        amPm='PM';
        if(hours>12)
        hours-=12;
    }
    let mins=minutes%60;

    return `${pad(hours)}:${pad(mins)} ${amPm}`
};
