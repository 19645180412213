import {version} from '../../pckg';
export const LATEST_APP_VERSION_REDUCER='LATEST_APP_VERSION_REDUCER';

export const latestAppVersionAction=(appVersion)=>{
    return {
        type: LATEST_APP_VERSION_REDUCER,
        payload:appVersion
    }
};

export default (state=version, action)=>{
    if(action.type===LATEST_APP_VERSION_REDUCER)
        return action.payload;
    return state;
}
