import React, {useContext, useCallback} from 'react';
import useBoolean from '../../../../hooks/useBoolean';
import './TopBarGuestTable.scss';
import {ReactComponent as UserIcon} from "../../../../assets/images/icons/wedding-reception.svg";
import {employeeFullName} from "../../../../services/modelUtils/employeeUtils";
import FullScreenEmployeeProfilePicker from "../../../FullScreenEmployeeProfilePicker/FullScreenEmployeeProfilePicker";
import {ApiContext} from "../../../../services/api/api-config";
import {menuScreenSGroups} from "../../../../services/modelUtils/visitUtils";
import FullScreenTablePicker from "../../../FullScreenTablePicker/FullScreenTablePicker";

const TopBarGuestTable = ({visit, onChange}) => {

    const [selectingProfile, setSelectingProfileTrue, setSelectingProfileFalse] = useBoolean(false);
    const [selectingTable, setSelectingTableTrue, setSelectingTableFalse] = useBoolean(false);
    const api = useContext(ApiContext);

    const updateVisit = useCallback((serverData)=>{
        api.visits.update({
            id: visit.id,
            params: serverData,
            customProp: 'visit@WaiterMenu'
        });
    }, [visit, api]);

    const onProfileSelected = useCallback((profile) => {
        api.employeeAttendingVisits.create({
            params: {
                visit: visit.id,
                employee: profile.employee.id
            }
        }).then(onChange);
        setSelectingProfileFalse();
    }, [api, onChange, visit, setSelectingProfileFalse]);

    const onTableSelected = useCallback((table)=>{
        const serverData = {
            tables: [table.id],
            sGroups: menuScreenSGroups
        };
        updateVisit(serverData);
        setSelectingTableFalse();
    },[setSelectingTableFalse, updateVisit]);

    return (
        <div className="TopBarGuestTable">
            <div className="TopBarGuestTable-name" onClick={setSelectingProfileTrue}>

                <UserIcon/>
                <p>{(visit && visit.employeesAttendingVisit[0])?employeeFullName(  visit.employeesAttendingVisit[0].employee) : 'Sin anfitrión'}</p>
            </div>
            <div className="TopBarGuestTable-table" onClick={setSelectingTableTrue}>
                <p>{visit && visit.tables && visit.tables.length > 0 ? `Mesa ${visit.tables[0].name}` : 'Sin mesa'}</p>
            </div>

            {selectingProfile &&
                <FullScreenEmployeeProfilePicker
                    onHide={setSelectingProfileFalse}
                    onSelect={onProfileSelected}
                    title={"Selecciona un anfitrión para asignarlo a " + ((visit&&visit.guest)?visit.guest.name:'')}
                />
            }
            {selectingTable &&
                <FullScreenTablePicker
                    onHide={setSelectingTableFalse}
                    onSelect={onTableSelected}
                    title={"Selecciona una mesa para asignarla a " + ((visit&&visit.guest)?visit.guest.name:'')}
                />
            }

        </div>
    )
};
export default TopBarGuestTable;
