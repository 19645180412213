import React,{useCallback} from 'react';
import className from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import MenuAmountSelect from "../MenuFoodCard/MenuAmountSelect/MenuAmountSelect";
import SpecialFoodImg from '../../../../../assets/images/specialFood.jpg';

const SpecialMenuCard = ({onSelect, quantity}) => {

    const image = {backgroundImage:`url(${SpecialFoodImg})`};

    const handleSelect=useCallback(()=>{
        onSelect();
    },[onSelect,]);

   /* const handleRemove=useCallback((e)=>{
        e.stopPropagation();
        onRemove(product);
    },[onRemove, product]);*/
    return (
        <div onClick={handleSelect} className="SpecialMenuCard MenuFoodCard" style={image}>
{/*
            {isPricedProduct(product)&&
            <span className={className('money-sign', !isPricedProductButCanBeFree(product)?'red':'')}>
                <FontAwesomeIcon icon={faDollarSign} />
            </span>}*/}

            <p>Platillo Especial</p>
            <MenuAmountSelect quantity={quantity}/>

            <button className={className("remove-button",quantity?'open':'')}>
                <FontAwesomeIcon icon={faTimes} />
            </button>

        </div>
    )
};
export default SpecialMenuCard