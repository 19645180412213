import {createContext} from 'react';
import _ from 'lodash';


export default class SecurityManager{

    constructor(me){

        if( !me )
            throw new Error("The Security object requires an user to be initialized");

        this.me = me;
        this.position = me.employee && me.employee.position && me.employee.position.code? me.employee.position.code:'';
        this.role = (me.role && me.role.name) ? me.role.name:'';
        this.permissions = me.permissionsArray||[];
    }

    havePermission($permission){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.includes(this.permissions, $permission);
    }

    haveAllPermissions($permissions){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.reduce($permissions, (haveAll, permission)=>haveAll&&this.havePermission(permission), true);
    }

    haveAtLeastOnePermission($permissions){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.intersection(this.permissions, $permissions).length>0
    }

    isActualUser(user){
        return !!(user && user.id===this.me.id )
    }

    isActualEmployee(employee){
        return !!(employee && this.me.employee && employee.id===this.me.employee.id )
    }

    canUseRemoteDavinci= ()=>this.havePermission('REMOTE_DAVINCI');

    canSeeFrontDesk= ()=>this.haveAllPermissions(['VISIT_CREATE','GUEST_CREATE']);

    canSeeOrozco= ()=>this.haveAllPermissions(['OROZCO_VISIT_ORDER_CREATE','OROZCO_PRODUCT_LIST','CHECKIN_LIST','VISIT_LIST']);

    canAdminPrinters= ()=>this.havePermission('TICKET_PRINTER_ASSIGN');
    canAdminCashRegisters= ()=>this.havePermission('OROZCO_CASH_REGISTER_ASSIGN');
    canAdminSessionTimeout= ()=>this.havePermission('DAVINCI_SESSION_TIMEOUT_CONFIG');

    canCancelCheckIn = ()=>this.havePermission('CHECKIN_CANCEL');

    canCloseCashRegister = ()=>this.havePermission('OROZCO_CASH_CLOSING_CREATE');

    canUpdateGuestNonGrata = ()=>this.havePermission('GUEST_UPDATE_NON_GRATA');

    canCreateAndUpdateExtraCharges = ()=>this.havePermission('OROZCO_VISIT_ORDER_PRODUCT_EXTRA_CHARGE_CREATE');
    canOrderSpecialProducts = ()=>this.havePermission('OROZCO_VISIT_ORDER_PRODUCT_CREATE_SPECIAL');

    canCancelVisitOrderProducts = ()=>this.havePermission('OROZCO_VISIT_ORDER_PRODUCT_CANCEL');

    canListOrozcoPrints = ()=>this.havePermission('OROZCO_PRINT_LIST');

    canListReservations = ()=>this.havePermission('RESERVATION_LIST');

}

export const SecurityContext = createContext(null);
