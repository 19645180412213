import React,{useContext,useEffect,useState} from 'react';
import './LastEntries.scss';
import {ReactComponent as SearchIcon} from "../../../../assets/images/icons/Lupa-icon.svg";
import {Link} from "react-router-dom";
import {ApiContext} from "../../../../services/api/api-config";
import {useSelector} from "react-redux";
import {filterActiveVisits, filterVisitsByGuestFullName, lastEntriesSGroups} from "../../../../services/modelUtils/visitUtils";
import {guestFullName} from "../../../../services/modelUtils/guestUtils";
import moment from 'moment';
import useInput from "../../../../hooks/useInput";
import {paths} from "../../../../services/routes/appRoutes";
import useActualLounge from "../../../../hooks/useActualLounge";


const emptyArr=[];
const LastEntries = ()=>{

    const api=useContext(ApiContext);

    const actualLounge=useActualLounge();

    useEffect(()=>{
        if(!actualLounge)
            return;
        api.visits.get({params:{
                sGroups:lastEntriesSGroups,
                'order[createdDate]':'DESC',
                itemsPerPage:110,
                lounge: actualLounge.id,
            }, customProp:'lastEntriesVisits'});
    },[api, actualLounge]);

    const visits=useSelector(({api})=>api.lastEntriesVisits||emptyArr);

    const [filteredVisits, setFilteredVisits]=useState(visits);

    const filterIn=useInput('');

    useEffect(()=>{
        const activeVisits = filterActiveVisits(visits);
        if(filterIn.value.trim())
            setFilteredVisits(
                filterVisitsByGuestFullName(activeVisits, filterIn.value));
        else
            setFilteredVisits(activeVisits);
        }, [filterIn.value, visits]);

    return(
        <div className="LastEntries">
            <div className="LastEntries-header">
                <input
                    placeholder="Últimas Entradas"
                    className="LastEntries-header-input full-width"
                    {...filterIn.bind}
                />
                <SearchIcon/>
            </div>
            <div className="LastEntries-content">
                {visits.length < 1 ?
                    <p className="LastEntries-noInfo">No hay entradas</p>
            :
                <ul className="LastEntries-list">
                {
                    filteredVisits.map((visit)=>{
                        return(
                            <Link to={paths.frontDeskGuest
                                .replace(':visitId',visit.id)}
                                  key={visit.id}>
                                <li className="LastEntries-list-item">
                                    <p>{guestFullName(visit.guest).toUpperCase()}</p>
                                    <div className="LastEntries-list-item-timeAndVisit">
                                        <p>{moment(visit.firstCheckInDate).format('HH:mm')}</p>
                                        <p>{visit.activePersons}</p>
                                    </div>
                                </li>
                            </Link>
                        )
                    })
                }
                </ul>
                }
            </div>
        </div>
    )
};
export default LastEntries;
