import React,{useState,useEffect,useContext,useCallback} from 'react';
import './FrontDesk.scss';
import TopBar from '../../components/TopBar/TopBar';
import NewReservation from "./Components/NewReservation/NewReservation";
import LastEntries from "./Components/LastEntries/LastEntries";
import NearReservations from "./Components/NearReservations/NearReservations";
import NearToExpire from "./Components/NearToExpire/NearToExpire";
import SimilarResults from "./Components/SimilarResults/SimilarResults";
import {ApiContext} from "../../services/api/api-config";
import {getNotifier} from "../../services/notifierUtils";
import {guestCheckInSGroups} from "../../services/modelUtils/guestUtils";
import FixedMenu from "../../components/FixedMenu/FixedMenu";
import Button from "../../components/Button/Button";

const FrontDesk=()=>{

    const [search, setSearch]=useState(null);//Filters to search similar guests
    const [selectedGuest, setSelectedGuest]=useState(null);//A similar guest was selected
    const [fullGuest, setFullGuest]=useState(null);//After the selection we fetch the complete data of the guest
    const [startCheckin, setStartCheckin] = useState(null);

    const api=useContext(ApiContext);

    const reloadSelectedGuest = useCallback(() => {
        if(selectedGuest)
            api.guests.get({params:{sGroups:guestCheckInSGroups ,id:selectedGuest.id}, customProp:'FrontDeskGuest'})
                .then(guests=>{
                    if(guests.length)
                        setFullGuest(guests[0]);
                    else
                        getNotifier().error('Error al cargar información de huésped. ID');
                });
        else
            setFullGuest(null);
    }, [api, selectedGuest]);

    useEffect(reloadSelectedGuest,[reloadSelectedGuest]);

    const [selectedReservation, setSelectedReservation]=useState(null);
    const reservationChange=useCallback((reservation)=>{
        setSelectedReservation(reservation);
        if(reservation.guest){
            setSelectedGuest(reservation.guest);
        }
    },[]);

    const clearState=useCallback(()=>{
        setFullGuest(null);
        setSelectedReservation(null);
        setSearch(null);
    },[]);

    return (
        <div className='FrontDesk'>
            <FixedMenu className='frontDesk'/>
            <TopBar/>
            <div className="FrontDesk-content">
                <div className="FrontDesk-left">
                    <SimilarResults
                        search={search}
                        onGuestSelect={setSelectedGuest}
                        selectedGuest={fullGuest}
                        reloadSelectedGuest={reloadSelectedGuest}
                    />
                </div>

                {!startCheckin &&
                    <div className="start-button-container">
                        <Button className='clear-btn solid' onClick={() => setStartCheckin(true)}>Comenzar registro</Button>
                    </div>}

                <NewReservation
                    onSearchChange={setSearch}
                    searching={search}
                    guest={fullGuest}
                    reservation={selectedReservation}
                    onClearState={clearState}
                    onFinishCapture={() => setStartCheckin(false)}
                    startCheckin={startCheckin}
                />

                <div className="FrontDesk-right">
                    <LastEntries/>
                    <NearReservations
                        onSelect={reservationChange}
                    />
                    <NearToExpire/>
                </div>
            </div>
        </div>
    )
};

FrontDesk.displayName = 'FrontDesk';

export default FrontDesk;
