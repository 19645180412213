import React, {useCallback, useContext, useLayoutEffect, useRef, useState} from 'react';
import _ from "lodash";
import './_MrzScanner.scss';
import Croppie from 'croppie';
import Button from "../../../Button/Button";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getNotifier} from "../../../../services/notifierUtils";
import {CROPPIE_CONFIG, MRZ_TYPES, mzrToCheckIn, recognizeMzrInImage} from "../../../../services/mrzUtils";
import Select from "react-select";
import reactSelectStyles from "../../../../assets/styles/react-select-styles";
import {getPngFromVideoElement, getPreferredCamera, savePreferredCamera} from "../../../../services/cameraUtils";
import {ApiContext} from "../../../../services/api/api-config";
import Lottie from "lottie-react";
import scanAnimation from '../../assets/scan-anim-17265.json';
import useBoolean from "../../../../hooks/useBoolean";
import RadioSelector from "../../../RadioSelector/RadioSelector";

const options = [
    { value:MRZ_TYPES.PASSPORT, label:'Pasaporte' },
    { value:MRZ_TYPES.IDENTIFICATION, label:'Identificación' },
]

const MrzScanner = ({ onScan }) => {

    const api = useContext(ApiContext);

    const videoElement = useRef(null);
    const streamRef = useRef(null);
    const [photoWithMrz, setPhotoWithMrz] = useState(null);
    const [croppie, setCroppie] = useState(null);
    const croppieContainer = useRef(null);
    const [loading, startLoading, stopLoading] = useBoolean();

    const [mrzType, setMrzType] = useState(options[0].value);
    const [videoDevices, setVideoDevices ] = useState([]);
    const [selectedDevice, setSelectedDevice ] = useState([]);

    // ----- Show the video feed os the device id in the video html element
    const getStreamFromDevice = useCallback((deviceId)=>{
        if (navigator.mediaDevices?.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { deviceId, width: {exact: 1920}, height: {exact: 1080} } })
                .then(function (stream) {
                    videoElement.current.srcObject = stream;
                    streamRef.current = stream;
                })
                .catch(function () {
                    getNotifier().error('No se puede establecer la conexión con la cámara.');
                });
        }
        else{
            getNotifier().error("Tu navegador no soporta el control avanzado de la cámara.");
        }
    },[]);

    // ----- Initialize stream and cameras list
    useLayoutEffect(()=>{

        getStreamFromDevice( getPreferredCamera() );

        if( navigator?.mediaDevices?.enumerateDevices )
            navigator.mediaDevices.enumerateDevices()
                .then(devices=>setVideoDevices(devices.filter(d=>d.kind==='videoinput') ));

        return ()=>_.forEach(streamRef.current?.getTracks(), track=>track.stop());
    },[getStreamFromDevice]);

    // ----- Initialize stream and cameras list

    const handleCameraSelect = useCallback(( device )=>{
        savePreferredCamera( device?.deviceId );
        getStreamFromDevice( device?.deviceId );
        setSelectedDevice(device);
    },[getStreamFromDevice]);

    // Function to take photo //
    const handleTakePhoto = () => {
        const data = getPngFromVideoElement( videoElement.current );
        setPhotoWithMrz( data);
        const croppieConfig = CROPPIE_CONFIG[ mrzType ];
        const croppie = new Croppie(croppieContainer.current, croppieConfig);
        croppie.bind({url: data, points: croppieConfig.points });
        setCroppie(croppie);
    };

    // Clear data to repeat the photo
    const repeatPhoto = useCallback(()=>{
        setCroppie(croppie=>{
            croppie && croppie.destroy();
            return null;
        });
        setPhotoWithMrz(null);
    },[]);

    // Function to scan mrz //
    const handleMRZScan = useCallback(() => {

        startLoading();
        croppie.result({size:'viewport'})//Get cropped img from croppie
            .then(croppedImg=>recognizeMzrInImage(croppedImg, mrzType))
            .then( result=>{
                console.log(result);
                console.log(result.lines.join("\n"));
                if(!result.valid){
                    getNotifier().error("Error leyendo documento. Asegurate que está derecho y no tiene ningún reflejo");
                    repeatPhoto();
                    stopLoading();
                    return;
                }
                mzrToCheckIn(result.data, api)
                    .then( check=>{
                        onScan(check);
                    })
                    .catch(e=>{
                        getNotifier().error("Error al convertir la información en un check in. Error : "+e.message);
                        stopLoading();
                    })
            } )
            .catch((e) => {
                repeatPhoto();
                stopLoading();
                console.log(e);
                return getNotifier().error('No se pudo identificar el documento.');
            })
    },[croppie, mrzType, api, repeatPhoto, onScan, startLoading, stopLoading]);

    return (
        <div className={"MrzScanner"}>

            <Select
                styles={reactSelectStyles}
                options={videoDevices}
                placeholder={videoDevices.length?"Selecciona una cámara":"No hay cámaras disponibles"}
                isSearchable={false}
                value={selectedDevice}
                onChange={handleCameraSelect}
            />

            <div className="MrzScanner-videoContainer" >
                <video autoPlay={true} id="videoElement" width="758" ref={videoElement} style={{ display: !photoWithMrz?"block":"none" }}/>
                {loading && <div className='loading-overlay' >
                    <Lottie animationData={scanAnimation} className={"loading-anim"} />
                </div>}
                <div ref={croppieContainer} style={{ display: photoWithMrz?"block":"none" }}/>
            </div>
            <div className="MrzScanner-accept-btn-container">
                <Button disabled={!photoWithMrz || loading} onClick={repeatPhoto}>
                    <FontAwesomeIcon icon={faUndo} className='repeat-btn'/>
                </Button>
                <Button solid onClick={photoWithMrz?handleMRZScan:handleTakePhoto} disabled={loading}>
                    {photoWithMrz?"Confirmar":"Tomar foto"}
                </Button>
            </div>
            <div>
                <RadioSelector options={options} onChange={setMrzType} value={mrzType}/>
            </div>

        </div>
    );
};

export default MrzScanner;
