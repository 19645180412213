import {useEffect} from 'react';

export default (callback)=>{
    useEffect(()=>{
        const listener= e=>{
            if(e.key==='Escape')callback();
        };
        document.body.addEventListener('keydown',listener);
        return ()=>{
            document.body.removeEventListener('keydown',listener);
        }
    },[callback]);
}
