import React, {useCallback, useState} from 'react';
import Modal from "../../../../../../components/Modal/Modal";
import Button from "../../../../../../components/Button/Button";
import ExtraField from '../../../../../FrontDesk/Components/CheckInsForm/ExtraField';
import LoadingAnimation from '../../../../../../components/LoadingAnimation/LoadingAnimation';
import '../../../../../FrontDesk/Components/CheckInsForm/SingleCheckInForm.scss';
import _ from 'lodash/fp';

const addFields = (adultsToAdd, kidsToAdd)=>field=>({
    ...(field.onePerCompanion&&adultsToAdd>0?{companions: [...new Array(adultsToAdd)].map(v=>'')}:{}),
    ...(field.onePerChild&&kidsToAdd>0?{kids: [...new Array(kidsToAdd)].map(v=>'')}:{})
});

const getNonEmpty = x => {
    let v = {};
    for(let k in x){
        if(!_.isEmpty(x[k])){
            v[k] = x[k];
        }
    }
    return v;
};

const getInitialFields = (adultsToAdd, kidsToAdd) => _.flow(
    _.filter(v=>v.onePerChild||v.onePerCompanion),
    _.keyBy('name'),
    _.mapValues(addFields(adultsToAdd, kidsToAdd)),
    getNonEmpty
);

const EditCheckInModal = ({onClose, onSave, checkIn, adultsToAdd, kidsToAdd}) =>{
    const [extraFieldsState, setExtraFieldsState] = useState(
        getInitialFields(adultsToAdd, kidsToAdd)(checkIn.loungeAccessMethod.config.extraFields));
    const [loading, setLoading] = useState(false);
    //const [extraCheckInsInfo, setExtraCheckInsInfo] = useState(getInitialCheckInsInfo(adultsToAdd));
    const extraFields = checkIn.loungeAccessMethod.config.extraFields;
    const adultFields = _.filter('onePerCompanion', extraFields);
    const kidFields = _.filter('onePerChild', extraFields);
    const adultsArray = [...new Array(adultsToAdd)].map((i, k)=>k);
    const kidsArray = [...new Array(kidsToAdd)].map((i, k)=>k);

    const editCheckIn = useCallback(()=>{
        setLoading(true);
        onSave(extraFieldsState).then(()=>onClose()).finally(()=>setLoading(false));
    }, [onSave, extraFieldsState, onClose]);
    const setField = useCallback((type, fieldName, index, value) => {
        setExtraFieldsState((xs)=>{
            const edited = _.clone(xs);
            edited[fieldName][type][index] = value;
            return edited;
        });
    }, []);
    return (
        <Modal
            onClose={onClose}
            title={'Editar acompañantes del check in'}
            className='EditCheckInModal CheckInFormModal SingleCheckInForm'
        >
            
            {adultsArray.map(i=>
                             _.chunk(2, adultFields).map((row, rowIndex)=>(
                                 <div className='form-row' key={'adult-'+rowIndex}>
                                     {row.map((extraField, fieldIndex)=>
                                              <div className='form-column half-width' key={fieldIndex}>
                                                  <ExtraField
                                                      title={extraField.name+` - acompañante ${i+1}`}
                                                      onChange={e=>setField('companions', extraField.name, i, e.target.value)}
                                                      value={extraFieldsState[extraField.name].companions[i]}
                                                      extraField={extraField}
                                                  />
                                              </div>)}
                                 </div>
                             )))}
            {kidsArray.map(i=>
                             _.chunk(2, kidFields).map((row, rowIndex)=>(
                                 <div className='form-row' key={'kid-'+rowIndex}>
                                     {row.map((extraField, fieldIndex)=>
                                              <div className='form-column half-width' key={fieldIndex}>
                                                  <ExtraField
                                                      title={extraField.name+` - niño ${i+1}`}
                                                      onChange={e=>setField('kids', extraField.name, i, e.target.value)}
                                                      value={extraFieldsState[extraField.name].kids[i]}
                                                      extraField={extraField}
                                                  />
                                              </div>)}
                                 </div>
                             )))}
            <Button onClick={onClose}>Cancelar</Button>
            <Button blue onClick={editCheckIn} disabled={loading}>{loading?<LoadingAnimation/>:'Guardar'}</Button>
        </Modal>

    );
};

export default EditCheckInModal;
