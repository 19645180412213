import React, {useMemo,useCallback} from 'react';
import reactSelectStyles from "../../assets/styles/react-select-styles";
import Select from "react-select";
import './CurrencySelector.scss';
import {iconForCurrency, nameForCurrency} from "../../services/currency/currencyUtils";
import _ from 'lodash';
import classNames from 'classnames';


const CurrencySelector = ({availableCurrencies=['MXN'], value, onChange, className, placeholder='Divisa'})=>{



    const currenciesArr = useMemo( ()=>availableCurrencies.map((currency)=>({
        icon: iconForCurrency(currency),
        name: nameForCurrency(currency),
        currency
    })), [availableCurrencies]);

    const handleChange=useCallback((newValue)=>{
        onChange(newValue.currency);
    },[onChange]);

    const selected=useMemo(()=>_.find(currenciesArr, obj=>obj.currency===value),[currenciesArr, value]);

    return (
        <Select
            className={classNames("CurrencySelector", className)}
            styles={reactSelectStyles}
            options={currenciesArr}
            formatOptionLabel={ExchangeLabel}
            placeholder={placeholder}
            isSearchable={false}
            value={selected}
            onChange={handleChange}
        />
    );
};

export default CurrencySelector;


const ExchangeLabel = ({icon,name})=>(
    <div className="ExchangeLabel">
        <p><span className='flag'>{icon}</span> {name}</p>
    </div>
);
