import React, {useContext, useEffect} from 'react';
import './NumberOfVisits.scss';
import {ApiContext} from "../../../../../services/api/api-config";
import {useSelector} from "react-redux";

const NumberOfVisits = ({guestId})=>{

    const api = useContext(ApiContext);

    useEffect(()=>{
        api.visits.get({ params:{ itemsPerPage:0, guest: guestId }, customProp:'NumberOfVisits' });
    },[api, guestId]);

    const number = useSelector( s=>s.api?.NumberOfVisits?.meta?.totalItems  )||"1";

    return(
        <div className="NumberOfVisits">
            <p className="NumberOfVisits-title">Visitas</p>
            <p className="NumberOfVisits-text">{number}</p>
        </div>
    )
};
export default NumberOfVisits;
