import  React,{useContext,useEffect,useState,useCallback} from 'react';
import './NearToExpire.scss';
import Button from "../../../../components/Button/Button";
import {ReactComponent as SearchIcon} from "../../../../assets/images/icons/Lupa-icon.svg";
import {ApiContext} from "../../../../services/api/api-config";
import {nearToExpireSGroups} from "../../../../services/modelUtils/checkInUtils";
import {LoungeContext} from "../../../../services/modelUtils/loungeUtils";
import {useSelector} from "react-redux";
import {guestFullName} from "../../../../services/modelUtils/guestUtils";
import moment from 'moment';
import {serverDateTimeFormat} from "../../../../services/timeUtils";
import {Link} from "react-router-dom";
import {paths} from "../../../../services/routes/appRoutes";
import CheckInFormModal
    from "../../../FrontDeskGuest/Components/VisitInfo/CheckInInfo/CheckInFormModal/CheckInFormModal";
import {getNotifier} from "../../../../services/notifierUtils";
import _ from 'lodash';
import { visitFullSGroups} from "../../../../services/modelUtils/visitUtils";
import useInput from "../../../../hooks/useInput";

const loadingId='@NearToExpire.guest.full';
const emptyArr=[];
const NearToExpire = ()=>{

    const api=useContext(ApiContext);
    const actualLounge=useContext(LoungeContext);

    const loadChecks=useCallback(()=>{
        if(!actualLounge)
            return;
        api.checkIns.get({params:{
                sGroups:nearToExpireSGroups,
                'checkOutDate[exists]':false,
                'expirationDate[exists]':true,
                'order[expirationDate]':'ASC',
                'expirationDate[before]':moment().add(15, 'minutes').format(serverDateTimeFormat),
                'visit.lounge.id':actualLounge.id,
            },
            customProp:'NearToExpire.checkIns'
        },[actualLounge])
    },[api, actualLounge]);

    useEffect(loadChecks, [loadChecks]);

    const checkIns=useSelector(({api})=>api['NearToExpire.checkIns']||emptyArr);

    // -------- Handle re check in ----------------

    const [reChecking, setReChecking]=useState(null);
    const stopReChecking=useCallback(()=>setReChecking(null),[]);

    const handleReCheckInClick=useCallback((e)=>{
        const visitId=e.target.dataset.visit;
        const checkId=e.target.dataset.check;

        api.visits.get({params: {id:visitId, sGroups: visitFullSGroups}, customProp: 'NearToExpire.visit.reCheckIn', loadingId})
            .then(([visit])=>{
                if(!visit)
                    return getNotifier().error('Visita no encontrada');
                const checkIn=_.find(visit.checkIns, checkIn=>checkIn.id===checkId);
                if(!checkIn)
                    return getNotifier().error('Check in no encontrado');
                setReChecking({visit, checkIn:checkIn});
            });
    },[api]);

    const finishedReChecking=useCallback(()=>{
        loadChecks();
        stopReChecking();
    },[loadChecks, stopReChecking]);

    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    // ---------- Local filter -----------


    const [filteredCheckIns, setFilteredCheckIns]=useState(checkIns);

    const filterIn=useInput('');

    useEffect(()=>{
        if(filterIn.value.trim())
            setFilteredCheckIns( _.filter(checkIns, checkIn=>guestFullName(checkIn.visit.guest).toLowerCase().indexOf(filterIn.value.trim().toLowerCase())!==-1) );
        else
            setFilteredCheckIns(checkIns)
    }, [filterIn.value, checkIns]);


    return(
        <div className="NearToExpire">
                <div className="NearToExpire-header">
                    <input
                        {...filterIn.bind}
                        placeholder="Próximas a Expirar"
                        className="NearToExpire-header-input"/>
                        <SearchIcon/>
                </div>
                <div className="NearToExpire-content">
                    {checkIns.length < 1 ?
                        <p className="NearToExpire-noInfo">No hay datos</p>
                        :
                        <ul className="NearToExpire-list">
                            {
                                filteredCheckIns.map((checkIn) => {
                                    return (
                                        <li key={checkIn.id} className="NearToExpire-list-item">
                                            <div className="NearToExpire-list-text">
                                                <p className='name'>
                                                    <Link to={paths.frontDeskGuest
                                                        .replace(':visitId',checkIn.visit.id)}>
                                                        {guestFullName(checkIn.visit.guest).toUpperCase()}
                                                    </Link>
                                                </p>
                                                <p>{moment(checkIn.expirationDate).format('HH:mm')}</p>
                                            </div>
                                            <Button
                                                main
                                                data-visit={checkIn.visit.id}
                                                data-check={checkIn.id}
                                                disabled={loading}
                                                onClick={handleReCheckInClick}>
                                                Re-checkin
                                            </Button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                </div>
            {reChecking &&
                <CheckInFormModal
                    visit={reChecking.visit}
                    guest={reChecking.visit.guest}
                    oldCheckIn={reChecking.checkIn}
                    onClose={stopReChecking}
                    onFinish={finishedReChecking}
                />}
            </div>
    )
};
export default NearToExpire;
