import React,{useCallback, useContext,useState, useRef} from 'react';
import {ApiContext} from "../../services/api/api-config";
import {useSelector} from "react-redux";
import './Login.scss';
import LogoDavinci from './assets/LogoDavinci.png';
import Button from "../../components/Button/Button";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import {LOGIN_LOADING_ID} from "tide-api";
import KeyBoardNumeric from "../../components/KeyBoardNumeric/KeyBoardNumeric";



const Login=()=>{
    const api=useContext(ApiContext);
    const isLoading = useSelector(({loadingIds})=>!!loadingIds[LOGIN_LOADING_ID]);

    const [userValue,setUserValue] = useState('');
    const [passValue, setPassValue] = useState('');


    const onSubmit=useCallback((e)=>{
        e.preventDefault();
        api.login(userValue, passValue)
            .then(()=>{
                api.me.get();
                api.discoverLounge.get({preventNotifier: true});
            });
    },[api,userValue,passValue]);

    const loginState=useSelector(({api})=>api.loggedIn);

    const [focus,setFocus] = useState('noFocus');

    const userFocus=useRef(null);
    const passFocus=useRef(null);


    const isUserFocusHandler = useCallback(()=>{
        setFocus('user');
    },[]);

    const isPassFocusHandler = useCallback(()=>{
        setFocus('pass');
    },[]);

    const selectNumber = useCallback((e)=>{
        if(focus === 'user'){
            setUserValue( userValue + e.currentTarget.dataset.key);
            userFocus.current.focus();
        }else if(focus ==='pass'){
            setPassValue( passValue + e.currentTarget.dataset.key);
            passFocus.current.focus();
        }
    },[userValue,passValue,focus]);

    const backSpaceHandler = useCallback(()=>{
        if(focus === 'user'){
            const inputUserText = userValue.substring(0,userValue.length - 1);
            setUserValue(inputUserText)
        }else if(focus ==='pass'){
            const inputPassText = passValue.substring(0,passValue.length - 1);
            setPassValue(inputPassText)
        }
    },[userValue,passValue,focus]);

    const userHandler = useCallback((e)=>{
        setUserValue(e.currentTarget.value)
    },[]);

    const passHandler = useCallback((e)=>{
        setPassValue(e.currentTarget.value)
    },[]);

    return (
        <div className='Login'>
            <div className="Login-left left">
                <img className="Login-left-img" src={LogoDavinci} alt="Logo-davinci"/>
            </div>

            <div className="Login-right">
                <div className="Login-wrapper">
                    <p className="Login-title">¡Bienvenido!</p>
                    <form className="full-width" onSubmit={onSubmit}>
                        <input ref={userFocus} value={userValue} onChange={userHandler} onFocus={isUserFocusHandler} autoFocus  className="Login-input full-width"  placeholder='User'  autoCapitalize={'off'}/>
                        <input ref={passFocus} value={passValue} onChange={passHandler} onFocus={isPassFocusHandler}  className="Login-input full-width"  placeholder='Password' type='password'/>
                        <Button main className="full-width" type='submit' disabled={isLoading}>{isLoading?<LoadingAnimation/>:'Login'}</Button>
                    </form>
                    <p className="Login-status">{loginState==="NOT_LOGGED"?"":loginState==="BAD_CREDENTIALS"?"CORREO O CONTRASEÑA EQUIVOCADA":""}</p>
                    <KeyBoardNumeric
                        selectNumber={selectNumber}
                        backSpaceHandler={backSpaceHandler}
                    />
                </div>
            </div>
        </div>
    );
};
Login.displayName='Login';
export default Login;
