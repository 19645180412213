
export const prepareOrozcoFoodBarProductsForServer = (foodBarProducts)=>{
    return foodBarProducts
        .filter( fbp=>fbp.quantity )
        .map( fbp=>({
            ...fbp,
            orozcoProduct: fbp.orozcoProduct.id
        }));
};

export const orozcoFoodBarOrderMenuSGroups = [
    'orozco_food_bar_order_read_id',
    'orozco_food_bar_order_read_created_date',
    'orozco_food_bar_order_read_orozco_food_bar_order_products',
        'orozco_order_product_read_id',
        'orozco_order_product_read_data',
        'orozco_order_product_read_quantity',
        'orozco_order_product_read_created_date',
        'orozco_order_product_read_orozco_product',
            'orozco_product_read_id',
            'orozco_product_read_name',


];
