import React, {useState, useCallback, useContext} from 'react';
import './EditFlightModal.scss';
import Modal from "../../../../../../components/Modal/Modal";
import {visitFullSGroups} from "../../../../../../services/modelUtils/visitUtils";
import TideEntitySelect from "../../../../../../components/TideEntitySelect/TideEntitySelect";
import Button from "../../../../../../components/Button/Button";
import {ApiContext} from "../../../../../../services/api/api-config";
import {getNotifier} from "../../../../../../services/notifierUtils";
import {useSelector} from "react-redux";
import classNames from "classnames";

const EditFlightModal = ({closeFlightEditModal,flight,visitId,reload}) => {

    const [flightForm,setFlightForm] = useState(flight);
    const [animated, setAnimated]=useState('');

    const checkInLoadingId='@EditFlight.visit.update';
    const loading=useSelector(({loadingIds})=>!!loadingIds[checkInLoadingId]);

    const handleSimpleChange = useCallback((value)=>{
            setFlightForm(value)
    },[]);

    const api=useContext(ApiContext);
    const notifier=getNotifier();

    const handleEditFlight = useCallback(()=>{
            try {
                const flightId = flightForm.id;
                api.visits.update({
                    id:visitId,
                    params:{
                        flight:flightId,
                        sGroups:visitFullSGroups
                    }, loadingId: checkInLoadingId})
                    .then(()=>{
                        reload();
                        notifier.success('Vuelo editado');
                        setAnimated('animated bounceOutUp');
                        setTimeout(()=>{closeFlightEditModal();setAnimated('')},1000);
                    });
            }
            catch(e){
                notifier.error(e.message);
            }
        },[api,notifier,flightForm,closeFlightEditModal,visitId,reload]);

    return (
            <Modal
                title={"Editar Vuelo"}
                onClose={closeFlightEditModal}
            >
                <div className={classNames("EditFlightModal",animated)}>
                    <div className="form-row-single">
                        <p>Selecciona un vuelo:</p><br/>
                        <TideEntitySelect
                            entity='flights'
                            value={flightForm}
                            onChange={handleSimpleChange}
                            labelCreator={f=>`${f.airline?f.airline.name:'AEROLÍNEA NO REGISTRADA'} - ${f.number} - ${f.destination}`}
                            apiCustomProp={'FullFlights'}
                            filterLocal
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-column full-width">
                            <Button className="full-width" solid onClick={handleEditFlight} disabled={loading}>Editar vuelo</Button>
                        </div>
                    </div>
                </div>
            </Modal>
    )
};
export default EditFlightModal
