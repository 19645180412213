import React, {useCallback, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import TideEntitySelect from "../../../components/TideEntitySelect/TideEntitySelect";
import useActualTicketPrinter from "../../../hooks/useActualTicketPrinter";
import useActualLounge from "../../../hooks/useActualLounge";

const TicketPrinterConfig = ({title, storageKey}) => {
    const lounge = useActualLounge();
    const [actualTicketPrinter] = useActualTicketPrinter({localStorageKey: storageKey});
    const [selectedPrinter, setSelectedPrinter] = useState(actualTicketPrinter);
    const handleChange=useCallback((ticketPrinter)=>{
        setSelectedPrinter(ticketPrinter);
        const newValue=JSON.stringify(ticketPrinter);
        window.localStorage.setItem(storageKey, newValue);
        const event=new StorageEvent('storage', {key: storageKey, oldValue: null, newValue });
        window.dispatchEvent(event);
    },[storageKey, setSelectedPrinter]);

    return (
        <div className='config-block CashRegisterConfig'>
            <div className='config-title'>
                {title}
            </div>
            <div className='config-content'>
                <div className='left'>
                    <div className='icon'><FontAwesomeIcon icon={faPrint}/></div>
                </div>
                <div className='right'>
                    <p className='sub-title'>Impresora actual</p>
                    <h3 className='actual-name'>{selectedPrinter? selectedPrinter['name'] : 'Sin registrar'}</h3>
                </div>
                <div className='bottom'>
                    <p>Cambiar de impresora</p>
                    <TideEntitySelect
                        entity={'ticketPrinters'}
                        onChange={handleChange}
                        additionalFilters={{lounge: lounge.id}}
                    />
                </div>
            </div>
        </div>
    );
};

export default TicketPrinterConfig;
