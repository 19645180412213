import {copyPropertyIdOrNull} from "../formUtils";
import {
    OROZCO_PRODUCT_SPECIAL_ID,
    isPricedProduct,
    isPricedProductAndShouldBePayed,
    isPricedProductButCanBeFree
} from "./orozcoProductUtils";
import _ from 'lodash';
import {getVisitAvailableFreeFreeMeals} from "./visitUtils";


export const updateProductsPaymentsSGroups = [
    'orozco_visit_order_read_orozco_visit_order_products',
    'orozco_visit_order_product_read_id',
    'orozco_visit_order_read_orozco_visit_order_payments',
    'orozco_payment_read_id',
    'orozco_visit_order_product_read',
    'orozco_visit_order_product_read_orozco_product',
    'orozco_visit_order_product_read_data',
    'orozco_product_read'
];

export const emptyOrozcoVisitOrder = (visit) => ({
    orozcoVisitOrderProducts: [],
    scheduledServices: [],
    visit
});

const productsHaveSameModifiers = (x, y) =>
    _.isEqual(x.orozcoProductModifiers, y.orozcoProductModifiers);

const sameOrozcoProduct = (x, y) =>
    x.orozcoProduct === y.orozcoProduct;

const samePayableStatus = (x, y) =>
    x.shouldBePayed === y.shouldBePayed;

const sameNotes = (x, y) =>
    x.notes === y.notes;

const accumulateSameProducts = products => {
    if (products.length === 0) {
        return [];
    }
    const firstProduct = products[0];
    const tail = products.slice(1);
    if (typeof firstProduct === 'string') { // id of previous order, we'll just add it
        return [firstProduct, ...accumulateSameProducts(tail)];
    }
    // Also if this product is a special product, we won't consider
    // duplicates (this is taken care on selection flow)
    if (firstProduct.orozcoProduct === OROZCO_PRODUCT_SPECIAL_ID) {
        return [firstProduct, ...accumulateSameProducts(tail)];
    }

    // if not, this is a new product, we'll check for duplicates.
    // A duplicate is a order product with the same product,
    // same modifiers and same payable status
    const duplicateIndex = _.findIndex(tail, t => typeof t === 'object' ?
        productsHaveSameModifiers(t, firstProduct) &&
        sameOrozcoProduct(t, firstProduct) &&
        samePayableStatus(t, firstProduct) &&
        sameNotes(t, firstProduct)
                                       : false
    );
    if (duplicateIndex === -1) {
        // No duplicates
        return [firstProduct, ...accumulateSameProducts(tail)];
    }

    const tailWithoutDuplicate = [
        ...tail.slice(0, duplicateIndex),
        ...tail.slice(duplicateIndex + 1)
    ];
    // If a duplicate is found, we'll combine them (adding their qtys)
    return accumulateSameProducts([
        {
            ...firstProduct,
            quantity: firstProduct.quantity + tail[duplicateIndex].quantity
        },
        ...tailWithoutDuplicate
    ]);
};

export const prepareOrozcoVisitOrderForServer = (orozcoVisitOrder) => {
    const prepared = {...orozcoVisitOrder, sGroups: addingVisitOrderSGroups};

    copyPropertyIdOrNull(orozcoVisitOrder, prepared, 'visit');
    //Change orderProduct.orozcoProduct objects for the ids
    prepared.orozcoVisitOrderProducts = accumulateSameProducts(
        prepared.orozcoVisitOrderProducts.map(orderProduct => (
            typeof orderProduct === 'string' ?
                orderProduct : {...orderProduct, orozcoProduct: orderProduct.orozcoProduct.id})
        )
    );
    return prepared;
};


export const getPricedMealsThatCanBeFreeCountFromVisitOrder = (orozcoVisitOrder) => {

    return getPricedMealsThatCanBeFreeFromVisitOrder(orozcoVisitOrder)
        .reduce((acc, orderProduct) => acc + orderProduct.quantity, 0);
};

export const getPricedMealsThatCanBeFreeFromVisitOrder = (orozcoVisitOrder) => {
    if (!orozcoVisitOrder || !orozcoVisitOrder.orozcoVisitOrderProducts || !orozcoVisitOrder.orozcoVisitOrderProducts.length)
        return [];
    return _.filter(orozcoVisitOrder.orozcoVisitOrderProducts, (orderProduct) =>
        !orderProduct.canceledDate &&
        !orderProduct.payed &&
        isPricedProductButCanBeFree(orderProduct.orozcoProduct) &&
        !( orderProduct.shouldBePayed )
    );
};

export const getPricedMealsThatShouldBePayedFromVisitOrder = (orozcoVisitOrder) => {

    if (!orozcoVisitOrder || !orozcoVisitOrder.orozcoVisitOrderProducts || !orozcoVisitOrder.orozcoVisitOrderProducts.length)
        return [];
    return _.filter(orozcoVisitOrder.orozcoVisitOrderProducts, (orderProduct) => !orderProduct.canceledDate && isPricedProductAndShouldBePayed(orderProduct.orozcoProduct));
};

/**
 * Checks all the orderProducts that should be payed and sets the shouldBePayed flag to true on them
 * and to false in all the others.
 *
 * @param selectedOrderProducts
 * @param orozcoVisit
 * @param orozcoVisitOrder if sent, the used product counts will be started from here, instead of from zero
 * @returns {*}
 */
export const setPayableFlagOnSelectedOrderProducts = (selectedOrderProducts, orozcoVisit, orozcoVisitOrder) => {
    const availableMeals = getVisitAvailableFreeFreeMeals(orozcoVisit);
    const usedMeals = orozcoVisitOrder ? getPricedMealsThatCanBeFreeCountFromVisitOrder(orozcoVisitOrder) : 0;

    let freeMeals = availableMeals - usedMeals;

    //Flag all orderProduct for whether should be paid or not
    //If they ask for 3 dishes and 2 can be free but the 3rd can't, we split the order in two. So we can mark one
    //as payable and the others as free.
    const flaggedOrderProducts = [];
    _.forEach(selectedOrderProducts, (orderProduct) => {
        const newOrderProduct = {...orderProduct, payed: !!orderProduct.payed};

        if (newOrderProduct.canceledDate || newOrderProduct.payed) {//If it's canceled or payed, do nothing
            return flaggedOrderProducts.push(newOrderProduct);
        } else if (!isPricedProduct(newOrderProduct.orozcoProduct)) {//If it's a free product
            newOrderProduct.shouldBePayed = false;
        } else if (isPricedProductAndShouldBePayed(newOrderProduct.orozcoProduct)) {//If it's a product that should always be paid
            newOrderProduct.shouldBePayed = true;
        } else if (freeMeals > 0) {//If the product applies to be a free meal
            if (freeMeals >= newOrderProduct.quantity) {
                freeMeals -= newOrderProduct.quantity;
                newOrderProduct.shouldBePayed = false;
            } else {//Here we split the orderProduct
                flaggedOrderProducts.push({
                    ...orderProduct,
                    quantity: newOrderProduct.quantity - freeMeals,
                    shouldBePayed: true,
                    payed: false,
                    id: undefined
                });
                newOrderProduct.quantity = freeMeals;
                newOrderProduct.shouldBePayed = false;
            }
        } else {//If the product could be free meal, but the available free meals are already used
            newOrderProduct.shouldBePayed = true;
        }

        flaggedOrderProducts.push(newOrderProduct);
    });

    return flaggedOrderProducts;
};

/**
 * Re-calculates the payable flag. Useful when cancelling some products and all payable flags should be re-calculated
 *
 * @param orozcoVisitOrderProducts
 * @param orozcoVisit
 */
export const setPayableFlagOnOrderProducts = (orozcoVisitOrderProducts, orozcoVisit) => {

};

export const removeFreeProductsFromOrder = (orozcoVisitOrderProducts, countToRemove) => {
    const orderProducts = [...orozcoVisitOrderProducts].reverse();
    let newOrderProducts = [];
    for (let i = 0; i < orderProducts.length; i++) {
        if (countToRemove - orderProducts[i].quantity === 0) {
            newOrderProducts = orderProducts.slice(i + 1);
            break;
        } else if (countToRemove - orderProducts[i].quantity < 0) {
            newOrderProducts = orderProducts.slice(i + 1);
            const changedOrderProduct = {...orderProducts[i], quantity: orderProducts[i].quantity - countToRemove};
            newOrderProducts.unshift(changedOrderProduct);
            break;
        } else
            countToRemove -= orderProducts[i].quantity;
    }

    return newOrderProducts.reverse();
};


export const addingVisitOrderSGroups = [
    'orozco_visit_order_read',
    'orozco_visit_order_read_orozco_visit_order_products',
    'orozco_visit_order_product_read',
    'orozco_visit_order_product_read_orozco_product',
    'orozco_visit_order_product_read_data',
    'orozco_product_read',
    "orozco_visit_order_read_notes",
];

export const cancelingVisitOrderProductsSGroups = [
    'orozco_visit_order_read',
    'orozco_visit_order_read_orozco_visit_order_products',
        'orozco_visit_order_product_read',
    'orozco_visit_order_read_orozco_visit_order_payments',
        'orozco_payment_read'
];
