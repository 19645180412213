import {useState, useCallback, useEffect, useRef} from 'react'

function useTimer() {
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [milliseconds, setMilliseconds] = useState(0); 
    const [startTime, setStartTime] = useState();

    const timer = useRef();

    const updateTimer = useCallback(() => {
        const currentTime = new Date().getTime();
        const startTimerInterval = startTime || currentTime;
        const ms = currentTime - startTimerInterval;
    
        setMilliseconds(ms);

        if(!startTime){
            setStartTime(startTimerInterval)
        }
    }, [startTime]);

    const clearTimer = useCallback(() => {
        clearInterval(timer.current);
        timer.current = null;
    }, []);

    const reset = useCallback(() => {
        setMilliseconds(0);
        setStartTime(null);
        setIsTimerActive(false);
    }, []);

    const stop = useCallback(() => {
        setIsTimerActive(false);
    }, []);

    const start = useCallback(() => {
        setStartTime(null);
        setIsTimerActive(true);
    }, []);

    useEffect(() => {
        if(isTimerActive && !timer.current){
            timer.current = setInterval(updateTimer, 500);
        } else if(!isTimerActive) {
            clearTimer();
        }

        return() => clearTimer();
    }, [isTimerActive, updateTimer, clearTimer]);

    return {milliseconds, reset, stop, start};
}

export {useTimer};