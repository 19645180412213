import React,{useState,useCallback,useContext} from 'react';
import './EditInfoModal.scss';
import Modal from "../../../../components/Modal/Modal";
import {editGuest, guestFullName, prepareEditGuestInfoForServer} from "../../../../services/modelUtils/guestUtils";
import GuestForm from "../GuestForm/GuestForm";
import {onlyNumbers, onlyText, validEmail} from "../../../../services/inputUtils";
import useConstantRandoms from "../../../../hooks/useConstantRandoms";
import useBoolean from "../../../../hooks/useBoolean";
import useCallbackCreator from "use-callback-creator/src";
import classNames from "classnames";
import Button from "../../../../components/Button/Button";
import {ApiContext} from "../../../../services/api/api-config";
import {getNotifier} from "../../../../services/notifierUtils";
import {useSelector} from "react-redux";
import {useActualLoungeOccupation} from "../../../../services/modelUtils/loungeUtils";


const EditInfoModal = ({closeEditModal,guest,reload}) => {
    /*    guest.visits[0].flights*/
    const checkInLoadingId='@EditGuestInfo.guest.update';
    // --------   Handle inputs change  -------
    const [editForm, setEditForm]=useState(editGuest(guest));
    const [animated, setAnimated]=useState('');

    const api=useContext(ApiContext);
    const notifier=getNotifier();

    const handleInputChange=useCallback(({target})=>{
        if(target.name === 'name' || target.name === 'patLastName'){
            setEditForm({...editForm, [target.name]:onlyText(target.value)});
        }else if(target.name === "phone"){
            setEditForm({...editForm, [target.name]:onlyNumbers(target.value)});
        }else if(target.name === 'email'){
            setEditForm({...editForm, [target.name]:target.value});
        }
        else{
            setEditForm({...editForm, [target.name]:target.value});
        }


    },[editForm]);

    const constantRandom=useConstantRandoms();
    const [editBtn,setEditBtnBlock,setEditBtn] = useBoolean();
    const verifyEmail = useCallback((value)=>{
        if(validEmail(value) || value.currentTarget.value === ""){
            setEditBtn()
        }else{
            setEditBtnBlock()
        }
    },[setEditBtn,setEditBtnBlock]);

    const handleSimpleChange=useCallbackCreator((prop,value)=>{
        setEditForm({...editForm, [prop]:value});
    },[editForm]);


    const {refreshOccupation}=useActualLoungeOccupation();


    const handleEditGuestInfo=useCallback(()=>{
        try {
            const guestInfoEdited = prepareEditGuestInfoForServer(editForm);
            /*const guestInfoEdited = {...editForm, "country":editForm.country.id};*/

            api.guests.update({
                id:guestInfoEdited.id,
                params:{
                    ...guestInfoEdited,
                    sGroups:[]
                }, loadingId: checkInLoadingId})
                .then(()=>{
                    refreshOccupation();
                    reload();
                    notifier.success('Información de huésped editada');
                    setAnimated('animated bounceOutUp');
                    setTimeout(()=>{closeEditModal();setAnimated('')},1000);
                });
        }
        catch(e){
            notifier.error(e.message);
        }
    },[api, editForm,notifier,refreshOccupation,closeEditModal,reload]);


    const loading=useSelector(({loadingIds})=>!!loadingIds[checkInLoadingId]);

    return (
        <Modal
            title={guestFullName(guest).toUpperCase()}
            onClose={closeEditModal}
        >
            <div className={classNames("NewReservation NewReservation-fullWidth", animated)}>
            <div className="NewReservation-form full-width">
                <GuestForm
                form={editForm}
                handleInputChange={handleInputChange}
                constantRandom={constantRandom}
                checkInBtn={editBtn}
                verifyEmail={verifyEmail}
                handleSimpleChange={handleSimpleChange}
                editingGuestInfo
                />
                <hr/><br/>
                <div className="form-row">
                    <div className="form-column full-width">
                        <Button solid onClick={handleEditGuestInfo} disabled={loading || editBtn}>Editar</Button>
                    </div>
                </div>
            </div>
            </div>
        </Modal>
    )
};
export default EditInfoModal
