import React,{useEffect, useState} from 'react';
import './TopBarClock.scss';
import moment from "moment";

const TopBarClock = ()=>{
    const [timeString, setTimeString]=useState(moment().format('HH:mm'));

    useEffect(()=>{
        const timeInterval = setInterval(()=>{
            setTimeString(moment().format('HH:mm'));
        }, 10000);
        return ()=>clearInterval(timeInterval);
    },[]);

    return(
        <div className="TopBar-time">
            <p>{timeString}</p>
        </div>
    )
};
export default TopBarClock;