import React from 'react';
import {getNotifier} from "../notifierUtils";
import join from 'url-join';
import {version} from '../../pckg';
import compareVersions from 'compare-versions';
import {latestAppVersionAction} from "../redux/latestAppVersionReducer";
import {employeeFullName} from "../modelUtils/employeeUtils";
import {isRemoteDavinci} from "../remoteDavinciUtils";


const addSwitchUserHeader = (headers, options) => {
    if (localStorage.switchUser && !options.config.preventUserSwitch)
        headers["x-view-analytics"] = window.localStorage.switchUser;
};

function errorHandler(e, callParams) {
    if (!callParams?.config?.preventNotifier)
        getNotifier().error((e && e.detail) || 'Parece que hubo un error', 'Uh Oh!');
}

let latestVersion = version;

function dataFromResponse(response, headers) {
    handleReceivedVersion(headers && headers.get('x-davinci-app-version'), this.store);
    return response && response.data;
}

const config = {
    host: 'https://gledev.tide.mx',
    commonPath: 'api',
    credentials: 'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: dataFromResponse,
    getMetaDataFromResponse: (r) => r.meta,
    appendHeaders: addSwitchUserHeader,
    onError: errorHandler,
    endpoints: [
        'airlines',
        'banks',
        'checkIns',
        'countries',
        'currencyExchanges',
        'employeeAttendingVisits',
        'flightDelayStatuses',
        'guests',
        'loungeAccessMethods',
        'loungeMaps',
        'lounges',
        'me',
        'orozcoEmployeeProfiles',
        'orozcoFoodBarOrders',
        'orozcoFoodBars',
        'orozcoProductCategories',
        'orozcoProducts',
        'orozcoVisitOrders',
        'orozcoPrints',
        'reservations',
        'seats',
        'services',
        'serviceRequests',
        'serviceVisits',
        'tables',
        'ticketPrinters',
        'users',
        'visits',
        {
            name: 'appFiles',
            preventDefaultMethods: true,
            customMethods: {
                getFileUrl: function (appFile) {
                    return join(this.host, 'file', appFile.id + '')
                }
            }
        },
        {
            name: 'comments',
            customMethods: {
                createForVisit: function ({visit, text, customProp = 'visitComment', ...config}) {
                    return this.apiCall( '/comments/by_visit', customProp, {visit, text}, {method:'POST',...config} )
                }
            }
        },
        {
            name: 'configurations',
            preventDefaultMethods: true,
            customMethods: {
                find: function ({id, customProp = 'configurations'}) {
                    return this.apiCall(
                        join('configurations', id),
                        customProp,
                        {}, {}
                    )
                }
            }
        },
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function (error, stack, user, additionalInfo) {

                    let params = {stack};
                    if (error && error.message)
                        params.error = error.message;

                    if (user) {
                        params.user = {id: user.id, username: user.username};

                        if (user.employee) {
                            params.employee = {
                                id: user.employee.id, name: employeeFullName(user.employee)
                            }
                        }
                    }

                    if (additionalInfo)
                        params = {...params, ...additionalInfo};

                    return this.apiCall('/frontend_error', "error_sent", params, {
                        method: "POST",
                        queueable: true,
                        useCommonPath: false
                    })
                }
            }
        },
        {
            name: 'flights',
            customMethods: {
                findWithAlias: function ({params, customProp = 'flights', config={}}) {
                    return this.apiCall('/flights/find_with_alias', customProp, params, config);
                }
            }
        },
        {
            name: 'orozcoCashRegisters',
            customMethods: {
                currentMoney: function ({id, customProp = 'customMethods.currentMoney'}) {
                    return this.apiCall(
                        join('orozco_cash_registers', id, 'current_money'),
                        customProp,
                        {}, {}
                    );
                }
            }
        },
        {
            name: 'orozcoCashClosings',
            customMethods: {
                currentShiftCashClosing: function ({customProp = 'customMethods.currentShiftCashClosing'}) {
                    return this.apiCall(
                        join('orozco_cash_closings', 'current_shift_cash'),
                        customProp,
                        {}, {}
                    );
                }
            }
        },
        {
            name: 'discoverLounge',
            useCommonPath: false
        },
        {
            name: 'personsWithoutDrinks',
            customMethods: {
                getPersonsWithoutDrinks: function ({params={}, ...config}={}) {
                    return this.apiCall('/visits/persons_without_drinks', '', params || {}, config);
                }
            }
        },
        {
            name: 'personsWithoutSeat',
            customMethods: {
                getPersonsWithoutSeat: function ({params={}, ...config}={}) {
                    return this.apiCall('/visits/persons_without_seat', '', params || {}, config);
                }
            }
        },
        {
            name: 'personsWithoutSeatCount',
            customMethods: {
                getPersonsWithoutSeatCount: function ({params={}, ...config}={}) {
                    return this.apiCall('/visits/persons_without_seat_count', '', params || {}, config);
                }
            }
        },
    ],
};

const handleReceivedVersion = (versionHeader, store) => {

    if (versionHeader === latestVersion || !versionHeader || typeof versionHeader !== 'string')
        return;

    if (compareVersions(versionHeader, version) !== 1)
        return;

    console.log("Newer version detected " + versionHeader);

    if (window.swRegistration)
        window.swRegistration.update();

    latestVersion = versionHeader;

    store.dispatch(latestAppVersionAction(versionHeader));
};

if (process.env.REACT_APP_BUILD === 'dev')
    config.host = localStorage.host || 'https://gle-back.tide.company';

if (process.env.REACT_APP_BUILD === 'staging')
    config.host = 'https://davinci-pruebas-back.tide.company';
else if (process.env.REACT_APP_BUILD === 'test')
    config.host = localStorage.host || 'https://davinci-pruebas-back.tide.company';

if (process.env.REACT_APP_BUILD === 'prod')
    config.host = localStorage.host || 'https://s1.tgle.mx';

if (process.env.REACT_APP_BUILD === 'prod' && isRemoteDavinci())
    config.host = localStorage.host || 'https://back.tgle.mx';

export default config;

export const ApiContext = React.createContext(null);
