import React,{useState} from 'react';
import './NonGrataAndVipModal.scss';
import Modal from "../../Modal/Modal";
import Button from "../../Button/Button";
import Select from "react-select";
import moment from 'moment';
import reactSelectStyles from "../../../assets/styles/react-select-styles";

const nonGrataLevels = new Array(5).fill('').map((_,i)=>i+1);
const nonGrataLevelOptions = nonGrataLevels.map(i=>({value:i,label:i}));


const NonGrataAndVipModal = ({updateGuest, closeNonGrataAndVipModal, nonGrataModal}) => {
    const [nonGrataLevel,setNonGrataLevel] = useState(nonGrataLevelOptions[0]);
    const [reason, setReason] = useState('');

    const handleLevelChange=target=>{
        setNonGrataLevel(target);
    };

    const changeReason = e=>setReason(e.target.value);

    const saveNonGrata = () => {
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        updateGuest({
            nonGrataSince: now,
            nonGrataReason: reason,
            nonGrataLevel: nonGrataLevel.value
        })();
        closeNonGrataAndVipModal();
    };
    const saveVip = () => {
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        updateGuest({
            vipSince: now,
            vipReason: reason,
        })();
        closeNonGrataAndVipModal();
    };

    const saveGuestStatus = nonGrataModal?saveNonGrata:saveVip;

    return (
        <Modal
            title={"Especifica el motivo"}
            onClose={closeNonGrataAndVipModal}
        >
            {nonGrataModal &&

                <Select
                    styles={reactSelectStyles}
                    options={nonGrataLevelOptions}
                    placeholder={"Nivel de non grata"}
                    isSearchable={false}
                    value={nonGrataLevel}
                    onChange={handleLevelChange}
                />

            }
            <textarea onChange={changeReason} value={reason} placeholder="Especifica el motivo..." className="CommentBox-textArea NonGrataAndVipModalCommentBox full-width"/>
            <Button disabled={!reason} onClick={saveGuestStatus} className="NonGrataAndVipModalBtn" main blue>Guardar</Button>
        </Modal>
    )
};
export default NonGrataAndVipModal;
