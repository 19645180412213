import React, {useCallback, useState} from 'react';
import './DocumentReaderModal.scss';
import Modal from "../Modal/Modal";
import MrzScanner from "./components/MrzScanner/MrzScanner";
import BoardingPassReader from "./components/BoardingPassReader/BoardingPassReader";
import Button from "../Button/Button";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShare} from "@fortawesome/free-solid-svg-icons/faShare";

const DocumentReaderModal = ({ onClose, onScan }) => {

    const [scanner, setScanner] = useState( 'boardingPass' );

    const onBoardingPassScan = useCallback((data)=>{
        onScan(data);
        setScanner('mrz');
    },[onScan]);

    const onMrzScan = useCallback((data)=>{
        onClose();
        onScan(data);
    },[onScan, onClose]);

    return (
        <Modal
            className='DocumentReaderModal'
            title='Registro por Documento'
            bsSize="lg"
            onClose={onClose}
        >
            <h3 className='text-center'>Asegura que el documento esté bien alineado con la cámara.</h3>

            {scanner==='boardingPass'&&<>
                <BoardingPassReader onScan={onBoardingPassScan} />
                <Button solid className={'m-auto'}
                        onClick={()=>setScanner('mrz')}>
                    Saltar pase de abordar &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faShare}/>
                </Button>
            </>}

            {scanner=== 'mrz' &&
            <MrzScanner onScan={onMrzScan} />}

        </Modal>
    );
};

export default DocumentReaderModal;
