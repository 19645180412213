const BalloonsConfig =
    [...new Array(20)].map(()=> {
        return (
            {
                color: `hsl(${Math.random() * 360},100%,80%)`,
                position: `${Math.floor(Math.random() * (100 - 10) + 10)}%`,
                delay: `${Math.random() * 7000}ms`

            }
        )
    });
export default BalloonsConfig;

/*
const BalloonsConfig =
    [
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`

        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },

        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color: `hsl(${Math.random() * 360},100%,80%)`,
            position: `${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay: `${Math.random()*7000 + Math.random()*500}ms`
        },
        {
            color:`hsl(${Math.random() * 360},100%,80%)`,
            position:`${Math.floor(Math.random()* (100 - 10) + 10)}%`,
            delay:`${Math.random()*7000 + Math.random()*500}ms`
        }
];
export default BalloonsConfig;*/
