import React,{useCallback,useState} from 'react';
import './Bill.scss';
import {ReactComponent as DollarIcon} from "../../../../../assets/images/icons/dollarIcon.svg";
import {moneyFormatter} from "../../../../../services/currency/currencyUtils";
import useExchangeRates from "../../../../../hooks/useExchangeRates";
import {getVisitUnpaidAmount} from "../../../../../services/modelUtils/visitUtils";
import {ReactComponent as MxFlag} from "../../../../../assets/images/icons/mexicoFlag.svg";
import {ReactComponent as UsaFlag} from '../../../../../assets/images/icons/usaFlag.svg';
import {ReactComponent as EuFlag} from "../../../../../assets/images/icons/euFlag.svg";
import classNames from 'classnames';


const Bill = ({visit, currency, onCurrencyChange, onPayClick})=>{


    //------- uncontrolled state ----------
    const [currencyState, setCurrencyState]=useState('MXN');
    const handleCurrencyChange=useCallback((e)=>{
        if(onCurrencyChange){
            onCurrencyChange(e.currentTarget.dataset.currency);
        }else
            setCurrencyState(e.currentTarget.dataset.currency)
    },[onCurrencyChange]);

    const usedCurrency=currency||currencyState;
    const [exchangeRates, loadingExchangeRates]=useExchangeRates();
    if(!visit || loadingExchangeRates){
        return null;
    }

    const amount=getVisitUnpaidAmount(visit, usedCurrency, exchangeRates);

    return(
        <div className="Bill">
                <div className="Bill-text">{amount?
                    <div className='bill-amount' onClick={onPayClick}>
                        <p><DollarIcon className={'dollar-icon'}/>{moneyFormatter(amount)}</p>
                        {onPayClick&&<p className='charge-text'>Cobrar</p>}
                    </div>:
                    'Sin cobros'}
                </div>

            <button
                onClick={handleCurrencyChange}
                data-currency={'MXN'}
                className={classNames('flag-btn', usedCurrency==='MXN'?'selected':usedCurrency==='USD'?'flag-1':'flag-2')}>
                <MxFlag/>
            </button>
            <button
                onClick={handleCurrencyChange}
                data-currency={'USD'}
                className={classNames('flag-btn', usedCurrency==='USD'?'selected':usedCurrency==='EUR'?'flag-1':'flag-2')}>
                <UsaFlag/>
            </button>
            <button
                onClick={handleCurrencyChange}
                data-currency={'EUR'}
                className={classNames('flag-btn', usedCurrency==='EUR'?'selected':usedCurrency==='MXN'?'flag-1':'flag-2')}>
                <EuFlag/>
            </button>
        </div>
    )
};
export default Bill;
