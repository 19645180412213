import React from 'react';
import './Modal.scss';
import useBackgroundBlur from "../../hooks/useBackgroundBlur";
import {createPortal} from "react-dom";
import classNames from 'classnames';

const Modal = ({onClose,title,children, className}) => {
    useBackgroundBlur();
    return createPortal(
        <>
            <div className='Modal-overlay' onClick={onClose}/>
            <div className='Modal-container'>
                <div  className={classNames("Modal animated bounceInDown faster", className)}>
                    <div onClick={onClose} className="Modal-closeCross"/>
                    <div className="Modal-title">
                        <p>{title}</p>
                    </div>
                    <div className="Modal-template">
                        {children}
                    </div>
                </div>
            </div>
        </>
        , document.body)
};
export default Modal;
