import _ from "lodash";

const idsSubServices = s => {
    if(s.subServices.length===0){
        return s.id;
    }
    return s.subServices.map(idsSubServices);
};

const subServicesIds = s => _.flatMapDeep(s, idsSubServices);

const serviceDefinition = s=>{
    if(s.subServices.length === 0){
        return s;
    }
    return s.subServices.map(z=>({
        ...z,
        name: s.name+' - '+z.name,
        ...(s.onePerPerson?{
            onePerPersonServices: subServicesIds(s.subServices)
        }:{}),
    })).map(serviceDefinition);
};

export const flattenServices = services=>
    _.flatMapDeep(services, serviceDefinition);

export const servicesListSGroups = [
    'service_read_id',
    'service_read_name',
    'service_read_sub_service'
];
